import React, { Fragment, useEffect, useState } from 'react';
import { capitalizeFirstLetter, forceString } from '../../../../functions/StrFunctions';
import Inputs from '../../../../common/smallComponents.js/Inputs';
import { setPopUp } from '../../../../functions/PageAnimation';
import errorManagement from '../../../../services/errorManagement';
import BomDaoV3 from '../../../../services/machines/BomDaoV3';
import { v4 as uuidv4 } from 'uuid';
import { ProgressBar } from 'react-bootstrap';
import WaitingRoundSnippers from '../../../../common/smallComponents.js/WaitingRoundSnippers';

const InjectInFocus = ({ bom_content, focus_content, type_of_bom, machine_info, bom_planning, setBomPlanning, type_kit }) => {
    const [loading, setLoading] = useState(false);
    const [arr, setArr] = useState([]);
    const [progress, setProgress] = useState();
    const [progressMsg, setProgressMsg] = useState();
    const [msg, setMsg] = useState();
    const [done, setDone] = useState(false);
    const [warning, setWarning] = useState(false);

    useEffect(() => {
        // Do not delete AV
        var t = [];
        var v;
        var mach_line = machine_info.no_ligne;
        var err = undefined;
        
        
        if (bom_content){
            for (let i = 0; i < bom_content.length; i++) {
                v = bom_content[i];
               
                if( ( !isEg( v.ref ) && v.delta !== 0 ) || ( isEg( v.ref ) && v.delta > 0)  )
                    t.push( bom_content[i] );
                
                if( isEg( v.ref ) ){
                    var se_line = v.ref.split("-")[2].substring(0, 1);
                    if ( forceString( mach_line ) !== forceString( se_line ) ) setWarning(true);
                }
            }

            setArr( t );
        }
        
    }, []);

    function isEg(ref){ return ref.startsWith("NM-") || ref.startsWith("NE-") }

    const injectNewMachine = async () => {

        setLoading(true);

        try {

            const uniqueId = uuidv4();
            setProgress(0);
            setProgressMsg("Injection des AV 1 / 2")
            await BomDaoV3.firstInjectionFocus(type_of_bom, bom_content, machine_info, "favirdc", uniqueId, "")

            setProgress(50);
            setProgressMsg("Injection des AV 2 / 2")
            await BomDaoV3.firstInjectionFocus(type_of_bom, bom_content, machine_info, "favirdt", uniqueId, "")

            //Remove doublon spares

            setProgress();
            setDone(true);

            // Change status
            var o = { ...bom_planning };
            o.status = "Injecté dans Focus";
            BomDaoV3.updateBomStatus(o);
            setBomPlanning(o);

            var business = machine_info.affaire;
            business = business.substring(0, business.length - 1) + "H";
            setMsg("Rendez vous dans l'affaire: " + business + " et ajoutez la machine");

            setLoading(false);
        } catch (error) {
            setLoading(false);
            setPopUp(errorManagement.getErrorMsg(error), "danger", 5000)
            setProgress();
        }

    }

    function getMsg(v) {
        if (v.qty === 0) {
            return "A <strong>supprimer</strong> dans Focus"
        } else if (v.focus_qty === 0) {
            return "A <strong>ajouter</strong> dans Focus"
        } else {
            return "Qté à <strong>modifier</strong> dans Focus"
        }
    }

    const validateChange = (e, k) => {
        var t = [...arr];
        if (e.target.checked) {
            t[k].checked = true;
        } else {
            delete t[k].checked;
        }

        setArr(t);
    }

    const validateAllChange = (e) => {
        var t = [...arr];
        if (e.target.checked) {
            for (let i = 0; i < t.length; i++) t[i].checked = true;
        } else {
            for (let i = 0; i < t.length; i++) delete t[i].checked;
        }

        setArr(t);
    }

    const injectChange = async () => {
        try {
            //Inject NM first
            var nm = arr.filter( v => v.ar.startsWith( "AV_" ) )
            var p = ( ( nm.length / arr.length ) / 2 ) * 100
            
            const uniqueId = uuidv4();
            setProgress(p);
            if( nm.length > 0 ){
                setProgressMsg("Injection des AV 1 / 2")
                await BomDaoV3.firstInjectionFocus(type_of_bom, nm, machine_info, "favirdc", uniqueId, "")
               
                p = p * 2;
                setProgress(p);
                setProgressMsg("Injection des AV 2 / 2")
                await BomDaoV3.firstInjectionFocus(type_of_bom, nm, machine_info, "favirdt", uniqueId, "")
            }else{
                p = p * 2;
            }
           

            //Then articles
            nm = arr.filter( v => !v.ar.startsWith( "AV_" ) );
            var o;
            for( let i = 0; i < nm.length; i++ ){
                var temp = [...arr];
                o = nm[i];
                setProgress( ( p + ( ( i / nm.length ) * 100 ) ).toFixed(0) );
                setProgressMsg( ( o.focus_qty === 0 ? "Ajout de l'" : "Suppression de l'" ) + o.ar 
                    + " dans Focus")
                var res = await BomDaoV3.updateFocusBom(type_of_bom, o, machine_info, "favirdt", uniqueId, "")
                var idx = arr.findIndex( v => o.ar === v.ar && o.rep === v.rep  );
              
                if( idx !== -1 ) temp[idx].log = res.join("<br>");
                setArr( temp )
            }

            setProgress();
            setProgressMsg();
            setDone(true);

        } catch (error) {
            setLoading(false);
            setPopUp(errorManagement.getErrorMsg(error), "danger", 5000)
            setProgress();
        }        
    }

    return (
        warning ?
        <div className='alert alert-warning text-center fw-bold'>
            <h1>ATTENTION: cette machine dépend d'une autre ligne, confirmez-vous avoir traité la machine 
                précédente ?</h1>

            <div className='text-center mt-5'>
                <button className='btn btn-success'
                    onClick={() =>setWarning()}>Je confirme et souhaite poursuivre l'injection</button>
            </div>
        </div>
        :<div>
            { /** FIRST INJECTION */}
            {focus_content.length === 0 && !done && <div className='card ms-auto me-auto' style={{ width: "500px" }}>
                <h5 className='text-center'>Première injection {type_of_bom} complète dans Focus</h5>

                {progress !== undefined &&
                    <div className='alert alert-info fw-bold text-center'>
                        {progressMsg}
                        <ProgressBar label={progress + ' %'} animated now={progress} />
                    </div>}

                {(machine_info && forceString(machine_info.lot_piece_det).startsWith("Vendu")) &&
                    <div className='text-center fw-bold'>
                        {capitalizeFirstLetter(machine_info.lot_piece_det.replace("Vendu:", "").trim(), false)} inclus !</div>}
                
                        {progress === undefined &&
                <div className="mt-3">
                    <Inputs.SubmitBtn loading={loading} label={"Injecter"} type_btn='success'
                        onClick={injectNewMachine} />
                </div>}
            </div>}

            {done &&
                <div>
                    { bom_planning.in_business &&
                        <Fragment>
                            <h5 className='text-center'>Nomenclature injectée dans Focus, modifiez la dans l'affaire</h5>
                            <img src="/img/articles/second injection Focus.gif" style={{ width: "100%" }} />
                        </Fragment>
                    }

                    {!bom_planning.in_business &&
                        <Fragment>
                            <h5 className='text-center'>Nomenclature injectée dans Focus, insérez la dans l'affaire</h5>
                            <img src="/img/articles/first injection Focus.gif" style={{ width: "100%" }} />
                        </Fragment>
                    }

                </div>
            }

            {arr.length > 0 && <div>

                <h5 className='text-center mt-3'>Mise à jour de la nomenclature {type_of_bom} dans Focus</h5>
                <h6 className='text-center'>Sélectionnez les modifications à effectuer dans Focus puis validez</h6>
                {progress !== undefined &&
                    <div className='alert alert-info fw-bold text-center'>
                        {progressMsg}
                        <ProgressBar label={progress + ' %'} animated now={progress} />
                    </div>
                }

                <table className='table ms-auto me-auto' style={{ width: "90%" }}>
                    <thead>
                        <tr className='table-secondary text-center'>
                            <th className='text-start'>
                                <input type='checkbox' className='form-check-input big'
                                    onChange={(e) => validateAllChange(e)} />
                            </th>
                            <th>S/E</th>
                            <th>Rep</th>
                            <th>Cat</th>
                            <th>Art</th>
                            <th>Ref</th>
                            <th>Delta</th>
                            <th>Action</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {arr.map((v, k) => {
                            return <tr key={k}>
                                <td>
                                    <input type='checkbox' className='form-check-input big'
                                        onClick={(e) => validateChange(e, k)} checked={v.checked} />
                                </td>
                                <td>{v.se}</td>
                                <td>{v.rep}</td>
                                <td>{v.cat}</td>
                                <td>{v.ar}</td>
                                <td>{v.ref}</td>
                                <td>{v.delta}</td>
                                <td style={{ width: "300px" }} dangerouslySetInnerHTML={{ __html: getMsg(v) }}></td>
                                <td style={{ width: "300px" }} 
                                    className={ (v.log && v.log.toLowerCase().indexOf("erreur") !== -1) ? 'bg-danger text-white' : ''}
                                    dangerouslySetInnerHTML={{ __html: v.log }}></td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <div className='text-center'>
                    <button className='btn btn-success' onClick={injectChange}>Valider les modifs</button>
                </div>

            </div>}

            {progress !== undefined && <WaitingRoundSnippers launchGame={true} hideRound={true} />}

            {(focus_content.length > 0 && arr.length === 0) && <div className='alert alert-info text-center fw-bold'>
                Aucune différence détectée avec Focus, rien à injecter
            </div>}

        </div>
    );
};

export default InjectInFocus;