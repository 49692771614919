import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useState } from "react";
import { SmallFrame } from "../../../../common/smallComponents.js/CustomDivs";
import { CustomToolTipInfo } from "../../../../common/smallComponents.js/CustomToolTip";
import ErrorMessage from "../../../../common/smallComponents.js/ErrorMessage";
import Inputs from "../../../../common/smallComponents.js/Inputs";
import ReactTable from "../../../../common/smallComponents.js/ReactTable";
import WaitingRoundSnippers from "../../../../common/smallComponents.js/WaitingRoundSnippers";
import { removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../../../functions/PageAnimation";
import { forceString } from "../../../../functions/StrFunctions";
import ArticleInfo from "../../../../services/articles/ArticleInfo";

const OtherPicking = (p) => {
    const [search, setSearch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [art, setArt] = useState([]);
    const [error, setError] = useState();

    const [tab, setTab] = useState("");
    const [data, setData] = useState();
    const [value, setValue] = useState();
    const [added, setAdded] = useState("");
    const [freeIdx, setFreeIdx] = useState(0);


    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('key',
            {
                header: '#',
                cell: (i) => <div className="text-center">
                    {!hideButton(i.row.original) && <button className="btn btn-outline-success"
                        onClick={() => addArticle(i.row.id)}>Ajouter</button>}
                    {i.row.original.previous_ship && <CustomToolTipInfo
                        props={{ tooltipText: i.row.original.previous_ship, divClass: "text-start" }} />

                    }
                </div>
            }));

        arr.push(columnHelper.accessor('affaire',
            { header: 'Affaire', cell: (i) => <div className="text-center">{i.getValue()}</div> }));


        arr.push(columnHelper.accessor('ar',
            { header: 'Article', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('remaining', {
            header:
                <div>Qté à livrer <CustomToolTipInfo props={{ tooltipText: "Le recalcul ne se fait pas en direct lors des modifications dans le tableau, mais après sauvegarde et rafraîchissement de la page." }} /></div>, cell: (i) =>
                    <div className="text-center">
                        {i.getValue()}
                    </div>
        }));

        arr.push(columnHelper.accessor('label',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            { header: 'Ref.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('fonction',
            { header: 'Machine', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        return arr;
    }

    /**
     * Check if you can hide the button in list choice
     * @param {*} v 
     * @returns 
     */
    function hideButton(v) {
        if (!v) return;

        const foundKey = p.picking.currentPicking.find((a) => { return a.id === getFormedId(v.source, v.id) });

        var remaining = v.remaining === null ? parseFloat(v.qty) : parseFloat(v.remaining);

        return foundKey !== undefined || remaining <= 0;
    }

    const updQty = (e, k) => {
        //Update current picking
        var picking = { ...p.picking };
        var arr = [...picking.currentPicking];
        arr[k].qty = parseFloat(e.target.value);
        picking.currentPicking = arr;
        p.setPicking(picking);
    }

    /**
     * Create the id according source and id of row
     * @param {*} source 
     * @param {*} id need id eg
     * @returns 
     */
    function getFormedId(source, id) { return forceString(source).trim() + "_" + id }

    const changeTab = (tab) => {
        setTab(tab);
        setData(p.picking.picking[tab.trim()])
    }

    /**
     * Add an article chosen in the list of customer client, kit...
     * @param {*} k 
     */
    const addArticle = (k) => {
        var picking = { ...p.picking };

        var o = {
            ...data[k],
            //Override qty: if the is remaining set it
            qty: data[k].remaining || data[k].qty,
            machine: -1,
            id: getFormedId(data[k].source, data[k].id),
            ar: data[k].ar,
            av: null,
            display: 1,
            type: "Article"
        }

        picking.currentPicking.push(o);
        p.setPicking(picking);
    }

    /**
     * Add an articles typed, not linked in any Focus business
     * @param {*} v 
     */
    const onChooseArt = (k) => {
        var v = art[k];

        var picking = { ...p.picking };
        picking.currentPicking.push({
            source: "free",
            machine: -1,
            id: getFormedId("free", freeIdx),
            "ar": v.CDARL,
            "qty": 1,
            "ref": v.ZN02PA,
            "en_label": v.ZN14PA,
            "label": v.ZN03PA,
            "dim1": v.DIM1L,
            "dim2": v.DIM2L,
            "dim3": v.DIM3L,
            "weight": v.POIDL,
            "hst_code": v.DOUA,
            "hs_code": v.ZN23PA,
            type: "Article"
        })
        p.setPicking(picking);
        setValue();
        setAdded(v.CDARL);

        setFreeIdx(freeIdx + 1);
    }

    /**
     * Remove the article in the array
     * @param {*} k 
     */
    const removeArticle = (k) => {
        var picking = { ...p.picking };
        picking.currentPicking = removeAndReturnArray(picking.currentPicking, k);
        p.setPicking(picking);
    }

    const columns = getColumns();

    const searchArticle = (e) => {
        setError();
        setAdded();
        setArt([]);
        e.preventDefault();

        setSearching(true);
        ArticleInfo.searchArticlePlus(e.target.term.value, true).then(
            (res) => {
                setArt(res);
                setSearching(false);
            }, (error) => {
                setSearching(false)
                setError(error);
            }
        )
    }

    return <Fragment>
        <h5 className="text-center">Liste de picking {p.pickingId ? "n°" + p.pickingId : ""}</h5>
        { p.picking && <Fragment>
            <h6 className="text-center">{p.picking.exp.business}</h6>
            { ( p.picking.picking_head.comments && p.picking.picking_head.comments !== "" ) &&
                <div className="fst-italic fw-bold alert alert-info text-center">{p.picking.picking_head.comments}</div>
            }
        </Fragment> }
        
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Qté</th>
                    <th scope="col">Article</th>
                    <th scope="col">Désignation</th>
                    <th scope="col">Référence</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {p.picking && p.picking.currentPicking && p.picking.currentPicking.map((v, k) => {
                    return <tr key={k}>
                        <td>
                            <input className="form-control text-center" type="number" value={v.qty}
                                style={{ width: "80px" }} onChange={(e) => updQty(e, k)} />
                        </td>
                        <td>{v.ar}</td>
                        <td>{v.label}</td>
                        <td>{v.ref}</td>
                        <td>
                            <img className="see-img w-30" src="/common/remove.png"
                                onClick={() => removeArticle(k)} />
                        </td>
                    </tr>
                })}
            </tbody>
        </table>

        <button className="btn btn-secondary mt-3" onClick={() => setSearch(true)}>Ajouter des éléments</button>

        <div className="text-center mt-3">
            <button className="btn btn-success" disabled={p.saving} onClick={() => p.saveOtherPicking(false)}>
                {p.saving && <ButtonWaiting />}
                Sauvegarder
            </button>
        </div>

        {search && <SmallFrame close={() => { setSearch(false); setAdded(); setError(); setArt([]) }} component={<div>
            <h5 className="text-center">Ajouter des articles</h5>

            <ul class="nav nav-tabs">
                {(p.picking && p.picking.picking.toDo.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "toDo" ? "active" : "")}
                            onClick={() => changeTab("toDo")}>Sauvegardé</a>
                    </li>}

                {(p.picking && p.picking.picking.sav.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "sav" ? "active" : "")}
                            onClick={() => changeTab("sav")}>Du picking SAV</a>
                    </li>}

                {(p.picking && p.picking.picking.mes.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "mes" ? "active" : "")}
                            onClick={() => changeTab("mes")}>Du picking MeS</a>
                    </li>}

                {(p.picking && p.picking.picking.counter.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "counter" ? "active" : "")}
                            onClick={() => changeTab("counter")}>De la sortie comptoir</a>
                    </li>}

                {(p.picking && p.picking.picking.kit.length > 0) &&
                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "kit" ? "active" : "")}
                            onClick={() => changeTab("kit")}>De la demande de kit REPA</a>
                    </li>}

                <li class="nav-item">
                    <a class={"nav-link fw-bold text-dark " + (tab === "article" ? "active" : "")}
                        onClick={() => changeTab("article")}>Ajout libre</a>
                </li>
            </ul>

            {(data && data.length > 0) &&
                <ReactTable columns={columns} data={data} origin="picking_ship_other" classes={"no-padding fixFstCol "} top0={true} />
            }

            {tab === "article" && <div className="mt-3">
                {added && <div className="alert alert-success text-center fw-bold mb-3">{added} ajouté !</div>}
                {error && <ErrorMessage error={error} errorState={setError} />}
                <form onSubmit={searchArticle} style={{ width: "400px" }} className="ms-auto me-auto mb-3">
                    <Inputs.Input name={"term"} placeholder="Code article, ref. ou désignation"
                        hideSpan required inputClass="text-center fw-bold" />
                </form>

                {searching && <WaitingRoundSnippers />}

                <div style={{ maxHeight: "60vh" }} className="overflow-y-auto">
                    {art.length > 0 && <table className="table">
                        <thead>
                            <tr className="text-center">
                                <th>Article</th>
                                <th>Ref</th>
                                <th>Désignation</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {art.map((v, k) => {
                                return <tr>
                                    <td>{v.CDARL}</td>
                                    <td>{v.ZN02PA}</td>
                                    <td>{v.ZN03PA}</td>
                                    <td className="text-center">
                                        <button className="btn btn-success"
                                            onClick={() => onChooseArt(k)}>Choisir</button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>

                    }
                </div>

            </div>}
        </div>} />
        }
    </Fragment>

}

export default OtherPicking;