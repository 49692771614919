import { useState } from "react";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { scrollToTop } from "../../../../functions/PageAnimation";
import Folder from "./Folder";
import { forceString, getLabelFromInput } from "../../../../functions/StrFunctions";
import Miscellaneous, { logObj } from "../../../../services/common/Miscellaneous";
const PartThree = (p) => {
    const [dp] = useState(p.dp);

    const onSelectChange = (e) => {
        saveChange(e);
    }

    const onInputType = (e) => {
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var o = { ...p.machineInfo };
        o[field] = value;
        p.setMachineInfo(o);
    }

    const saveChange = (e) => {
        if (p.readOnly) return;
        var field = e.target.getAttribute("data-field");
        var value = e.target.value;

        var old_value = p.machineInfo[field];

        if (forceString(old_value) === forceString(value) || old_value === null && value === "") return;

        var log = { ...logObj };
        log.origin = "machine";
        log.id = p.machineInfo.no_machine;
        log.log_text = "Mise à jour du champ " + (getLabelFromInput(e.target, field) || field) + " (coiffe)";
        log.old_value = old_value === null ? "" : old_value;
        log.new_value = value;

        var o = { ...p.machineInfo };
        o[field] = value;
        p.setMachineInfo(o);

        MachineInfo.updateLid(p.machineInfo.no_machine, field, value).then(
            () => { Miscellaneous.saveLog(log); },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    return <div className="table-responsive">
        { forceString( p.machineInfo.group ) !== "2P" &&
            <Folder machineInfo={p.machineInfo} setMachineInfo={p.setMachineInfo} setError={p.setError}
                dp={p.dp} readOnly={p.readOnly} box1={p.box1} onSelectChange={onSelectChange}
                onInputType={onInputType} onInputChange={saveChange} />
        }

        { forceString( p.machineInfo.group ) === "2P" && <div className="d-flex">
            {p.box1 && p.box1.map( (v, k)  => {
                return <Folder machineInfo={p.machineInfo} setMachineInfo={p.setMachineInfo} setError={p.setError}
                dp={p.dp} readOnly={p.readOnly} box1={p.box1} onSelectChange={onSelectChange}
                onInputType={onInputType} onInputChange={saveChange} k={k+1}/>
            })}
        </div>}
    </div >
}

export default PartThree;