import { Fragment, useState } from "react";
import { removeAndReturnArray, removeEltArray } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting, setPopUp } from "../../../../functions/PageAnimation";
import _ from "lodash";

/**
 * UPDATE: need to avoid tree of tags: #1 #1.1 #1.1.1 ... Stay with integer only
 * @param {*} p 
 * @returns 
 */
const MachPicking = (p) => {
    const [cursor, setCursor] = useState("");
    const [toAdd, setToAdd] = useState();
    const [list, setList] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(0);

    /**
     * Insert Row
     */
    const insert = (e) => {
        e.preventDefault();

        var arr = [...p.picking.machines];
        var container = parseInt(e.target.container.value);

        var full = [...p.picking.machines[toAdd.k].fullPicking];
        var machineContent = arr[toAdd.k].picking;
        var newMachContent = [];

        var lvl = container === -1 ? 1 : machineContent[toAdd.k2].level + 1;

        //Add the articles in the machine object
        for (let i = 0; i < machineContent.length; i++) {
            newMachContent.push(machineContent[i]);
            //Add the articles after it
            if (i === toAdd.k2) {
                for (let j = 0; j < list.length; j++) {
                    var o = { ...full[parseInt(list[j])] };
                    o.level = lvl;
                    newMachContent.push(o);
                }
            }
        }
        arr[toAdd.k].picking = newMachContent;

        resetAndSaveChange(arr);
    }

    const insertSpecific = (e) => {
        e.preventDefault();

        var arr = [...p.picking.machines];
        var container = parseInt(e.target.container.value);
        var machineContent = arr[toAdd.k].picking;
        var newMachContent = [];

        var o = {
            "av": "MA_OPT000_00001",
            "ar": "PA_SPE00_00001",
            "label": e.target.label.value,
            "type": e.target.type.value,
            "machine": arr[toAdd.k].machine.no_machine,
            "dim1": 0,
            "dim2": 0,
            "dim3": 0,
            "weight": 0,
            "qty": 1,
            "level": container === -1 ? 1 : machineContent[toAdd.k2].level + 1
        }

        //Add the articles in the machine object
        for (let i = 0; i < machineContent.length; i++) {
            newMachContent.push(machineContent[i]);
            //Add the article after it
            if (i === toAdd.k2) newMachContent.push(o);
        }
        arr[toAdd.k].picking = newMachContent;

        resetAndSaveChange(arr);

    }

    /**
     * Delete Row
     */
    const deleteRow = (k, k2) => {
        var arr = [...p.picking.machines];
        arr[k].picking = removeAndReturnArray(arr[k].picking, k2)
        resetAndSaveChange(arr);
    }

    /**
     * Common: reset ID and rank then save in state
     * @param {*} arr 
     */
    function resetAndSaveChange(arr) {
        // Reset the ids
        var id = 1, rank = 1;
        for (let i = 0; i < arr.length; i++) {
            var picking = arr[i].picking;

            // Set the new id and rank
            for (let j = 0; j < picking.length; j++) {
                picking[j].id = id++;
                picking[j].rank = rank++;
            }
        }

        // Update the state
        var o = { ...p.picking };
        o.machines = arr;
        p.setPicking(o)

        setToAdd();
        setList([]);

        p.setChangeDone(true);
    }

    function getTree() {
        var tree = [];

        var arr = [...p.picking.machines];

        var machine = { ...arr[toAdd.k] };
        var picking = [...machine.picking];

        for (let i = toAdd.k2; i >= 0; i--) {
            tree.push(picking[i]);
            //If we are in the top level add it and stop the search
            if (picking[i].level === 1) break;
        }

        return tree;
    }

    const updateField = async (e, k, k2, field) => {
        var arr = [...p.picking.machines];
        var machine = { ...arr[k] };
        var picking = [...machine.picking];
        var old_value = _.cloneDeep( picking[k2][field] );

        if( old_value === new_val ) return;

        var new_val = e.target.value;
        if( new_val.trim() === "" ) {
            setPopUp("Saisissez une valeur !", "danger", 4000)
            return;
        }

        picking[k2][field] = new_val;

        if( field === "id" )
            arr[k].picking = await updateTag( [...picking], new_val, k2, old_value );
        
        var o = { ...p.picking };
        o.machines = arr

        p.setPicking(o);
        setForceUpdate( s => s + 1 )
        p.setChangeDone(true);
    }

    const updateTag = async (p, value, k, old_value) => {

        var updatedPicking = [...p];
        for( let i = 0; i < updatedPicking.length; i++ ){
            if( i !== k && parseInt( updatedPicking[i].id ) === parseInt( value ) ){
                updatedPicking[i].id = old_value;
            }
        }

        updatedPicking.sort( (a, b) => a.id - b.id );

        return updatedPicking;
    }

    const deleteMachine = (k) => {
        var o = { ...p.picking };
        var machines = [...o.machines];
        machines[k].picking = [];
        o.machines = machines;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const changeComment = (e) => {
        var val = e.target.value;
        var o = { ...p.picking };
        o.picking_head.comments = val;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const checkToAdd = (e, k) => {
        var arr = [...list];
        if (e.target.checked) { arr.push(k); }
        else { arr = removeEltArray(arr, k); }

        setList(arr);
    }

    const selectContainer = (id, label) => {
        const inputs = document.querySelectorAll('input.expedition_container');
        inputs.forEach(input => input.value = id );
        setToAdd((prevState) => ({ ...prevState, step: 1, id: id, label: label }));
    }

    function getIndentation(level){
        if( p.tab === "picking" ) return {};

        return {marginLeft: (level*10) + "px"}
    }
 
    return <Fragment>
        { p.tab === "picking" && 
            <h5 className="text-center">Picking list {p.pickingId ? "n°" + p.pickingId : ""} 
                - {p.picking && p.picking.businessFocus.NOCDE}</h5>}

            { p.tab === "packing" && 
                <h5 className="text-center">Packing list {p.pickingId ? "n°" + p.pickingId : ""} 
                    - {p.picking && p.picking.businessFocus.NOCDE}</h5>}

        {p.picking && <Fragment>

            {p.picking.machines.map((v, k) => {
                return v.picking.length > 0 && <div key={"machine" + v.machine.no_machine} className="mb-3">
                    <h5>Machine <a href={"/machine?machine=" + v.machine.no_machine} target="_blank">{v.machine.no_machine}</a> ({v.machine.typ_config})
                        <img src="/common/remove_icon.png" onClick={() => deleteMachine(k)}
                            className="no-print cursor-pointer ms-2" style={{ width: "20px" }} />
                    </h5>

                    <table className="table">
                        <thead className="text-center">
                            <tr>
                                <th style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}></th>
                                <th scope="col">ID</th>
                                <th scope="col">Qté</th>
                                <th scope="col">Article</th>
                                <th scope="col">Désignation</th>
                                <th scope="col">Type de colisage</th>
                                { p.tab === "packing" && <Fragment>
                                    <th scope="col">Longueur</th>
                                    <th scope="col">Largeur</th>
                                    <th scope="col">Hauteur</th>
                                    <th scope="col">Poids</th>
                                </Fragment>}                                
                                <th scope="col" className="no-print"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {v.picking.map((v2, k2) => {
                                return <Fragment>
                                    <tr key={"pick" + k + "_" + k2} onMouseEnter={() => setCursor(k + "_" + k2)}
                                        onMouseLeave={() => setCursor("")}>
                                        <td style={{ borderBottomStyle: "none", borderTopStyle: "none" }}></td>
                                        <th scope="row">
                                            <div style={getIndentation(v2.level) } className="d-flex">
                                                <div className="me-1 fw-bold mt-auto mb-auto">#</div>
                                                <div>
                                                <input className="form-control text-center" type="number" defaultValue={v2.id}
                                                    onBlur={(e) => updateField(e, k, k2, "id")} 
                                                        style={{width: "60px"}} key={forceUpdate}/>
                                                {/* #{v2.id} */}
                                                </div>
                                    
                                            </div>
                                        </th>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.qty || ""}
                                                onBlur={(e) => updateField(e, k, k2, "qty")} />
                                        </td>
                                        <td>{v2.ar}</td>
                                        <td>{v2.label}</td>
                                        <td>{v2.type}</td>
                                        { p.tab === "packing" && <Fragment>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.dim1 || ""}
                                                onBlur={(e) => updateField(e, k, k2, "dim1")} />
                                        </td>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.dim2 || ""}
                                                onBlur={(e) => updateField(e, k, k2, "dim2")} />
                                        </td>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.dim3 || ""}
                                                onBlur={(e) => updateField(e, k, k2, "dim3")} />
                                        </td>
                                        <td>
                                            <input className="form-control text-center" type="number" defaultValue={v2.weight || ""}
                                                onBlur={(e) => updateField(e, k, k2, "weight")} />
                                        </td>
                                        </Fragment>}
                                        <td className="no-print">
                                            <img src="/common/remove_icon.png" className="see-img w-30 cursor-pointer"
                                                onClick={() => deleteRow(k, k2)} />
                                        </td>
                                    </tr>

                                    <tr key={"add" + k + "_" + k2} onMouseEnter={() => setCursor(k + "_" + k2)}
                                        onMouseLeave={() => setCursor("")} title="Ajouter un élément"
                                        className={cursor === k + "_" + k2 ? "no-print" : "no-print d-none"}>

                                        <td style={{ width: "30px", borderBottomStyle: "none", borderTopStyle: "none" }}
                                            className="cursor-pointer position-relative p-0"
                                            onClick={() => setToAdd({ k: k, k2: k2, step: p.tab === "picking" ? 1 : 0 })}>
                                            <img src="/common/add_btn.png" className="position-absolute"
                                                style={{ maxWidth: "30px", marginTop: "-15px", zIndex: "1000" }} />
                                        </td>

                                        <td colSpan={11} className="see-border p-0"></td>
                                    </tr>
                                </Fragment>
                            })}
                        </tbody>
                    </table>


                </div>
            })}

            <div className="form-floating col-6 offset-3 mb-3">
                <textarea className="form-control" placeholder="xxx" id="floatingTextarea2"
                    style={{ height: "100px", padding: "1rem .75rem" }} onBlur={changeComment} ></textarea>
                <label htmlFor="floatingTextarea2">Remarques</label>
            </div>

            <div className="text-center">
                <button className="btn btn-secondary" disabled={p.saving} onClick={() => p.savePicking(false)}>
                    {p.saving && <ButtonWaiting />}
                    Sauvegarder
                </button>

                { p.tab === "picking" && 
                <button className="btn btn-success ms-3" disabled={p.saving} onClick={() => p.savePicking(true)}>
                    {p.saving && <ButtonWaiting />}
                    Sauvegarder et envoyer un mail au SLOG
                </button>}
            </div>

            {toAdd && <div className="custom-tooltip-v2">
                <div className="modal-body xl blue-purple-bg">
                    <button type="button" className="btn-close" onClick={() => setToAdd()}></button>

                    <h5 className="text-center">Insérer un élément à la machine {p.picking.machines[toAdd.k].machine.no_machine}</h5>

                    <form onSubmit={insert} style={{ width: "800px" }} className="ms-auto me-auto">

                        {/** CHOOSE WHERE THE ARTICLE WILL PLACED */}
                        {toAdd.step === 0 && <Fragment>
                            <h6 className="text-center mb-3">Où désirez-vous placer l'élément ?</h6>

                            <div className="mb-3 text-center">
                                <button type="button" className="see-btn" style={{ width: "400px" }}
                                    onClick={() => selectContainer(-1)}>Machine {p.picking.machines[toAdd.k].machine.no_machine}</button>
                            </div>

                            {getTree().map((v, k) => {
                                return <div className="mb-3 text-center" key={"to_add_other_lvl" + k}>
                                    <button type="button" className="see-btn" style={{ width: "400px" }}
                                        onClick={() => selectContainer(v.id, v.label)}>
                                        <strong>#{v.id}</strong>: {v.ar} - {v.label}
                                    </button>
                                </div>
                            })}

                        </Fragment>}

                        <input type="hidden" name="container" className="expedition_container" 
                            defaultValue={p.tab === "picking" ? "-1" : ""}/>

                        {/** CHOOSE THE ARTICLE */}
                        {toAdd.step > 0 && <Fragment>
                            <h6 className="text-center">Choisissez un ou plusieurs articles à mettre dans&nbsp;
                                {toAdd.id === 0 ? "la machine" : toAdd.label}
                                <img src="/common/edit.png" className="ms-2 cursor-pointer" style={{ width: "20px" }}
                                    title="Changer de container" onClick={() => setToAdd((prev) => ({ ...prev, step: 0 }))} />
                            </h6>

                            <div style={{ height: "300px" }} className="overflow-y-auto mb-3">
                                <ul className="list-group">
                                    {p.picking.machines[toAdd.k].fullPicking.map((v, k) => {
                                        return <li className="list-group-item" key={"prop" + k}>
                                            <input type="checkbox" className="me-2" onChange={(e) => checkToAdd(e, k)}
                                                checked={list.includes(k)} />
                                            {v.ar + " - " + v.label + " (" + v.type + ")"}
                                        </li>
                                    })}

                                </ul>

                            </div>

                            {list.length > 0 && <div className="text-center">
                                <button className="btn btn-success">Ajouter</button>
                            </div>}

                        </Fragment>}

                    </form>

                    <form onSubmit={insertSpecific} className="ms-auto me-auto" style={{ width: "800px" }}>
                        <input type="hidden" name="container" className="expedition_container"
                            defaultValue={p.tab === "picking" ? "-1" : ""} />

                        {toAdd.step > 0 && <Fragment>
                            <h6 className="text-center">Ou ajoutez un élément spécifique</h6>
                            <div className="input-group mb-3">

                                <span className="input-group-text">Désignation</span>
                                <input type="text" name="label" className="form-control" />

                                <label className="input-group-text">Type de colisage</label>
                                <select className="form-select" name="type" required>
                                    <option value="">Choose...</option>
                                    <option value="Caisse">Caisse</option>
                                    <option value="Carton">Carton</option>
                                    <option value="Chevalet">Chevalet</option>
                                    <option value="Complet">Complet</option>
                                    <option value="Palette">Palette</option>
                                    <option value="Article">Article</option>
                                </select>

                                <button className="btn btn-success">Ajouter</button>
                            </div>
                        </Fragment>}
                    </form>

                </div>
            </div>}

        </Fragment>}

    </Fragment>
}

export default MachPicking;