export function addAfter(array, index, newItem) {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];
};

export function arrayRemoveElt(arr, index){
    var array = [...arr];
    index = parseInt( index );
    return [
        ...array.slice(0, index),
        ...array.slice(index+1)
    ];
}

export function removeAndReturnArray(array, index){
    var arr = [...array];
    arr = arrayRemoveElt(arr, index);
    return arr;
}

export function updateSimpleArray(array, index, value){
    var arr = [...array];
    arr[index] = value;
    return arr;
}

export function array_move(array, fromIndex, toIndex) {
    // Remove the element from its current position
    const element = array.splice(fromIndex, 1)[0];
    // Insert the element at the new position
    array.splice(toIndex, 0, element);
    return array;
};

export function addOrRemoveOnCheck(array, e, k){
    var arr = [...array];

    if( e.target.checked ){ arr.push(k) }
    else{ arr = removeEltArray(arr, k) }

    return arr;
}

export function hasSameElements(arr1, arr2){
    return arr1.length === arr2.length && 
    arr1.sort().toString() === arr2.sort().toString();
}

/**
 * Remove element with simple value
 * @param {*} arr 
 * @param {*} value 
 */
export function removeEltArray( arr, value ){
    var newArr = new Array();
    arr.map( v => { if( v !== value ) newArr.push(v) } );
    return newArr;
}

export function getKey(arr, key, value){

    for(let i=0; i<arr.length; i++){
        if( arr[i][key] === value ) return i;
    }
        

    return null;
}


export function getKeyInt(arr, key, value){

    for(let i=0; i<arr.length; i++){
        if( parseInt(arr[i][key]) === parseInt(value) ) return i;
    }
        

    return null;
}

export function getKeyString(arr, key, value){

    for(let i=0; i<arr.length; i++){
        if( arr[i][key].toString() === value.toString() ) return i;
    }
        

    return null;
}

export function addOrUpdateElement(arr, field, value){
    var found = false;

    for( let i=0; i<arr.length; i++ ){
        if( arr[i].field === field ){
            arr[i].value = value;
            found = true;
        }
    }

    if( !found ){
        arr.push({
            field: field,
            value: value
        });
    }

    return arr;
}

/**
 * 
 * @param {*} arr array to update
 * @param {*} key of the array to compare
 * @param {*} keyValue value searched to find the row
 * @param {*} o object to add
 * @returns 
 */
export function addOrUpdateObj(arr, key, keyValue, o){
    var found = false;

    for( let i=0; i<arr.length; i++ ){
        if( arr[i][key] === keyValue ){
            arr[i] = o;
            found = true;
        }
    }

    if( !found ){
        arr.push(o);
    }

    return arr;
}

export function sortTable(tableId, n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById(tableId);
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    /* Make a loop that will continue until no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the first, which contains table headers): */
      for (i = 1; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare, one from current row and one from the next: */
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];

        var valX = x.innerHTML.toLowerCase();
        if( x.getElementsByTagName("input").length > 0 ) valX = x.getElementsByTagName("input")[0].value.toLowerCase();

        var valY = y.innerHTML.toLowerCase();
        if( y.getElementsByTagName("input").length > 0 ) valY = y.getElementsByTagName("input")[0].value.toLowerCase();

        /* Check if the two rows should switch place, based on the direction, asc or desc: */
        if (dir === "asc") {
          if (valX > valY) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir === "desc") {
          if (valX < valY) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount ++;
      } else {
        /* If no switching has been done AND the direction is "asc", set the direction to "desc" and run the while loop again. */
        if (switchcount === 0 && dir === "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }


  export function sortArrayDate(arr, field, sense){
    if( sense === "desc" ){
        return arr.sort( (a, b) => new Date( a[field].replace("T", " ") ) - new Date( b[field].replace("T", " ") ) );
    }else{
        return arr.sort( (a, b) => new Date( b[field].replace("T", " ") ) - new Date( a[field].replace("T", " ") ) );
    }
  }

  export function sortArrayNumber(arr, field, sense){
    if( sense === "desc" ){
        return arr.sort( (a, b) => parseFloat( a[field] ) - parseFloat( b[field] ) );
    }else{
        return arr.sort( (a, b) => parseFloat( b[field] ) - parseFloat( a[field] ) );
    }
  }

  /**
   * 
   * @param {*} arr to sort
   * @param {*} field array
   * @param {*} sense asc or desc
   * @returns 
   */
  export function sortArrayText(arr, field, sense){
   
    if( sense === "desc" ){
        return arr.sort( (a, b) => {
            for( let i = 0; i<field.length; i++ ){
                if( a[ field[i] ] > b[ field[i] ] ) return 1;
                if( a[ field[i] ] < b[ field[i] ] ) return -1;
            }

            return 0;
        });
    }else{
        return arr.sort( (a, b) => {
            for( let i = 0; i<field.length; i++ ){
                if( a[ field[i] ] > b[ field[i] ] ) return -1;
                if( a[ field[i] ] < b[ field[i] ] ) return 1;
            }

            return 0;
        } );
    }
  }

  export function splitIntoChunks(array, chunkSize) {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}