import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "AI";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getModels = () => {
    return axios
    .post(API_URL + "/get/models", {
        fake_header: authHeader()
    }, { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getResponse = ( model, prompt ) => {
    return axios
    .post(API_URL + "/get/response", {
        fake_header: authHeader(),
        model: model, 
        prompt: prompt
    }, { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const chat = ( model, messages ) => {
    return axios
    .post(API_URL + "/get/chat", {
        fake_header: authHeader(),
        model: model, 
        messages: messages
    }, { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    getModels,
    getResponse,
    chat
}