import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const Map = (center) => {
    // const center = [48.8566, 2.3522]; // Paris

    return (
      <MapContainer center={center} zoom={13} style={{ height: "200px", width: "450px" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={center}>
          <Popup>Paris, France</Popup>
        </Marker>
      </MapContainer>
    );
}

export default Map;



  

