import { useState } from "react";
import { CustomToolTipInfo } from "../../../../common/smallComponents.js/CustomToolTip";
const PartOne = (p) => {
    const [dp] = useState(p.dp);

    return <div className="table-responsive">
        <div className="table-responsive">
            <table className="table businessCardStyle">
                <thead>
                    <tr className="bg-dark bg-gradient text-white text-center rounded">
                        <th scope="col" colSpan={3}>Général</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div className="text-center">
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.resp_med_mep}
                                        placeholder="Resp. MeS" readOnly />
                                    <label>Resp. MeS</label>
                                </div>
                            </div>

                            <div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.client_final}
                                        placeholder="XXX" readOnly />
                                    <label>Client final</label>
                                </div>
                            </div>
                        </td>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.chef_proj}
                                        placeholder="Resp. MeS" readOnly />
                                    <label>Chef de projet</label>
                                </div>
                            </div>

                            <div>
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="Fabricant" data-field="fabricant_mach" data-type="text"
                                        value={p.machineInfo.fabricant_mach} onChange={p.updateMachineTable} disabled={p.readOnly}>
                                        <option value=""></option>
                                        <option value="B+">B+Equipment</option>
                                        <option value="LANTECH">Lantech</option>
                                        <option value="TECMA">Tecma Aries</option>
                                        <option value="AMME">AMME</option>
                                    </select>
                                    <label for="Fabricant">Fabricant</label>
                                </div>
                            </div>

                            <div>
                                <div className="form-floating mb-3 d-flex">
                                    <select className="form-select" id="Statut machine" data-field="machine_state" data-type="text"
                                        value={p.machineInfo.machine_state} onChange={p.updateMachineTable} 
                                        disabled>
                                        <option value=""></option>
                                        <option value="O">Machine d'occasion</option>
                                        <option value="N">Machine neuve</option>
                                        <option value="R0">Retrofit pas de montage</option>
                                        <option value="R1">Retrofit montage 1 sem.</option>
                                        <option value="R2">Retrofit montage 2 sem.</option>
                                        <option value="R3">Retrofit montage 3 sem.</option>
                                    </select>
                                    <label for="Statut machine">Statut machine</label>
                                    <CustomToolTipInfo props={{ tooltipText: "Information récupérée du planning général" }}/>
                                </div>
                            </div>
                        </td>
                        <td className="border-end text-center" style={{ width: "33.33%" }}>
                            <div>
                                <div className="form-floating mb-3">
                                    <input type="text" className="form-control" value={p.machineInfo.customer_id}
                                        placeholder="Resp. MeS" readOnly />
                                    <label>Client</label>
                                </div>

                            </div>

                            <div className="form-floating mb-3 d-flex">
                                <select className="form-select" id="Fabricant" data-field="manufacturing" data-type="text"
                                    value={p.machineInfo.manufacturing} onChange={p.updateMachineTable} 
                                    disabled>
                                    <option value=""></option>
                                    <option value="B+">B+Equipment</option>
                                    <option value="LANTECH">Lantech</option>
                                    <option value="TECMA">Tecma Aries</option>
                                    <option value="AMME">AMME</option>
                                    <option value="SEE_US">Sealed Air US</option>
                                </select>
                                <label for="Fabricant">Montage</label>
                                <CustomToolTipInfo props={{ tooltipText: "Information récupérée du planning général" }}/>
                            </div>

                        </td>

                    </tr>


                </tbody>
            </table>

        </div>
    </div>
}

export default PartOne;