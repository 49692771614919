import { Fragment, useState } from "react";
import WaitingRoundSnippers from "../../../../common/smallComponents.js/WaitingRoundSnippers";
import { useEffect } from "react";
import { ButtonWaiting, scrollToTop, setPopUp } from "../../../../functions/PageAnimation";
import errorManagement from "../../../../services/errorManagement";
import MachineInfo from "../../../../services/machines/MachineInfo";
import ErrorMessage from "../../../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../../../common/smallComponents.js/SuccessMessage";
import Moment from "moment";
import { cleanString, dataURLtoFile, fileToBase64, getBase64 } from "../../../../functions/StrFunctions";
import { PhotoOrFile } from "../../../../common/smallComponents.js/PhotoOrFile";
import ShareFileDao from "../../../../services/common/ShareFileDao";
import { arrayRemoveElt } from "../../../../functions/ArrayFunctions";

const PartTen = (p) => {
    const [loading, setLoading] = useState(false);
    const [machineInfo, setMachineInfo] = useState();
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [typeMachine, setTypeMachine] = useState();
    const [boxInfo, setBoxInfo] = useState();
    const [allMachInfo, setAllMachInfo] = useState();
    const [step, setStep] = useState("samples");
    const [cart, setCart] = useState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [key, setKey] = useState(-1);
    const [img, setImg] = useState();

    useEffect(() => {
        setMachineInfo(p.machineInfo);
        setAllMachInfo(p.allMachInfo);
        setTypeMachine(p.allMachInfo[0].typ_config);
    }, []);


    const onBoxSelect = (e) => {
        var opt = e.target.options;
        var step = opt[opt.selectedIndex].getAttribute("data-step");
        setStep(step);

        var boxInfo = allMachInfo[parseInt(e.target.value)];
        setBoxInfo(boxInfo);

        if (!boxInfo) return;

        if (step === "samples") { setCart(boxInfo.cart_samp) }
        else { setCart(boxInfo.cart_pres) }

        setKey(parseInt(e.target.value));

        //setCart(allMachInfo[parseInt(e.target.value)].cart_samp);
    }

    useEffect(() => {
        if (!boxInfo) return;

        if (step === "samples") { setCart(boxInfo.cart_samp) }
        else { setCart(boxInfo.cart_pres) }

    }, [step]);

    const save = () => {
        var o = {
            field: step === "samples" ? "cartonnier_samples" : "cartonnier_preseries",
            machine: boxInfo.no_machine,
            tray: boxInfo.no_piste,
            box: boxInfo.no_caisse,
            cartonnier: cart
        }
        setLoading(true);

        MachineInfo.saveCartonnier(o).then(
            (response) => {
                setMsg("Sauvegardé !");
                scrollToTop();
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(p, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    function getCannelureCoiffe(info) {
        if (info.cannelure_coiffe === null) return null;

        return boxInfo.cannelure_coiffe.substring(boxInfo.cannelure_coiffe.indexOf("/") + 1, boxInfo.cannelure_coiffe.indexOf("mm"));
    }

    function getCannelureCaisse(info) {
        if (info.cannelure_caisse === null) return null;

        return boxInfo.cannelure_caisse.substring(boxInfo.cannelure_caisse.indexOf("/") + 1, boxInfo.cannelure_caisse.indexOf("mm"));
    }

    const updateField = (e, field, valueToCompare = null) => {
        var o = { ...cart };
        o[field] = e.target.value;

        if (valueToCompare) {
            var fieldDelta = field.replace("Real", "Delta");
            var val = e.target.value;

            o[fieldDelta] = compare(val, valueToCompare);

            /*if (val === "") {
                o[fieldDelta] = "";
            } else if (isNaN(valueToCompare) || isNaN(val)) {
                o[fieldDelta] = cleanString(val) === cleanString(valueToCompare) ? "OK" : "NOK";
            } else {
                o[fieldDelta] = parseFloat(val) - parseFloat(valueToCompare);
            }*/
        }

        setCart(o);
    }

    function compare(val, valueToCompare) {
        if (val === "") {
            return "";
        } else if (isNaN(valueToCompare) || isNaN(val)) {
            return cleanString(val) === cleanString(valueToCompare) ? "OK" : "NOK";
        } else {
            return parseFloat(val) - parseFloat(valueToCompare);
        }
    }

    const print = () => {
        //Resize all textarea

        window.print();
    }

    const copyData = (e) => {
        e.preventDefault();

        var opt = e.target.origin.options;
        var step = opt[opt.selectedIndex].getAttribute("data-step");
        var boxInfo = allMachInfo[e.target.origin.value]
        var origin = step === "samples" ? { ...boxInfo.cart_samp } : { ...boxInfo.cart_samp.cart_pres };

        var target = { ...cart };

        for (var key in origin)
            if (origin.hasOwnProperty(key) && key.endsWith("Real")) target[key] = origin[key];

        setCart(target);

    }

    const addAnnexe = async (e) => {
        e.preventDefault();
        if (!img) {
            setError("Choisissez ou prenez une photo avant de soumettre.")
            scrollToTop();
            return;
        }

        var arr = [...p.annexes];

        arr.push({
            name: e.target.legend.value,
            img: img,
            new: true,
            typ_card: "cartonnier"
        });


        p.setAnnexes(arr);

        const b64 = {
            name: e.target.legend.value,
            base64: img.split(",")[1],
            fullBase64: img
        }
        MachineInfo.uploadFile(p.machineInfo.no_machine, "cartonnier", b64, "/upload-annexe").then(
            (response) => {
                setPopUp("Sauvegardé")
            },
            (error) => {
                setPopUp("Erreur lors de l'import !")
            }
        )   

        /*dataURLtoFile(img, e.target.legend.value, function (res) {
            setImg();
 
            //Save in database
            var o = { machine: p.machineInfo.no_machine, type_card: "cartonnier" };
            ShareFileDao.uploadAnyFiles("machines/info/upload-annexe", o, [res]).then(
                (response) => {
                    console.log(response)
                },
                (error) => {
                    p.setError(errorManagement.handleError(p, error));
                    scrollToTop();
                    setLoading(false);
                }
            )
        })*/


    }

    const deleteAnnexe = (e) => {
        if (p.readOnly) return;

        var key = e.target.getAttribute("data-key");
        var arr = [...p.annexes];

        MachineInfo.deleteAnnexe(p.machineInfo.no_machine, arr[key].name).then(
            (response) => {
                
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
                setLoading(false);
            }
        )

        arr = arrayRemoveElt(arr, key);

        p.setAnnexes(arr);

    }

    return <Fragment>
        <h3 className="text-center no-print">Prototypes - Cartonnier</h3>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            allMachInfo &&
            <div className="col-6 offset-3">

                <div className="input-group mb-3 no-print">
                    <Fragment>
                        <span className="input-group-text no-print">Choisir la caisse</span>
                        <select className="form-select" id="trail-select"
                            onChange={onBoxSelect}>
                            <option selected>Choose...</option>
                            {
                                allMachInfo.map(
                                    (v, k) => {
                                        return <Fragment>
                                            <option value={k} data-step="samples">Tray {v.no_piste} - Box {v.no_caisse} (Samples)</option>
                                            <option value={k} data-step="preseries">Tray {v.no_piste} - Box {v.no_caisse} (Preserie)</option>
                                        </Fragment>
                                    }
                                )
                            }
                        </select>
                    </Fragment>
                </div>

                {key !== -1 &&

                    <form onSubmit={copyData} className="input-group mb-3 no-print">
                        <span className="input-group-text no-print">Copier les données de la caisse</span>
                        <select className="form-select" name="origin">
                            <option selected>Choose...</option>
                            {
                                allMachInfo.map(
                                    (v, k) => {
                                        return <Fragment>
                                            {(k !== key || (k === key && step !== "samples")) && <option value={k} data-step="samples">Tray {v.no_piste} - Box {v.no_caisse} (Samples)</option>}
                                            {(k !== key || (k === key && step !== "preseries")) && <option value={k} data-step="preseries">Tray {v.no_piste} - Box {v.no_caisse} (Preserie)</option>}
                                        </Fragment>
                                    }
                                )
                            }
                        </select>
                        <button className="btn btn-outline-success">Copier</button>
                    </form>}
            </div>
        }

        {
            boxInfo &&
            <Fragment>
                <div className="mb-2 text-center no-print">
                    <img src="/img/print.png" className="cursor-pointer no-print" onClick={print}
                        style={{ position: "absolute", left: "5px", marginTop: "-80px" }}></img>

                    {/*<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" className="btn-check" checked={step === "samples"} />
                        <label className="btn btn-outline-secondary" onClick={() => setStep("samples")}>Samples</label>

                        <input type="radio" className="btn-check" checked={step === "preseries"} />
                        <label className="btn btn-outline-secondary" onClick={() => setStep("preseries")}>Preseries</label>
                    </div>*/}
                </div>

                <div className="col-8 offset-2 print-only">
                    <table className="table text-center fw-bold">
                        <tr>
                            <td>Tray: {boxInfo.no_piste} / Box: {boxInfo.no_caisse}</td>
                            <td>Checked by: {user.last_name} {user.first_name}</td>
                            <td>Date: {Moment(new Date()).format("DD/MM/yyyy")}</td>
                            <td>Type: {step === "samples" ? "Samples" : "Preserie"}</td>
                        </tr>
                    </table>
                </div>
            </Fragment>
        }

        {/**
         * NON E-CUBE PART
         */}
        {
            (boxInfo && cart && !typeMachine.startsWith("EC")) &&
            <div className="cartonnier">
                <div className="table-responsive">
                    <table className="table simple-border">
                        <tbody>

                            <tr className="text-center fw-bold table-primary" style={{ backgroundColor: "" }}>
                                <td colSpan="6">{boxInfo.procede_emb}</td>
                            </tr>

                            {/** BOX */}
                            <tr className="text-center fw-bold table-info">
                                <td colSpan="4">{boxInfo.type_caisse}</td>
                                <td>Comments</td>
                                <td>Corrective actions</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>B+ Equipment drawing number</td>
                                <td>
                                    <input className="form-control" value={cart.boxDrawingNb}
                                        onChange={(e) => updateField(e, "boxDrawingNb")}></input>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.boxDrawingNbComs}
                                        onChange={(e) => updateField(e, "boxDrawingNbComs")}></textarea>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.boxDrawingNbAct}
                                        onChange={(e) => updateField(e, "boxDrawingNbAct")}></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Global appearance</td>
                                <td>
                                    <input className="form-control" value={cart.boxGlobalAppNb}
                                        onChange={(e) => updateField(e, "boxGlobalAppNb")}></input>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.boxGlobalAppComs}
                                        onChange={(e) => updateField(e, "boxGlobalAppComs")}></textarea>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.boxGlobalAppAct}
                                        onChange={(e) => updateField(e, "boxGlobalAppAct")}></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Position of the glued flap</td>
                                <td><input className="form-control" value={cart.boxFlapReal}
                                    onChange={(e) => updateField(e, "boxFlapReal")}></input></td>
                                <td><textarea className="form-control" value={cart.boxFlapComs}
                                    onChange={(e) => updateField(e, "boxFlapComs")}></textarea></td>
                                <td><textarea className="form-control" value={cart.boxFlapAct}
                                    onChange={(e) => updateField(e, "boxFlapAct")}></textarea></td>
                            </tr>
                            <tr className="text-center">
                                <td></td>
                                <td className="fw-bold table-info">Theoretical (mm)</td>
                                <td className="fw-bold table-info">Real (mm)</td>
                                <td className="fw-bold table-info">/</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Flute type</td>
                                <td className="text-center">{boxInfo.cannelure_caisse}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxFluteReal}
                                        onChange={(e) => updateField(e, "boxFluteReal", boxInfo.cannelure_caisse)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxFluteDelta}
                                        onChange={(e) => updateField(e, "boxFluteDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxFluteComs}
                                        onChange={(e) => updateField(e, "boxFluteComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxFluteAct}
                                        onChange={(e) => updateField(e, "boxFluteAct")}></textarea></td>
                            </tr>
                            <tr>
                                <td>Thickness</td>
                                <td className="text-center">
                                    {getCannelureCaisse(boxInfo)}
                                </td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxThickReal}
                                        onChange={(e) => updateField(e, "boxThickReal", getCannelureCaisse(boxInfo))} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxThickDelta}
                                        onChange={(e) => updateField(e, "boxThickDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxThickComs}
                                        onChange={(e) => updateField(e, "boxThickComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxThickAct}
                                        onChange={(e) => updateField(e, "boxThickAct")}></textarea></td>
                            </tr>
                            <tr>
                                <td>Length (external dimensions)</td>
                                <td className="text-center">{boxInfo.l_dim_ext}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxLgtReal}
                                        onChange={(e) => updateField(e, "boxLgtReal", boxInfo.l_dim_ext)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxLgtDelta}
                                        onChange={(e) => updateField(e, "boxLgtDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxLgtComs}
                                        onChange={(e) => updateField(e, "boxLgtComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxLgtAct}
                                        onChange={(e) => updateField(e, "boxLgtAct")}></textarea></td>
                            </tr>
                            <tr>
                                <td>Width (external dimensions)</td>
                                <td className="text-center">{boxInfo.w_dim_ext}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxWdtReal}
                                        onChange={(e) => updateField(e, "boxWdtReal", boxInfo.w_dim_ext)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxWdtDelta}
                                        onChange={(e) => updateField(e, "boxWdtDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxWdtComs}
                                        onChange={(e) => updateField(e, "boxWdtComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxWdtAct}
                                        onChange={(e) => updateField(e, "boxWdtAct")}></textarea></td>
                            </tr>
                            <tr>
                                <td>Height (external dimensions)</td>
                                <td className="text-center">{boxInfo.h_dim_ext}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxHgtReal}
                                        onChange={(e) => updateField(e, "boxHgtReal", boxInfo.h_dim_ext)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxHgtDelta}
                                        onChange={(e) => updateField(e, "boxHgtDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxHgtComs}
                                        onChange={(e) => updateField(e, "boxHgtComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxHgtAct}
                                        onChange={(e) => updateField(e, "boxHgtAct")}></textarea></td>
                            </tr>
                            <tr>
                                <td>Wall height</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxWallHgtTheo}
                                        onChange={(e) => updateField(e, "boxWallHgtTheo")} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxWallHgtReal}
                                        onChange={(e) => updateField(e, "boxWallHgtReal", cart.boxWallHgtTheo)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxWallHgtDelta}
                                        onChange={(e) => updateField(e, "boxWallHgtDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxWallHgtComs}
                                        onChange={(e) => updateField(e, "boxWallHgtComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxWallHgtAct}
                                        onChange={(e) => updateField(e, "boxWallHgtAct")}></textarea></td>
                            </tr>
                            <tr>
                                <td>Ecoflap height</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxFlatTheo}
                                        onChange={(e) => updateField(e, "boxFlatTheo")} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxFlatReal}
                                        onChange={(e) => updateField(e, "boxFlatReal", cart.boxFlatTheo)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxFlatDelta}
                                        onChange={(e) => updateField(e, "boxFlatDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxFlatComs}
                                        onChange={(e) => updateField(e, "boxFlatComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxFlatAct}
                                        onChange={(e) => updateField(e, "boxFlatAct")}></textarea></td>
                            </tr>
                            <tr>
                                <td>hight level mark</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxHgtLvlTheo}
                                        onChange={(e) => updateField(e, "boxHgtLvlTheo")} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxHgtLvlReal}
                                        onChange={(e) => updateField(e, "boxHgtLvlReal", cart.boxHgtLvlTheo)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.boxHgtLvlDelta}
                                        onChange={(e) => updateField(e, "boxHgtLvlDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxHgtLvlComs}
                                        onChange={(e) => updateField(e, "boxHgtLvlComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.boxHgtLvlAct}
                                        onChange={(e) => updateField(e, "boxHgtLvlAct")}></textarea></td>
                            </tr>
                            <tr><td colSpan={6}>&nbsp;</td></tr>

                            {/** Tray and Lid */}
                            <tr className="text-center fw-bold table-info">
                                <td colSpan="4">tray and lid mechanical clearance</td>
                                <td>Comments</td>
                                <td>Corrective actions</td>
                            </tr>

                            <tr>
                                <td>Length clearance</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lgtClearTheo}
                                        onChange={(e) => updateField(e, "lgtClearTheo")} style={{ width: "100px" }} defaultValue={0}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lgtClearReal}
                                        onChange={(e) => updateField(e, "lgtClearReal", cart.lgtClearTheo)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lgtClearDelta}
                                        onChange={(e) => updateField(e, "lgtClearDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.lgtClearComs}
                                        onChange={(e) => updateField(e, "lgtClearComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.lgtClearAct}
                                        onChange={(e) => updateField(e, "lgtClearAct")}></textarea></td>
                            </tr>

                            <tr>
                                <td>Width clearance</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.wdtClearTheo}
                                        onChange={(e) => updateField(e, "wdtClearTheo")} style={{ width: "100px" }} defaultValue={0}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.wdtClearReal}
                                        onChange={(e) => updateField(e, "wdtClearReal", cart.wdtClearTheo)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.wdtClearDelta}
                                        onChange={(e) => updateField(e, "wdtClearDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.wdtClearComs}
                                        onChange={(e) => updateField(e, "wdtClearComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.wdtClearAct}
                                        onChange={(e) => updateField(e, "wdtClearAct")}></textarea></td>
                            </tr>

                            <tr><td colSpan={6}>&nbsp;</td></tr>

                            {/** HEADER */}
                            <tr className="text-center fw-bold table-info">
                                <td colSpan="4">Lid</td>
                                <td>Comments</td>
                                <td>Corrective actions</td>
                            </tr>
                            {/** DRAWING NUMB */}
                            <tr>
                                <td colSpan={3}>Drawing number</td>
                                <td>
                                    <input className="form-control" value={cart.lidDrawingNb}
                                        onChange={(e) => updateField(e, "lidDrawingNb")}></input>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidDrawingNbComs}
                                        onChange={(e) => updateField(e, "lidDrawingNbComs")}></textarea>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidDrawingNbAct}
                                        onChange={(e) => updateField(e, "lidDrawingNbAct")}></textarea>
                                </td>
                            </tr>
                            {/** GLOBAL APPEARANCE */}
                            <tr>
                                <td colSpan={3}>Global appearance</td>
                                <td>
                                    <input className="form-control" value={cart.lidGlobalAppNb}
                                        onChange={(e) => updateField(e, "lidGlobalAppNb")}></input>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidGlobalAppComs}
                                        onChange={(e) => updateField(e, "lidGlobalAppComs")}></textarea>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidGlobalAppAct}
                                        onChange={(e) => updateField(e, "lidGlobalAppAct")}></textarea>
                                </td>
                            </tr>
                            {/** HEADER */}
                            <tr className="text-center">
                                <td></td>
                                <td className="fw-bold table-info">Theoretical (mm)</td>
                                <td className="fw-bold table-info">Real (mm)</td>
                                <td className="fw-bold table-info">/</td>
                                <td></td>
                                <td></td>
                            </tr>
                            {/** FLUTE TYPE*/}
                            <tr>
                                <td>Flute type</td>
                                <td className="text-center">{boxInfo.cannelure_coiffe}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidFluteReal}
                                        onChange={(e) => updateField(e, "lidFluteReal", boxInfo.cannelure_coiffe)} style={{ width: "100px" }}></input>
                                </td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidFluteDelta}
                                        onChange={(e) => updateField(e, "lidFluteDelta")} style={{ width: "100px" }}></input>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidFluteComs}
                                        onChange={(e) => updateField(e, "lidFluteComs")}></textarea>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidFluteAct}
                                        onChange={(e) => updateField(e, "lidFluteAct")}></textarea>
                                </td>
                            </tr>
                            {/** THICKNESS */}
                            <tr>
                                <td>Thickness</td>
                                <td className="text-center">
                                    {getCannelureCoiffe(boxInfo)}
                                </td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidThickReal}
                                        onChange={(e) => updateField(e, "lidThickReal", getCannelureCoiffe(boxInfo))} style={{ width: "100px" }}></input>
                                </td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidThickDelta}
                                        onChange={(e) => updateField(e, "lidThickDelta", cart.lidThickReal)} style={{ width: "100px" }}></input>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidThickComs}
                                        onChange={(e) => updateField(e, "lidThickComs")}></textarea>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidThickAct}
                                        onChange={(e) => updateField(e, "lidThickAct")}></textarea>
                                </td>
                            </tr>
                            {/** LENGTH */}
                            <tr>
                                <td>Length (external dimensions)</td>
                                <td className="text-center">{boxInfo.l_dim_ext + (2 * getCannelureCoiffe(boxInfo))}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidLgtReal}
                                        onChange={(e) => updateField(e, "lidLgtReal", boxInfo.l_dim_ext + (2 * getCannelureCoiffe(boxInfo)))} style={{ width: "100px" }}></input>
                                </td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidLgtDelta}
                                        onChange={(e) => updateField(e, "lidLgtDelta")} style={{ width: "100px" }}></input>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidLgtComs}
                                        onChange={(e) => updateField(e, "lidLgtComs")}></textarea>
                                </td>
                                <td>
                                    <textarea className="form-control" value={cart.lidLgtAct}
                                        onChange={(e) => updateField(e, "lidLgtAct")}></textarea>
                                </td>
                            </tr>
                            {/** WIDTH */}
                            <tr>
                                <td>Width (external dimensions)</td>
                                <td className="text-center">{boxInfo.w_dim_ext + (2 * getCannelureCoiffe(boxInfo))}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidWdtReal}
                                        onChange={(e) => updateField(e, "lidWdtReal", boxInfo.w_dim_ext + (2 * getCannelureCoiffe(boxInfo)))} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidWdtDelta}
                                        onChange={(e) => updateField(e, "lidWdtDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidWdtComs}
                                        onChange={(e) => updateField(e, "lidWdtComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidWdtAct}
                                        onChange={(e) => updateField(e, "lidWdtAct")}></textarea></td>
                            </tr>
                            {/** FLAPS */}
                            <tr>
                                <td>Flaps height</td>
                                <td className="text-center">{boxInfo.hauteur_rabat_coiffe}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidFlatReal}
                                        onChange={(e) => updateField(e, "lidFlatReal", boxInfo.hauteur_rabat_coiffe)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidFlatDelta}
                                        onChange={(e) => updateField(e, "lidFlatDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidFlatComs}
                                        onChange={(e) => updateField(e, "lidFlatComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidFlatAct}
                                        onChange={(e) => updateField(e, "lidFlatAct")}></textarea></td>
                            </tr>
                            {/** CUT OUT DIM */}
                            <tr>
                                <td>Cut out dim</td>
                                <td className="text-center">{boxInfo.ech_l + " x " + boxInfo.ech_h}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidCoDimReal}
                                        onChange={(e) => updateField(e, "lidCoDimReal", boxInfo.ech_l + " x " + boxInfo.ech_h)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidCoDimDelta}
                                        onChange={(e) => updateField(e, "lidCoDimDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidCoDimComs}
                                        onChange={(e) => updateField(e, "lidCoDimComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidCoDimAct}
                                        onChange={(e) => updateField(e, "lidCoDimAct")}></textarea></td>
                            </tr>
                            {/** CUT OUT POS */}
                            <tr>
                                <td>Cut out position</td>
                                <td className="text-center" style={{ wordWrap: "break-word", overflowWrap: "break-word", maxWidth: "200px" }}>{boxInfo.ech_com}</td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidCoPosReal}
                                        onChange={(e) => updateField(e, "lidCoPosReal", boxInfo.ech_com)} style={{ width: "100px" }}></input></td>
                                <td className="center-input-td">
                                    <input className="form-control" value={cart.lidCoPosDelta}
                                        onChange={(e) => updateField(e, "lidCoPosDelta")} style={{ width: "100px" }}></input></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidCoPosComs}
                                        onChange={(e) => updateField(e, "lidCoPosComs")}></textarea></td>
                                <td>
                                    <textarea className="form-control" value={cart.lidCoPosAct}
                                        onChange={(e) => updateField(e, "lidCoPosAct")}></textarea></td>
                            </tr>

                            {/** CONCLUSION */}
                            <tr className="text-center fw-bold table-info">
                                <td colSpan="6">Conclusion</td>
                            </tr>
                            <tr>
                                <td className="center-input-td">Global status</td>
                                <td colSpan="5">
                                    <textarea className="form-control" value={cart.globalStatus}
                                        onChange={(e) => updateField(e, "globalStatus")}></textarea></td>
                            </tr>
                            <tr>
                                <td className="center-input-td">Open points</td>
                                <td colSpan="5">
                                    <textarea className="form-control" value={cart.openPoints}
                                        onChange={(e) => updateField(e, "openPoints")}></textarea></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        }

        {/**
         * E-CUBE PART
         */}
        {
            (boxInfo && typeMachine.startsWith("EC")) &&
            <Fragment>
                <div className="table-responsive">
                    <table className="table simple-border">
                        <tbody>
                            <tr className="text-center fw-bold table-primary" style={{ backgroundColor: "" }}>
                                <td colSpan="6">e-Cube process</td>
                            </tr>
                            <tr className="text-center fw-bold table-info">
                                <td colSpan="4">Measures</td>
                                <td>Comments</td>
                                <td>Corrective actions</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Drawing number</td>
                                <td><input className="form-control"></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Global appearance</td>
                                <td><input className="form-control"></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td colSpan={3}>Position of the glued flap</td>
                                <td><input className="form-control"></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr className="text-center">
                                <td></td>
                                <td className="fw-bold table-info">Theoretical (mm)</td>
                                <td className="fw-bold table-info">Real (mm)</td>
                                <td className="fw-bold table-info">/</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Flute type</td>
                                <td className="text-center">{boxInfo.cannelure_caisse}</td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td>Thickness</td>
                                <td className="text-center">
                                    {getCannelureCaisse(boxInfo)}
                                </td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td>Length (external dimensions)</td>
                                <td className="text-center">{boxInfo.l_dim_ext}</td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td>Width (external dimensions)</td>
                                <td className="text-center">{boxInfo.w_dim_ext}</td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td>Height (external dimensions)</td>
                                <td className="text-center">{boxInfo.haut_ext_brut}</td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td>Cutting starters</td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td>High level mark</td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td className="center-input-td"><input className="form-control" style={{ width: "100px" }}></input></td>
                                <td><textarea className="form-control"></textarea></td>
                                <td><textarea className="form-control"></textarea></td>
                            </tr>

                            {/** CONCLUSION */}
                            <tr className="text-center fw-bold table-info">
                                <td colSpan="6">Conclusion</td>
                            </tr>
                            <tr>
                                <td className="center-input-td">Global status</td>
                                <td colSpan="5"><textarea className="form-control"></textarea></td>
                            </tr>
                            <tr>
                                <td className="center-input-td">Open points</td>
                                <td colSpan="5"><textarea className="form-control"></textarea></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br></br>
                <div className="text-center">
                    <button className="btn btn-success">Save</button>
                </div>
            </Fragment>
        }

        <div className="card">
            <h5 className="text-center">Attachments</h5>

            {p.annexes.map((v, k) => {
                return v.typ_card === "cartonnier" && <div className="text-center" key={"cartAnnex" + k}>
                    <img src={v.img} />
                    <label>{v.name}</label>
                    {
                        (!v.new && !p.readOnly) &&
                        <img src={"/common/remove_icon.png"} className="cursor-pointer" title="Supprimer l'image ci-dessus"
                            style={{ marginLeft: "10px", width: "30px" }}
                            onClick={deleteAnnexe} data-key={k}></img>
                    }
                </div>
            })}

            {!p.readOnly &&
                <form onSubmit={addAnnexe} className="no-print">
                    <h5 className="text-center mt-3">Ajouter une image en annexe</h5>
                    <input className="form-control mb-3" name="legend" placeholder="Légende de l'image" required />
                    {!img && <PhotoOrFile setImg={setImg} />}
                    {img && <div className="mb-3">
                        Aperçu:<br></br>
                        <img src={img} className="mb-3" />
                        <div>
                            <button type="button" onClick={() => setImg()}
                                className="btn btn-outline-warning">Prendre ou choisir une autre image</button>
                        </div>
                    </div>}
                    <div className="text-center">
                        <button className="btn btn-success">Ajouter</button>
                    </div>
                </form>

            }
        </div>

        {
            boxInfo &&
            <div className="text-center mt-2">
                <button className="btn btn-success" onClick={save} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Save</button>
            </div>
        }

        <br></br>
        <style>
            {'.center-input-td input{ text-align: center; }'}
        </style>



    </Fragment>

}

export default PartTen;