import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { CustomToolTipInfo } from "../../common/smallComponents.js/CustomToolTip";
import { ArticleAutoComplete, isSideBarOpened, SideNavBarV3 } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { addOrRemoveOnCheck, getKeyString } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { currentEnDate, forceString, getFrFormat } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import Movement from "../../services/storehouse/Movement";
import Miscellaneous from "../../services/common/Miscellaneous";

const RotatingInventory = (props) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [list, setList] = useState([]);
    const [users, setUsers] = useState([]);
    const [tab, setTab] = useState(0);
    const user = JSON.parse(localStorage.getItem("user"));
    const readonly = user && !user.roles.includes("ROLE_STOREHOUSE_N1");
    const [userCounter, setUserCounter] = useState("");
    const [usersCounter, setUsersCounter] = useState([]);
    const [dateCount, setDateCount] = useState("");
    const [locToChange, setLocToChange] = useState([]);
    const [pin, setPin] = useState(isSideBarOpened());
    const [progession, setProgression] = useState("");
    const [value, setValue] = useState("");
    const [canScan, setCanScan] = useState(false);

    Moment.locale('fr');

    useEffect(() => {
        Movement.getRotatingInventoryList().then(
            (response) => {
                //console.log(response)
                if (response.err) setError("Il y a des erreurs dans les quantités renseignées dans la fiche article. "
                    + "Vous devez faire l'inventaire de ce qui a été corrigé par Focus avant de continuer."
                );
                setList(response.list);
                getInfos(response.list);
                setUsers( response.users );

                if( !response.err ){
                    var txt = "";
                    var cpt = 0;
                    for( let i=0; i<response.list.length; i++ )
                        if( forceString( response.list[i].ZN24PA ) === "2") cpt++;
                    
                    txt += cpt + " / " + response.list.length;
                    txt += " ~(" + ((cpt/response.list.length)*100).toFixed(1) + "%)";

                    setProgression(txt);
                }

                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );

        Miscellaneous.getStateByType("rotating_inventory_scan_ar").then(
            (r) => {if( r ) setCanScan( r.value1 === "true" ) }
        )
    }, []);

    const getInfos = (list) => {
        var users = [];

        for (let i = 0; i < list.length; i++) {
            if (list[i].alreadyInvt === "Oui" && list[i].ZN25PA != null) 
                if( list[i].alreadyInvt === "Oui" && !users.includes( list[i].ZN26PA ) ) users.push( list[i].ZN26PA );
        }

        //If nothing saved
        setDateCount(currentEnDate());
        setUsersCounter(users);
    }

    const getCss = (v) => {
        if (v.isValidated === "Oui") return " bg-success-subtle";
        return "";
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('alreadyInvt', {
            header: 'A imprimer',
            cell: (i) => <div className={"text-center" + getCss(i.row.original)} style={{ height: "100%" }}>
                <div className="p-3">
                    {forceString(i.row.original.coms).indexOf("-ERR ECO-") === -1 &&
                        <input className="form-check-input check cursor-pointer" type="checkbox" style={{ width: "30px", height: "30px" }}
                          data-id={String(i.row.original.CDARL)} onChange={(e) => checkToDo(e, i.row.id)} checked={i.getValue() === 'Oui'}></input>
                    }
                </div>
            </div>
        }));

        arr.push(columnHelper.accessor('isValidated', {
            header: 'Validé',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>
                {i.getValue()}
                {i.getValue() === "Oui" && <div className="fst-italic">Le {getFrFormat( i.row.original.ZN25PA, true )}</div>}
            </div>
        }));

        arr.push(columnHelper.accessor('isCommercial', {
            header: 'Pièce de commerce ?',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('CDARL', {
            header: 'Article',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ZN02PA', {
            header: 'Plan',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ZN04PA', {
            header: 'Renvois',
            filterFn: 'strWithEmpty',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ZN26PA', {
            header: 'Compteur',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>
                {i.getValue()}
                
                {/*<select className="form-select text-center mt-1" value={i.getValue()} disabled={i.row.original.alreadyInvt !== "Oui"}
                    onChange={(e) => { onFieldChange(e, i.row.id, "ZN26PA"); updateUsersList(e); }}>
                    <option value=""></option>
                    {users.map( (v, k) => {
                        return <option value={v.trigram}>{v.trigram}</option>
                    } )}
                </select> */}
            </div>
        }));

        arr.push(columnHelper.accessor('isBlocked', {
            header: 'Bloqué ?',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center" + getCss(i.row.original)}>{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('ZN27PA', {
            header: 'Qté comptée',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                <input className="form-control text-center" type="number" defaultValue={i.getValue()}
                    onBlur={(e) => onFieldChange(e, i.row.id, "ZN27PA")} />
            </div>
        }));

        arr.push(columnHelper.accessor('moved', {
            header: 'Delta',
            cell: (i) => <div style={{ height: "100%" }}
                className={"text-center" + getCss(i.row.original) 
                            + " " + ( i.getValue() && ( i.getValue() > 0 ? "text-primary" : i.getValue() < 0 ? "text-danger" : "" ) ) }>
                                {i.getValue()}
                                { i.getValue() !== 0 && <div className="fst-italic">{ (i.getValue() * i.row.original.PXREL).toFixed(2) } €</div> }
                            </div>
        }));

        arr.push(columnHelper.accessor('ZN28PA', {
            header: 'Commentaires',
            cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                {forceString(i.row.original.coms).indexOf("-ERR ECO-") === -1 &&
                    <textarea className="form-control" defaultValue={i.getValue()}
                        onBlur={(e) => onFieldChange(e, i.row.id, "ZN28PA")} />
                }
            </div>
        }));

        if (!readonly) {
            arr.push(columnHelper.accessor('xxx', {
                header: 'Stck vers Hors stck',
                cell: (i) => <div style={{ height: "100%" }} className={"text-center p-1" + getCss(i.row.original)}>
                    <input className="form-check-input cursor-pointer" type="checkbox" style={{ width: "30px", height: "30px" }}
                        onChange={(e) => changeLocation(e, i.row.id)} defaultChecked={locToChange.indexOf(i.row.id) !== -1} />
                </div>
            }));
        }


        return arr;
    }


    const changeArtLocation = () => {
        var arr = [];

        for( let i=0; i<locToChange.length; i++ )
            arr.push( list[ parseInt( locToChange[i] ) ] );
        
        confirmAlert({
            title: "Changement d'emplacement",
            message: "Confirmez-vous les modifications d'emplacement de "+locToChange.length+" article(s) ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        Movement.updateArticleLocation(arr).then(
                            () => {
                                window.location.reload();
                            }, (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });

        

    }

    const changeLocation = (e, k) => {
        setLocToChange(addOrRemoveOnCheck(locToChange, e, k));
    }

    const columns = getColumns();

    const checkToDo = (e, k) => {
        var arr = [...list];
        arr[k].ZN24PA = e.target.checked ? 1 : null;
        arr[k].alreadyInvt = e.target.checked ? "Oui" : null;
        setList(arr);

        //update database In rotating inventory && Inventory date & Inventory user if exists
        updateDB("ZN24PA", arr[k].ZN24PA, arr[k].CDARL);

        if (arr[k].ZN24PA === 1) {
            updateDB("ZN25PA", dateCount, arr[k].CDARL);
            updateDB("ZN26PA", userCounter, arr[k].CDARL);
        } else {
            updateDB("ZN25PA", null, arr[k].CDARL);
            updateDB("ZN26PA", null, arr[k].CDARL);
        }

    }

    function updateDB(field, value, ar) {
        Movement.updateRotatingInventoryList(field, value, ar);
    }

    const onFieldChange = (e, k, field) => {
        var arr = [...list];
        arr[k][field] = e.target.value;
        setList(arr);

        var value = e.target.value === "" ? null : e.target.value;

        updateDB(field, value, arr[k].CDARL);
    }

    const validateInventory = async () => {
        setError();

        var toValidate = [];
        var o, delta;

        var errUser = "";

        for (let i = 0; i < list.length; i++) {
            o = list[i];
            if (o.alreadyInvt && o.alreadyInvt === "Oui" && o.ZN26PA === userCounter) {
                //The focus logic is !=  of intranet logic
                delta = calculateDelta(o);

                if (delta === "") {
                    setError("Veuillez saisir toutes les quantités");
                    scrollToTop();
                    return;
                } else {
                    o.delta = delta * -1;
                    if (o.ZN28PA === null) o.ZN28PA = "";
                    toValidate.push(o);
                }
            }
        }

        if (toValidate.length === 0) {
            setError("Il n'y a rien à valider !");
            scrollToTop();
            return;
        }

        if (errUser !== "") {
            setError(errUser);
            scrollToTop();
            return;
        }


        confirmAlert({
            title: "Fin d'inventaire",
            message: "Confirmez-vous la validation d'inventaire, les mouvements seront effectués ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        Movement.validateRotatingInventoryList(toValidate).then(
                            () => {
                                window.location.reload();
                            }, (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });

    }

    function calculateTotal(v) {
        var tot = 0;
        if (v.ZN27PA && v.ZN27PA !== "") tot += parseFloat(v.ZN27PA);
        if (v.ZN29PA && v.ZN29PA !== "") tot += parseFloat(v.ZN29PA);
        if (v.nc && v.nc !== "") tot += parseFloat(v.nc);
        if (v.b2_qty && v.b2_qty !== "") tot += parseFloat(v.b2_qty);

        return tot;
    }

    function calculateDelta(v) {
        if ( v === undefined || v.ZN27PA === null || v.ZN27PA === "") return "";
        return calculateTotal(v) - parseFloat(v.QTSTL);
    }

    function calculateDeltaInValue(v) {
        var deltaInQty = calculateDelta(v);
        if (deltaInQty === "" || parseFloat(deltaInQty) >= 0) return "";

        return Math.abs((parseFloat(deltaInQty) * parseFloat(v.PXREL))).toFixed(2) + " €";
    }

    function getDeltaColor(v) {
        var delta = calculateDelta(v);
        if (delta < 0) return "bg-danger-subtle";
        if (delta > 0) return "bg-success-subtle";
        return "";
    }

    const canScanState = (state) => {
        setCanScan(state);
        Miscellaneous.updateState("rotating_inventory_scan_ar", forceString(state) );
    }

    const selectToAssign = (e) => {
        e.preventDefault();
        var qtyToAssign = e.target[0].value;
        var arr = [...list];
        const arrFiltered = arr.filter((v) => v.alreadyInvt !== "Oui" && v.ZN26PA === null);

        var selectedItems = [];
        while (selectedItems.length < qtyToAssign && arrFiltered.length > 0) {
            const randomIndex = Math.floor(Math.random() * arrFiltered.length);
            selectedItems.push(arrFiltered.splice(randomIndex, 1)[0]);
        }

        if(selectedItems.length > 0) {
            for (var i = 0; i < selectedItems.length; i++) {
                
                if(selectedItems[i].CDARL){
                    selectedItems[i].ZN24PA = 1;
                    selectedItems[i].alreadyInvt = "Oui";
                }
                setList([...list]);
            }
        }
        document.getElementById("qtyToAssign").value = ""
    }
    

    const assignUsers = (e) => {
        e.preventDefault();

        var users = [...usersCounter];

        const formElements = e.target.trigrams;

        const checkedElements = Array.from(formElements).filter((element) => element.type === "checkbox" && element.checked);
        const usersAffected = checkedElements.map((element) => element.value);
        
        const toCount = Array.from(list).filter( (element) => element.alreadyInvt === "Oui" && element.ZN26PA === null )

        if( toCount.length < usersAffected.length ){
            setError( usersAffected.length + " personnes pour " + toCount.length + " articles! Réduisez le nombre de compteurs ou ajoutez des articles");
            return;
        }

        var nbAr = Math.floor(toCount.length / usersAffected.length);
        var cursor = 0;
        var articleCount = 0;
        var arr = [];

        var temp = [...list];
        
        for( let i=0; i<toCount.length; i++ ){
            arr.push({ar: toCount[i].CDARL, trigram: usersAffected[cursor]});

            //zn26pa
            temp[ getKeyString(temp, "CDARL", toCount[i].CDARL) ].ZN26PA = usersAffected[cursor];

            articleCount++;
            if( articleCount >= nbAr && cursor < usersAffected.length - 1 ){
                cursor++;
                articleCount=0;
            } 
        }

        setList( temp );

        for( let i=0; i<usersAffected.length; i++ ) if( !users.includes(usersAffected[i]) ) users.push( usersAffected[i] )
        setUsersCounter( users );

        //save in database
        Movement.updateRotatingInventoryCounter(arr).then(
            () => {
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        )
    }

    return <div className="bg-see navbarV3 right-side">
        <SideNavBarV3 setPin={setPin} content={<div className="ps-3 pe-1">
            <div className="mb-3 text-center">
                <h5>Progression:<br></br>{progession}</h5>
            </div>

            <div className="mb-3">
                <a href="#" className="text-dark " onClick={() => window.print()}>Imprimer</a>
            </div>

            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Affecter les compteurs
                    </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                        <form onSubmit={assignUsers}>
                        {users.map( (v, k) => {
                            return <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="trigrams" value={v.trigram} />
                            <label className="form-check-label">{v.trigram}</label>
                          </div>
                        } )}

                        <div className="text-center">
                            <button className="btn btn-secondary">Affecter</button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>

            </div>

           
            <div className="accordion accordion-flush" id="accordionFlushExample3">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" 
                            data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" 
                            aria-expanded="false" aria-controls="flush-collapseThree">
                        Ajouter une quantité a affecter
                    </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" 
                        data-bs-parent="#accordionFlushExample3">
                    <div className="accordion-body">
                    <form onSubmit={selectToAssign}>
                    <input className="form-control mb-2" id="qtyToAssign" min={0} readOnly={!list || list.length === 0} type="number" name="selectToAssign" placeholder="Nombre d'articles" />
                        <div className="text-center">
                            <button className="btn btn-secondary">Selectionner</button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>

            <div className="accordion accordion-flush" id="accordionFlushExample2">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" 
                            data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" 
                            aria-expanded="false" aria-controls="flush-collapseTwo">
                        Info
                    </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" 
                        data-bs-parent="#accordionFlushExample2">
                    <div className="accordion-body">
                        Cette liste comporte tous les articles:
                        <ul>
                            <li>Noté en emplacement "Stock mag." dans la fiche article</li>
                            <li>Non bloqués "Complet"</li>
                            <li>Ayant un PMP supérieur 0</li>
                        </ul>
                    </div>
                    </div>
                </div>

            </div>

            {locToChange.length > 0 &&
                <div className="mb-3">
                    <a href="#" className="text-dark mb-3"
                        onClick={changeArtLocation}>Modifier les emplacement stck vers hors stck</a>
                </div>
            }
            <br></br>
            {!readonly && <div className="text-center">
                <form className="mb-3" onSubmit={(e) => {e.preventDefault(); updateDB("ZN24PA", 1, e.target.ar.value)}}>
                    <h6 className="text-center">Ajouter un article à inventorier</h6>

                    <div>
                        <ArticleAutoComplete value={value} setValue={setValue} required/>
                    </div>

                    <div className="text-center mt-3 mb-5">
                        <button className="btn btn-outline-secondary">Ajouter</button>
                    </div>
                </form>

                { canScan && 
                    <button className="btn btn-outline-warning mb-3"
                        onClick={()=>canScanState(false)}>Bloquer le scan libre</button>}

                { !canScan && 
                    <button className="btn btn-outline-success mb-3"
                        onClick={()=>canScanState(true)}>Débloquer le scan libre</button>}

                <button className="btn btn-success mt-5"
                onClick={validateInventory} disabled={loading}>
                {loading && <ButtonWaiting />}
                Valider cet inventaire</button>
            </div>}
        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

            <h4 className="text-center no-print">Inventaire tournant du {getFrFormat(dateCount, true)} par {userCounter}</h4>

            {loading && <WaitingRoundSnippers />}<br></br>

            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            <ul className="nav nav-tabs no-print" style={{ marginTop: "-50px" }}>
                <li className="nav-item" style={{ zIndex: "2000" }}>
                    <a className={"nav-link " + (tab === 0 ? "active" : "")}
                        href="#" onClick={() => setTab(0)} data-tab="0">Liste Inventaire Tournant</a>
                </li>

                <li className="nav-item" style={{ zIndex: "2000" }}>
                    <a className={"nav-link " + (tab === 1 ? "active" : "")}
                        href="#" onClick={() => setTab(1)} data-tab="2">Liste à  imprimer</a>
                </li>

            </ul>

            {tab === 0 &&
                <ReactTable 
                    columns={columns} data={list} 
                    top0={true} origin="rotating-inventory"  tdStyle={{ padding: "0" }} />
            }

            {tab === 1 && <div>
                <div className="fw-bold mt-auto">
                    <table className="table table-bordered text-center">
                        <tbody>
                            <tr>
                                <td className="grey-back" style={{ width: "33.33%" }}>Donneur d'ordre</td>
                                <td className="grey-back" rowSpan={2} style={{ width: "33.33%" }}>Inventaire tournant
                                    <br></br>Date d'édition: {getFrFormat(dateCount, true)}
                                </td>
                                <td style={{ width: "33.33%" }}>Nom & Signature du compteur</td>
                            </tr>

                            <tr>
                                <td className="grey-back" style={{ height: "50px" }}>
                                    {user.last_name} {user.first_name}
                                </td>
                                <td>
                                    <div className="col-6 offset-3 no-print">
                                        <select className="form-select text-center mt-1" 
                                            onChange={(e) => {setUserCounter(e.target.value)}}>
                                            <option value=""></option>
                                            {usersCounter.map( (v, k) => {
                                                return <option key={"usc"+k} value={v}>{v}</option>
                                            } )}
                                        </select> 
                                    </div>
                                    <div className="print-only text-start ms-3">
                                        {userCounter}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



                <table className="table table-bordered border-dark-subtle text-center mb-3">
                    <thead>
                        <tr>
                            <th rowSpan={2} className="ar-col">AR</th>
                            <th rowSpan={2} className="ref-col">DÉSIGNATION / PLAN</th>
                            <th rowSpan={2} className="ref-col">RENVOIS</th>
                            <th colSpan={3}>QTE DEPORTEES</th>
                            <th>QTE</th>
                            <th rowSpan={2} className="qty-col">TOTAL</th>
                            <th rowSpan={2} className="qty-col no-print">DELTA</th>
                            <th rowSpan={2} className="qty-col">Commentaires</th>
                        </tr>
                        <tr>
                            <th className="qty-col">FNC</th>
                            <th className="qty-col">B+2</th>
                            <th className="qty-col d-flex" style={{position: "relative"}}>
                                <div className="text-center" style={{width: "100%"}}>FOURNISSEURS</div>
                                <CustomToolTipInfo imgStyle={{position: "absolute", right: "0"}}
                                    props={{ tooltipText: "<ul className='text-start'><li>COMATEL</li><li>CHASSENEUIL</li></ul>"}}/>
                            </th>
                            <th className="qty-col">STOCK</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((v, k) => {
                            return (v.ZN24PA === 1 && ( v.ZN26PA === userCounter ) ) && <tr key={"inv" + k}>
                                <td className="in_inventory">{v.CDARL}</td>
                                <td>{v.ZN02PA}</td>
                                <td>{v.ZN04PA}</td>
                                <td>{v.nc || 0}</td>
                                <td>{v.b2_qty || 0}</td>
                                <td>
                                    <input className="no-print form-control ms-auto me-auto text-center counted_qty" type="number" style={{ width: "100px" }}
                                        defaultValue={v.ZN29PA || 0} onBlur={(e) => onFieldChange(e, k, "ZN29PA")} />
                                    <div className="print-only">{v.ZN29PA || 0}</div>
                                </td>
                                <td>
                                    <input className="no-print form-control ms-auto me-auto text-center counted_qty" type="number" style={{ width: "100px" }}
                                        defaultValue={v.ZN27PA} onBlur={(e) => onFieldChange(e, k, "ZN27PA")} />
                                    <div className="print-only">{v.ZN27PA}</div>
                                </td>
                                <td className="text-center fw-bold">
                                    <div>{calculateTotal(v)}</div>
                                    <div className="fst-italic fw-normal no-print">Focus: {v.QTSTL}</div>
                                </td>
                                <td className={"text-center no-print " + getDeltaColor(v)}>
                                    <div>{calculateDelta(v)}</div>
                                    <div className="fst-italic">{calculateDeltaInValue(v)}</div>
                                </td>
                                <td>
                                    {forceString(v.coms).indexOf("-ERR ECO-") === -1 &&
                                        <Fragment>
                                            <textarea className="form-control no-print"
                                                defaultValue={v.ZN28PA} onBlur={(e) => onFieldChange(e, k, "ZN28PA")} />
                                            <div className="print-only">{v.ZN28PA}</div>
                                        </Fragment>
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

            </div>}
        </div>
    </div>
}

export default RotatingInventory;