import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import { SmallFrame } from "../../../common/smallComponents.js/CustomDivs";
import { SideNavBarV3, isSideBarOpened } from "../../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import Inputs from "../../../common/smallComponents.js/Inputs";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { addOrUpdateObj, removeAndReturnArray } from "../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import getUrlParam, { compareTwoDatesObj, forceString, fuzzySearch, getDate, getEnFormat, getFrFormat } from "../../../functions/StrFunctions";
import errorManagement from "../../../services/errorManagement";
import BusinessDao from "../../../services/machines/BusinessDao";
import transport from "../../../services/supplyChain/transport";
import PickingPanel from "./PickingPanel";

const NewRequest = (p) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const changeBusiness = user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_WRITE_FM"));
    const [shipmentReq, setshipmentReq] = useState();

    const [search, setSearch] = useState(false);

    const [machines, setMachines] = useState([]);
    const [selectedMachines, setSelectedMachines] = useState([]);
    const [form, setForm] = useState();
    const [formOrigin, setFormOrigin] = useState();
    const [formOriginChange, setFormOriginChange] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);

    const [businessInfo, setBusinessInfo] = useState({});
    const [addressBook, setAddressBook] = useState();
    const [contact, setContact] = useState();
    const [contactRes, setContactRes] = useState([]);

    const [typePack, setTypePack] = useState([]);
    const [displayDiff, setDisplayDiff] = useState(false);

    const [ picking, setPicking ] = useState([]);
    const [ openPickingPanel, setOpenPickingPanel ] = useState(false);
    
    useEffect(() => {
        var id = getUrlParam(p.props, "id");

        if (id) setIsUpdate(true);

        transport.getForm(id).then(
            (r) => {
                if (r.machines !== null) {
                    setMachines(JSON.parse(r.machines));
                    setSelectedMachines(JSON.parse(r.machines));

                    //Retrieve all machines of this business if found
                    //var basicCode = r.business.substring(0, r.business.length - 1) + "0";
                    BusinessDao.getMachines(r.business).then(
                        (r) => {
                            setBusinessInfo(r[0])
                            var arr = [];
                            r.map(v => arr.push({ value: v.no_machine, label: v.no_machine + " (" + v.typ_config + ")" }));
                            setMachines(arr);
                        }, () => { }
                    )
                }

                setshipmentReq(r.shipmentReqId);
                if( r.picking ) setPicking( r.picking );

                setForm(r);
                setFormOrigin({ ...r });
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setLoading(false);
            }
        )

        //Get all contacts
        BusinessDao.getAddressBook().then(
            (r) => {
                setAddressBook(r);
            }, (error) => {
                console.error(error);
            }
        )
    }, []);

    /** On any change in form */
    const onFormChange = (field, e) => {
        var val = !e.target ? e : e.target.value;
        var o = { ...form };
        o[field] = val;
        setForm(o);

        //If we change the shipment track it
        if (form.id) checkChange(o, field, e);

        if (val === "focus") checkAddress();

        if (field === "packer") updateRecipientOnPackerClick(val, o)
    }

    // On any change exception for machine V2
    const onAnychange = (e, field) => {
        var val = e.target.value;
        var o = { ...form };
        o[field] = val;
        setForm(o);

        if( isUpdate ) trackChange(e, field, val);

        if (field === "packer") updateRecipientOnPackerClick(val.toString(), o)
    }

    // On change log it
    const trackChange = (e, field, newVal) => {
        var arr = [...formOriginChange];

        if (e.target.getAttribute("type") === "date" && getEnFormat(formOrigin[field], true) !== getEnFormat(newVal, true)) {
            var c = {
                field: field,
                oldVal: getEnFormat(formOrigin[field], true),
                newVal: getEnFormat(newVal, true),
                label: e.target.getAttribute("data-label") + ( e.target.getAttribute("data-label2") || "" )
            }

            setFormOriginChange(addOrUpdateObj(arr, "field", field, c));

        } else if (e.target.getAttribute("type") === "text" && newVal.trim().toLowerCase() !== forceString(formOrigin[field]).trim().toLowerCase()) {
            var c = {
                field: field,
                oldVal: formOrigin[field],
                newVal: newVal,
                label: e.target.getAttribute("data-label") + ( e.target.getAttribute("data-label2") || "" )
            }

            setFormOriginChange(addOrUpdateObj(arr, "field", field, c));
        } else {
            const index = arr.findIndex(item => item.field === field);
            setFormOriginChange(removeAndReturnArray(arr, index))
        }
    }

    //Cancel change
    const cancelChange = (k) => {
        var c = [...formOriginChange];
        var f = {...form};
        var elt = c[k];

        // Update the original form
        f[ elt.field ] = elt.oldVal;
        setForm(f);

        //Delete the change in the table
        const index = c.findIndex(item => item.field === elt.field);
        setFormOriginChange(removeAndReturnArray(c, index))
        if( c.length <= 1 ) setDisplayDiff(false);
    }

    const updateRecipientOnPackerClick = (packer, o) => {
        if (!businessInfo) return;

        //1 = emballeur, 2 = client, 3 = B+, 4 = autre
        if (packer === "1" || packer === "4") {
            o.cn_recip = "";
            o.country_recip = "";
            o.address_recip = "";
            o.cp_recip = "";
            o.city_recip = "";
            o.comp_add_recip = "";
            o.ln_recip = "";
            o.fn_recip = "";
            o.tel_recip = "";
            o.mail_recip = "";

            o.cn_sender = "B+ Equipment - Sealed Air";
            o.country_sender = "France";
            o.address_sender = "465, Avenue du Col de l'Angle";
            o.cp_sender = "13420";
            o.city_sender = "Gémenos";
            o.comp_add_sender = "";
            o.ln_sender = user.last_name;
            o.fn_sender = user.first_name;
            o.tel_sender = user.phone_nb || "";
            o.mail_sender = user.email;
        } else if (packer === "2") {
            var b = businessInfo;

            o.cn_recip = b.societe_livrais;
            o.country_recip = b.pays_livrais;
            o.address_recip = b.adress_livrais;
            o.cp_recip = b.cp_livrais;
            o.city_recip = b.ville_livrais;
            o.comp_add_recip = b.adress_comp_livrais;
            o.ln_recip = b.nom_chef_proj;
            o.fn_recip = b.prenom_chef_proj;
            o.tel_recip = b.mobile_chef_proj || b.fixe_chef_proj;
            o.mail_recip = b.mail_chef_proj;

            o.cn_sender = "B+ Equipment - Sealed Air";
            o.country_sender = "France";
            o.address_sender = "465, Avenue du Col de l'Angle";
            o.cp_sender = "13420";
            o.city_sender = "Gémenos";
            o.comp_add_sender = "";
            o.ln_sender = user.last_name;
            o.fn_sender = user.first_name;
            o.tel_sender = user.phone_nb || "";
            o.mail_sender = user.email;

            setForm(o);
        } else if (packer === "3") {
            o.cn_recip = "B+ Equipment - Sealed Air";
            o.country_recip = "France";
            o.address_recip = "465, Avenue du Col de l'Angle";
            o.cp_recip = "13420";
            o.city_recip = "Gémenos";
            o.comp_add_recip = "";
            o.ln_recip = user.last_name;
            o.fn_recip = user.first_name;
            o.tel_recip = user.phone_nb || "";
            o.mail_recip = user.email;

            o.cn_sender = "";
            o.country_sender = "";
            o.address_sender = "";
            o.cp_sender = "";
            o.city_sender = "";
            o.comp_add_sender = "";
            o.ln_sender = "";
            o.fn_sender = "";
            o.tel_sender = "";
            o.mail_sender = "";

            setForm(o);
        }
    }

    //To delete
    const checkChange = (obj, field, e) => {
        var label = e.target.parentElement.getElementsByClassName("input-group-text")[0].innerText;
        if (obj[field] !== formOrigin[field]) {
            var c = {
                field: field,
                oldVal: formOrigin[field],
                newVal: obj[field],
                label: label
            }

            var arr = [...formOriginChange];
            setFormOriginChange(addOrUpdateObj(arr, "field", field, c));
        }
    }

    const checkChangeBusiness = (obj, field, e) => {
        var label = e.target.parentElement.getElementsByClassName("input-group-text")[0].innerText;
        if (obj[field] !== businessInfo[field]) {
            var c = {
                field: field,
                oldVal: businessInfo[field],
                newVal: obj[field],
                label: label
            }

            var arr = [...formOriginChange];
            setFormOriginChange(addOrUpdateObj(arr, "field", field, c));
        }
    }

    /**
     * If no address set fill in with Focus info
     */
    function checkAddress() {


        if (!o.cn_recip && typePack.length > 0) {
            var o = { ...form };
            var b = typePack[0];

            o.cn_recip = b.cn_recip;
            o.country_recip = b.country_recip;
            o.address_recip = b.address_recip;
            o.cp_recip = b.cp_recip;
            o.city_recip = b.city_recip;
            o.comp_add_recip = b.comp_add_recip;
            o.ln_recip = b.ln_recip;
            o.fn_recip = b.fn_recip;
            o.tel_recip = b.tel_recip;
            o.mail_recip = b.mail_recip;

            setForm(o);
        }


    }

    /** Check business */
    const checkBusiness = async (e) => {
        setError();
        var basicCode = e.target.value.trim();

        if (businessInfo && forceString( businessInfo.affaire ).trim() === basicCode.trim()) return;

        var o = { ...form };

        o.machines = null;
        o.other_pack_id = null;

        setMachines([]);
        setSelectedMachines([]);
        setTypePack([]);
        // setSelectedFocusPick([]);

        if (basicCode.trim() !== "") {

            setSearch(true);
            //Get machines infos and addresses
            BusinessDao.getMachines(basicCode).then(
                (r) => {
                    if (r.length === 0) {
                        o.machines = null;
                        setSearch(false);
                    } else {
                        var b = r[0];
                        o.business = e.target.value.trim().toUpperCase();
                        o.cn_recip = b.societe_livrais;
                        o.country_recip = b.pays_livrais;
                        o.address_recip = b.adress_livrais;
                        o.cp_recip = b.cp_livrais;
                        o.city_recip = b.ville_livrais;
                        o.comp_add_recip = b.adress_comp_livrais;
                        o.ln_recip = b.nom_chef_proj;
                        o.fn_recip = b.prenom_chef_proj;
                        o.tel_recip = b.mobile_chef_proj || b.fixe_chef_proj;
                        o.mail_recip = b.mail_chef_proj;

                        setBusinessInfo(b);

                        var arr = [];
                        r.map(v => arr.push({
                            value: v.no_machine,
                            label: v.no_machine + " (" + v.typ_config + ")",
                            disabled: shipmentReq !== null
                        }));
                        setMachines(arr.length === 0 ? null : arr);
                        setSearch(false);
                    }
                }, (error) => {
                    o.business = e.target.value.trim().toUpperCase();
                    setSearch(false);
                }
            )
        }

        setForm(o);
    }


    /**
     * 
     * @param {*} e 
     */
    const onMachineChoose = (e) => {
        var o = { ...form };

        if (isUpdate) {
            var arr = [...formOriginChange];

            const oldValues = JSON.parse(formOrigin.machines).map(v => v.value);
            const newValues = e.map(v => v.value);

            const allOldInNew = oldValues.every(val => newValues.includes(val));
            const allNewInOld = newValues.every(val => oldValues.includes(val));

            if (!allOldInNew || !allNewInOld) {
                var c = {
                    field: "machines",
                    oldVal: oldValues.join(", "),
                    newVal: newValues.join(", "),
                    label: "Machines à expédier"
                }

                setFormOriginChange(addOrUpdateObj(arr, "field", "machines", c));
            } else {
                const index = arr.findIndex(item => item.field === "machines");
                setFormOriginChange(removeAndReturnArray(arr, index))
            }

        }

        o.machines = JSON.stringify(e);
        setForm(o);
    }

    const send = (e, send) => {
        e && e.preventDefault();

        setError();
        var err = checkBeforeSave();
        if (err !== "") {
            setError(err);
            scrollToTop();
            return;
        }

        //Check if date after 48h
        const lessThan48h = compareTwoDatesObj(new Date(), new Date(form.date_load)) < 2

        if (lessThan48h) {
            confirmAlert({
                title: "Expédition en moins de 48h",
                message: "Etes-vous sûr de vouloir expédier ceci en moins de 48h ?",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => { doSave(send, lessThan48h); }
                    },
                    {
                        label: "Non"
                    }
                ]
            });
        } else {
            doSave(send, lessThan48h);
        }
    }

    function checkBeforeSave() {
        var err = "";

        if (!form.business || form.business.trim() === "") err += "Inscrivez le code affaire<br>"
        if (!form.type_pack || form.type_pack.trim() === "") err += "Choisissez le type de colis<br>"

        if (form.type_pack.trim() === "machine") {
            if (form.type_pack && (!form.machines || form.machines === "" || JSON.parse(form.machines).length === 0))
                err += "Choisissez les numéros de machine<br>";
            //You have to check it only if it's a client delivery
            if (forceString(form.packer) === "2" && businessInfo.decharg_manu && businessInfo.decharg_manu.toLowerCase().indexOf("connu") !== -1)
                err += "Le déchargement n'est pas connu, vous devez saisir un côté avant d'envoyer la demande<br>";
        }

        // If it's for other package, choose picking content
        if( form.type_pack.trim() !== "machine" && picking.length === 0 ){
            setOpenPickingPanel( true );
            return;
        }

        return err;
    }

    function doSave(send, lessThan48h) {
        setSaving(true);
        transport.saveForm(form, send, formOriginChange, businessInfo, lessThan48h, picking).then(
            (r) => {
                window.location.href = "/supply-chain/tools/expedition";
            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setSaving(false);
            }
        )
    }

    const updateBusinessField = (field, value) => {
        if (!changeBusiness || !businessInfo) return;

        setError();
        BusinessDao.updateBusinessField(businessInfo.id_aff, field, value, "text", true).then(
            (response) => { },
            (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setLoading(false);
            }
        );
    }

    const onBusinessChange = (field, value, e) => {
        var o = { ...businessInfo };
        o[field] = value;
        setBusinessInfo(o);

        //If we change the shipment track it
        if (form.id) checkChangeBusiness(o, field, e);
    }

    const searchContact = (e) => {
        setContactRes(fuzzySearch(addressBook, e.target.value));
    }

    const chooseContact = (k) => {
        var res = addressBook[k];

        var f = contact.field === "recipient" ? "_recip" : "_sender";
        var oldValue = form["address" + f]

        var o = { ...form }
        o["cn" + f] = res.company;
        o["country" + f] = res.country;
        o["address" + f] = res.address;
        o["cp" + f] = res.postal_code;
        o["city" + f] = res.city;
        o["comp_add" + f] = res.add_address;
        o["ln" + f] = res.project_man_ln;
        o["fn" + f] = res.project_man_fn;
        o["tel" + f] = res.project_man_mobile || res.project_man_phone;
        o["mail" + f] = res.project_man_mail;

        if (form.id) {
            var c = {
                field: contact.field + "_contact",
                oldVal: "" + oldValue,
                newVal: res.address,
                label: "Adresse et/ou contact " + contact.field === "recipient" ? "du destinataire" : "de l'expéditeur"
            }

            var arr = [...formOriginChange];
            setFormOriginChange(addOrUpdateObj(arr, "field", c.field, c));
        }

        setForm(o);
        setContact();

    }

    const chooseContactFocus = (k) => {
        var res = typePack[k];

        var f = contact.field === "recipient" ? "_recip" : "_sender";
        var oldValue = form["address" + f]

        var o = { ...form }
        o["cn" + f] = res.cn_recip;
        o["country" + f] = res.country_recip;
        o["address" + f] = res.address_recip;
        o["cp" + f] = res.cp_recip;
        o["city" + f] = res.city_recip;
        o["comp_add" + f] = res.comp_add_recip;
        o["ln" + f] = res.ln_recip;
        o["fn" + f] = res.fn_recip;
        o["tel" + f] = res.tel_recip;
        o["mail" + f] = res.mail_recip;

        if (form.id) {
            var c = {
                field: contact.field + "_contact",
                oldVal: "" + oldValue,
                newVal: res.address,
                label: "Adresse et/ou contact " + contact.field === "recipient" ? "du destinataire" : "de l'expéditeur"
            }

            var arr = [...formOriginChange];
            setFormOriginChange(addOrUpdateObj(arr, "field", c.field, c));
        }

        setForm(o);
        setContact();

    }

    // On change picking submit or not the form
    useEffect( () => {
        if( picking.length > 0 )
            setOpenPickingPanel( false );            
        
    }, [picking] )

    return <div className="bg-see custom-bootstrap" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
        <SideNavBarV3 setPin={setPin} content={<div>
            <div className="img-lab mb-3">
                <img src="/img/expeditions/boat.png"></img>
                <div className="ms-2 fw-bold title">Demande d'expédition</div>
            </div>

            <div className="text-center mb-3">
                <a href="/supply-chain/tools/expedition?page=tracking&all=true">Annuler et revenir au tableau</a>
            </div>

            <div className="text-center mb-5">
                <a href="/supply-chain/tools/expedition">Annuler et revenir à l'accueil</a>
            </div>

            {formOriginChange.length > 0 &&
                <div className="text-center mb-3">
                    <a href="#" onClick={(e) => setDisplayDiff(true)}>Visualiser les modifications</a>
                </div>
            }

        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h4 className="text-center mb-4">{isUpdate ? "Mise à jour de d" : "D"}emande expédition</h4>

            {shipmentReq && <div className="alert alert-info text-center fw-bold">
                Votre demande d'expédition ayant été traité par la logistique sous la ref. TRANS{shipmentReq},
                les champs, affaire, machine et type de colis ne sont pas modifiable. Si un des champs sont
                erronés, annulez cette demande et faites en une autre.
            </div>}

            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}

            {
                form && <Fragment>
                    <form onSubmit={(e) => send(e, true)} id="form" onKeyDown={(e) => { if (e.code === 'Enter') { e.preventDefault(); return false; } }}>


                        {/** 2 PARTS */}
                        <div className="d-flex">
                            {/** REFERENCES + EXPEDITEUR */}
                            <div className="col-6" style={{ minWidth: "630px" }}>
                                {/** REFERENCES */}
                                <div className="see-underline mb-3" style={{ width: "70%" }}>
                                    <h4>Références</h4>
                                </div>

                                <div style={{ width: "600px", height: "400px" }}>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>N° demande</span>
                                        <input type="text" className="form-control" value={form.id ? "EXP" + form.id : "Fourni à l'envoi"} readOnly />

                                        <span className="input-group-text">en date du {getFrFormat(form.date_request)} </span>
                                    </div>

                                    <div className="input-group mb-3">
                                        <span className="input-group-text" style={{ width: "180px" }}>Demandeur</span>
                                        <input type="text" className="form-control" value={user.last_name + " " + user.first_name} readOnly />
                                    </div>

                                    {/** AFFAIRES */}
                                    <Inputs.InputGroup
                                        spanWidth="180px" label="Affaire*" value={form.business}
                                        placeholder="Séparer les codes affaires par une virgule si besoin"
                                        onChange={(e) => onAnychange(e, "business")} onBlur={checkBusiness}
                                        autoFocus={getUrlParam(p.props, "id") === null} required readOnly={shipmentReq !== null}
                                    />

                                    {/** RECHERCHE */}
                                    {search && <div className="mb-3"><ButtonWaiting />Recherche en cours...</div>}

                                    {/** TYPE DE COLIS */}
                                    <Inputs.SelectGroup spanWidth="180px" label="Type de colis" value={form.type_pack}
                                        disabled={shipmentReq !== null} onChange={(e) => onAnychange(e, "type_pack")}
                                        options={[{ value: "machine", label: "Machine", display: machines.length > 0 }, { value: "other", label: "Autre colis", display: true }]}
                                    />

                                    {/** MACHINES */}
                                    {(form.type_pack === "machine") &&
                                        <Select className="mb-3" options={machines} isMulti defaultValue={selectedMachines}
                                            closeMenuOnSelect={false} onChange={onMachineChoose} required={form.type_pack === "machine"} />}

                                    {/** LIBELLE EXP */}
                                    <Inputs.InputGroup
                                        spanWidth="180px" label="Libellé de l'expédition*" value={form.exp_lab}
                                        onChange={(e) => onAnychange(e, "exp_lab")} required />

                                    {/** LIVRAISON */}
                                    <div className="mb-3">
                                        <div className="form-check form-check-inline fw-bold" style={{ width: "100px" }}>Livraison</div>

                                        {/** LIVRAISON UE */}
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ue" data-label="Livraison"
                                                onChange={(e) => onAnychange(e, "ue")} value="ue" checked={form.ue === "ue"} required />
                                            <label className="form-check-label fw-bold" htmlFor="inlineRadio1">UE</label>
                                        </div>

                                        {/** LIVRAISON HORS UE */}
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ue" data-label="Livraison"
                                                onChange={(e) => onAnychange(e, "ue")} value="ext" checked={form.ue === "ext"} />
                                            <label className="form-check-label fw-bold" htmlFor="inlineRadio2">Hors UE</label>
                                        </div>

                                    </div>

                                    {/** ENVOI */}
                                    <div className="">
                                        {/** ENVOI EMABLLEUR */}
                                        <div className="form-check form-check-inline fw-bold" style={{ width: "100px" }}>Envoi</div>
                                        {form.type_pack && form.type_pack === "machine" &&
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="packer" data-label="Envoi"
                                                    onChange={(e) => onAnychange(e, "packer")} value="1" checked={forceString(form.packer) === "1"} required />
                                                <label className="form-check-label" htmlFor="inlineRadio1">chez <strong>l'emballeur</strong></label>
                                            </div>
                                        }

                                        {/** ENVOI CLIENT */}
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="packer" data-label="Envoi"
                                                onChange={(e) => onAnychange(e, "packer")} value="2" checked={forceString(form.packer) === "2"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">chez <strong>le client</strong></label>
                                        </div>

                                        {/** ENVOI B+ */}
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="packer" data-label="Envoi"
                                                onChange={(e) => onAnychange(e, "packer")} value="3" checked={forceString(form.packer) === "3"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">chez <strong>B+</strong></label>
                                        </div>

                                        {/** ENVOI AUTRE*/}
                                        {form.type_pack !== "machine" && <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="packer" data-label="Envoi"
                                                onChange={(e) => onAnychange(e, "packer")} value="4" checked={forceString(form.packer) === "4"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2"><strong>autre</strong></label>
                                        </div>}
                                    </div>

                                </div>

                                {/** EXPEDITEUR */}
                                <div className="see-underline mb-3 d-flex" style={{ width: "70%" }}>
                                    <h4>Expéditeur</h4>
                                    {addressBook &&
                                        <img className="ms-2 see-img w-30 cursor-pointer" src="/common/contacts.png"
                                            onClick={() => setContact({ field: "sender" })} />}
                                </div>

                                <div style={{ width: "600px" }}>
                                    <div className="input-group mb-3">
                                        {/** RAISON SOC. EXPEDITEUR */}
                                        <Inputs.Input spanWidth="180px" label="Raison sociale" value={form.cn_sender}
                                            onChange={(e) => onAnychange(e, "cn_sender")} required={true} labelNext=" (expéditeur)"/>

                                        {/** ADRESSE. EXPEDITEUR */}
                                        <Inputs.Input spanWidth="65px" label="Adresse" value={form.address_sender}
                                            onChange={(e) => onAnychange(e, "address_sender")} required={true} labelNext=" (expéditeur)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** SUITE EXPEDITEUR */}
                                        <Inputs.Input spanWidth="180px" label="Suite" value={form.comp_add_sender}
                                            onChange={(e) => onAnychange(e, "comp_add_sender")} labelNext=" (adresse expéditeur)" />

                                        {/** CP EXPEDITEUR */}
                                        <Inputs.Input spanWidth="65px" label="CP" value={form.cp_sender}
                                            onChange={(e) => onAnychange(e, "cp_sender")} required={true} labelNext=" (expéditeur)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** VILLE EXPEDITEUR */}
                                        <Inputs.Input spanWidth="180px" label="Ville" value={form.city_sender}
                                            onChange={(e) => onAnychange(e, "city_sender")} required={true} labelNext=" (expéditeur)" />

                                        {/** PAYS EXPEDITEUR */}
                                        <Inputs.Input spanWidth="65px" label="Pays" value={form.country_sender}
                                            onChange={(e) => onAnychange(e, "country_sender")} required={true} labelNext=" (expéditeur)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** NOM EXPEDITEUR */}
                                        <Inputs.Input spanWidth="180px" label="Nom" value={form.ln_sender}
                                            onChange={(e) => onAnychange(e, "ln_sender")} required={true} labelNext=" (expéditeur)" />

                                        {/** PRENOM EXPEDITEUR */}
                                        <Inputs.Input spanWidth="65px" label="Prénom" value={form.fn_sender}
                                            onChange={(e) => onAnychange(e, "fn_sender")} required={true} labelNext=" (expéditeur)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** E-MAIL EXPEDITEUR */}
                                        <Inputs.Input spanWidth="180px" label="Téléphone" value={form.tel_sender}
                                            onChange={(e) => onAnychange(e, "tel_sender")} required={true} labelNext=" (expéditeur)" />

                                        {/** TEL EXPEDITEUR */}
                                        <Inputs.Input spanWidth="65px" label="E-mail" value={form.mail_sender}
                                            onChange={(e) => onAnychange(e, "mail_sender")} required={true} labelNext=" (expéditeur)" />
                                    </div>
                                </div>

                            </div>

                            <datalist id="decharg_manu"><option value="A la charge de B+ / Non connu&nbsp;" text="A la charge de B+ / Non connu&nbsp;"></option><option value="A la charge de B+ / Par l’arrière&nbsp;" text="A la charge de B+ / Par l’arrière&nbsp;"></option><option value="A la charge de B+ / Par le côté&nbsp;" text="A la charge de B+ / Par le côté&nbsp;"></option><option value="A la charge du client / Non connu" text="A la charge du client / Non connu"></option><option value="A la charge du client / Par l’arrière&nbsp;" text="A la charge du client / Par l’arrière&nbsp;"></option><option value="A la charge du client / Par le côté&nbsp;" text="A la charge du client / Par le côté&nbsp;"></option></datalist>
                            <datalist id="emb_aff"><option value="A la charge de B+ / Air 2B" text="A la charge de B+ / Air 2B"></option><option value="A la charge de B+ / Air 4B" text="A la charge de B+ / Air 4B"></option><option value="A la charge de B+ / Mer 2C" text="A la charge de B+ / Mer 2C"></option><option value="A la charge de B+ / Mer 4C" text="A la charge de B+ / Mer 4C"></option><option value="A la charge de B+ / Standard" text="A la charge de B+ / Standard"></option><option value="A la charge du client / Air 2B" text="A la charge du client / Air 2B"></option><option value="A la charge du client / Air 4B" text="A la charge du client / Air 4B"></option><option value="A la charge du client / Mer 2C" text="A la charge du client / Mer 2C"></option><option value="A la charge du client / Standard" text="A la charge du client / Standard"></option></datalist>
                            <datalist id="transp_aff"><option value="A la charge de B+ / Avion" text="A la charge de B+ / Avion"></option><option value="A la charge de B+ / Bateau" text="A la charge de B+ / Bateau"></option><option value="A la charge de B+ / Camion" text="A la charge de B+ / Camion"></option><option value="A la charge du client / Avion" text="A la charge du client / Avion"></option><option value="A la charge du client / Bateau" text="A la charge du client / Bateau"></option><option value="A la charge du client / Camion" text="A la charge du client / Camion"></option></datalist>

                            {/** INFORMATIONS + DESTINATAIRE */}
                            <div className="col-6" style={{ minWidth: "630px" }}>
                                {/** INFORMATIONS TRANSPORT*/}
                                <div className="see-underline mb-3" style={{ width: "70%" }}>
                                    <h4>Informations Transport</h4>
                                </div>


                                <div style={{ width: "600px", height: "400px" }}>
                                    {(form && form.type_pack === "machine") && <Fragment>

                                        {forceString(form.packer) === "2" && <Fragment>
                                            {/** TYPE D'EMBALLAGE */}
                                            <Inputs.InputGroup
                                                spanWidth="180px" label="Type d'emballage*" value={businessInfo.emb_aff}
                                                onBlur={(e) => { updateBusinessField("emb_aff", e.target.value) }}
                                                onChange={(e) => { onBusinessChange("emb_aff", e.target.value, e) }}
                                                required readOnly={!changeBusiness} list="emb_aff"
                                            />

                                            {/** DECHARG. MANUTENTION */}
                                            <Inputs.InputGroup
                                                spanWidth="180px" label="Décharg. et manut.*" value={businessInfo.decharg_manu}
                                                onBlur={(e) => { updateBusinessField("decharg_manu", e.target.value) }}
                                                onChange={(e) => { onBusinessChange("decharg_manu", e.target.value, e) }}
                                                required readOnly={!changeBusiness} list="decharg_manu"
                                            />

                                            {/** TRANSPORT */}
                                            <Inputs.InputGroup
                                                spanWidth="180px" label="Transport*" value={businessInfo.transp_aff}
                                                onBlur={(e) => { updateBusinessField("transp_aff", e.target.value) }}
                                                onChange={(e) => { onBusinessChange("transp_aff", e.target.value, e) }}
                                                required readOnly={!changeBusiness} list="transp_aff"
                                            />

                                            {/** SPARES */}
                                            <Inputs.InputGroup
                                                spanWidth="180px" label="Lot de pièces détachées" value={businessInfo.lot_piece_det}
                                                onBlur={(e) => { updateBusinessField("lot_piece_det", e.target.value) }}
                                                onChange={(e) => { onBusinessChange("lot_piece_det", e.target.value, e) }}
                                                required readOnly={true}
                                            />
                                        </Fragment>
                                        }

                                        {forceString(form.packer) !== "2" && <Fragment>
                                            <div className="text-center fst-italic" style={{ marginTop: "-15px" }}>Les trois premiers champs sont par défaut car l'envoi ne concerne pas le client</div>

                                            {/** TYPE D'EMBALLAGE */}
                                            <Inputs.InputGroup
                                                spanWidth="180px" label="Type d'emballage*" value={"A la charge de B+ / Standard"} readOnly />

                                            {/** DECHARG. MANUTENTION */}
                                            <Inputs.InputGroup
                                                spanWidth="180px" label="Décharg. et manut*" value={"A la charge de B+ / Par le côté"} readOnly />

                                            {/** TRANSPORT */}
                                            <Inputs.InputGroup
                                                spanWidth="180px" label="Transport*" value={"A la charge de B+ / Camion"} readOnly />
                                        </Fragment>
                                        }
                                    </Fragment>}

                                    {/** DATE DE CHARGEMENT */}
                                    <Inputs.InputGroup
                                        type="date" spanWidth="180px" label="Date de chargement"
                                        value={getDate(form.date_load, "en")} onChange={(e) => onAnychange(e, "date_load")}
                                        required
                                    />

                                    {/** DATE DE LIVRAISON */}
                                    <Inputs.InputGroup
                                        type="date" spanWidth="180px" label="Date de livraison"
                                        value={getDate(form.date_del, "en")} onChange={(e) => onAnychange(e, "date_del")}
                                        required
                                    />

                                    {/** COMMENTAIRE */}
                                    <Inputs.TextAreaGroup spanWidth="180px" label="Commentaire" height="108px"
                                        value={form.com} onChange={(e) => onAnychange(e, "com")} />

                                </div>

                                {/** DESTINATAIRE */}
                                <div className="see-underline mb-3 d-flex" style={{ width: "70%" }}>
                                    <h4>Destinataire</h4>
                                    {addressBook &&
                                        <img className="ms-2 see-img w-30 cursor-pointer" src="/common/contacts.png"
                                            onClick={() => setContact({ field: "recipient" })} />}
                                </div>

                                <div style={{ width: "600px" }}>
                                    <div className="input-group mb-3">
                                        {/** RAISON SOC. DESTINATAIRE */}
                                        <Inputs.Input spanWidth="180px" label="Raison sociale" value={form.cn_recip}
                                            onChange={(e) => onAnychange(e, "cn_recip")} required={true} labelNext=" (destinataire)" />

                                        {/** ADRESSE. DESTINATAIRE */}
                                        <Inputs.Input spanWidth="65px" label="Adresse" value={form.address_recip}
                                            onChange={(e) => onAnychange(e, "address_recip")} required={true} labelNext=" (destinataire)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** SUITE DESTINATAIRE */}
                                        <Inputs.Input spanWidth="180px" label="Suite" value={form.comp_add_recip}
                                            onChange={(e) => onAnychange(e, "comp_add_recip")} labelNext=" (adress destinataire)" />

                                        {/** CP DESTINATAIRE */}
                                        <Inputs.Input spanWidth="65px" label="CP" value={form.cp_recip}
                                            onChange={(e) => onAnychange(e, "cp_recip")} required={true} labelNext=" (destinataire)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** VILLE DESTINATAIRE */}
                                        <Inputs.Input spanWidth="180px" label="Ville" value={form.city_recip}
                                            onChange={(e) => onAnychange(e, "city_recip")} required={true} labelNext=" (destinataire)" />

                                        {/** PAYS DESTINATAIRE */}
                                        <Inputs.Input spanWidth="65px" label="Pays" value={form.country_recip}
                                            onChange={(e) => onAnychange(e, "country_recip")} required={true} labelNext=" (destinataire)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** NOM DESTINATAIRE */}
                                        <Inputs.Input spanWidth="180px" label="Nom" value={form.ln_recip}
                                            onChange={(e) => onAnychange(e, "ln_recip")} required={true} labelNext=" (destinataire)" />

                                        {/** PRENOM DESTINATAIRE */}
                                        <Inputs.Input spanWidth="65px" label="Prénom" value={form.fn_recip}
                                            onChange={(e) => onAnychange(e, "fn_recip")} required={true} labelNext=" (destinataire)" />
                                    </div>

                                    <div className="input-group mb-3">
                                        {/** E-MAIL DESTINATAIRE */}
                                        <Inputs.Input spanWidth="180px" label="Téléphone" value={form.tel_recip}
                                            onChange={(e) => onAnychange(e, "tel_recip")} required={true} labelNext=" (destinataire)" />

                                        {/** TEL DESTINATAIRE */}
                                        <Inputs.Input spanWidth="65px" label="E-mail" value={form.mail_recip}
                                            onChange={(e) => onAnychange(e, "mail_recip")} required={true} labelNext=" (destinataire)" />
                                    </div>


                                </div>
                            </div>
                        </div>

                        <button type="submit" id="submit-btn" className="d-none">Submit</button>
                    </form>
                    <br></br>
                    { picking.length > 0 && <div className="text-center">
                        <button className="btn btn-secondary"
                            onClick={() => setOpenPickingPanel(true)}>Voir mon picking</button>
                    </div>}

                    <div className="text-center mt-3">
                        {form.sent !== 1 &&
                            <button className="see-btn me-3" style={{ width: "200px" }}
                                onClick={() => send(null, false)} disabled={saving}>
                                {saving && <ButtonWaiting />}
                                Sauvegarder sans envoyer
                            </button>
                        }
                        <button className="see-btn" disabled={saving}
                            onClick={() => document.getElementById("submit-btn").click()}>
                            {saving && <ButtonWaiting />}
                            Envoyer</button>
                    </div>

                    {contact && <div className="custom-tooltip-v2">
                        <div className="modal-body xl blue-purple-bg">
                            <button type="button" className="btn-close" onClick={() => setContact()}></button>
                            <h5 className="text-center">Saisie d'une nouvelle adresse {contact.field === "recipient" ? "du destinatire" : "de l'expéditeur"}</h5>

                            {forceString(form.packer) === "1" && <div className="alert alert-warning text-center fw-bold">
                                Vous avez coché l'envoi chez l'emballeur, saisissez donc l'adresse de l'emballeur et non du client
                            </div>}

                            <h6 className="text-center fst-italic">Remarque: cette action ne modifiera pas les données de la fiche affaire</h6>

                            <div className="col-6 offset-3 mb-3">
                                <input className="form-control text-center fw-bold" onChange={searchContact}
                                    placeholder="Saisissez la raison sociale, adresse ou nom du contact"
                                    autoFocus />
                            </div>

                            <ul className="list-group">
                                {typePack && typePack.map((v, k) => {
                                    return <li className="list-group-item overflow-y-auto" style={{ maxHeight: "500px" }} key={"contact" + k}
                                        onClick={(e) => chooseContactFocus(k)}>
                                        <strong>Adresse de l'affaire Focus:</strong> {v.cn_recip}, {v.address_recip},  {v.cp_recip} {v.cn_recip}, {v.country_recip}
                                    </li>
                                })}

                                {contactRes.length > 0 && <div className="overflow-y-auto" style={{ maxHeight: "500px" }}>

                                    <table className="table">
                                        <thead>
                                            <tr className="table-info text-center fixed-header-zero">
                                                <th>Raison sociale</th>
                                                <th>Adresse</th>
                                                <th>Suite</th>
                                                <th>CP</th>
                                                <th>Ville</th>
                                                <th>Pays</th>
                                                <th>Nom</th>
                                                <th>Prénom</th>
                                                <th>E-mail</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contactRes.map((v, k) => {
                                                return <tr key={"choose_ct" + k} onClick={(e) => chooseContact(v.key)}
                                                    className="cursor-pointer bg-secondary-hover">
                                                    <td>{v.company}</td>
                                                    <td>{v.address}</td>
                                                    <td>{v.add_address}</td>
                                                    <td>{v.postal_code}</td>
                                                    <td>{v.city}</td>
                                                    <td>{v.country}</td>
                                                    <td>{v.project_man_fn}</td>
                                                    <td>{v.project_man_ln}</td>
                                                    <td>{v.project_man_ln}</td>
                                                </tr>

                                            })}
                                        </tbody>
                                    </table>

                                </div>}

                            </ul>

                        </div>
                    </div>}

                </Fragment>
            }

            {displayDiff && <SmallFrame close={() => setDisplayDiff(false)}
                component={<table className="table table-bordered">
                    <thead>
                    <tr className="text-center table-secondary">
                        <th>Champ</th>
                        <th>Ancienne valeur</th>
                        <th>Nouvelle valeur</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {formOriginChange.map( (v, k) => {
                        return <tr key={"diff" + k}>
                            <td>{v.label}</td>
                            <td>{v.oldVal}</td>
                            <td>{v.newVal}</td>
                            <td className="text-center">
                                <button className="btn btn-outline-danger"
                                    onClick={()=>cancelChange(k)}>Annuler la modif</button>
                            </td>
                        </tr>
                    } )

                    }
                    </tbody>
                </table>} />

            }

            { openPickingPanel && <PickingPanel setOpenPickingPanel={setOpenPickingPanel}
                business={form.business} setParentPicking={ setPicking } parentPicking={picking}/>}

        </div>
    </div>

}

export default NewRequest;