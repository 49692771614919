
import { Fragment, useState } from "react";
import SnakeGame from "react-simple-snake";

const WaitingRoundSnippers = ({ launchGame = false, hideRound = false }) => {

    return (
        <div className="text-center">
            {/*<div className="spinner-grow text-secondary" role="status"></div>
            <div className="spinner-grow text-success" role="status"></div>
            <div className="spinner-grow text-danger" role="status"></div>
            <div className="spinner-grow text-warning" role="status"></div>*/}

            <div>
                {!hideRound && <Fragment>
                    <img src="/common/bullet_see.png" className="rotate-no-limite" />
                    <div>Loading...</div>
                </Fragment>}


                {launchGame && <div>
                    <SnakeGame />
                </div>}
            </div>
        </div>
    )
}

export default WaitingRoundSnippers;