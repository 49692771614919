import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useState } from "react";
import ReactTable from "../../../../common/smallComponents.js/ReactTable";
import BomDaoV2 from "../../../../services/machines/BomDaoV2";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import errorManagement from "../../../../services/errorManagement";
import { removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { confirmAlert } from "react-confirm-alert";
import ErrorMessage from "../../../../common/smallComponents.js/ErrorMessage";

const Cabinets = (props) => {
    const [loading, setLoading] = useState(false);
    const [cabinets, setCabinets] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        BomDaoV2.getCabinets().then(
            (response) => { setCabinets(response); },
            () => { });
    }, []);

    const upd = (e, k, field) => {
        var arr = [...cabinets];
        arr[k][field] = e.target.value;
        setCabinets(arr);
    }

    const save = (k) => {
        setLoading(true);
        setError();

        BomDaoV2.saveCabinet(cabinets[k]).then(
            (response) => {
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    const deleteRef = (k) => {

        setError();

        confirmAlert({
            title: "Confirmation",
            message: "Etes-vous sûr(e) de vouloir supprimer la référence n°" + cabinets[k].order_ref,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        BomDaoV2.deleteCabinet(cabinets[k].id).then(
                            (response) => {
                                var arr = removeAndReturnArray(cabinets, k);
                                setCabinets(arr);
                                setLoading(false);
                            },
                            (error) => {
                                setLoading(false);
                                setError(errorManagement.handleError(props, error));
                                scrollToTop();
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });


    }

    const addRow = (e) => {
        e.preventDefault();

        var cabinet = {
            typ_config: e.target.typ_config.value,
            automate: e.target.automate.value,
            type_mach: e.target.type_mach.value,
            label: e.target.label.value,
            order_ref: e.target.order_ref.value,
            ref: e.target.order_ref.value,
            price: e.target.price.value,
            supplier: e.target.supplier.value,
        }

        setLoading(true);
        setError();

        var arr = [...cabinets];
        BomDaoV2.saveCabinet(cabinet).then(
            (response) => {
                arr.push(cabinet);
                setCabinets(arr);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id', {
            header: 'ID',
            cell: i => <div className="display-flex">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('typ_config', {
            header: 'Type de machine',
            cell: i => <div className="text-center fw-bold">
                <input className="form-control" defaultValue={i.getValue()} onBlur={(e) => upd(e, i.row.id, "typ_config")} />
            </div>
        }));

        arr.push(columnHelper.accessor('automate', {
            header: 'Automate',
            cell: i => <div className="text-center">
                <input className="form-control" defaultValue={i.getValue()} onBlur={(e) => upd(e, i.row.id, "automate")} />
            </div>
        }));

        arr.push(columnHelper.accessor('type_mach', {
            header: 'Type de machine',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} onBlur={(e) => upd(e, i.row.id, "type_mach")} />
            </div>
        }));

        arr.push(columnHelper.accessor('label', {
            header: 'Désignation',
            cell: i => <div>
                <input className="form-control" defaultValue={i.getValue()} title={i.getValue()}
                    onBlur={(e) => upd(e, i.row.id, "label")} />
            </div>
        }));

        arr.push(columnHelper.accessor('order_ref', {
            header: 'Référence',
            cell: i => <div className="display-flex">
                <input className="form-control" defaultValue={i.getValue()} onBlur={(e) => upd(e, i.row.id, "order_ref")} />
            </div>
        }));

        arr.push(columnHelper.accessor('supplier', {
            header: 'Code fournisseur Focus',
            cell: i => <div className="display-flex">
                <input className="form-control" type="text" defaultValue={i.getValue()}
                    onBlur={(e) => upd(e, i.row.id, "supplier")} />
            </div>
        }));

        arr.push(columnHelper.accessor('price', {
            header: 'Prix',
            cell: i => <div className="display-flex">
                <input className="form-control" type="number" defaultValue={i.getValue()} onBlur={(e) => upd(e, i.row.id, "price")} />
            </div>
        }));

        arr.push(columnHelper.accessor('xx', {
            header: '',
            cell: i => <div className="text-center d-flex">
                <button className="btn btn-success" onClick={() => save(i.row.id)} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Sauvegarder</button>


                <button className="btn btn-danger ms-2" onClick={() => deleteRef(i.row.id)} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Supprimer</button>
            </div>
        }));

        return arr;
    }


    //Set columns
    const columns = getColumns();

    return <Fragment>
        <form onSubmit={addRow}>
            <div className="input-group mb-3">
                <span className="input-group-text">Type de machine</span>
                <input type="text" className="form-control" name="typ_config" required />

                <span className="input-group-text">Automate</span>
                <input type="text" className="form-control" name="automate" required />

                <span className="input-group-text">Type de machine</span>
                <input type="text" className="form-control" name="type_mach" required />

                <span className="input-group-text">Désignation</span>
                <input type="text" className="form-control" name="label" required />

                <span className="input-group-text">Référence</span>
                <input type="text" className="form-control" name="order_ref" required />

                <span className="input-group-text">Code FRN Focus</span>
                <input type="text" className="form-control" name="supplier" required />

                <span className="input-group-text">Prix</span>
                <input type="text" className="form-control" name="price" required />

                <button className="btn btn-outline-success" disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Ajouter</button>
            </div>
        </form>

        { error && <ErrorMessage error={error} errorState={setError}/> }

        <ReactTable columns={columns} data={cabinets}
            origin="bom_cabinets" classes={"no-padding"} top0={true} />
    </Fragment>

}

export default Cabinets;