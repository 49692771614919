import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";
import { fileToBase64, multipleFileToBase64 } from "../../functions/StrFunctions";

const API_URL = process.env.REACT_APP_API_URL +"storehouse/quality";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getNcList = (filter, notClosedOnly) => {
    return axios
        .post(API_URL + "/non-conformities/get", {
                fake_header: authHeader(),
                filter: filter,
                notClosedOnly: notClosedOnly
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const getNcTypeList = () => {
    return axios
        .post(API_URL + "/non-conformities/get/nc-type-list", {
                fake_header: authHeader()
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const saveNcList = (list) => {
    return axios
        .post(API_URL + "/non-conformities/update/detail", {
                fake_header: authHeader(),
                list: list
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updateNcHeader = (o) => {
    return axios
        .post(API_URL + "/non-conformities/update/header", {
                fake_header: authHeader(),
                header: o
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const exportCurrentNc = (type, start, end, month, year) => {
    return axios
        .post(API_URL + "/non-conformities/export", {
                fake_header: authHeader(),
                type: type,
                start: start,
                end: end,
                month: month,
                year: year
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const notifySolved = (o) => {
    return axios
        .post(API_URL + "/non-conformities/solved", {
                fake_header: authHeader(),
                row: o
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const notifyCancellation = (o) => {
    return axios
        .post(API_URL + "/non-conformities/cancel", {
                fake_header: authHeader(),
                row: o
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const getOrderInfo = (order) => {
    return axios
        .post(API_URL + "/non-conformities/get/order-info", {
                fake_header: authHeader(),
                order: order
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const updateNcEmail = (o) => {
    return axios
        .post(API_URL + "/non-conformities/update/supplier-mail", {
                fake_header: authHeader(),
                ...o
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const saveNc = async (infos, list, img, isCreation, chosenBusiness, ncHeader) => {
    var url = isCreation ? "/non-conformities/save" : "/non-conformities/update";
   
    var fArr = new Array(null, null, null, null);
    
    for( let i = 0; i<img.length; i++ ){
        if( img[i] instanceof File ){
            const b64 = await fileToBase64(img[i]);
            fArr[i] = b64;
        }else if(img[i]){
            fArr[i] = {
                name: img[i],
                skip: true
            }
        }
    }
    
    var o = {
        infos: infos,
        list: list,
        chosenBusiness: chosenBusiness,
        ncHeader: ncHeader,
        files: fArr
    }    

    return axios
        .post(API_URL + url, {
                fake_header: authHeader(),
                ...o
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const getNcCard = (id) => {
    return axios
        .post(API_URL + "/non-conformities/get/card", {
                fake_header: authHeader(),
                id: id
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

const sendNcSupplier = (nc, files, files64, contacts, msg) => {
    var arr = [];
    for( let i = 0; i < files.length; i++ ){
        if( files[i] )
            arr.push( {name: files[i], base64: files64[i] } )
    }

    return axios
        .post(API_URL + "/non-conformities/send/supplier", {
                fake_header: authHeader(),
                nc: nc,
                files: arr,
                contacts: contacts,
                msg: msg
            },{
                headers: textPlainHeader()
            })
        .then((response) => {
            return response.data;
        });
}

export default {
    getNcList,
    saveNcList,
    updateNcHeader,
    getNcTypeList,
    exportCurrentNc,
    notifyCancellation,
    notifySolved,
    getOrderInfo,
    saveNc,
    getNcCard,
    sendNcSupplier,
    updateNcEmail
}