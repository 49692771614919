import { useState } from "react";
import TableExcelPaste from "../../../common/smallComponents.js/TableExcelPaste";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import { confirmAlert } from "react-confirm-alert";
import DisposableArticles from "../../../services/articles/DisposableArticles";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import errorManagement from "../../../services/errorManagement";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";

const AddDisposableXls = (p) => {
    const [label, setlabel] = useState({ labels: ["Articles"] });
    const [inputvalue, setinputvalue] = useState({ inputs: [] });
    const [com, setCom] = useState("");
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState();
    const [throw_requested, setThrowRequested] = useState(1);

    const throwArticles = () => {
        var arr = inputvalue.inputs.filter(v => { return v["Articles"].trim() !== "" })

        if (arr.length === 0) {
            setError("Il n'y a aucun article à jeter !");
            scrollToTop();
            return;
        } else if (com.trim() === "") {
            setError("Saisissez la raison de ces jets d'AR");
            scrollToTop();
            return;
        }

        confirmAlert({
            title: "Confirmation",
            message: "Es-tu sûr de vouloir jeter ces " + arr.length + " articles ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        DisposableArticles.getArticlesByCodes(arr, "Articles").then(
                            (res) => {
                                save(arr, res);
                            }, (err) => {
                                setError(errorManagement.handleError(p.props, err));
                                setLoading(false);
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const save = (original, found) => {
        var arr = [];
        for (let i = 0; i < found.length; i++) {
            arr.push({
                article: found[i].CDARL.trim(),
                throw_requested: throw_requested,
                com_ipt: com,
                DES2L: found[i].DES2L
            })
        }

        DisposableArticles.addToThrowMultiple(arr).then(
            () => {
                var missing;

                if (original.length !== found.length) {
                    const subsetKeys = new Set(found.map(row => row["CDARL"].trim()));
                    missing = original.filter(row => !subsetKeys.has(row["Articles"]));
                }

                setLoading(false);
                setMsg("Sauvegardé" + (missing.length > 0 ? " sauf " + missing.join(", ") : ""));
            },
            (error) => {
                setError(errorManagement.handleError(p.props, error));
                setLoading(false);
            }
        )


    }

    return <div>
        <h5 className="text-center">Ajout d'articles à jeter</h5>
        <div className="text-center fw-bold mb-3">Copie/colle les codes articles de ton fichier Excel ici et valide</div>

        {error && <ErrorMessage error={error} errorState={setError} />}
        {loading && <WaitingRoundSnippers />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        <div class="form-check">
        <input class="form-check-input big" type="checkbox" checked={ throw_requested === 1 }
            onClick={() => setThrowRequested( throw_requested === 1 ? null : 1 ) }/>
        <label class="form-check-label fw-bold ms-2" for="flexCheckDefault">Cocher à jeter</label>
        </div>

        <div className="form-floating mb-3 ms-auto me-auto" style={{ width: "400px" }}>
            <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2"
                style={{ height: "100px" }} onBlur={(e) => setCom(e.target.value)}></textarea>
            <label for="floatingTextarea2">Pourquoi jetez-vous ces pièces ?</label>
        </div>

        <TableExcelPaste label={label} setlabel={setlabel} inputvalue={inputvalue}
            setinputvalue={setinputvalue} initNb={300} />

        <div className="text-center mt-3">
            <button className="btn btn-secondary" onClick={throwArticles} disabled={loading}>
                {loading && <ButtonWaiting />}
                Jeter</button>
        </div>
    </div>

}

export default AddDisposableXls;