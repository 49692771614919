import { Fragment } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Tooltip as Tooltip2 } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css';
/**
 * eg: props={{ tooltipText: "A quoi sert ce produit ?" }}
 * @param {*} tooltipText 
 * @param {*} originText visible text without mouse on
 * @returns 
 */
const CustomToolTip = (p) => {

    return (
        <OverlayTrigger delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props}>
                    <div dangerouslySetInnerHTML={{ __html: p.props.tooltipText }}></div>
                </Tooltip>
            )}
            placement="top"><div dangerouslySetInnerHTML={{ __html: p.props.originText }}></div>
        </OverlayTrigger>
    )
}

export const CustomToolTipOj = ( { tooltipText, originText } ) => {

    return (
        <OverlayTrigger delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props}>
                   {tooltipText}
                </Tooltip>
            )}
            placement="top"><span>{originText}</span>
        </OverlayTrigger>
    )
}


/**
 * props={{ tooltipText: "your text" }}
 * @param {*} p 
 * @returns 
 */
export const CustomToolTipInfo = (p) => {

    return (
        <OverlayTrigger delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props} style={{ zIndex: 9999, ...props.style }}>
                   <div style={{ zIndex: 9999}} className={ p.props.divClass }
                    dangerouslySetInnerHTML={{ __html: p.props.tooltipText }}></div>
                </Tooltip>
            )}
            placement="top"><div className="ms-auto">
                <img src="/common/question-mark.png" style={ p.imgStyle ? p.imgStyle : {} }
                    className="see-img w-30 ms-auto cursor-pointer no-print" /></div>
        </OverlayTrigger>
    )
}

/**
 * props={{tooltipText: <div>Your element</div>}}
 * @param {*} p 
 * @returns 
 */
export const CustomToolTipInfoObj = (p) => {

    return (
        <OverlayTrigger delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props}>
                   <div>{p.props.tooltipText }</div>
                </Tooltip>
            )}
            placement="top"><div className="ms-auto">
                <img src="/common/question-mark.png" style={ p.imgStyle ? p.imgStyle : {} }
                    className="see-img w-30 ms-auto cursor-pointer no-print" /></div>
        </OverlayTrigger>
    )
}

{/**
    tooltipText
*/}
export const CustomToolTipDanger = (p) => {

    return (
        <OverlayTrigger delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props}>
                   <div>{p.props.tooltipText }</div>
                </Tooltip>
            )}
            placement="top"><div className="ms-auto">
                <img src="/common/danger_icon.png" style={ p.imgStyle ? p.imgStyle : {} }
                    className="see-img w-30 ms-auto cursor-pointer no-print" /></div>
        </OverlayTrigger>
    )
}

export const CustomToolTipClickable = (p) => {
    return <div>
        <a id={"clickable"+p.k}>{getText(p)}</a>
        <Tooltip2 anchorSelect={"#clickable"+p.k} place="bottom" clickable>
            {getClickableUrl(p)}
        </Tooltip2>
    </div>
}

function getText(p){
    if( !p.value ) return "";

    if( p.value.length > 16 ){
        return p.value.substring(0, 16) + "...";
    }else{
        return p.value;
    }
}

const getClickableUrl = (p) => {
    if( !p.value ) return "";

    return <div>
        {p.value.split( p.splitCode ).map((v, k) => 
            <div className="mb-3">
                <a className="btn btn-secondary" href={p.url+v.trim()} target="_blank" key={"click" +k+ v}>{v.trim()}</a>
            </div>
        )}
        
    </div>
}

export default CustomToolTip;