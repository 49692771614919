import { Fragment, useEffect, useState } from "react";
import { SmallFrame } from "../../../common/smallComponents.js/CustomDivs";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import errorManagement from "../../../services/errorManagement";
import transport from "../../../services/supplyChain/transport";
import { forceString } from "../../../functions/StrFunctions";
import Inputs from "../../../common/smallComponents.js/Inputs";
import QRCode, { QRCodeCanvas } from "qrcode.react";

const DeliveryComp = ({ props, delivery, setDelivery, getCleanedArr, getArray, business, reliquat, picking, shipment }) => {
    const [initialSendReq, setInitialSendReq] = useState();
    const [comparisonInfo, setComparisonInfo] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Remove ZR_PREST or YR_TRANS
        picking = picking.filter(a => a.ARAVF && a.ARAVF.startsWith("AR_"));

        // Get what is intended to send
        var want_to_send;
        if ( ["all", "scanner"].includes( delivery.target ) ) { want_to_send = picking }
        else { want_to_send = picking.filter((a, k) => reliquat.indexOf(forceString(k)) === -1) }

        var comparisonInfo = { complete_ship: [], partial_ship: [], no_ship: [], pick_nb: [] };

        setInitialSendReq(want_to_send);

        // Get from delivery what has been sent or not by ID "sav_" + need ID
        var shipped = want_to_send.filter(a => shipment.some(b => "sav_" + a.NORMM === b.sent_art_id));

        // Construct the array to send effectively
        // Get all articles not found in shipped
        var to_send = want_to_send.filter(a => !shipped.some(b => a.NORMM === b.NORMM))
        comparisonInfo.complete_send = to_send;

        // Get articles found in shipped but not sent completely -> adjust qty
        var sent = want_to_send.filter(a => shipped.some(b => a.NORMM === b.NORMM));
        for (let i = 0; i < sent.length; i++) {
            var inital_qty = parseFloat(sent[i].QTARF);

            // Get all rows sent for this ID
            var sh = shipment.filter(a => "sav_" + sent[i].NORMM === a.sent_art_id);

            //Calcule all sent qty
            var sent_qty = 0;
            for (let j = 0; j < sh.length; j++) sent_qty += parseFloat(sh[j].qty);

            // If sent qty < wanted qty change qty and add to table
            if (inital_qty > sent_qty) {
                comparisonInfo.partial_ship.push({ ...sent[i], to_send_qty: inital_qty - sent_qty })
                sent[i].QTARF = inital_qty - sent_qty;
                to_send.push(sent[i]);
            } else {
                comparisonInfo.no_ship.push(sent[i])
            }
        }

        // Get shipment picking not sent to append if you want
        var ship_picking = shipment.filter(a => a.truck_load_real === null && a.picking_id !== null );
        var pick_nb = [...new Set(ship_picking.map(a => a.picking_id))];
        comparisonInfo.pick_nb = pick_nb;

        setComparisonInfo(comparisonInfo);
        setDelivery(s => ({ ...s, to_send: to_send }));

    }, [])

    const createDelivery = (picking_nb) => {
        setDelivery((s) => ({ ...s, error: undefined }))
        setLoading(true);

        var o = {
            picking_nb: picking_nb,
            business: business,
            delivery: delivery,
            picking: picking,
            target: delivery.target
        }

        if( picking_nb !== null ){
            var pick_info = shipment.filter( a => a.picking_id === picking_nb );
            
            if( pick_info.length > 0 ){
                o.trans_id = pick_info[0].trans_id;
                o.shipment_id = pick_info[0].exp_id
            }
        }

        transport.createCompleteProcessFromPicking(o).then(
            (res) => {
                var del = { ...delivery };
                del.url = process.env.REACT_APP_URL
                + "supply-chain/tools/expedition?page=doc&shipment_id=" + res.shipment_id
                + "&id=" + res.id
                + "&picking_id=" + res.picking_id
                + "&type=other&source=sav";

                del.qr_code = process.env.REACT_APP_URL
                + "supply-chain/tools/expedition?page=qr_code&shipment_id=" + res.shipment_id
                + "&id=" + res.id
                + "&picking_id=" + res.picking_id
                + "&type=other&source=sav";

                del.saved = true;

                setDelivery( del );
                setLoading( false );
            }, (error) => {
                setDelivery((s) => ({ ...s, error: errorManagement.handleError(props, error) }))
                setLoading( false );
            }
        )
    }

    return <SmallFrame close={() => { setDelivery((s) => s.open = false) }}
        component={<div>
            <h5 className="text-center mb-3">Fiche de colisage {delivery.target === "all" ? "du picking complet"
                : "des reliquats sélectionnés"}</h5>
            <h6 className="text-center">{delivery.to_send && delivery.to_send.length} article(s) à envoyer</h6>
            {delivery.error && <ErrorMessage error={delivery.error} />}

            {delivery.to_send && <div>
                {/** 
                 * INFORM THE USER THAT THERE ARE DIFF
                 */}
                {(initialSendReq && delivery.to_send
                    && (initialSendReq.length > delivery.to_send.length)) && <div className="alert alert-warning fw-bold text-center mb-3">
                        Nous n'enverrons que {delivery.to_send.length} article(s) sur les {initialSendReq.length} demandées

                        <div className="mt-3">
                            <button className="btn btn-outline-secondary"
                                onClick={() => setComparisonInfo(s => ({ ...s, display: !s.display }))}>
                                {comparisonInfo.display ? "Masquer" : "Afficher"} le détail
                            </button>
                        </div>

                        {comparisonInfo.display &&
                            <div className="mt-3 text-start">
                                {comparisonInfo.complete_send.length > 0 && <Fragment>
                                    <h6>Envoi complet</h6>
                                    {comparisonInfo.complete_send.map(v => {
                                        return <li>#{v.NLIGF} - {v.ARAVF} ( {v.ZN03PA} )</li>
                                    })}
                                </Fragment>}

                                {comparisonInfo.partial_ship.length > 0 && <Fragment>
                                    <h6 className="mt-3">Envoi partiel</h6>
                                    {comparisonInfo.partial_ship.map(v => {
                                        return <li>#{v.NLIGF} - {v.ARAVF} ( {v.ZN03PA} ) : {v.to_send_qty} au lieu de {v.QTARF}</li>
                                    })}
                                </Fragment>}

                                {comparisonInfo.no_ship.length > 0 && <Fragment>
                                    <h6 className="mt-3">Aucun envoi car déjà traité</h6>
                                    {comparisonInfo.no_ship.map(v => {
                                        return <li>#{v.NLIGF} - {v.ARAVF} ( {v.ZN03PA} )</li>
                                    })}
                                </Fragment>}

                            </div>
                        }
                    </div>}

                {/** 
                 * ASK CONFIRMATION OF SEND IF POSSIBLE, CHOOSE PICKING IF THERE IS SOME NOT SHIPPED
                 */}
                {delivery.saved ? <div>
                    <h5 className="text-center mb-5">Traitement de la Fiche de colisage n° {delivery.picking_id} terminé</h5>
                    <div className="d-flex">
                        <div className="text-center col-6">
                            <button className="pushable green mb-auto mt-auto" style={{ width: "290px" }}
                                onClick={() => window.location.href= delivery.url }>
                                <span className="front green">Terminer l'envoi sur PC</span>
                            </button>
                        </div>
                        <div className="text-center col-6">
                            <h6 className="text-center">Ou terminer sur la douchette</h6>

                            <QRCodeCanvas value={ delivery.qr_code } size={200} />
                        </div>
                    </div>
                </div> :
                    <div>
                        {delivery.to_send.length > 0 ?
                            <div>
                                <div className="mb-3">
                                    <Inputs.SubmitBtn label={"Créer une nouvelle liste de colisage"}
                                        loading={loading} onClick={() => createDelivery(null)} type_btn="success" />
                                </div>
                                {comparisonInfo.pick_nb.map(v => {
                                    return <div className="mb-3">
                                        <Inputs.SubmitBtn label={"Ajouter à liste de colisage " + v}
                                            loading={loading} onClick={() => createDelivery(v)} type_btn="success" />
                                    </div>
                                })}
                            </div> :
                            <div className="alert alert-info text-center">
                                Aucun envoi possible via ce picking.<br></br>
                                Passez par <a href={"/supply-chain/tools/expedition?page=form&all=true"}>la demande de d'expédition classique</a> pour envoyer envoyer tout de même un article
                            </div>
                        }
                    </div>
                }
            </div>}

        </div>} />

}

export default DeliveryComp