import { Fragment, useEffect, useState } from "react";
import { isNumeric } from "validator";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { currentFrDate, forceString, isAtLeastOneNotEmpty } from "../../functions/StrFunctions";
import errorManagement from "../../services/errorManagement";
import Quality from "../../services/storehouse/Quality";
import NcEmailSupplier from "./components/NcEmailSupplier";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { CustomToolTipInfo } from "../../common/smallComponents.js/CustomToolTip";

const NonConformityCard = (props) => {
    const [order, setOrder] = useState("");
    const [orderInfo, setOrderInfo] = useState();
    const [contacts, setContacts] = useState();
    const [chosenContacts, setChosenContacts] = useState();
    const [ncHeader, setNcHeader] = useState({ packing_type: "", packing_length: "", packing_width: "", packing_height: "", packing_weight: "" });

    const [id, setId] = useState();

    const row = { ar: "", label: "", ref: "", serial: "", com: "", ordered: "", qtyNc: "", status: "rep", impact_date: "" };
    const [rows, setRows] = useState([{ ...row }, { ...row }, { ...row }, { ...row }, { ...row }]);
    const [files, setFiles] = useState(new Array(4));
    const [files64, setFiles64] = useState(new Array(4));
    const [imgDisplay, setImgDisplay] = useState();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();

    const [businessList, setBusinessList] = useState();
    const [chosenBusiness, setChosenBusiness] = useState();

    const [artList, setArtList] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const readOnly = user && !user.roles.includes("ROLE_STOREHOUSE") && !user.roles.includes("ROLE_ADMIN");
    const isPurchase = user && (user.roles.includes("ROLE_PURCHASE") || user.roles.includes("ROLE_ADMIN"));
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        var id = getUrlParam(props, "id");
        if (id !== null) {
            //return;
            setId(id);
            //Get NC
            setLoading(true);

            Quality.getNcCard(id).then(
                (res) => {
                    setChosenContacts(res.contacts);
                    setOrder(res.nc[0].no_order);

                    // Get saved rows
                    var i = 0;
                    var nc = new Array();
                    res.nc.map(v => {
                        var o = { ...row };
                        o.ar = v.ar;
                        o.label = v.designation;
                        o.ref = v.ref;
                        o.serial = v.serial_num;
                        o.com = v.comment;
                        o.ordered = v.qty_in_order;
                        o.qtyNc = v.nc_qty;
                        o.status = v.reprise ? "rep" : "der";
                        o.no_nc = v.no_nc;
                        o.excel_fnc = v.excel_fnc;
                        o.supplier = v.subcontractor;
                        o.observation = v.observation;
                        o.author = v.author;
                        o.order_row = v.order_row;
                        o.impact_date = v.impact_date
                        nc.push(o);
                        i++;
                    });
                    // Complete until 5 rows
                    for (i; i < 5; i++) nc.push({ ...row });
                    setRows(nc);

                    // Get saved files
                    var arr = new Array(4);
                    var arr64 = new Array(4);
                    
                    if (res.nc[0].attachment_1 !== null) {
                        arr[0] = res.nc[0].attachment_1;
                        arr64[0] = res.nc[0].att_1_b64;
                    }

                    if (res.nc[0].attachment_2 !== null) {
                        arr[1] = res.nc[0].attachment_2;
                        arr64[1] = res.nc[0].att_2_b64;
                    }

                    if (res.nc[0].attachment_3 !== null) {
                        arr[2] = res.nc[0].attachment_3;
                        arr64[2] = res.nc[0].att_3_b64;
                    }
                    if (res.nc[0].attachment_4 !== null) {
                        arr[3] = res.nc[0].attachment_4;
                        arr64[3] = res.nc[0].att_4_b64;
                    }
                  
                    setFiles(arr);
                    setFiles64(arr64);

                    //Get distinct business
                    arr = new Array();
                    res.order_detail.map(v => {
                        if (arr.indexOf(v.business) === -1) arr.push(v.business);
                    });

                    if (arr.length >= 1)
                        setChosenBusiness(arr[0]);

                    setBusinessList(arr);

                    setOrderInfo(res.order_detail);

                    setContacts(res.contacts);

                    setLoading(false);
                },
                (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                }
            );
        }

    }, []);

    /**
     * Get order details
     * 
     * @param {*} e 
     */
    const getOrderInfo = (e) => {
        e.preventDefault();
        setLoading(true);
        setError();
        setMsg();

        Quality.getOrderInfo(e.target.order.value).then(
            (res) => {
                setOrder(e.target.order.value);

                //Get distinct business
                var arr = new Array();
                res.order_detail.map(v => {
                    if (arr.indexOf(v.business) === -1) arr.push(v.business);
                });

                if (arr.length === 1)
                    setChosenBusiness(arr[0]);

                setBusinessList(arr);

                setOrderInfo(res.order_detail);
                setContacts(res.contacts);

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    /**
     * On business change, update list of articles and reset the rows
     */
    useEffect(() => {
        var arr = new Array();
        orderInfo && orderInfo.map(v => {
            if (chosenBusiness === v.business && arr.indexOf(v.ar) === -1)
                arr.push({ ...v });
        });
        setArtList(arr);

        !id && setRows([{ ...row }, { ...row }, { ...row }, { ...row }, { ...row }]);
    }, [chosenBusiness]);

    /**
     * On change field of row
     */
    const onChangeField = (row, field, e) => {
        var arr = [...rows];
        var value = e.target.value;
        arr[row][field] = value;

        if (field === "ar") {
            var idx = e.target.options[e.target.options.selectedIndex].getAttribute("data-key")
            var r = artList[idx];
            arr[row].label = r.designation;
            arr[row].ref = r.ref;
            arr[row].ordered = r.ordered_qty;
            arr[row].order_row = r.order_row;
            arr[row].serial = r.machine || "Stock";
        }

        setRows(arr);
    }

    /**
     * Add or remove file
     */
    const onFileChoose = (idx, e) => {
        var arr = [...files];
        arr[idx] = e.target.files[0];
        setFiles(arr);
    }

    /**
     * Save NC
     */
    const saveNc = () => {
        setError();
        for (let i = 0; i < rows.length; i++) {
            var v = rows[i];
          
            if (i === 0 && ( v.ar === "" || !isNumeric( forceString( v.qtyNc ) ))) {
                setError("La première ligne doit être obligatoirement remplie");
                scrollToTop();
                return;
            } else if (i > 0 && v.ar !== "" && !isNumeric( forceString( v.qtyNc ) )) {
                setError("Merci de compléter les informations manquantes (articles et qté)");
                scrollToTop();
                return;
            }
        };

        setLoading(true);
        scrollToTop();

        Quality.saveNc(orderInfo[0], rows, files, !id, chosenBusiness, ncHeader).then(
            (res) => {
                window.location.href = "/storehouse/quality/nc-list";
            },
            (error) => {
                console.error(error)
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    /**
     * Delete file
     */
    const deleteFile = (idx) => {
        var arr = [...files];
        arr[idx] = undefined;
        setFiles(arr);
    }

    const displayFile = (idx) => {
        setImgDisplay(files64[idx])

    }

    const checkContact = (e, field) => {
        var o = { ...chosenContacts };
      
        if (e.target.checked) { o[field] = contacts[field]; }
        else { o[field] = null; }
        setChosenContacts(o);
    }

    const updateNcEmail = (e, field) => {
        var o = {
            supplier: orderInfo[0].supplier,
            email: e.target.value,
            field: field
        }

        Quality.updateNcEmail(o).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );
    }

    const updateNcHeader = (e, field) => {
        var o = {...ncHeader};
        o[field] = e.target.value;
        setNcHeader(o);
    }

    return <Fragment>
        {error && <ErrorMessage error={error} errorState={setError} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {
            (rows && id) &&
            <a target="_blank" href={API_URL + "storehouse/quality/non-conformities/download?"
                + "Authorization=Bearer " + user.accessToken + "&id=" + rows[0].no_nc}>
                <img src="/img/print.png" style={{ position: "absolute" }} title="Télécharger le fichier"></img>
            </a>
        }

        <div className="card col-10 offset-1" style={{ minWidth: "18rem", marginTop: "0px" }}>
            <div className="card-body">
                <h4 className="text-center">Nouvelle Fiche de Non-Conformité</h4>
                <h5 className="card-title text-center">Information sur la commande</h5>

                {/** FORM */}
                <form onSubmit={getOrderInfo}>
                    <div className="input-group mb-3">
                        <span className="input-group-text">N° de commande</span>
                        <input type="number" className="form-control text-center fw-bold"
                            name="order" defaultValue={order} required disabled={id} />
                        {
                            !id &&
                            <button className="btn btn-outline-success" disabled={loading}>
                                {loading && <ButtonWaiting />}
                                Rechercher
                            </button>
                        }
                    </div>
                </form>

                {/** ORDER INFOS */}
                {
                    orderInfo && <div className="table-responsive">
                        <div className="display-flex mb-3" style={{ minWidth: "800px" }}>
                            {/** BUSINESS */}
                            {businessList && <div className="me-1 ms-auto">
                                {businessList.length === 1 && <div className="form-floating ms-auto">
                                    <input type="text" className="form-control" placeholder="xxx"
                                        value={chosenBusiness} readOnly />
                                    <label>Affaire</label>
                                </div>}

                                {businessList.length > 1 && <div className="form-floating ms-auto">
                                    <select className="form-select" defaultValue={chosenBusiness}
                                        onChange={(e) => setChosenBusiness(e.target.value)} disabled={chosenBusiness}>
                                        <option value="">Choisissez l'affaire</option>
                                        {businessList.map((v, k) => { return <option value={v} key={"biz" + k}>{v}</option> })}
                                    </select>
                                    <label>Affaire</label>
                                </div>}
                            </div>}

                            <div className="form-floating me-1">
                                <input type="text" className="form-control" placeholder="xxx"
                                    value={orderInfo[0].supplier} readOnly />
                                <label >Fournisseur</label>
                            </div>

                            <div className="form-floating me-1">
                                <input type="text" className="form-control" placeholder="xxx"
                                    value={currentFrDate()} readOnly />
                                <label >Date de création</label>
                            </div>

                            <div className="form-floating me-1">
                                <input type="text" className="form-control" placeholder="xxx"
                                    value={orderInfo[0].tel} readOnly />
                                <label >Téléphone</label>
                            </div>

                            <div className="form-floating me-auto">
                                <input type="text" className="form-control" placeholder="xxx"
                                    value={id ? rows[0].author : user.trigram} readOnly />
                                <label >Auteur</label>
                            </div>
                        </div>

                        {/** EMAILS */}
                        <div className="input-group mb-3">
                            {/** Mail 1 */}
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox"
                                    defaultChecked={chosenContacts && chosenContacts.first_mail !== null}
                                    onClick={(e) => checkContact(e, "first_mail")} />
                            </div>
                            <input type="text" className="form-control" title={contacts.first_mail}
                                defaultValue={contacts.first_mail} readOnly />

                             {/* Mail 2
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox"
                                    defaultChecked={chosenContacts && chosenContacts.second_mail !== null}
                                    onClick={(e) => checkContact(e, "second_mail")} />
                            </div>
                            <input type="text" className="form-control" title={contacts.second_mail}
                                defaultValue={contacts.second_mail} readOnly />

                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox"
                                    defaultChecked={chosenContacts && chosenContacts.third_mail !== null}
                                    onClick={(e) => checkContact(e, "third_mail")} />
                            </div>
                            <input type="text" className="form-control" title={contacts.third_mail}
                                defaultValue={contacts.third_mail} readOnly /> */}

                            {/** Mail special NC 1 */}
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox"
                                    defaultChecked={chosenContacts && chosenContacts.nc_1 !== null}
                                    onClick={(e) => checkContact(e, "nc_1")} />
                            </div>
                            <input type="text" className="form-control" title={contacts.nc_1}
                                defaultValue={contacts.nc_1} readOnly={!isPurchase}
                                onBlur={(e) => updateNcEmail(e, "ZN08PF")}/>

                            {/** Mail special NC 2 */}
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox"
                                    defaultChecked={chosenContacts && chosenContacts.nc_2 !== null}
                                    onClick={(e) => checkContact(e, "nc_2")} />
                            </div>
                            <input type="text" className="form-control" title={contacts.nc_2}
                                defaultValue={contacts.nc_2} readOnly={!isPurchase}
                                onBlur={(e) => updateNcEmail(e, "ZN09PF")} />

                            {/** Mail special NC 3 */}
                            <div className="input-group-text">
                                <input className="form-check-input mt-0" type="checkbox"
                                    defaultChecked={chosenContacts && chosenContacts.nc_3 !== null}
                                    onClick={(e) => checkContact(e, "nc_3")} />
                            </div>
                            <input type="text" className="form-control" title={contacts.nc_3}
                                defaultValue={contacts.nc_3} readOnly={!isPurchase}
                                onBlur={(e) => updateNcEmail(e, "ZN10PF")} />
                            <CustomToolTipInfo props={{ tooltipText: "Pour Schneider l'adresse première est Yves Guermeur, les autres en copie" }}/>
                        </div>

                        {/** PACKING */}
                        <div className="input-group mb-3">
                            <span className="input-group-text fw-bold" style={{ borderColor: "transparent" }}>Colisage</span>
                            <label className="input-group-text">Type</label>
                            <select className="form-select" value={ncHeader.packing_type}
                                onChange={(e)=>updateNcHeader(e, "packing_type")}>
                                <option value="">Choisissez</option>
                                <option value="Colis">Colis</option>
                                <option value="Palette">Palette</option>
                                <option value="Caisse">Caisse</option>
                                <option value="Enveloppe bulle">Enveloppe bulle</option>
                                <option value="Tube">Tube</option>
                            </select>

                            <label className="input-group-text">Longueur</label>
                            <input type="number" className="form-control" value={ncHeader.packing_length} 
                                onChange={(e)=>updateNcHeader(e, "packing_length")}/>
                            <span class="input-group-text">mm</span>

                            <label className="input-group-text">Largeur</label>
                            <input type="number" className="form-control" value={ncHeader.packing_width}
                                onChange={(e)=>updateNcHeader(e, "packing_width")} />
                            <span class="input-group-text">mm</span>

                            <label className="input-group-text">Hauteur</label>
                            <input type="number" className="form-control" value={ncHeader.packing_height}
                                onChange={(e)=>updateNcHeader(e, "packing_height")} />
                            <span class="input-group-text">mm</span>

                            <label className="input-group-text">Poids</label>
                            <input type="number" className="form-control" value={ncHeader.packing_weight}
                                onChange={(e)=>updateNcHeader(e, "packing_weight")} />
                            <span class="input-group-text">kg</span>
                        </div>
                    </div>
                }


            </div>
        </div>

        {
            (user && (user.roles.includes("ROLE_PURCHASE") || user.roles.includes("ROLE_ADMIN")) && files) &&
            <NcEmailSupplier rows={rows} files={files} files64={files64} contacts={chosenContacts} setMsg={setMsg} setError={setError}
                setLoading={setLoading} loading={loading} />
        }

        {loading && <WaitingRoundSnippers />}

        {
            orderInfo &&
            <div className="table-responsive">
                <table className="table mb-3">
                    <thead className="table-dark">
                        <tr>
                            <th>Code article</th>
                            <th>Désignation</th>
                            <th>Référence</th>
                            <th>N° de série</th>
                            <th>Description/commentaires</th>
                            <th>Qté en<br></br>cmd</th>
                            <th>Qté N.C</th>
                            <th>Traitement de la NC<br></br>Réservée au responsable</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map((v, k) => {
                                return <tr key={"ncRow" + k}>
                                    <td>
                                        <select className="form-select" value={v.ar}
                                            onChange={(e) => onChangeField(k, "ar", e)} disabled={readOnly}>
                                            <option value="">Choisissez...</option>
                                            {artList && artList.map((v2, k2) => {
                                                return <option value={v2.ar} key={"biz" + k2} data-key={k2}>{v2.ar}</option>
                                            })}
                                        </select>
                                    </td>

                                    <td>
                                        <input type="text" className="form-control" value={v.label} readOnly />
                                    </td>

                                    <td>
                                        <input type="text" className="form-control" value={v.ref} readOnly />
                                    </td>

                                    <td>
                                        <input type="text" className="form-control" value={v.serial}
                                            onChange={(e) => onChangeField(k, "serial", e)} readOnly={readOnly} />
                                    </td>

                                    <td>
                                        <textarea type="text" className="form-control" value={v.com} readOnly={readOnly}
                                            onChange={(e) => onChangeField(k, "com", e)} style={{ height: "30px" }} />
                                    </td>

                                    <td>
                                        <input type="text" className="form-control fw-bold text-center" style={{ maxWidth: "70px" }}
                                            value={v.ordered} readOnly />
                                    </td>

                                    <td>
                                        <input type="number" className="form-control" value={v.qtyNc} style={{ maxWidth: "70px" }}
                                            onChange={(e) => onChangeField(k, "qtyNc", e)} readOnly={readOnly} />
                                    </td>

                                    <td>
                                        <select className="form-select" value={v.status} disabled={readOnly}
                                            onChange={(e) => onChangeField(k, "status", e)}>
                                            <option value="rep">Rep. par S/T</option>
                                            <option value="der">Dérogation</option>
                                        </select>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>

                {
                    (id && rows) && <div className="col-10 offset-1 alert alert-secondary mt-2">
                        <h6 className="fw-bold">Observations:</h6>
                        {rows[0].observation}
                    </div>
                }

                <div>
                    {files[0] && <div className="mb-1">
                        {typeof (files[0]) === "string" && <Fragment>
                            <span className="me-2 fw-bold">PJ 1: {files[0]}</span>
                            <button className="btn btn-outline-secondary me-3" onClick={() => displayFile(0)}>Visualiser</button>
                        </Fragment>}
                        {typeof (files[0]) !== "string" && <span className="me-2 fw-bold">PJ 1 (nouveau): {files[0].name}</span>}
                        {!readOnly && <button className="btn btn-outline-danger" onClick={() => deleteFile(0)}>Effacer</button>}
                    </div>}

                    {files[1] && <div className="mb-1">
                        {typeof (files[1]) === "string" && <Fragment>
                            <span className="me-2 fw-bold">PJ 2: {files[1]}</span>
                            <button className="btn btn-outline-secondary me-3" onClick={() => displayFile(1)}>Visualiser</button>
                        </Fragment>}
                        {typeof (files[1]) !== "string" && <span className="me-2 fw-bold">PJ 2 (nouveau): {files[1].name}</span>}
                        {!readOnly && <button className="btn btn-outline-danger" onClick={() => deleteFile(1)} disabled={readOnly}>Effacer</button>}
                    </div>}

                    {(files[2]) && <div className="mb-1">
                        {typeof (files[2]) === "string" && <Fragment>
                            <span className="me-2 fw-bold">PJ 3: {files[2]}</span>
                            <button className="btn btn-outline-secondary me-3" onClick={() => displayFile(2)}>Visualiser</button>
                        </Fragment>}
                        {typeof (files[2]) !== "string" && <span className="me-2 fw-bold">PJ 3 (nouveau): {files[2].name}</span>}
                        {!readOnly && <button className="btn btn-outline-danger" onClick={() => deleteFile(2)} disabled={readOnly}>Effacer</button>}
                    </div>}

                    {files[3] && <div className="mb-1">
                        {typeof (files[3]) === "string" && <Fragment>
                            <span className="me-2 fw-bold">PJ 4: {files[3]}</span>
                            <button className="btn btn-outline-secondary me-3" onClick={() => displayFile(3)}>Visualiser</button>
                        </Fragment>}
                        {typeof (files[3]) !== "string" && <span className="me-2 fw-bold">PJ 4 (nouveau): {files[3].name}</span>}
                        {!readOnly && <button className="btn btn-outline-danger" onClick={() => deleteFile(3)} disabled={readOnly}>Effacer</button>}
                    </div>}

                </div>

                {
                    !readOnly &&
                    <Fragment>
                        <div className="mb-3 display-flex">
                            {!files[0] && <div className="input-group me-2">
                                <label className="input-group-text">PJ n°1 </label>
                                <input type="file" className="form-control" onChange={(e) => onFileChoose(0, e)} />
                            </div>}


                            {!files[1] && <div className="input-group me-2">
                                <label className="input-group-text">PJ n°2 </label>
                                <input type="file" className="form-control" onChange={(e) => onFileChoose(1, e)} />
                            </div>}

                            {!files[2] && <div className="input-group me-2">
                                <label className="input-group-text">PJ n°3 </label>
                                <input type="file" className="form-control" onChange={(e) => onFileChoose(2, e)} />
                            </div>}

                            {!files[3] && <div className="input-group me-2">
                                <label className="input-group-text">PJ n°4 </label>
                                <input type="file" className="form-control" onChange={(e) => onFileChoose(3, e)} />
                            </div>}

                        </div>

                        <div className="text-center mb-3">
                            <button className="btn btn-outline-success" onClick={saveNc}>Enregistrer</button>
                        </div>
                    </Fragment>
                }

            </div>
        }

        {imgDisplay && <div className="custom-tooltip-v2">
            <div className="modal-body xl blue-purple-bg">
                <button type="button" className="btn-close" onClick={() => setImgDisplay()}></button>
                <div>
                    <img src={imgDisplay} style={{ width: "100%" }} />
                </div>
            </div>
        </div>}


    </Fragment>

}

export default NonConformityCard;