import React, { Fragment, useEffect, useState } from 'react';
import Inputs from '../../../../common/smallComponents.js/Inputs';
import { getRelevanceScore } from '../../../../functions/StrFunctions';
import { setPopUp } from '../../../../functions/PageAnimation';

const Resources = ({this_business}) => {
    const [ original, setOriginal ] = useState([]);
    const [ list, setList ] = useState([]);

    useEffect( () => {
        setOriginal( this_business.resources );
        setList( this_business.resources );
    }, [this_business])

    function containsTerm(string, term) {
        // Normalize the string by replacing "#" with spaces and splitting into words
        const words = string.toLowerCase().split("#");
        
        // Normalize the term
        const normalizedTerm = term.toLowerCase();
    
        // Check if any word contains the term as a substring
        return words.some(word => word.includes(normalizedTerm));
    }

    const search = (e) => {
        var term = e.target.value.trim()
        
        if( term === "" ){
            setList([...original])
        }else{
            var arr = [];

            for( let i = 0; i < list.length; i++ )
                if(containsTerm( list[i].tags, term ) || containsTerm( list[i].title, term ) ) 
                    arr.push( list[i] );
    
            setList( arr );
        }        
    }

    function copyInClipBoard( txt ) {
        navigator.clipboard.writeText(txt);
        setPopUp("Copié dans le presse papier", "success", 3000)
    }

    function displayList( e, rank ){        
        var elt = document.getElementsByClassName("file_" + rank);

        if( !e.target.classList.contains("rotate-90") ){
            //Display
            e.target.classList.add("rotate-90")
            e.target.classList.remove("rotate-90-reverse");

            for( let i = 0; i< elt.length; i++ )
                elt[i].classList.remove("d-none")
        }else{
            //Hide
            e.target.classList.add("rotate-90-reverse");
            e.target.classList.remove("rotate-90"); 

            for( let i = 0; i< elt.length; i++ )
                elt[i].classList.add("d-none")          
        }
        
    }

    return (
        <div>
            <div style={{width: "300px"}} className='ms-auto me-auto'>
            <Inputs.Input hideSpan autoFocus={true} placeholder='Rechercher'
                onChange={(e) => search(e)} inputClass='text-center fw-bold'/>
            </div>

            <div style={{height: "70vh"}} className='overflow-x-auto'>
                { list.map( (v, k) => {
                    return <Fragment>
                        { v.level === 0 && <h5>
                            { v.type === "directory" ? 
                                        <img src="/common/folder.png" className='see-img w-30 me-2'/>
                                        : v.type === "internet" ? 
                                            <img src="/common/link.png" className='see-img w-30 me-2'/>
                                        :<img src="/common/file.png" className='see-img w-30 me-2'/> }
                            <a href="#" className='text-dark'
                                        onClick={()=>copyInClipBoard( v.path )}>{v.title}</a>
                        </h5> }

                        { v.level > 0 && 
                            <div className={"ms-" + (v.level * 2) + " d-flex " + ( v.parent !== -1 ? "file_" + v.parent + " d-none" : "" )}>
                                <div>
                                    { v.type === "directory" ? 
                                        <img src="/common/right_arrow_icon.png" className='see-img w-30 me-2 cursor-pointer'
                                            onClick={(e) => displayList(e, v.rank)}/>
                                        : v.type === "internet" ? 
                                            <img src="/common/link.png" className='see-img w-30 me-2'/>
                                        :<img src="/common/file.png" className='see-img w-30 me-2'/> }
                                </div>
                                <div>
                                { v.type === "directory" ?
                                    <a href="#" className='text-dark fw-bold'
                                        onClick={()=>copyInClipBoard( v.path )}>{v.title}</a>
                                    : v.type === "internet" ?
                                        <a href={v.path} className='text-dark' target='_blank'>{v.title}</a>
                                    :<div>
                                        {v.title}
                                    </div>
                                }
                                </div>
                            </div>

                        }
                    </Fragment>
                } )}
            </div>
        </div>
    );
};

export default Resources;