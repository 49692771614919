import React from 'react';
import { arrayRemoveElt, removeAndReturnArray, removeEltArray } from '../../../../functions/ArrayFunctions';
import Inputs from '../../../../common/smallComponents.js/Inputs';
import { forceString } from '../../../../functions/StrFunctions';
import BomDaoV3 from '../../../../services/machines/BomDaoV3';
import { setPopUp } from '../../../../functions/PageAnimation';
import errorManagement from '../../../../services/errorManagement';

const ChangeValidation = ({ bom_content, setBomContent, bom_content_change, setBomContentChange,
    bom_content_origin, loading, setLoading, machine_info, type_of_bom, setTab }) => {

    const validateChange = () => {
        var bom = [];
        setLoading( true );

        var done = "";
        // Get BOM to update, do not take multiple rows if there are multiple change for one row
        for( let i = 0; i < bom_content_change.length; i++ ){
            var id = forceString( bom_content_change[i].id );
            if( done.indexOf( id + ";" ) === -1 ) bom.push( bom_content[ parseInt( id ) ] )
            done += id + ";";
        }

        BomDaoV3.updateIntranetBom( bom_content_change, machine_info, type_of_bom, bom ).then(
            (res) => {
                setPopUp( "Sauvegardé" );
                setLoading( false );
                setBomContentChange([]);
                setTab("overview");
            }, (error) => {
                setPopUp( errorManagement.getErrorMsg( error ), "danger", 5000 )
                setLoading( false );
            }
        )
    };

    const cancelChange = (k) => {
        var bom = [...bom_content];
        var change = [...bom_content_change];

        var id = parseInt(bom_content_change[k].id);
        var field = bom_content_change[k].field;
        var old_val = bom_content_origin[id][field];

        //Update BOM
        bom[id][field] = old_val;

        //Update change table
        change = arrayRemoveElt(change, k);

        // If remove ref change, remove AR change
        if (field === "ref") {
            bom[id].ar = bom_content_origin[id].ar;
            bom[id].frenchLabel = bom_content_origin[ id ].frenchLabel;
            bom[id].stock = bom_content_origin[id].stock;
            bom[id].coms = bom_content_origin[id].coms;
            var idx = change.findIndex(v => parseInt(v.id) === id && v.field === "ar");
            if (idx !== -1) change = arrayRemoveElt(change, idx);
        } else if (field === "ar") {
            bom[id].ref = bom_content_origin[id].ref;
            bom[id].frenchLabel = bom_content_origin[ id ].frenchLabel;
            bom[id].stock = bom_content_origin[id].stock;
            bom[id].coms = bom_content_origin[id].coms;
            var idx = change.findIndex(v => parseInt(v.id) === id && v.field === "ref");
            if (idx !== -1) change = arrayRemoveElt(change, idx);
        }

        setBomContent(bom);
        setBomContentChange(change);
    }

    return (
        <div>
            <h6 className='text-center'>Récapitulatif des modifications à sauvegarder</h6>

            <div className='ms-auto me-auto' style={{ width: "90%" }}>
                <table className='table'>
                    <thead>
                        <tr className='fw-bold table-secondary'>
                            <th>Champ</th>
                            <th>S/E</th>
                            <th>Rep.</th>
                            <th>Ancienne valeur</th>
                            <th>Nouvelle valeur</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {bom_content_change.map((v, k) => {
                            return <tr key={"change" + k}>
                                <td>{v.label}</td>
                                <td>{v.se}</td>
                                <td>{v.rep}</td>
                                <td>{v.old_val}</td>
                                <td>{v.new_val}</td>
                                <td>
                                    <button className='btn btn-outline-warning'
                                        onClick={() => cancelChange(k)}>Annuler la modif</button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <div className='text-center'>
                    <Inputs.SubmitBtn label="Sauvegarder" loading={loading} onClick={validateChange} />
                </div>
            </div>
        </div>
    );
};

export default ChangeValidation;