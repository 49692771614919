import { createColumnHelper } from '@tanstack/react-table';
import React, { Fragment, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { isSideBarOpened, SideNavBarV3 } from '../../../common/smallComponents.js/DesignedIpt';
import Inputs from '../../../common/smallComponents.js/Inputs';
import ReactTable from '../../../common/smallComponents.js/ReactTable';
import WaitingRoundSnippers from '../../../common/smallComponents.js/WaitingRoundSnippers';
import { setPopUp } from '../../../functions/PageAnimation';
import { fileToBase64, hasRole, sPlural } from '../../../functions/StrFunctions';
import errorManagement from '../../../services/errorManagement';
import BomDaoV3 from '../../../services/machines/BomDaoV3';
import { splitIntoChunks } from '../../../functions/ArrayFunctions';
import { ProgressBar } from 'react-bootstrap';
import { getStatusBg } from './SubComp/Common';
import { CustomToolTipInfo } from '../../../common/smallComponents.js/CustomToolTip';
import { WarningMsgObj } from '../../../common/smallComponents.js/ErrorMessage';

const ReadBomV3 = (props) => {
    const fileTypes = ["XLSX"];
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [temp_bom, setTempBom] = useState();
    const [machine, setMachine] = useState("");
    const [pin, setPin] = useState(isSideBarOpened());

    const [list, setList] = useState();
    const [comparison, setComparison] = useState();
    const [compareWith, setCompareWith] = useState("");

    const [progress, setProgress] = useState();
    const [progressMsg, setProgressMsg] = useState();
    const [launchGame, setLaunchGame] = useState(false);

    const [warning, setWarning] = useState();

    /**
     * SEND THE FILES
     * @param {*} e 
     * @returns 
     */
    const onSubmit = (e) => {
        e.preventDefault();
        setError();

        if (files.length === 0) { setPopUp("Téléchargez la ou les nomenclatures", "danger"); return; }

        var o = { machine: e.target.machine.value, files: files }

        setLoading(true);

        BomDaoV3.readBOM(o).then(
            (response) => {
                // console.log(response)
                setTempBom(response)
                setList(response.bom_content)
                if( response.warning.length > 0 ) setWarning( response.warning );
                setLoading(false);
            }, (error) => {
                var err = errorManagement.getErrorMsg(error);
                if (err.indexOf("<table") !== -1) {
                    setError(err)
                } else {
                    setPopUp(err, "danger", 5000)
                }

                setLoading(false);
            }
        )
    }

    /**
     * ON FILE UPLOAD
     */
    const shareFiles = async (e) => {
        var files = [];
        setLoading(true);

        for (let i = 0; i < e.length; i++) files.push(await fileToBase64(e[i]));

        if (files.length === 1) {
            var file_name = files[0].name;
            if (file_name.indexOf("-") !== -1) setMachine(file_name.split("-")[1]);
        }

        setFiles(files);
        setLoading(false);
    }

    /**
     * TABLE
     */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('se',
            { header: 'S/E.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('rep',
            { header: 'Rep.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('cat',
            { header: 'Cat.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ar',
            { header: 'Art.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            {
                header: 'Ref.', cell: (i) => <div className="text-center" style={{ width: "300px" }}>
                    {i.getValue()}
                    <div className='fst-italic' style={{ fontSize: "12px" }}>{i.row.original.searched_ref}</div>
                </div>
            }));

        arr.push(columnHelper.accessor('frenchLabel',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('qty',
            { header: 'Qté. à commander', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        if (comparison) {
            if (compareWith == "intranet") {
                arr.push(columnHelper.accessor('intranet_qty',
                    { header: 'Qté intranet', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
            } else {
                arr.push(columnHelper.accessor('focus_qty',
                    { header: 'Qté. Focus', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
            }

            arr.push(columnHelper.accessor('delta',
                {
                    header: 'Delta', cell: (i) => <div style={{ height: "100%" }} className={"text-center "
                        + (i.getValue() !== 0 ? "bg-warning" : "")}>{i.getValue()}</div>
                }));
        } else {
            arr.push(columnHelper.accessor('qty',
                { header: 'Qté.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        }

        arr.push(columnHelper.accessor('message',
            { header: 'Alerte', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    /**
     * SAVE TEMP
     */
    const save = async (mode) => {
        setLoading(true);
        setLaunchGame(true);
        setError();

        try {
            // Delete temp BOM
            setProgressMsg("Récupération des codes articles et enregistrement, merci de pas fermer la page");
            setProgress(0);
            await BomDaoV3.deleteBOM(temp_bom.machine_info, temp_bom.type_of_bom);

            var chunk = splitIntoChunks(temp_bom.bom_content, 50);
            var chunk_length = chunk.length;
            //Chunk table for progression
            for (let i = 0; i < chunk_length; i++) {
                await BomDaoV3.saveTempBOM(chunk[i], mode)
                setProgress(((i / chunk_length) * 100).toFixed(0))
            }

            BomDaoV3.notifyInjectedBOM(temp_bom.bom_planning, temp_bom.machine_info, temp_bom.type_of_bom, mode);

            setProgress(100);
            setProgressMsg("Redirection en cours...");
            window.location.href = "/bom/get/v3?machine=" + temp_bom.machine_info.no_machine
                + "&type_of_bom=" + temp_bom.type_of_bom
        } catch (error) {
            setProgress();
            setProgressMsg();
            setLoading(false);
            setPopUp("Une erreur s'est produite: " + error, "danger", 5000)
        }

    }

    /**
     * COMPARE
     */
    const compare = (source) => {
        setLaunchGame(true);
        setLoading(true);
        setError();
        setCompareWith(source);
        setList([]);

        const bom_content = temp_bom.bom_content;
        const machine = temp_bom.machine_info.no_machine;
        const business = temp_bom.machine_info.affaire;
        const type_of_bom = temp_bom.type_of_bom;

        BomDaoV3.compareTempBOM(bom_content, machine, source, business, type_of_bom, temp_bom.machine_info).then(
            (response) => {
                setComparison(response);
                setList(response);
                setLoading(false);
                setLaunchGame(false);
            }, (error) => {
                setPopUp(errorManagement.handleError(props, error), "danger", 4000)
                setLoading(false);
                setLaunchGame(false);
            }
        )
    }



    const reset = () => {
        setTempBom();
        setError();
        setFiles([]);
        setProgress();
        setProgressMsg();
    }

    return (
        <div className="bg-see navbarV3 right-side">
            <SideNavBarV3 setPin={setPin} force_open={true} content={<div className='ps-3 pe-1'>
                {loading && <WaitingRoundSnippers />}

                {((error || temp_bom) && !loading) && <div className='mb-3'>
                    <h6 className='text-center'>Action</h6>
                    <a href="#" onClick={reset}>
                        Recommencer l'import</a>
                </div>}

                {(temp_bom && !loading) && <div>
                    {compareWith !== "" &&
                        <div className='mb-3'>
                            <a href='#' onClick={() => { setList(temp_bom.bom_content); setCompareWith("") }} >
                                Effacer le comparatif</a>
                        </div>}

                    {compareWith == "" && <Fragment>
                        <h6 className='text-center'>Simulation</h6>
                        <div className='mb-3'>
                            <a href='#' onClick={() => compare("intranet")} >Comparer avec la nomenclature intranet existante</a>
                        </div>

                        <div className='mb-3'>
                            <a href='#' onClick={() => compare("focus")} >Comparer avec Focus</a>
                        </div>

                        <h6 className='text-center'>Validation</h6>
                        <div className='mb-3'>
                            <a href='#' onClick={() => save("validator")} >Sauvegarder dans l'intranet</a>
                        </div>

                        {hasRole(["ROLE_ADMIN", "ROLE_BOM_VALIDATION"]) &&

                            <div className='mb-3'>
                                <a href='#' onClick={() => save("purchase")} >Sauvegarder dans l'intranet et envoyer aux achats</a>
                            </div>}
                    </Fragment>}



                </div>}
            </div>} />

            <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
                <h3 className='text-center mb-3'>Lecture de nomenclature (compilée ou non)</h3>
                
                { warning && 
                <WarningMsgObj warning={<div>
                        <h6 className='text-center'>Des éléments non bloquants requièrent votre attention</h6>
                        <table className='table'>
                            <thead>
                            <tr>
                                <td>Fichier</td>
                                <td>N° de ligne</td>
                                <td>Alerte</td>
                            </tr>
                            </thead>
                            <tbody>
                                { warning.map( v => {
                                    return <tr>
                                        <td>{v.file}</td>
                                        <td>{v.line}</td>
                                        <td>{v.msg}</td>
                                    </tr>
                                } )}
                            </tbody>
                        </table>
                    </div>} warningState={setWarning}/>}

                {loading && <WaitingRoundSnippers launchGame={launchGame} />}
                {(!error && !temp_bom) &&
                    <form onSubmit={onSubmit}>
                        <div className='ms-auto me-auto mb-3' style={{ width: "500px" }}>
                            <Inputs.Input hideSpan name="machine" inputClass="text-center fw-bold"
                                placeholder='N° de machine (préfixe conseillé en cas de rétrofit)'
                                onChange={(e) => setMachine(e.target.value)} value={machine || ""} required />
                        </div>

                        <FileUploader handleChange={shareFiles} name="file" types={fileTypes} multiple={true}
                            children={
                                <div className="border ms-auto me-auto rounded text-center mb-3"
                                    style={{ overflow: "hidden", width: "500px" }}>
                                    <h4 className="">Sélectionnez ou glisser les fichiers</h4>
                                    <div className="mb-3">Type de fichier autorisé: {fileTypes.join(", ")}</div>

                                    <img className="mt-auto mb-auto" src="/common/upload_icon.png"></img>
                                </div>
                            } />

                        {files.length > 0 &&
                            <Inputs.SubmitBtn label={"Lire " + files.length + " " + sPlural("fichier", files)}
                                loading={loading} />}
                    </form>}

                {error && <div className='ms-auto me-auto mt-3 col-10'>
                    <div dangerouslySetInnerHTML={{ __html: error }}></div>
                </div>}

                {(temp_bom && list) && <div className='mt-3'>
                    <div className='text-center fw-bold alert alert-info'>
                        <div>La nomenclature de {temp_bom.bom_content.length} articles est prête à être sauvegarder</div>
                    </div>

                    {progress !== undefined && <div className='mb-3'>
                        <h5 className='text-center'>{progressMsg}</h5>
                        <ProgressBar label={progress + ' %'} animated now={progress} />

                    </div>}

                    {progress === undefined &&
                        <div>
                            <div className='ps-3 pb-3 d-flex'>
                                <div className='d-flex w-100'><div style={{ minWidth: "80px" }}>Machine:</div> <strong>{temp_bom.machine_info.no_machine}</strong></div>
                                <div className='d-flex w-100'><div style={{ minWidth: "80px" }}>Config:</div> <strong>{temp_bom.machine_info.typ_config}</strong></div>
                                <div className='d-flex w-100'><div style={{ minWidth: "80px" }}>Ligne:</div>
                                    <div><strong>{temp_bom.machine_info.no_ligne}</strong></div>
                                    <div><CustomToolTipInfo props={{ tooltipText: "Les S/E qui ne commencent pas par ce n° de ligne ne sont pas pris en compte dans le comparatif avec Focus. Il est normal d'avoir des delta" }} /></div>
                                </div>
                                <div className='d-flex w-100'><div style={{ minWidth: "80px" }}>Affaire:</div> <strong>{temp_bom.machine_info.affaire}</strong></div>
                                <div className={'d-flex w-100 p-1 rounded ' +
                                    getStatusBg(temp_bom.bom_planning && temp_bom.bom_planning.status)}>
                                    <div style={{ minWidth: "80px" }}>Statut:</div>
                                    <strong>{!temp_bom.bom_planning ? "Non enregistré" : temp_bom.bom_planning.status}</strong></div>
                            </div>
                            <ReactTable columns={columns} data={list} origin="temp_bom_preview" classes={"no-padding fixFstCol "} top0={true} />
                        </div>

                    }
                </div>}
            </div>
        </div>
    );
};

export default ReadBomV3;