import React, { Fragment, useEffect, useRef, useState } from 'react';
import { compareTwoDates } from '../../../../functions/StrFunctions';

const Planning = ({ planning }) => {
    const [plan, setPlan] = useState([]);
    const [showing, setShowing] = useState(-1);

    const timelineRef = useRef(null);
    const [scrollPos, setScrollPos] = useState(0);
    const xScrolling = 280;
    const [isPrevDisabled, setIsPrevDisabled] = useState(true);
    const [isNextDisabled, setIsNextDisabled] = useState(false);

    function getPlanObj(time, step, date_start, date_end, date_start_real, date_end_real) {
        return { time: time, step: step, date_start: date_start, date_end: date_end, date_start_real: date_start_real, date_end_real: date_end_real }
    }



    useEffect(() => {
        setButtonStates();
    }, [scrollPos]);

    const isElementInViewport = (el) => {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    const setButtonStates = () => {
        if (timelineRef.current) {
            const firstItem = timelineRef.current.firstChild;
            const lastItem = timelineRef.current.lastChild;
            setIsPrevDisabled(isElementInViewport(firstItem));
            setIsNextDisabled(isElementInViewport(lastItem));
        }
    };

    const animateTl = (direction) => {
        if (timelineRef.current) {
            let newScrollPos = scrollPos + (direction === "next" ? -xScrolling : xScrolling);
            setScrollPos(newScrollPos);
            timelineRef.current.style.transform = `translateX(${newScrollPos}px)`;
        }
    };

    const isComing = (v) => {
        var d = v.date_start || v.date_start_real;
        if( !d ) return true;
        const [day, month, year] = d.split('/');
        d = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        return compareTwoDates(  new Date(), d ) > 0;
    }

    useEffect(() => {
        if (!planning) return;

        var arr = [];
        if (planning.launch) arr.push(getPlanObj(planning.launch, "Lancement", "", "", planning.launch_date, ""));
        if (planning.be_start) arr.push(getPlanObj(planning.be_start, "Etudes", planning.be_start_date, planning.be_end_date, planning.be_start_real_date, planning.be_end_real_date));
        if (planning.purchase_start) arr.push(getPlanObj(planning.purchase_start, "Achats", planning.purchase_start_date, planning.purchase_end_date, planning.purchase_start_real_date, planning.purchase_end_real_date));
        if (planning.shopper_start) arr.push(getPlanObj(planning.shopper_start, "Sorties de stock", planning.shopper_start_date, planning.shopper_end_date, planning.shopper_start_real_date, planning.shopper_end_real_date));
        if (planning.receiption_start_real) arr.push(getPlanObj(planning.receiption_start_real, "Appro", "", "", planning.receiption_start_real_date, planning.receiption_end_real_date));
        if (planning.manufacturing_start) arr.push(getPlanObj(planning.manufacturing_start, "Montage / Intégration", planning.manufacturing_start_date, planning.manufacturing_end_date, planning.manufacturing_start_real_date, planning.manufacturing_end_real_date));
        if (planning.mes_start) arr.push(getPlanObj(planning.mes_start, "Mise En Service (client)", planning.mes_start_date, planning.mes_end_date, planning.mes_start_real, planning.mes_end_real));
        if (planning.fat_start) arr.push(getPlanObj(planning.fat_start, "FAT", planning.fat_start_date, planning.fat_end_date, planning.fat_real_start_date , planning.fat_real_end_date));
        if (planning.delivery_real) arr.push(getPlanObj(planning.delivery_real, "Expédition", "", "", planning.delivery_real_date));
        if (planning.commis_start) arr.push(getPlanObj(planning.commis_start, "Mise en service (B+)", planning.commis_start_date, planning.commis_end_date, planning.cli_commiss_start_date, planning.cli_commiss_end_date));
        if (planning.mep_start) arr.push(getPlanObj(planning.mep_start, "Mise en production", planning.mep_start_date, planning.mep_end_date, planning.cli_mep_start_date, planning.cli_mep_end_date));

        arr.sort((a, b) => {
            var split_a = a.time.split('/');
            var split_b = b.time.split('/');

            if (parseInt(split_b[1]) < parseInt(split_a[1])) return 1;
            if (parseInt(split_b[1]) > parseInt(split_a[1])) return -1;

            if (parseInt(split_b[0]) < parseInt(split_a[0])) return 1;
            if (parseInt(split_b[0]) > parseInt(split_a[0])) return -1;
        })

        setPlan(arr);
    }, [planning])

    return <Fragment>

        {plan.length > 0 &&
            <section class="timeline">
                <ol ref={timelineRef}>
                    {plan.map((v, k) => {
                        return <li onMouseEnter={() => setShowing(k)}
                            onMouseLeave={() => setShowing(-1)} style={{ opacity : ( isComing( v ) ? "0.3" : "1" ) }}>
                            <div>
                                <time>{v.time}</time>
                                {v.step}<br></br>
                                <p className={showing === k ? "show" : ""}>
                                    {(v.date_start && v.date_start !== "") &&
                                        <Fragment>Prev: {v.date_start}</Fragment>}

                                    {(v.date_end && v.date_end !== "") &&
                                        <Fragment> → {v.date_end}</Fragment>}

                                    <br></br>

                                    {(v.date_start_real && v.date_start_real !== "") &&
                                        <Fragment>Réel: {v.date_start_real}</Fragment>}

                                    {(v.date_end_real && v.date_end_real !== "") &&
                                        <Fragment> → {v.date_end_real}</Fragment>}
                                </p>
                            </div>
                        </li>
                    })}
                    <li></li>
                </ol>

                <div class="arrows">
                    <button class="arrow arrow__prev disabled" className={`arrow arrow__prev ${isPrevDisabled ? "disabled" : ""}`}
                        onClick={() => animateTl("prev")}
                        disabled={isPrevDisabled}>
                        <img src="/common/arrow_prev.svg" alt="prev timeline arrow" />
                    </button>
                    <button class="arrow arrow__next" className={`arrow arrow__next ${isNextDisabled ? "disabled" : ""}`}
                        onClick={() => animateTl("next")}
                        disabled={isNextDisabled}>
                        <img src="/common/arrow_next.svg" alt="next timeline arrow" />
                    </button>
                </div>
            </section>
        }



    </Fragment>
};

export default Planning;