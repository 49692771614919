import React, { Fragment, useEffect, useState } from 'react';
import getUrlParam from '../../functions/StrFunctions';
import Cabinets from '../bom/V2/SubComp/Cabinets';
import IA from './TestComponents/IA';

const AnyTable = (props) => {
    const [table, setTable] = useState("");

    useEffect( () => { console.log("ok"); setTable( getUrlParam(props, "table") ) }, [] )

    return <Fragment>
        { table === "cabinets" && <Cabinets  props={props}/> }
        { table === "ai-test" && <IA  props={props}/> }
    </Fragment>
};

export default AnyTable;