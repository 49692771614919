import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { SideNavBarV3, isSideBarOpened } from "../../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { ButtonWaiting, scrollToTop, setPopUp } from "../../../functions/PageAnimation";
import getUrlParam from "../../../functions/StrFunctions";
import errorManagement from "../../../services/errorManagement";
import { downloadBase64File, downloadTempFile } from "../../../services/functions/FilesProcess";
import transport from "../../../services/supplyChain/transport";
import DeliveryNote from "./Machines/DeliveryNote";
import MachPacking from "./Machines/MachPacking";
import MachPicking from "./Machines/MachPicking";
import OtherPacking from "./Machines/OtherPacking";
import OtherPicking from "./Machines/OtherPicking";
import { QRCodeCanvas } from "qrcode.react";

const PackingDocs = (p) => {
  const [pin, setPin] = useState(isSideBarOpened());
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState("picking");
  const [type, setType] = useState();
  const [changeDone, setChangeDone] = useState(false);
  const [packingList, setPackingList] = useState([]);

  const { t } = useTranslation('shipment');

  const [picking, setPicking] = useState();

  const [pickingId, setPickingId] = useState();

  const [qrCode, setQrCode] = useState();

  useEffect(() => {
    //Get type of transport to generate specific doc
    var type = getUrlParam(p.props, "type");
    setType(type);

    //Get picking content of machine 
    if (type === "machine") {
      getMachinePicking();
    } else {
      getOtherPicking();
      setQrCode(window.location.href.replace("page=doc", "page=qr_code"));
    }

  }, []);

  //Get machine picking
  function getMachinePicking(picking_id = null) {
    transport.getPicking(getUrlParam(p.props, "shipment_id"), picking_id).then(
      (r) => {
        //console.log(r)
        setPicking(r);
        setPickingId(r.picking_id);


        setLoading(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setLoading(false);
      })
  }

  /**
   * Get 
   * - currentPicking (array): ? 
   * - packing_list (array): palet - pack mentionned in transport request
   * - picking (object): get counter, kit, commissioning, customer client picking linked to this business
   * - pickingList (array): ?
   * - picking_head: detail of this picking
   * - picking_id: this picking
   * - shipment: transport detail
   */
  function getOtherPicking(picking_id = getUrlParam(p.props, "picking_id")) {

    transport.getOtherPicking(getUrlParam(p.props, "shipment_id"), getUrlParam(p.props, "id"), picking_id).then(
      (r) => {

        if (r.picking_head && r.picking_head.current_picking)
          r.currentPicking = JSON.parse(r.picking_head.current_picking)

        console.log(r)
        setPicking(r);
        setPickingId(r.picking_id);
        setPackingList(r.packing_list);
        setLoading(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setLoading(false);
      })
  }

  /**
   * Select a created picking
   * @param {*} picking_id 
   */
  const changePicking = (picking_id) => {
    setLoading(true);
    setPicking();
    setPickingId();

    if (type === "machine") { getMachinePicking(picking_id) }
    else { getOtherPicking(picking_id) }
  }

  /**
   * Save machine picking 
   * @param {*} send 
   */
  const savePicking = (send = false) => {
    setError();
    var p = [...picking.machines];

    var arr = [];
    for (let i = 0; i < p.length; i++) arr = arr.concat(p[i].picking)

    setSaving(true);

    transport.savePicking(picking.shipment.id, arr, picking.picking_head, send).then(
      (r) => {
        var o = { ...picking };
        o.picking_id = r;
        o.picking_head.picking_id = r;
        if (!o.pickingList.includes(r)) o.pickingList.push(r);
        setPicking(o);

        setPickingId(r);

        setPopUp("Sauvegardé")
        setSaving(false);
        setChangeDone(false);
      }, (error) => {
        console.error(error)
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setSaving(false);
      })
  }

  /**
   * Save other picking
   * 
   * @param {*} send 
   */
  const saveOtherPicking = (send = false) => {
    setError();

    setSaving(true);
    transport.saveOtherPicking(picking.shipment.id, picking.picking_head, send, picking.currentPicking, picking).then(
      (r) => {
        var o = { ...picking };
        o.picking_id = r;
        o.picking_head.picking_id = r;
        if (!o.pickingList.includes(r)) o.pickingList.push(r);
        setPicking(o);

        setPickingId(r);

        setPopUp("Sauvegardé " + (send ? "et notifié" : ""), 3000)
        setSaving(false);
        setChangeDone(false);
      }, (error) => {
        console.error(error)
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setSaving(false);
      })
  }

  /**
   * Print Hastag
   */
  const printHashTag = () => {
    var arr = getUrlParam(p.props, "type") === "other" ? getOthersTags() : getMachineTags();

    if (arr.length === 0) {
      setPopUp("Rien à imprimer, veuillez commencer par faire votre packing", "danger");
      return
    }

    setSaving(true);
    transport.downloadHashTag(arr).then(
      (r) => {
        downloadTempFile(r)
        setSaving(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setSaving(false);
      })
  }

  /**
   * Get list of tags to print for OTHER packing
   * @returns 
   */
  function getOthersTags() {
    var arr = [];

    var detail = picking.shipment.detail;

    for (let i = 0; i < detail.length; i++) {
      arr.push({
        id: (i + 1),
        machine: detail[i].type
      });
    }

    return arr;
  }

  /**
   * Get list of tags to print for machine packing
   * @returns 
   */
  function getMachineTags() {
    var arr = [];

    for (let i = 0; i < picking.machines.length; i++) {
      var v = picking.machines[i];

      for (let j = 0; j < v.picking.length; j++) {
        arr.push({
          id: v.picking[j].id,
          machine: v.machine.no_machine
        });
      }
    }

    return arr;
  }

  function dimConform(v) {
    if (v.type === "Caisse" && (parseFloat(v.pack_width) < 100 || parseFloat(v.pack_height) < 20 || parseFloat(v.truck_height) < 20)) {
      return "Attention pour les caisses, les dimensions minimales sont 100 x 20 x 20";
    } else if (v.type === "Colis" && (parseFloat(v.pack_width) < 24 || parseFloat(v.pack_height) < 18 || parseFloat(v.truck_height) < 8)) {
      return "Attention pour les colis, les dimensions minimales sont 24 x 18 x 8";
    } else if (v.type === "Palette" && (parseFloat(v.pack_width) < 80 || parseFloat(v.pack_height) < 60 || parseFloat(v.truck_height) < 13)) {
      return "Attention pour les palettes, les dimensions minimales sont 80 x 60 x 13";
    }


    return null;
  }

  /**
   * Download packing doc
   */
  const printPacking = () => {
    var err = "";

    // If other packing check the dimensions
    if (getUrlParam(p.props, "type") === "other") {
      var detail = picking.shipment.detail;
      var err;
      for (let i = 0; i < detail.length; i++) {
        err = dimConform(detail[i]);

        if (err !== null) {
          setPopUp(err, "danger", 5000);
          return;
        }
      }
    }

    setSaving(true);
    transport.downloadPacking(picking, tab, getUrlParam(p.props, "type")).then(
      (r) => {
        downloadBase64File(r);
        //downloadTempFile(r)
        setSaving(false);
      }, (error) => {
        setError(errorManagement.handleError(p.props, error));
        scrollToTop();
        setSaving(false);
      })
  }

  /**
   * If you quite picking list, check save or not
   */
  const changeTab = (tab) => {
    //Check change if from picking

    setTab(tab);
  }

  function isSaved() { return !changeDone && pickingId !== null; }

  /**
   * Delete picking
   */
  const deletePicking = () => {
    confirmAlert({
      title: "Suppression",
      message: "Confirmez-vous la suppression du picking n° " + pickingId + " ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            setSaving(true);
            transport.deletePicking(pickingId).then(
              (r) => {
                window.location.reload();
              }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setSaving(false);
              }
            )
          }
        },
        {
          label: "Non"
        }
      ]
    });
  }

  return <div className="bg-see" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
    <SideNavBarV3 setPin={setPin}
      content={<div>
        {picking &&
          <div className="accordion" id="accordionExample">

            <div className="accordion-item" onClick={() => setTab("picking")}>
              <h2 className="accordion-header">
                <button className={"accordion-button " + (isSaved() ? "" : "bg-warning")} type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {t('picking list')}
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">

                <div className="accordion-body cursor-pointer">
                  <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />
                  <a href="#" onClick={() => window.print()}>Imprimer le picking</a>
                </div>

                <div className="accordion-body cursor-pointer" onClick={printHashTag}>
                  {saving && <ButtonWaiting />}
                  {!saving && <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />}
                  <a href="#">Imprimer les ID (hash tag)</a>
                </div>

                {picking.pickingList.length > 0 && <div>
                  <h6 className="text-center">Autre picking</h6>

                  <div className="mb-3 ps-3">
                    {picking.pickingList.map((v, k) => {
                      return v !== pickingId && <li key={"pick" + k} onClick={() => changePicking(v)}>Picking n°{v}</li>
                    })}

                    {/* <li onClick={() => changePicking("new")}>Faire une nouveau picking</li> */}
                  </div>

                </div>}

                {qrCode && <div className="text-center">
                  <h6>QR Code pour douchette</h6>
                  <QRCodeCanvas value={qrCode} />
                </div>}

                {pickingId && <div className="mt-3 text-center mb-3">
                  <br></br>
                  <button className="btn btn-outline-danger" disabled={saving} onClick={deletePicking}>
                    {saving && <ButtonWaiting />}
                    Supprimer ce picking</button>
                </div>}
              </div>
            </div>

            <Fragment>
              <div className="accordion-item" onClick={() => changeTab("packing")}>
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    {t('packing list')}
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body">

                    {!isSaved() && <div className="text-danger fw-bold text-center">
                      ATTENTION: picking non enregistré!
                    </div>}

                    {picking.picking_id &&
                      <div className="accordion-body cursor-pointer">
                        {saving && <ButtonWaiting />}
                        {!saving && <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />}
                        <a href="#" onClick={printPacking}>Imprimer la packing list</a>
                      </div>
                    }

                    {qrCode && <div className="text-center">
                      <h6>QR Code pour douchette</h6>
                      <QRCodeCanvas value={qrCode} />
                    </div>}
                  </div>
                </div>
              </div>

              <div className="accordion-item" onClick={() => changeTab("delivery note")}>
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    {t('delivery note')}
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">

                  <div className="accordion-body">
                    {!isSaved() && <div className="text-danger fw-bold text-center">
                      ATTENTION: picking non enregistré!
                    </div>}
                    {picking.picking_id &&
                      <div>
                        {saving && <ButtonWaiting />}
                        {!saving && <img src="/common/print.png" className="me-1" style={{ width: "20px" }} />}
                        <a href="#" onClick={printPacking}>Imprimer le bordereau d'expédition</a>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    {t('customs Invoice')}
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body"></div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    {t('container declaration')}
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                  <div className="accordion-body"></div>
                </div>
              </div>

              <div className="mt-3 text-center">
                <a href="/supply-chain/tools/expedition">Revenir à l'accueil</a>
              </div>
            </Fragment>

          </div>}
      </div>} />

    <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
      {loading && <WaitingRoundSnippers />}
      {error && <ErrorMessage error={error} errorState={setError} />}

      {(type && tab) && <Fragment>

        {/**Machines */}
        {type === "machine" && <Fragment>
          {tab === "picking" && <MachPicking picking={picking} setPicking={setPicking} pickingId={pickingId}
            setChangeDone={setChangeDone} savePicking={savePicking} saving={saving} tab={tab} />}

          {tab === "packing" && <MachPacking picking={picking} setPicking={setPicking} pickingId={pickingId}
            setChangeDone={setChangeDone} savePicking={savePicking} saving={saving} tab={tab} />}

          {tab === "delivery note" && <DeliveryNote picking={picking} tab={tab} />}

        </Fragment>}

        {/** Other */}
        {type === "other" && <Fragment>


          {tab === "picking" && <OtherPicking picking={picking} setPicking={setPicking} pickingId={pickingId}
            saveOtherPicking={saveOtherPicking} saving={saving} setSaving={setSaving} />}

          {tab === "packing" && <OtherPacking picking={picking} setPicking={setPicking} pickingId={pickingId}
            saveOtherPicking={saveOtherPicking} saving={saving} setSaving={setSaving} packing_list={packingList}
            setPackingList={setPackingList} props={p.props} />}
        </Fragment>}

      </Fragment>}


    </div>

  </div >


}

export default PackingDocs;