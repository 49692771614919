import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import ReactTable from '../../../../common/smallComponents.js/ReactTable';
import { ProgressBar } from 'react-bootstrap';
import Inputs from '../../../../common/smallComponents.js/Inputs';
import { artChange, chapterChange, familyChange } from './Common';
import { CustomToolTipInfo } from '../../../../common/smallComponents.js/CustomToolTip';
import { setPopUp } from '../../../../functions/PageAnimation';
import { splitIntoChunks } from '../../../../functions/ArrayFunctions';
import BomDaoV3 from '../../../../services/machines/BomDaoV3';
import { forceString, sPlural } from '../../../../functions/StrFunctions';
import { confirmAlert } from 'react-confirm-alert';
import errorManagement from '../../../../services/errorManagement';
import ErrorMessage from '../../../../common/smallComponents.js/ErrorMessage';

const CreateArticle = ({ bom_content, bom_content_origin, bom_content_change, setBomContentChange,
    articles_list, setBomContent, setLoading, machine_info }) => {
    const [arr, setArr] = useState([]);
    const [progress, setProgress] = useState();
    const [progressMsg, setProgressMsg] = useState();
    const intro = "Il est normal de trouver des nomenclatures ici, les AV seront créés en même temps que les AR";
    const [checkedAll, setCheckedAll] = useState(false);
    const [error, setError] = useState();

    const families = [
        { value: "MA", label: "Actionneur mecanique" },
        { value: "PA", label: "Actionneur pneumatique" },
        { value: "PV", label: "Composant pour le vide" },
        { value: "MS", label: "Eléments standards" },
        { value: "ML", label: "Guidage lineaire" },
        { value: "MR", label: "Guidage rotatif (rlt palier)" },
        { value: "MM", label: "Matiere (plaque plat profile)" },
        { value: "OI", label: "Piece d'injection Mat.plastiq" },
        { value: "OF", label: "Piece de fraisage" },
        { value: "OS", label: "Piece de mecano-soudure" },
        { value: "OT", label: "Piece de tôlerie" },
        { value: "OO", label: "Piece de tournage" },
        { value: "OG", label: "Piece forgee" },
        { value: "PR", label: "Raccord" },
        { value: "OE", label: "Tournage Fraisage" },
        { value: "PF", label: "Traitement de l'air" },
        { value: "PT", label: "Tuyauterie" },
        { value: "MV", label: "Visserie / Stock géré à vue" }
    ];

    useEffect(() => {
        if (bom_content) {

            getArtToCreate();

            /*setArr(Object.entries(bom_content)
                .filter(([key, v]) => v.ar === null || v.ar.trim() === "")
                .map(([key, v]) => ({ ...v, key: key }))
            )*/
        }
    }, [bom_content])

    function getArtToCreate(){
        var t = [];
        var v;
        var mach_line = machine_info.no_ligne;
        var err = undefined;

        for (let i = 0; i < bom_content.length; i++) {
            v = bom_content[i];
            v.key = i;
            if (v.ar === null || v.ar.trim() === "") {
                t.push({ ...v });

                //Check if other line subset to create
                if (v.ref.startsWith("NM-") && v.se.endsWith("EG")) {
                    var se_line = v.ref.split("-")[2].substring(0, 1);
                  
                    if ( machine_info.type_machine !== "EC16" && !machine_info.typ_config.toLowerCase().startsWith( "2pac" )
                            && forceString( mach_line ) !== "0" 
                            && forceString( mach_line ) !== forceString( se_line ) ) {
                        err = "Veuillez créer les sous-ensemble de la machine de ligne " + se_line
                            + ", injectez la dans Focus, ensuite traitez cette machine-ci.<br>"
                            + "Si c'est déjà traité lancer la recherche d'AV";
                    }
                }
            }
        }

        setError( err );
        setArr(t);
    }

    /**
     * On change article
     * @param {*} e 
     * @param {*} k 
     */
    const onArtChange = (e, k) => {
        // Get the key of origin array
        k = parseInt(arr[k].key)
        artChange(bom_content, bom_content_origin, bom_content_change, setBomContentChange,
            articles_list, setBomContent, e, k);
    }

    /**
     * On chapter change
     * @param {*} e 
     * @param {*} k 
     */
    const onFamilyChange = (e, k) => {
        // Get the key of origin array
        k = parseInt(arr[k].key)
        familyChange(bom_content, bom_content_change, setBomContent, setBomContentChange,
            bom_content_origin, e, k)
    }

    /**
     * 
     * @param {*} e 
     * @param {*} k 
     * @returns 
     */
    const checkForceCreaction = (e, k) => {
        var origin_key = parseInt(arr[k].key);

        //Check if family is filled
        var family_prod = bom_content[origin_key].family_prod;

        if (!family_prod || family_prod.trim() === "") {
            setPopUp("Choisissez d'abord la famille de produit", "danger", 5000)
            return;
        }

        var temp_arr = [...arr];
        if (e.target.checked) { temp_arr[k].force_creation = 1 }
        else { delete temp_arr[k].force_creation }

        setArr(temp_arr);

    }

    /**
     * 
     */
    const checkAllForceCreaction = (e) => {
        if (e.target.checked) {
            // Check if all families are filled
            var err = arr.filter(v => !v.family_prod || v.family_prod === "")

            if (err.length > 0) {
                setPopUp("Choisissez d'abord la famille de produit", "danger", 5000)
                return;
            }

            setCheckedAll(true);

            var temp_arr = [...arr];
            for (let i = 0; i < temp_arr.length; i++)
                temp_arr[i].force_creation = 1;

            setArr(temp_arr);
        } else {
            setCheckedAll(false);

            var temp_arr = [...arr];
            for (let i = 0; i < temp_arr.length; i++)
                delete temp_arr[i].force_creation;

            setArr(temp_arr);
        }
    }

    /**
     * 
     */
    const searchArticles = async () => {
        const split = splitIntoChunks(arr, 20);
        var found = [];
        var temp_bom = [...bom_content];

        setProgress(0);
        var sl = split.length;
        for (let i = 0; i < sl; i++) {
            var res = await BomDaoV3.checkArticles(split[i])
            found = found.concat(res)
            setProgress((i / sl) * 100);
        }

        // Set the articles in the table
        try {
            for (let i = 0; i < found.length; i++) {
                var idx = bom_content.findIndex(v => parseInt(v.id) === parseInt(found[i].id));
                if (found[i].ar) temp_bom[idx].ar = found[i].ar.trim();
            }
        } catch (error) {
            window.location.reload();
        }


        setBomContent(temp_bom);
        setPopUp(found.length + " " + sPlural("Article trouvée", found))
        setProgress();
        getArtToCreate();
    }

    /**
     * 
     */
    const createArticles = () => {
        var spe = 0, nm = 0, oth = 0;
        var business = machine_info.affaire.substring(0, machine_info.affaire.length - 1);

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].ref.startsWith("NM-" + business) || (arr[i].ref.startsWith("NE-" + business) && arr[i].se.endsWith("EG"))) { nm++; }
            else if (arr[i].ref.startsWith("PL-" + business)) { spe++; }
            else { oth++ }
        }

        var txt = "";
        if (nm > 0) txt += nm + " AV, "
        if (spe > 0) txt += spe + " AR spé, "
        if (oth > 0) txt += oth + " autre(s) AR"

        confirmAlert({
            title: "Lancement de création",
            message: "Confirmez-vous la création de " + txt + " ?",
            buttons: [
                { label: "Oui", onClick: () => { launchCreation(); } },
                { label: "Non" }
            ]
        });
    }

    /**
     * 
     */
    const launchCreation = async () => {
        
        setProgress(0);
        var sl = arr.length;
        setLoading(true);
        var temp_bom = [...bom_content];

        var temp = [...arr]
        for (let i = 0; i < sl; i++) {
            try {
                var res = await BomDaoV3.createArticle(arr[i], arr[i].force_creation !== undefined, machine_info);
                setProgress(((i / sl) * 100).toFixed(0));
                setProgressMsg(res.msg);

                if (res.article_code === null || res.article_code === "") {
                    temp[i].alert = res.msg;
                } else {
                    temp[i].ar = res.article_code;
                }

            } catch (error) {
                setPopUp("Erreur lors de la création de l'article: " + errorManagement.getErrorMsg(error), "danger", 5000)
            }
        }

        // Write the article in compilation
        for (let i = 0; i < temp.length; i++)
            temp_bom[parseInt(temp[i].key)] = temp[i];

        setBomContent(temp_bom);
        setProgress();
        setTimeout(() => { setProgressMsg(); }, 10000);
        setLoading(false);
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('force_creation',
            {
                header: <div>
                    Forcer <CustomToolTipInfo props={{ tooltipText: "Il s'agit de forcer la création de l'article même si on ne trouve pas le générique. A n'utiliser que dans le cadre de la R&D." }} />
                    <input type='checkbox' className='form-check-input big'
                        onClick={(e) => checkAllForceCreaction(e)} checked={checkedAll} />
                </div>,
                cell: (i) => <div className="text-center">
                    <input type='checkbox' className='form-check-input big' checked={i.getValue() === 1}
                        onClick={(e) => checkForceCreaction(e, i.row.id)} />
                </div>,
                enableColumnFilter: false,
                enableSorting: false,
            }));

        arr.push(columnHelper.accessor('cat',
            {
                header: 'Cat.', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('se',
            { header: 'S/E.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('rep',
            { header: 'Rep.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('qty',
            {
                header: 'Qté. à commander', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('frenchLabel',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            {
                header: 'Ref.', cell: (i) => <div className="text-center">
                    {i.getValue()}
                    {(i.getValue().startsWith("NE-") && !i.getValue().endsWith("EG")) && <div className='fst-italic'>
                        A partir de {i.row.original.remarkAr}
                    </div>}
                </div>
            }));

        arr.push(columnHelper.accessor('maker',
            {
                header: 'Fabricant', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('family_prod',
            {
                header: 'Famille de produit', cell: (i) => <div className="text-center">
                    <Inputs.SelectGroup no_span={true} onChange={(e) => onFamilyChange(e, i.row.id)}
                        inputClass='w-100' value={i.getValue()} options={families} display_all={true} />
                </div>
            }));

        arr.push(columnHelper.accessor('ar',
            {
                header: 'Art.', cell: (i) => <div className="text-center">
                    <Inputs.Input defaultValue={i.getValue()} hideSpan inputClass='w-180'
                        onBlur={(e) => onArtChange(e, i.row.id)} />
                </div>
            }));

        arr.push(columnHelper.accessor('alert',
            {
                header: 'Alertes', cell: (i) => <div className={"text-center "
                    + (i.getValue() !== undefined ? "bg-danger text-white" : "")
                }>{i.getValue()}</div>
            }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    return (
        <div>
            <div className='alert alert-info fw-bold text-center'>
                {progressMsg || intro}
            </div>

            {error && <ErrorMessage error={error} />}

            {(arr && arr.length > 0 && !progress) && <div className='text-center mb-3 d-flex'>
                <div className={'ms-auto ' + (error ? " me-auto" : " me-3")}>
                    <button className='btn btn-secondary'
                        onClick={searchArticles}>Lancer la recherche d'articles</button>
                </div>
                {!error &&
                    <div className='me-auto'>
                        <button className='btn btn-success'
                            onClick={createArticles}>Lancer la création</button>
                    </div>}
            </div>}

            {progress !== undefined && <div className='mb-3'>
                <ProgressBar label={progress + ' %'} animated now={progress} />
            </div>}

            <ReactTable columns={columns} data={arr} origin="temp_bom_overview" classes={"no-padding fixFstCol "} top0={true} />


        </div>
    );
};

export default CreateArticle;