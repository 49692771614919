
import { Fragment, useEffect, useState } from "react";
import { isSideBarOpened, SideNavBarV3 } from "../../common/smallComponents.js/DesignedIpt";
import Spectifications from "./smallCom/lifesheetmachine/Spectifications";
import Steps from "./smallCom/lifesheetmachine/Steps";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { setPopUp } from "../../functions/PageAnimation";
import MachineInfo from "../../services/machines/MachineInfo";
import Home from "./smallCom/lifesheetmachine/Home";
import getUrlParam from "../../functions/StrFunctions";
import HomeV2 from "./smallCom/lifesheetmachine/HomeV2";
import Resources from "./smallCom/lifesheetmachine/Resources";
import Quality from "./smallCom/lifesheetmachine/Quality";

const Lifesheetmachine = (p) => {

    const [pin, setPin] = useState(isSideBarOpened());
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(false);
    const [machineInfo, setMachineInfo] = useState();
    const [machine, setMachine] = useState();

    const [business_arr, setBusinessArr] = useState();
    const [machine_arr, setMachineArr] = useState();
    const [planning_arr, setPlanningArr] = useState();

    const [this_business, setThisBusiness] = useState();
    const [this_planning, setThisPlanning] = useState();

    useEffect(() => {
        var machine = getUrlParam(p, "machine");
        
        if (machine !== null){
            setMachine(machine);
            getLifeLog(machine);
        } 
    }, []);

    const getLifeLog = (machine) => {
        setLoading(true);

        MachineInfo.getLifeLogMachine(machine).then(
            (res) => {
                console.log(res)
                setBusinessArr(res.business);
                setThisBusiness(res.business[0]);
                setMachineArr(res.distinct_machine);
                setPlanningArr(res.planning);
                getPlanning(res.planning, res.business[0].machines.no_machine);

                setLoading(false);
            },
            (error) => {
                setPopUp("Une erreur s'est produite lors de la recherche", "danger", 5000);
                setLoading(false);
            }
        );
    }

    function getPlanning(arr, machine) {
        var idx = arr.findIndex(v => v.machine.trim() === machine);
        if (idx === -1) { setThisPlanning(); }
        else { setThisPlanning(arr[idx].planning); }
    }

    const SearchBar = ({ leftPosition, inputWidth, inputClass }) => {
        return <div>
            <form>
                <div className="text-center  me-auto ms-auto">
                    <input type="text" placeholder="N° de machine" defaultValue={machine}
                        style={{ position: "relative", width: inputWidth }} name="machine"
                        className={inputClass + ' text-center fw-bold'} autoFocus></input>

                    <button style={{ position: "relative", left: leftPosition, bottom: "35px", border: "none", background: "white" }}>
                        <img width="25px" height="25px" src="\common\loupe.png"></img>
                    </button>
                </div>
            </form>
        </div>
    }

    const switchMachine = ( machine ) => {
        var idx = business_arr.findIndex(v => v.machines.no_machine && ( v.machines.no_machine.trim() === machine ) );
        setThisBusiness( business_arr[ idx ] );
        
        getPlanning( planning_arr, machine );
    }

    const NavList = ({p, title, img}) => {
        return <li className={"nav-item d-flex mb-2 mb-2 " + ( page === p ? "fw-bold ms-3" : "" )}>
                    <img width="25px" height="25px" src={img}></img>
                    <a className={'pt-0 pt-0 nav-link ' + (page === p ? "active" : "")}
                        aria-current="page" href="#" onClick={() => setPage(p)}>{title}</a>
                </li>
    }

    return <div className="bg-see navbarV3 right-side">
        <SideNavBarV3 setPin={setPin} content={<div className="ps-3 pe-1">

            <SearchBar inputWidth={"200px"} inputClass={"form-control"} leftPosition={"40px"} />
            {this_business && <Fragment>

                <ul className="nav flex-column">
                    <NavList p={0} img="\common\home.png" title="Accueil Machine"/>
                    <NavList p={1} img="\common\rouage.png" title="Spectifications"/>
                    <NavList p={2} img="\common\graph.png" title="Projet"/>
                    <NavList p={3} img="\common\note.png" title="Qualité"/>
                    <NavList p={4} img="\common\service.png" title="Service"/>
                    <NavList p={5} img="\common\documents.png" title="Documents"/>
                </ul>
            </Fragment>}
        </div>} />
        {
            <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

                {loading && <WaitingRoundSnippers />}
                {!this_business && <SearchBar inputClass={"form-control w-25 ms-auto me-auto"} leftPosition={"11%"} />}
                {this_business && <Fragment>
                    {machine_arr.length > 1 && <div className="d-flex mb-3">
                        <div className="mt-auto mb-auto fw-bold me-3">Cette machine a été traitée {machine_arr.length} fois en affaire</div>
                        <div>
                            { machine_arr.map( v => {
                                return <Fragment>
                                    <input type="radio" className="btn-check" checked={ this_business.machines.no_machine === v }/>
                                    <label className="btn" onClick={() => switchMachine( v )}>{v}</label>
                                </Fragment>
                            } )}                            
                        </div>
                    </div>}
                    {page === 0 && <HomeV2 business={this_business} planning={this_planning} />}
                    {page === 3 && <Quality business={this_business} />}

                    {page === -1 && <Spectifications data={machineInfo} />}

                    { page === 5 && <Resources this_business={this_business}/> }
                </Fragment>}
            </div>
        }
    </div>
}

export default Lifesheetmachine