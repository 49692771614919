import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { setMessage } from "../../actions/message";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import { arrayRemoveElt } from "../../functions/ArrayFunctions";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import getUrlParam, { getFrFormat, hasRole } from "../../functions/StrFunctions";
import Miscellaneous from "../../services/common/Miscellaneous";
import CheckList from "../../services/customClient/CheckList";
import errorManagement from "../../services/errorManagement";
import { downloadTempFile } from "../../services/functions/FilesProcess";
import CheckListForm from "./smallContent/CheckListForm";
import _ from "lodash";

const FAT = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [machineInfo, setMachineInfo] = useState();
    const [list, setList] = useState([]);
    const [form, setForm] = useState();
    const [pin, setPin] = useState(isSideBarOpened());
    const [addDisplay, setAddDisplay] = useState();

    const [machine, setMachine] = useState("");
    const [id, setId] = useState();

    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const lang = "fr-FR";

    const [ncSaved, setNcSaved] = useState();
    const [flagSave, setFlagSave] = useState();

    const readOnly = !hasRole(["ROLE_CUSTOMER_CLIENT", "ROLE_ADMIN"]);

    Moment.locale('fr');

    useEffect(() => {
        var id = getUrlParam(props, "id");
        var machine = getUrlParam(props, "machine");

        setLoading(true);
        if (!id) {
            CheckList.getFatList().then(
                (response) => {
                    //console.log(response)
                    setList(response);
                    setLoading(false);
                }, (error) => {
                    setError(errorManagement.handleError(props, error));
                    setLoading(false);
                    scrollToTop();
                }
            );
        } else {
            setId(id);
            CheckList.singleCheckListMachine(machine, "fat", lang, false, id).then(
                (response) => {
                    setMachineInfo(response.machineInfo);
                    setForm(response.form);
                    createAdditionnalDisplay(response.machineInfo)

                    //Save the NC already notified to avoid harassement
                    setNcSaved(response.form.form.filter(v => v.conform === 0 && (v.who !== null && v.who !== "")));


                    setLoading(false);
                }, (error) => {
                    setLoading(false);
                    setError(errorManagement.handleError(props, error));
                    scrollToTop();
                }
            );
        }


    }, []);


    // On save check what are the new NC and notify
    useEffect(() => {
        if (!flagSave) return;

        // Get all non conformities of the submitted form
        var arr = form.form.filter(v => v.conform === 0 && (v.who !== null && v.who !== ""));

        // Get what are the news NC mentioned
        var diff = arr.filter(a => !ncSaved.some(b => a.question === b.question && a.who === b.who));

        //Send emails
        notifyNc(diff);

        // save the new NC saved
        setNcSaved(arr);

        // Set flag as false to monitor again
        setFlagSave(false);
    }, [flagSave])

    // Notify NC according department
    const notifyNc = async (arr) => {
        var toNotify = [];

        //BE Autom
        toNotify = arr.filter(a => a.who === "BE Autom");
        if (toNotify.length > 0) await send(toNotify, ["joris.pasquali@sealedair.com"])

        //Montage, intégration, cartérisation
        toNotify = arr.filter(a => a.who === "Montage");
        if (toNotify.length > 0) await send(toNotify, ["cedric.lacolley@sealedair.com"])

        //BE Elec
        toNotify = arr.filter(a => a.who === "BE Elec");
        if (toNotify.length > 0) await send(toNotify, ["robin.large@sealedair.com"])

        //BE méca
        toNotify = arr.filter(a => a.who === "BE méca");
        if (toNotify.length > 0) await send(toNotify, ["frederic.duffes@sealedair.com"])

        //MeS
        toNotify = arr.filter(a => a.who === "MeS");
        if (toNotify.length > 0) await send(toNotify, ["issam.mettiji@sealedair.com"])
    }

    // Common function for sending
    const send = async (arr, recipients) => {

        recipients.push("wael.souabni@sealedair.com");

        var subject = "Non conformité en FAT interne";
        var body = "Bonjour,<br><br>"
        body += "Une non-conformité est survenue pendant la FAT interne de la machine "
            + arr[0].mach_nb + ". Voici le ou les points qui font défaut :<br><br>";
        body += arr.map((item, key) => `Point en NC: ${item.question_label}<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Cause: ${item.what || 'non renseignée'}<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Action à mener: ${item.action || 'non renseignée'}<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;- Date de résolution attendue: ${item.when || 'non renseignée'}
                    <br><br>
            `).join('');

        await Miscellaneous.sendAnyEmail(recipients, subject, body)

    }

    const deleteForm = (k) => {
        var chk_gp_id = list[k].chk_gp_id;
        CheckList.deleteFormById(chk_gp_id);

        var arr = [...list];
        arr = arrayRemoveElt(arr, k);
        setList(arr);
    }


    function createAdditionnalDisplay(machineInfo) {
        var o = new Object();

        o[1] = <div className="fst-italic fw-normal">Type de configuration de cette machine: {machineInfo.typ_config}</div>
        o[3] = <div className="fst-italic fw-normal">Couleur du châssis: {machineInfo.coul_chassis}</div>
        o[18] = <div className="fst-italic fw-normal">Attendu: {machineInfo.type_api}</div>

        setAddDisplay(o);
    }

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('affaire', {
            header: 'Affaire',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('mach_nb', {
            header: 'Machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('typ_config', {
            header: 'Type de machine',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));
        arr.push(columnHelper.accessor('creation_date', {
            header: 'Date de création',
            cell: i => <span>{Moment(i.getValue()).format("DD/MM/YYYY")}</span>
        }));
        arr.push(columnHelper.accessor('controller', { header: 'Contrôleur' }));
        arr.push(columnHelper.accessor('status_mes', {
            header: 'Validation MeS',
            cell: i => <div className={"h-100 text-center " + (i.getValue() === "En attente" ? "monday-blue" : "monday-green")}>
                {i.getValue() === "En attente" ? i.getValue() : getFrFormat(i.getValue(), true)}</div>
        }));
        arr.push(columnHelper.accessor('status_qhse', {
            header: 'Validation QHSE',
            cell: i => <div className={"h-100 text-center " + (i.getValue() === "En attente" ? "monday-blue" : "monday-green")}>
                {i.getValue() === "En attente" ? i.getValue() : getFrFormat(i.getValue(), true)}</div>
        }));

        arr.push(columnHelper.accessor('status_cdp', {
            header: 'Validation CDP',
            cell: i => <div className={"h-100 text-center " + (i.getValue() === "En attente" ? "monday-blue" : "monday-green")}>
                {i.getValue() === "En attente" ? i.getValue() : getFrFormat(i.getValue(), true)}</div>
        }));

        arr.push(columnHelper.accessor('x', {
            header: '',
            cell: i => <div className="text-center">
                <a className="btn btn-outline-success"
                    href={"/customer-client/fat?id=" + i.row.original.chk_gp_id
                        + "&machine=" + i.row.original.mach_nb}>Continuer</a>
            </div>
        }));

        if (user && user.roles.includes("ROLE_ADMIN")) {
            arr.push(columnHelper.accessor('xx', {
                header: '',
                cell: i => <div className="text-center">
                    <button className="btn btn-outline-danger"
                        onClick={() => deleteForm(i.row.id)}>Supprimer</button>
                </div>
            }));
        }

        return arr;
    }

    //Set columns
    const columns = getColumns();

    const createCheckList = (e) => {
        e.preventDefault();

        setError();
        if (machine.length === 0) {
            setError("Saisissez au moins une machine");
            scrollToTop();
            return;
        }

        if (machine.indexOf("/") === -1) {
            setError("Saisissez le numéro de machine en incluant l'année de fabrication, ex: 21/1850");
            scrollToTop();
            return;
        }

        //Check if machine exists to avoid double
        for (let i = 0; i < list.length; i++) {
            if (list[i].mach_nb.trim() === machine) {
                window.location.href = "/customer-client/fat?id="
                    + list[i].chk_gp_id + "&machine=" + list[i].mach_nb.trim()
                return;
            }
        }

        var notification = {
            recipients: ["wael.souabni@sealedair.com", "jeremy.pascale@sealedair.com"],
            subject: "Nouvelle FAT créée",
            body: "Bonjour,<br><br>" + user.first_name + " " + user.last_name + " a commencé la FAT interne "
                + " de la machine " + machine + " aujourd'hui"
        }

        setLoading(true);
        CheckList.singleCheckListMachine(machine, "fat", lang, true).then(
            (r) => {
                Miscellaneous.notifyChangeByEmail(notification);

                window.location.href = "/customer-client/fat?id="
                    + r.id + "&machine=" + r.machine
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    const generateReport = () => {
        setLoading(true);
        CheckList.generateFatReport(machineInfo, form).then(
            (r) => {
                downloadTempFile(r);
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    const validateFAT = (type) => {
        setLoading(true);
        CheckList.validateFAT(machineInfo, form, id, type).then(
            (r) => {
                setMessage("Validé");
                scrollToTop();
                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        );
    }

    return <div className="bg-see navbarV3 right-side">

        <SideNavBarV3 setPin={setPin} content={
            (id && user) && <div className="ps-2 pe-2">
                <a href="/customer-client/fat" className="mb-3">Liste des FAT</a>
                <br></br><br></br>
                <a href="#" className="mb-3" onClick={generateReport}>Générer le compte rendu</a>
                <br></br><br></br>
                {(user.roles.includes("ROLE_FAT_VALIDATION") || user.roles.includes("ROLE_ADMIN")
                    || user.roles.includes("ROLE_QHSE")) &&
                    <div>
                        {!user.signature && <div className="alert alert-warning">
                            Veuillez ajouter votre signature <a className="fw-bold" href="/profile">ici</a> pour valider
                        </div>}

                        {user.signature &&
                            <div className="mb-3 text-center">
                                <h6>Ma signature</h6>
                                <img src={user.signature} style={{ maxWidth: "150px" }} />
                            </div>}

                        {(user.signature && (user.roles.includes("ROLE_FAT_VALIDATION") || user.roles.includes("ROLE_ADMIN"))) && <div className="text-center mt-3">
                            <h6>Service client</h6>
                            <button className="btn btn-success" onClick={() => validateFAT("cs")} disabled={loading}>
                                {loading && <ButtonWaiting />}
                                Valider la FAT</button>
                        </div>}

                        {(user.signature && (user.roles.includes("ROLE_QHSE") || user.roles.includes("ROLE_ADMIN"))) && <div className="text-center mt-3">
                            <h6>Service QHSE</h6>
                            <button className="btn btn-success" onClick={() => validateFAT("qhse")} disabled={loading}>
                                {loading && <ButtonWaiting />}Valider la FAT</button>
                        </div>}

                        {(user.signature && (user.roles.includes("ROLE_WRITE_FM") || user.roles.includes("ROLE_ADMIN"))) && <div className="text-center mt-3">
                            <h6>Direction technique</h6>
                            <button className="btn btn-success" onClick={() => validateFAT("cdp")} disabled={loading}>
                                {loading && <ButtonWaiting />}Valider la FAT</button>
                        </div>}
                    </div>
                }
            </div>
        } />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h5 className="text-center">
                FAT {machineInfo && <Fragment>
                    {machineInfo.affaire} - Mach:
                    <a href={"/machine?machine=" + machineInfo.no_machine} target="_blank">
                        {machineInfo.no_machine}</a> ({machineInfo.typ_config})</Fragment>}
            </h5>

            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {(!id && !readOnly) && <form onSubmit={createCheckList} className="col-6 offset-3">
                <div className="input-group mb-3">
                    <span className="input-group-text">Numéro de machine</span>
                    <input type="text" className="form-control fw-bold text-center" autoFocus
                        onChange={(e) => setMachine(e.target.value)} required />
                    <button className="btn btn-outline-success" disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Commencer</button>
                </div>
            </form>}

            {form &&
                <CheckListForm form={form} setForm={setForm} machineInfo={machineInfo} typeForm={"fat"}
                    setError={setError} addDisplay={addDisplay} readOnly={readOnly} setFlagSave={setFlagSave} />}

            {!id && <div className="col-10 offset-1">
                <ReactTable columns={columns} data={list} top0={true} origin="fat" />
            </div>}
        </div>
    </div>

}

export default FAT;