import { createColumnHelper } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import ReactTable from '../../../../common/smallComponents.js/ReactTable';
import { nbFormat, sPlural } from '../../../../functions/StrFunctions';

const Spares = ({ bom_content, type_kit, bom_obj }) => {
    var [arr, setArr] = useState([]);
    const [tot_price, setTotalPrice] = useState(0);
    const [tot_sell_price, setTotalSellPrice] = useState(0);
    const SPARE_MECA = "SPM";
    const SPARE_ELEC = "SPE";

    useEffect(() => {
        if (bom_content && bom_content.length > 0) {
            var f = type_kit.indexOf("standard") !== -1 ? "stdKit" : "completeKit";

            var price = 0;
            var sell_price = 0;
            var arr = [];
            var v;
            for (let i = 0; i < bom_content.length; i++) {
                v = bom_content[i];
                if (v[f] > 0 && ( v.se.indexOf(SPARE_MECA) !== -1 || v.se.indexOf(SPARE_ELEC) !== -1 ) ) {
                    price += v.cost_price;
                    sell_price += v.sell_price;
                    arr.push(v);
                }
            }

            setArr(arr);
            setTotalPrice(price);
            setTotalSellPrice( sell_price );

        }
    }, [bom_content]);

    /**
            * TABLE
            */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat',
            { header: 'Cat.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('se',
            { header: 'S/E.', cell: (i) => <div className="text-center">
                {i.getValue()}
                <div style={{fontSize: "12px"}}>{ i.row.original.message }</div>
            </div> }));

        arr.push(columnHelper.accessor('rep',
            { header: 'Rep.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('qty',
            {
                header: 'Qté. à commander', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('frenchLabel',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            {
                header: 'Ref.', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('maker',
            {
                header: 'Fabricant', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

        arr.push(columnHelper.accessor('ar',
            {
                header: 'Art.', cell: (i) => <div className="text-center">{i.getValue()}</div>
            }));

            arr.push(columnHelper.accessor('cost_price',
                {
                    header: 'Prix', cell: (i) => <div className="text-center">
                        <div style={{fontSize: "12px"}}>Rev.: {i.getValue()}</div>    
                        <div style={{fontSize: "12px"}}>Vente: {i.row.original.sell_price === 0 ? "Non défini" : i.row.original.sell_price}</div>   
                    </div>
                }));

        if (type_kit.indexOf("standard") !== -1) {
            arr.push(columnHelper.accessor('stdKit',
                { header: 'Qté du kit', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        } else if (type_kit.indexOf("complet") !== -1) {
            arr.push(columnHelper.accessor('completeKit',
                { header: 'Qté du kit', cell: (i) => <div className="text-center">{i.getValue()}</div> }));
        }

        arr.push(columnHelper.accessor('fixedSpares',
            {
                header: 'A ne pas multiplier', cell: (i) => <div className={"text-center "
                    + ( bom_obj.spares_doublons.findIndex( v => v.ar === i.row.original.ar ) !== -1 ? "bg-danger text-white" : "" )
                }>
                    {i.getValue() ? "X" : ""}
                </div>
            }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    return <div>
        {(bom_obj && bom_obj.spares_doublons.length > 0) && <div className='alert alert-danger text-center fw-bold'>
            <div>{(bom_obj.spares_doublons.length / 2).toFixed(0) + ' ' 
                    + sPlural( "doublon trouvé", (bom_obj.spares_doublons.length / 2).toFixed(0) ) }</div>
            <div>Vérifiez les éléments de la colonne "A ne pas multiplier" en rouge</div>
        </div>}

        {bom_content &&
            <div>
                <div className='d-flex w-100'>
                    <div className='fw-bold w-100'>Prix de revient du kit: { nbFormat( tot_price, "fr-FR", 2 )} €</div>
                    <div className='fw-bold w-100'>Prix de vente du kit: { nbFormat( tot_sell_price, "fr-FR", 2 )} €</div>
                    <div className='fw-bold w-100'>{ type_kit }</div>
                </div>

                <ReactTable columns={columns} data={arr} origin="spares_bom_overview"
                    classes={"no-padding fixFstCol "} top0={true} />
            </div>}
    </div>
};

export default Spares;