import { Fragment, useEffect, useState } from "react";
import { SmallFrame } from "../../../common/smallComponents.js/CustomDivs"
import transport from "../../../services/supplyChain/transport";
import WaitingRoundSnippers from "../../../common/smallComponents.js/WaitingRoundSnippers";
import { createColumnHelper } from "@tanstack/react-table";
import Inputs from "../../../common/smallComponents.js/Inputs";
import ArticleInfo from "../../../services/articles/ArticleInfo";
import { setPopUp } from "../../../functions/PageAnimation";
import ReactTable from "../../../common/smallComponents.js/ReactTable";
import { copyToClipboard, forceString, sPlural } from "../../../functions/StrFunctions";
import { removeAndReturnArray } from "../../../functions/ArrayFunctions";

const PickingPanel = ({ setOpenPickingPanel, business, setParentPicking, parentPicking }) => {
    const [picking, setPicking] = useState([]);
    const [potentialPicking, setPotentialPicking] = useState();
    const [tab, setTab] = useState(0);
    const [searching, setSearching] = useState(false);
    const [art, setArt] = useState([]);
    const [freeIdx, setFreeIdx] = useState(0);
    const [displayInfo, setDisplayInfo] = useState(true);

    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('source',
            {
                header: '#',
                cell: (i) => <div className="text-center">{i.getValue().trim()}</div>
            }));

        arr.push(columnHelper.accessor('affaire',
            { header: 'Affaire', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ar',
            { header: 'Article', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('remaining', {
            header: 'Qté à livrer', cell: (i) => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('label',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            { header: 'Ref.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('fonction',
            { header: 'Machine', cell: (i) => <div className="text-center">{i.getValue()}</div> }));


        arr.push(columnHelper.accessor('x', {
            header: '', cell: (i) => <div className="text-center">
                {!added(i.row.original.id) &&
                    <button className="btn btn-success"
                        onClick={() => addArticleFromPicking(i.row.id)}>Choisir</button>
                }
            </div>
        }));
        return arr;
    }

    function added(id) {
        return picking.findIndex(a => forceString(a.id).endsWith(forceString(id))) !== -1;
    }

    const getColumns2 = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('CDARL',
            {
                header: 'Article',
                cell: (i) => <div className="text-center">{i.getValue().trim()}</div>
            }));

        arr.push(columnHelper.accessor('ZN02PA',
            { header: 'Ref.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ZN03PA',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('BLOCL',
            { header: 'Bloqué', cell: (i) => <div className="text-center">{i.getValue() === "N" ? "Non" : "Oui"}</div> }));

        arr.push(columnHelper.accessor('x', {
            header: '', cell: (i) => <div className="text-center">
                <button className="btn btn-success"
                    onClick={() => onChooseArt(i.row.id)}>Choisir</button>
            </div>
        }));

        return arr;
    }

    const columns = getColumns();
    const columns2 = getColumns2();

    const onChooseArt = (k) => {
        var v = art[k];

        var p = [...picking];
        p.push({
            source: "free",
            machine: -1,
            id: getFormedId("free", freeIdx),
            "ar": v.CDARL,
            "qty": 1,
            "ref": v.ZN02PA,
            "en_label": v.ZN14PA,
            "label": v.ZN03PA,
            "dim1": v.DIM1L,
            "dim2": v.DIM2L,
            "dim3": v.DIM3L,
            "weight": v.POIDL,
            "hst_code": v.DOUA,
            "hs_code": v.ZN23PA,
            level: 1,
            type: "Article"
        })

        setPopUp("Ajouté");
        setPicking(p);
        setFreeIdx(freeIdx + 1);
    }

    const addArticleFromPicking = (k) => {
        var p = [...picking];

        var o = {
            ...potentialPicking[k],
            //Override qty: if the is remaining set it
            qty: potentialPicking[k].remaining || potentialPicking[k].qty,
            machine: -1,
            id: getFormedId(potentialPicking[k].source, potentialPicking[k].id),
            ar: potentialPicking[k].ar,
            av: null,
            display: 1,
            level: 1,
            type: "Article"
        }

        p.push(o);
        setPicking(p);
    }

    useEffect(() => {
        setPicking([...parentPicking]);

        transport.getArticlesByBusinesss(business).then(
            (response) => {
                console.log(response)
                var arr = [];

                // Compile all
                if (response.counter.length > 0) arr = arr.concat(response.counter);
                if (response.kit.length > 0) arr = arr.concat(response.kit);
                if (response.mes.length > 0) arr = arr.concat(response.mes);
                if (response.sav.length > 0) arr = arr.concat(response.sav);

                // Filter to display only not processed
                arr = arr.filter(a => !a.picking_id || a.picking_id === null || a.picking_id === "");

                if (arr.length === 0) setTab(1);
                setPotentialPicking(arr);
            }, (error) => {
                setPotentialPicking([]);
                setTab(1);
            }
        )
    }, [])

    const searchArticle = (e) => {
        e.preventDefault();

        setSearching(true);
        ArticleInfo.searchArticlePlus(e.target.term.value, true).then(
            (res) => {
                setArt(res);
                setSearching(false);
            }, (error) => {
                setSearching(false)
                setPopUp("Article non trouvé", "danger")
            }
        )
    }

    function getFormedId(source, id) { return forceString(source).trim() + "_" + id }

    const removePickElt = (k) => {
        var arr = removeAndReturnArray(picking, k);
        if (arr.length === 0) setTab(1);
        setPicking(arr);
    }

    const updateLambdaArt = (e, k, field) => {
        var arr = [...picking];
        arr[k][field] = e.target.value;
        if( field === "label" ) arr[k].ref = e.target.value;
        setPicking(arr);
    }

    const save = () => {
        var to_do = picking.filter(a => a.label === "A RENSEIGNER");

        if (to_do.length > 0) {
            setPopUp("Veuillez saisir " + sPlural("le libellé de", to_do) + " "
                + to_do.length + " AR_LAMBD_00001", "danger", 3000)
        } else {
            setParentPicking(picking)
        }
    }

    return <SmallFrame close={() => setOpenPickingPanel(false)} component={
        <div>
            <h5 className="text-center">S'agissant d'un envoi autre que les machines vous devez saisir
                ce que vous envoyez. Faites votre recherche ci-dessous et envoyez</h5>

            {!potentialPicking && <WaitingRoundSnippers />}

            {potentialPicking && <Fragment>
                {(potentialPicking.length > 0 || picking.length > 0) && <ul className="nav nav-tabs">

                    {potentialPicking.length > 0 &&
                        <li className="nav-item">
                            <a className={'nav-link ' + (tab === 0 ? "active" : "")}
                                aria-current="page" href="#" onClick={() => setTab(0)}>Pickings trouvés</a>
                        </li>}

                    <li className="nav-item">
                        <a className={'nav-link ' + (tab === 1 ? "active" : "")}
                            href="#" onClick={() => setTab(1)}>Saisie libre</a>
                    </li>

                    {picking.length > 0 &&
                        <li className="nav-item">
                            <a className={'nav-link ' + (tab === 2 ? "active" : "")}
                                aria-current="page" href="#" onClick={() => setTab(2)}>
                                Récap de {sPlural("sélection", picking)}</a>
                        </li>}
                </ul>}

                {tab === 0 &&
                    <ReactTable columns={columns} data={potentialPicking} origin="picking_exp_req"
                        classes={"no-padding fixFstCol "} top0={true} />}

                {tab === 1 && <div>
                    <form onSubmit={searchArticle} style={{ width: "400px" }} className="ms-auto me-auto mb-3">
                        <Inputs.Input name={"term"} placeholder="Code article, ref. ou désignation"
                            hideSpan required inputClass="text-center fw-bold" />
                    </form>
                    {displayInfo && <div className="alert alert-info">
                        <p>
                            Veuillez chercher l'article par son code ou ref ou désignation.<br></br>
                            Si vous avez un doute sur lequel sélectionner, choisissez celui qui vous paraît le plus
                            proche de votre besoin en évitant si possible ceux qui sont bloqués.<br></br>
                            Si l'article n'existe pas choisissez l'AR_LAMBD_00001
                            <img src="/common/copy.png" className="see-img w-30 ms-1 cursor-pointer"
                                title="Copier le code article"
                                onClick={() => { copyToClipboard("AR_LAMBD_00001"); setPopUp("Copié dans le presse papier") }} />
                        </p>

                        <div className="text-center">
                            <a href="#" onClick={() => setDisplayInfo(false)}>Masquer la consigne</a>
                        </div>
                    </div>}

                    {searching && <WaitingRoundSnippers />}

                    {art && art.length > 0 &&
                        <ReactTable columns={columns2} data={art} origin="picking_exp_req"
                            classes={"no-padding fixFstCol "} top0={true} />}
                </div>}

                {tab === 2 && <div>
                    <h6 className="text-center">Vous désirez expédier {picking.length} {sPlural("article", picking)}</h6>

                    <ul className="list-group ms-auto me-auto" style={{ width: "500px" }}>
                        {picking.map((v, k) => {
                            return <li className="list-group-item d-flex" key={"recap" + k}>
                                {v.ar.trim() === "AR_LAMBD_00001" ? <div>
                                    <div className="input-group mb-3">
                                        {<input type="number" className="form-control" onBlur={(e) => updateLambdaArt(e, k, "qty")}
                                            defaultValue={v.qty} readOnly={!v.id.startsWith("free_")} />}
                                        <span className="input-group-text">x</span>
                                        <input type="text" className="form-control" value={v.ar} readOnly />
                                        <input type="text" className="form-control" placeholder="Libellé de cet article"
                                            defaultValue={v.label} onBlur={(e) => updateLambdaArt(e, k, "label")} />
                                    </div>
                                </div> :
                                    <div className="input-group mb-3">
                                        {<input type="number" className="form-control"
                                            defaultValue={v.qty} readOnly={!v.id.startsWith("free_")} />}
                                        <span className="input-group-text">x</span>
                                        <input type="text" className="form-control" value={v.ar} readOnly />
                                        <input type="text" className="form-control" value={v.label} readOnly />
                                    </div>}

                                <div className="ms-auto">
                                    <img src="/common/remove.png" className="see-img w-30"
                                        onClick={() => removePickElt(k)} />
                                </div>
                            </li>
                        })}
                    </ul>

                    <Inputs.SubmitBtn label="Sauvegarder" onClick={save}
                        divClass="mt-3 text-center" type_btn="success" />
                </div>}


            </Fragment>}

        </div>
    } />

}

export default PickingPanel;