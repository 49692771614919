import React, { Fragment, useEffect, useState } from 'react';
import transport from '../../../services/supplyChain/transport';
import errorManagement from '../../../services/errorManagement';
import getUrlParam, { forceString, getFrFormat } from '../../../functions/StrFunctions';
import { ButtonWaiting, scrollToTop, setPopUp, sleep } from '../../../functions/PageAnimation';
import WaitingRoundSnippers from '../../../common/smallComponents.js/WaitingRoundSnippers';
import Inputs from '../../../common/smallComponents.js/Inputs';
import ScanReader from '../../storehouse/components/ScanReader';
import _, { pick } from 'lodash';
import { Divider } from '../../../common/smallComponents.js/CustomDivs';
import { confirmAlert } from 'react-confirm-alert';
import Movement from '../../../services/storehouse/Movement';
import ErrorMessage from '../../../common/smallComponents.js/ErrorMessage';

const PackingMobile = (p) => {
    const [step, setStep] = useState("pick_or_pack");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const [picking, setPicking] = useState();
    const [pickingId, setPickingId] = useState();
    const [toPack, setToPack] = useState([]);
    const [currentPacking, setCurrentPacking] = useState();
    const [scanned, setScanned] = useState("");

    const newPackEmpty = {
        type: "", truck_load_side: "", truck_load: "",
        truck_unload: "", truck_type: "", truck_height: "", nb_strap: "", truck_weight: "",
        truck_meter: "", pack_width: "", pack_height: "", truck_height: "", pack_weight: "",
        pack_pick_date: "", pack_delivery: "", shipment_id: ""
    };


    const [newPack, setNewPack] = useState(_.cloneDeep(newPackEmpty));

    useEffect(() => {
        var shipment_id = getUrlParam(p.props, "shipment_id");
        var id = getUrlParam(p.props, "id");
        var picking_id = getUrlParam(p.props, "picking_id");

        transport.getOtherPicking(shipment_id, id, picking_id).then(
            (r) => {

                if (r.picking_head && r.picking_head.current_picking)
                    r.currentPicking = JSON.parse(r.picking_head.current_picking)

                //console.log(r)
                setPicking(r);
                setPickingId(r.picking_id);
                setToPack(r.currentPicking.filter(a => forceString(a.machine) === "-1"))

                setLoading(false);
            }, (error) => {
                setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        if (picking) setToPack(picking.currentPicking.filter(a => forceString(a.machine) === "-1"))
    }, [picking])

    useEffect(() => {
        if (step === "add_picking" || step === "add_to_packing")
            setTimeout(() => {
                document.getElementById("scanIpt").focus()
            }, 500);
    }, [step])

    /**
     * Add shipment pack
     * @param {*} e 
     */
    const addShipping = (e) => {
        e.preventDefault();

        setLoading(true);

        var o = { ...newPack };
        o.header_id = picking.shipment.id;
        o.type = e.target.type.value;
        o.pack_width = 1;
        o.pack_height = 1;
        o.pack_weight = 1;
        o.truck_height = 1;
        o.pack_delivery = picking.shipment.shipmentOrigin.date_del;
        o.pack_pick_date = picking.shipment.shipmentOrigin.date_load;

        transport.saveShipmentDetail(o).then(
            (response) => {
                var p = { ...picking };
                var shipment = { ...p.shipment }
                var detail = shipment.detail;


                if (o.detail_id) {
                    detail[detail.findIndex(a => a.detail_id === o.detail_id)] = o;
                    setNewPack(_.cloneDeep(newPackEmpty));
                    scrollToTop();
                } else {
                    detail.push(response);
                }

                p.shipment = shipment;
                setPicking(p);
                setLoading(false);
                setPopUp(o.detail_id ? "Modifié" : "Ajouté")
            }, (error) => {
                setLoading(false);
                setPopUp("Une erreur s'est produite", "danger")
            }
        )
    }

    function hasBeenShipped(id) {
        //Do not insert in this palet if an anothor picking has used it
        var idx = picking.all_picking.findIndex(a =>
            picking.picking_id !== a.picking_id && forceString(a.machine) === forceString(id));

        if (idx !== -1) return "Utilisé dans le picking n°"
            + picking.all_picking[idx].picking_id + ", impossible d'ajouter des éléments ici"

        // Do not insert if this palet has been shipped in this picking
        idx = picking.shipment.detail.findIndex(a => id === a.detail_id && a.truck_load_real !== null);

        if (idx !== -1) return "Déjà expédié le "
            + getFrFormat(picking.shipment.detail[idx].truck_load_real, true) + ", impossible d'ajouter des éléments ici"

        return null;
    }

    const goBack = () => {
        const prev = {
            "add_picking": "pick_or_pack",
            "move": "pick_or_pack",
            "add_packing": "pick_or_pack",
            "add_to_packing": "add_packing"
        }

        if (step === "add_packing") { setCurrentPacking(); }
        else if ("add_to_packing") { setCurrentPacking(); }

        setStep(prev[step]);
    }

    useEffect(() => {
        if (scanned !== "") {
            if (step === "add_picking") { addArticleToPicking(scanned); }
            else { addArticleToPacking(scanned); }
        }
    }, [scanned]);

    // Save the picking
    const saveOtherPicking = (send = false) => {
        if (picking.currentPicking.length === 0) {
            setPopUp("Il n'y a aucun article à enregistrer !", "danger", 3000)
            return;
        }

        setLoading(true);

        transport.saveOtherPicking(picking.shipment.id, picking.picking_head, send, picking.currentPicking).then(
            (r) => {
                var o = { ...picking };
                o.picking_id = r;
                o.picking_head.picking_id = r;
                setPicking(o);

                setPickingId(r);

                setPopUp("Sauvegardé")
                setLoading(false);

                askMove();
            }, (error) => {
                console.log(error)
                setLoading(false);
                //setError(errorManagement.handleError(p.props, error));
                //scrollToTop();
            })
    }

    /**
     * ASK USER IF HE WANTS TO DO MOVEMENTS
     */
    function askMove() {
        confirmAlert({
            title: "Mouvements de stock",
            message: "Voulez-vous effectuer les mouvements de stock pour ces articles ?",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setStep("move");
                        startMove();
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    /**
     * IF HE AGREES, START MOVEMENTS
     */
    const startMove = async () => {
        var p = _.cloneDeep(picking);

        for (let i = 0; i < picking.currentPicking.length; i++) {
            p = _.cloneDeep(p);

            if (!picking.currentPicking[i].scanned) continue;

            var business = picking.currentPicking[i].affaire;
            var article = picking.currentPicking[i].ar;
            var qty = picking.currentPicking[i].remaining;
            var machine = picking.currentPicking[i].fonction;
            var need = picking.currentPicking[i].need;

            try {
                var res = await Movement.moveArticle(article, business, qty, machine, need);
                console.log(res)
                p.currentPicking[i].move_err = res
            } catch (e) {
                console.log(e)
                var msg = e.response.data;
                if (msg.includes(":")) {
                    msg = msg.substring(msg.indexOf(":") + 1, msg.length);
                    //Ignore the AR_ITOH__00006: Erreur: part in this message
                    if (msg.includes(":"))
                        msg = msg.substring(msg.indexOf(":") + 1, msg.length);
                }


                p.currentPicking[i].move_err = "ERREUR: " + msg.replace("<br>", "");
            }

            setPicking(p)

        }
    }

    function addArticleToPicking(scanned) {
        // Check if it's only article or business
        if (scanned.indexOf("-") !== -1) {
            //Check if the business is included in this 
            var split = scanned.split("-");
            if (picking.exp.business.indexOf(split[0]) === -1) {
                setPopUp("Cette étiquette ne concerne pas cette affaire !", "danger");
            } else {
                // If it's the right business check if the need has been shipped or not
                var need_nb = split[5];
                if (!need_nb) {
                    setPopUp("le numéro de besoin n'apparaît pas sur cette étiquette !", "danger", 3000);
                } else {
                    var idx = picking.currentPicking.findIndex(a => forceString(a.id).endsWith(forceString(need_nb)))
                    // Check if this article has already been inserted in this picking
                    if (idx !== -1) {
                        setPopUp("Cet article est déjà dans ce picking !", "danger", 3000);
                    } else {
                        // If it's a new article, check if the qty remaining is enough
                        var row = getArticleByNeedNb(need_nb);
                        if (row === null) {
                            // If need number unknown can't proceed
                            setPopUp("Nous ne trouvons pas ce besoin en picking", "danger", 3000);
                        } else {
                            // If remaining qty === 0 can't proceed
                            if (!row.remaining || row.remaining === 0) {
                                setPopUp("Cet article semble avoir été expédié à 100%", "danger", 3000);
                            } else {
                                //Add finally !!
                                console.log(row)
                                row.qty = row.remaining || row.qty;
                                row.machine = -1;
                                row.need = row.id;
                                row.id = getFormedId(row.source, row.id);
                                row.art_id = getFormedId(row.source, row.id);
                                row.ar = row.ar;
                                row.av = null;
                                row.display = 1;
                                row.type = "Article";
                                row.scanned = true;

                                var p2 = { ...picking };
                                p2.all_picking.push(row);
                                p2.currentPicking.push(row);
                                setPicking(p2);

                                //Add in database

                                setPopUp("Ajouté !", undefined, 1000);
                            }
                        }
                    }
                }
            }
        } else {
            setPopUp("Etiquettes non conformes", "danger");
        }

        setScanned("");
    }

    function getFormedId(source, id) { return forceString(source).trim() + "_" + id }

    // Search in object picking the need
    function getArticleByNeedNb(nb) {

        for (const k in picking.picking) {
            var o = picking.picking[k]
            const idx = o.findIndex(a => forceString(a.id) === forceString(nb));
            if (idx !== -1) return o[idx];
        }

        return null;
    }

    // Add packing (doublon)
    const addArticleToPacking = (scanned) => {
        // Search the article in currentPicking   
        if (scanned.indexOf("-") !== -1) {
            var need_nb = scanned.split("-")[5];

            const idx = picking.currentPicking.findIndex(a => a.art_id.endsWith(forceString(need_nb)));
            // Chekc if the need number exists in the picking
            if (idx === -1) {
                setPopUp("Ce besoin ne figure pas dans le picking !", "danger", 4000);
            } else {
                // Add  the article in the packing by changing the machine only
                var p2 = { ...picking };
                // Get pack ID
                var id = picking.shipment.detail[currentPacking].detail_id

                p2.currentPicking[idx].machine = id;

                setPicking(p2);
            }
        } else {
            setPopUp("Etiquettes non conformes", "danger");
        }

        setScanned("");

    }



    return <div style={{ height: "80vh" }}>
        <h5 className='text-center'>Fiche de colisage</h5>
        <h6 className="text-center">{picking && picking.exp.business}</h6>

        {loading && <WaitingRoundSnippers />}

        {(origin && picking) && <div style={{ height: "80vh" }}>
            {(picking.picking_head.comments && picking.picking_head.comments !== "") &&
                <div className="fst-italic fw-bold alert alert-warning text-center"
                    dangerouslySetInnerHTML={{ __html: picking.picking_head.comments.replace("\n", "<br/>") }}></div>
            }

            {step !== "add_picking" &&
                <h6 className='text-center mb-3'>Il reste {toPack.length} articles à empaqueter</h6>
            }

            {/**
             * STEP 1: ASK IF HE WANTS TO ADD ARTICLES IN PICKING OR PACK IT
             */}
            {step === "pick_or_pack" && <div>
                <Inputs.SubmitBtn label={"Ajouter d'autres articles au picking"} loading={loading}
                    onClick={() => setStep("add_picking")} divClass='text-center mb-5 mt-5' btnWidth="300px" />

                {toPack.length > 0 &&
                    <Inputs.SubmitBtn label={"Placer les articles dans les colis"} loading={loading}
                        onClick={() => setStep("add_packing")} divClass='text-center mt-5' btnWidth="300px" />}
            </div>}

            {/**
             * STEP 2: ADD ARTICLES IN PICKING
             */}
            {step === "add_picking" && <div>
                <div className='alert alert-info text-center'>
                    <strong>Scannez toutes les pièces puis enregistrez.</strong>
                </div>

                <ScanReader scanned={scanned} setScanned={setScanned} />

                <div className='mt-5'>
                    <Inputs.SubmitBtn label={"Enregistrer"} loading={loading}
                        onClick={() => saveOtherPicking(false)} />
                </div>
            </div>}

            {/**
             * STEP 3: SELECT THE PARCEL WHERE TO SET ARTICLES
             */}
            {step === "add_packing" && <div>
                {currentPacking === undefined && <Fragment>
                    <div className='alert alert-info text-center fw-bold mb-3'>Choisissez ou créez un type de colis</div>

                    {/** GET EXISTING DETAILS 
                     * TODO: CHECK IF NOT USED IN OTHER PICK OR ALREADY SHIPPED
                     * SEE hasBeenShipped()
                     */}
                    {picking.shipment.detail.map((v, k) => {
                        return hasBeenShipped(v.detail_id) === null && <Inputs.SubmitBtn label={v.type + " n°" + (k + 1)} loading={loading}
                            onClick={() => { setCurrentPacking(k); setStep("add_to_packing") }} divClass='text-center mt-5' btnWidth="300px" />
                    })}

                    <Divider />

                    {/** CREATE DETAILS  */}
                    <form onSubmit={addShipping} className='mt-3'>
                        <Inputs.SelectGroup label="Nouveau type" spanWidth="180px"
                            name="type" onChange={(e) => { }} required={true}
                            options={[
                                { value: "Camion", label: "Camion", display: true },
                                { value: "Palette", label: "Palette", display: true },
                                { value: "Caisse", label: "Caisse", display: true },
                                { value: "Colis", label: "Colis", display: true },
                                { value: "Autres", label: "Autres", display: true }
                            ]} />

                        <Inputs.SubmitBtn label={"Ajouter"} loading={loading}
                            onClick={() => { }} divClass='text-center' btnWidth="300px" />
                    </form>
                </Fragment>}
            </div>}

            {/**
             * STEP 4: SELECT THE ARTICLES TO SET IN THIS PARCEL
             */}
            {step === "add_to_packing" && <Fragment>
                <ScanReader scanned={scanned} setScanned={setScanned} />

                <Inputs.SubmitBtn label={"Enregistrer"} loading={loading}
                    onClick={() => saveOtherPicking(false)} />
            </Fragment>}

            {/**
             * STEP 5: DO MOVEMENTS
            */}
            {step === "move" && <div className='table-responsive'>
                <table className='table' style={{ minWidth: "400px" }}>
                    <thead>
                        <tr>
                            <th style={{ width: "50px" }}></th>
                            <th>Art.</th>
                            <th>Qty</th>
                            <th>Msg</th>
                        </tr>
                    </thead>
                    <tbody>
                        {picking.currentPicking.filter(a => a.scanned).map((v, k) => {
                            return <tr key={k}>
                                <td className={v.move_err === undefined ? "" :
                                    v.move_err.startsWith("ERREUR") ? 'bg-danger' : 'bg-success'} >
                                    {v.move_err === undefined && <ButtonWaiting />}
                                </td>
                                <td>{v.ar}</td>
                                <td>{v.remaining}</td>
                                <td style={{ minWidth: "500px" }}>{v.move_err ? v.move_err : "Mouvement effectué"}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>}

            {step !== "pick_or_pack" && <div style={{ position: "absolute", left: "50%", transform: "translateX(-50%)", bottom: "15px" }}>
                <Inputs.SubmitBtn label={"Retour en arrière"} loading={loading}
                    onClick={goBack} divClass='text-center mt-5' btnWidth="300px" />
            </div>}

        </div>}
    </div>

};

export default PackingMobile;