import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { CustomToolTipInfo, CustomToolTipInfoObj } from '../../../../common/smallComponents.js/CustomToolTip';
import Inputs from '../../../../common/smallComponents.js/Inputs';
import ReactTable from '../../../../common/smallComponents.js/ReactTable';
import { arrayRemoveElt, hasSameElements, removeAndReturnArray } from '../../../../functions/ArrayFunctions';
import { setPopUp } from '../../../../functions/PageAnimation';
import { forceString } from '../../../../functions/StrFunctions';
import { artChange, categoryChange, makerChange, qtyChange, refChange } from './Common';

const Overview = ({ bom_content, focus_content, bom_content_origin, bom_content_change,
    articles_list, setBomContentChange, setBomContent }) => {
    const optionsArray = ["Std", "Std Pneu", "Std old", "Hst", "Hst Pneu", "Peint", "Spé", "CSM", "CSM vis", "CSM élec",
        "CSM cablo", "OLD", "CMP", "Presta"];

    /**
     * OPEN ARTICLE CARD
     */
    const openArticleCard = (e) => { window.open("/article/info/get?article=" + e.target.value, '_blank') }

    const onCategoryChange = (e, k) => {
        categoryChange(bom_content, bom_content_change, setBomContent, bom_content_origin, setBomContentChange,
            optionsArray, e, k)
    }

    const onQtyChange = (e, k) => {
        qtyChange(bom_content, bom_content_change, setBomContent, bom_content_origin, setBomContentChange, e, k);
    }

    const onMakerChange = (e, k) => {
        makerChange(bom_content, bom_content_change, setBomContent, setBomContentChange, bom_content_origin, e, k);
    }

    const onArtChange = (e, k) => {
        artChange(bom_content, bom_content_origin, bom_content_change, setBomContentChange,
            articles_list, setBomContent, e, k);
    }

    const onRefChange = (e, k) => {
        refChange(bom_content, bom_content_origin, articles_list, bom_content_change,
            setBomContentChange, setBomContent, e, k);
    }

    function isReadonly( v ){ return v.row.original.readonly }

    /**
        * TABLE
        */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('cat',
            {
                header: 'Cat.', cell: (i) => <div className="text-center">
                    { isReadonly(i) ? i.getValue() :
                    <Inputs.Input defaultValue={i.getValue()} hideSpan inputClass='w-99' list='cat'
                        onBlur={(e) => onCategoryChange(e, i.row.id)} />
                    }
                </div>
            }));

        arr.push(columnHelper.accessor('se',
            { header: 'S/E.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('rep',
            { header: 'Rep.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('qty',
            {
                header: <div className='d-flex'>
                    Qté. à commander
                    <CustomToolTipInfo props={{ tooltipText: "Qté demandée en nomenclature" }} />
                </div>, cell: (i) => <div className="text-center">
                    { isReadonly(i) ? i.getValue() :
                    <Inputs.Input inputType='number' defaultValue={i.getValue()} hideSpan
                        inputClass='w-80' onBlur={(e) => onQtyChange(e, i.row.id)} />}
                </div>
            }));

        arr.push(columnHelper.accessor('frenchLabel',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            {
                header: 'Ref.', cell: (i) => <div className="text-center">
                    { isReadonly(i) ? i.getValue() :
                    <Inputs.Input defaultValue={i.getValue()} hideSpan inputClass='w-250'
                        onBlur={(e) => onRefChange(e, i.row.id)} />}
                </div>
            }));

        arr.push(columnHelper.accessor('maker',
            {
                header: 'Fabricant', cell: (i) => <div className="text-center">
                    { isReadonly(i) ? i.getValue() :
                    <Inputs.Input defaultValue={i.getValue()} hideSpan inputClass='w-151'
                        onBlur={(e) => onMakerChange(e, i.row.id)} />}
                </div>
            }));

        arr.push(columnHelper.accessor('ar',
            {
                header: 'Art.', cell: (i) => <div className="text-center">
                    { isReadonly(i) ? i.getValue() :
                    <Inputs.Input defaultValue={i.getValue()} hideSpan inputClass='w-180'
                        onBlur={(e) => onArtChange(e, i.row.id)} />}
                </div>
            }));

            arr.push(columnHelper.accessor('cost_price',
                {
                    header: 'Prix', cell: (i) => <div className="text-center">
                        <div style={{fontSize: "12px"}}>Rev.: {i.getValue()}</div>    
                        <div style={{fontSize: "12px"}}>Vente: {i.row.original.sell_price === 0 ? "N/A" : i.row.original.sell_price}</div>  
                    </div>
                }));

            arr.push(columnHelper.accessor('blocked',
                { header: 'Bloc.', cell: (i) => <div className="text-center">
                    { !i.getValue() ? "" : i.getValue() === "N" ? "Non" : "Oui"}
                </div> }));

        if (focus_content && focus_content.length > 0) {
            arr.push(columnHelper.accessor('focus_qty',
                { header: <div className='d-flex'>
                    Qté. Focus
                    <CustomToolTipInfo props={{ tooltipText: "Qté renseignée dans l'AV ( pas l'affaire ! )" }} />
                </div>, 
                cell: (i) => <div className="text-center">{i.getValue()}</div> }));


            arr.push(columnHelper.accessor('delta',
                {
                    header: <div className='d-flex'>
                    Delta
                    <CustomToolTipInfo props={{ tooltipText: "Delta positif = qté en nomenclature supérieure à celle de Focus" }} />
                </div>, cell: (i) => <div style={{ height: "100%" }} className={"text-center "
                        + (i.getValue() !== 0 ? "bg-warning" : "")}>{i.getValue()}</div>
                }));
        }

        arr.push(columnHelper.accessor('stock',
            { header: <div className='d-flex'>
                Stock
                <CustomToolTipInfo props={{ tooltipText: "Stock à terme (stock en magasin + commandes - qté en besoin)" }} />
            </div>, cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('coms',
            {
                header: 'Coms.', cell: (i) => <div className="text-center">{
                    (i.getValue() && i.getValue().trim() !== "") && <CustomToolTipInfoObj props={{ tooltipText: i.getValue() }} />
                }</div>
            }));

        return arr;
    }

    //Set columns
    const columns = getColumns();

    return (
        <div>
            {bom_content &&
                <ReactTable columns={columns} data={bom_content} origin="temp_bom_overview" classes={"no-padding fixFstCol "} top0={true} />}

            <datalist id='cat'>
                {optionsArray.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </datalist>
        </div >
    );
};

export default Overview;