import { Fragment, useEffect, useState } from "react"
import ScanReader from "../storehouse/components/ScanReader"
import CsContracts from "../../services/customClient/CsContracts"
import { setPopUp } from "../../functions/PageAnimation"
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers"
import { forceString, sPlural } from "../../functions/StrFunctions"
import { confirmAlert } from "react-confirm-alert"

const PackingScan = (props) => {

    const [scanned, setScanned] = useState("")
    const [picking, setPicking] = useState()
    const [mode, setMode] = useState("business")
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [message, setMessage] = useState("")
    const [name, setName] = useState("")
    const [remainingRows, setRemainingRows] = useState("")

    useEffect(() => {
        if (mode === "business") {
            if (scanned.length >= 8) searchPicking();
        } else {
            if (scanned.split("-").length >= 5) updateField()
        }

    }, [scanned]);

    const searchPicking = () => {
        setLoading(true);

        CsContracts.getPicking(scanned).then(
            (response) => {
                setName(response[0].NOCDE)
                const newPicking = response.filter(index => {

                    const aravf = forceString(index.ARAVF).toUpperCase()
                    return index.NOCDF !== null && !aravf.includes("YR_")
                });
                setPicking(newPicking);
                setRemainingRows(newPicking.length)
                console.log(newPicking)
                setMode("picking");
                setLoading(false);
            },
            (error) => {
                setPopUp("Aucun picking n'a été trouvé", "danger")
            }
        )
    }

    const updateField = () => {

        var scan_need = scanned.split('-');
        if (scan_need.length <= 5) {
            setPopUp("Erreur: Numéro de besoin non renseigné", "danger")
        } else {
            if (isNaN(scan_need[5].trim())) {
                setPopUp(scan_need[5] + " n'est pas un n° de besoin !", "danger", 5000)
                return;
            }

            try {
                var row = picking.findIndex((v) => parseInt(v.NORMM) === parseInt(scan_need[5]));

                if (row === -1) {
                    setPopUp("Besoin non trouvé !", "danger")
                    return;
                }

                if (forceString(picking[row].ZN15PA).toUpperCase().indexOf("CSM") !== -1
                    || parseFloat(picking[row].QTARF) <= parseFloat(picking[row].moved_need_upd)) {

                    CsContracts.updatePicking("pack_ctrl", picking[row].NOCDE, picking[row].NLIGF, 1, picking[row]).then(
                        (res) => { console.log(res) },
                        (err) => { console.log(err) }
                    )

                    var arr = [...picking];
                    arr[row].pack_ctrl = 1
                    setPicking(arr)

                    const allPackCtrlOne = picking
                        .filter(item => !forceString(item.ZN15PA).toUpperCase().includes("CSM"))
                        .every(index => index.pack_ctrl === 1);

                    if (allPackCtrlOne) {
                        setPopUp("Dernier AR : " + arr[row].ARAVF)
                        setMessage("Contrôle colisage complet")
                    } else {
                        setRemainingRows(remainingRows - 1)
                        setPopUp("Enregistré : " + arr[row].ARAVF)
                    }
                } else if (parseFloat(picking[row].QTARF) >= parseFloat(picking[row].moved_need_upd)) {
                    confirmAlert({
                        title: "Le mouvement de stock n'a pas été effectué.",
                        message: "Souhaitez vous effectuer le mouvement ?",
                        buttons: [
                            {
                                label: "Oui",
                                onClick: () => { 
                                    var qtyNeeded = parseFloat(picking[row].QTARF) - parseFloat(picking[row].moved_need_upd)
                                    CsContracts.updatePicking("manual_out", picking[row].NOCDE, picking[row].NLIGF, qtyNeeded, picking[row]).then(
                                        (res) => { console.log(res) },
                                        (err) => { console.log(err) }
                                    )

                                    var arr = [...picking];
                                    arr[row].moved_need_upd = arr[row].QTARF
                                    setPicking(arr)
                                    setPopUp("Le mouvement a bien été effectué.", "success", 2000)
                                 }
                            },
                            {
                                label: "Non"
                            }
                        ]
                        
                    });
                }
            } catch (error) {
                setPopUp(error, "danger", undefined, 5000)
            }

        }
    }
    


    return <Fragment>
        {loading && <WaitingRoundSnippers />}
        {(!loading && !errorMessage && !message) && <div>
            <h3 className="text-center">Contrôle colisage {name}</h3>
            <div>
                <div className='alert alert-info text-center'>
                    <strong>{mode === "business" ? "Scannez votre affaire"
                        : ("Scannez vos articles à emballer: il y a " + remainingRows + " " + sPlural("ligne", remainingRows) )}</strong>
                </div>
                <ScanReader setScanned={setScanned} />
            </div>
        </div>}
        
        {errorMessage && <div className="alert alert-danger w-50 ms-auto me-auto text-center">{errorMessage}</div>}
        {message && <div className="alert alert-success w-50 ms-auto me-auto text-center">{message}</div>}

        { ( !loading && mode !== "business" ) && <div className="mt-5 text-center">
                <button className="btn btn-secondary" onClick={() => window.location.reload()}>Autre affaire</button>
            </div>
        }
    </Fragment>
}

export default PackingScan
