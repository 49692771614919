import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";
import { fileToBase64 } from "../../functions/StrFunctions";

const API_URL = process.env.REACT_APP_API_URL +"articles";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getDisposableArtListNotPending = () => {
    return axios
    .post(API_URL + "/get-articles/disposable",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getArticlesThrown = () => {
    return axios
    .post(API_URL + "/get-articles/disposable/thrown",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const validateArtToThrow = (list) => {
    return axios
    .post(API_URL + "/get-articles/disposable/throw",
    {
        list: list,
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getArticlesByCodes = (arr, field) => {
    return axios
    .post(API_URL + "/get-articles/by-codes",
    {
        arr: arr,
        field: field,
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const ceoValidation = (param) => {
    return axios
    .post(API_URL + "/public/get-articles/disposable/throw/validation",
    {
        param: param,
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const setInPalet = (param, packing) => {
    return axios
    .post(API_URL + "/public/get-articles/disposable/throw/destroy",
    {
        param: param,
        packing: packing,
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getById = (param) => {
    return axios
    .post(API_URL + "/get-articles/disposable/getById",
    {
        param: param,
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const addDestroyFile = async (param, file) => {
    let formData = new FormData();
    
    formData.append("file", file);

    const b64 = await fileToBase64(file);
    
    console.log(file)

    return axios
    .post(API_URL + "/add-destroy-file",
    {
        public_id: param,
        file: b64,
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });

    return axios({
        method: "post",
        url: API_URL + "/add-destroy-file",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        return response.data;
    });
}

const getProposals = () => {
    return axios
    .post(API_URL + "/get-articles/disposable/toAdd",
    {
        fake_header: authHeader()
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const addToThrow = (o) => {
    return axios
    .post(API_URL + "/get-articles/disposable/add",
    {
        fake_header: authHeader(),
        o: o
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const addToThrowMultiple = (arr) => {
    return axios
    .post(API_URL + "/get-articles/disposable/add/multiple",
    {
        fake_header: authHeader(),
        arr: arr
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const cancelThrow = (ar) => {
    return axios
    .post(API_URL + "/get-articles/disposable/remove",
    {
        fake_header: authHeader(),
        ar: ar
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const updateDestroyedArt = (ar, field, value) => {
    return axios
    .post(API_URL + "/destroyed/update",
    {
        fake_header: authHeader(),
        ar: ar,
        field: field,
        value: value
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    getDisposableArtListNotPending,
    getArticlesThrown,
    validateArtToThrow,
    getArticlesByCodes,
    ceoValidation,
    setInPalet,
    getById,
    addDestroyFile,
    getProposals,
    addToThrow,
    addToThrowMultiple,
    cancelThrow,
    updateDestroyedArt
}