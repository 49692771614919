import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { arrayRemoveElt, getKey } from "../../../../functions/ArrayFunctions";
import MachineInfo from "../../../../services/machines/MachineInfo";
import errorManagement from "../../../../services/errorManagement";
import { ButtonWaiting, scrollToTop, setPopUp } from "../../../../functions/PageAnimation";

const DpManagement = (p) => {
    const [dpSelect, setdpSelect] = useState();
    const [dpList, setdpList] = useState();
    const [ cur_opt, setCurOpt ] = useState();

    const [dpOptList, setOptdpList] = useState();
    const typeOfMachine = ["BA_TECMA_18_20_24", "BA_TECMA_30_40", "BA15P", "EC08",
        "EC16", "FORMEUSE_CAISSE_LANTEC", "IK", "IN", "LN", "POSE_CALE", "POSE_COIFFE", "WN", "2P"];

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        var dpS = new Array();
        p.dp.map((v) => { if (getKey(dpS, "field", v.field) === null) dpS.push(v); })
        setdpSelect(dpS);
    }, []);

    /**
     * 
     * @param {*} e 
     */
    const selectDp = (e) => {
        var selected = e.target.value;

        if( selected === "" ){
            setdpList()
        }else{
            var arr = new Array();
            p.dp.map(v => { if (v.field === selected) arr.push(v) })
            setdpList(arr)
        }
        
    }

    const selectDpOpt = (e) => {
        var selected = e.target.value;
        setCurOpt( selected );

        if( selected === "" ){
            setOptdpList();
        }else{
            var arr = new Array();
            p.dpOptions.map(v => { if (v.type_option === selected) arr.push(v) })
            setOptdpList(arr)
        }
        
    }

    const onDpChange = (e) => {
        var key = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var arr = [...dpList];
        arr[key][field] = e.target.value;
        setdpList(arr);
    }

    const saveDp = (e) => {
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        var arr = [...p.dp];
        arr[getKey(arr, "id", parseInt(id))][field] = value;
        p.setDp(arr);

        MachineInfo.updateDp(field, value, id).then(
            () => { },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )

    }

    const addDp = (e) => {
        setLoading(true);

        MachineInfo.addDp(dpList[0].field).then(
            (response) => {

                var o = {
                    designation: "",
                    en_label: "",
                    field: dpList[0].field,
                    id: response,
                    info1: "",
                    label: ""
                }

                var arr = [...dpList]
                arr.push(o);
                setdpList(arr);

                var arr2 = [...p.dp]
                arr2.push(o);
                p.setDp(arr2);

                setLoading(false);
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();

                setLoading(false);
            }
        );
    }

    const removeDp = (e) => {
        var key = e.target.getAttribute("data-key");
        var id = e.target.getAttribute("data-id");

        var arr = [...dpList];
        arr = arrayRemoveElt(arr, key);
        setdpList(arr);

        var arr2 = [...p.dp];
        arr2 = arrayRemoveElt(arr2, getKey(arr2, "id", parseInt(id)));
        p.setDp(arr2);

        MachineInfo.deleteDp(id).then(
            () => { },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        )
    }

    const onMachineTypeChoose = (e) => {
        var k = e.target.getAttribute("data-key");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        var arr = [...dpOptList];
        var newVal;
        
        if( e.target.checked ){
            newVal = arr[k].types_machine + value + ";";
        }else{
            newVal = arr[k].types_machine.replace( value + ";", "")
        }

        arr[k].types_machine = newVal;
        setOptdpList(arr);

        var arr2 = [...p.dpOptions];
        arr2[getKey(arr2, "id", parseInt(id))].types_machine = newVal;
        p.setDpOptions(arr2);

        MachineInfo.updateOptionDp(id, "types_machine", newVal).then(
            () => { },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }

    const onOptionChange = (e) => {
        var k = e.target.getAttribute("data-key");
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        var arr = [...dpOptList];
        arr[k][field] = value;
        setOptdpList(arr);

        var arr2 = [...p.dpOptions];
        arr2[getKey(arr2, "id", parseInt(id))][field] = value;
        p.setDpOptions(arr2);
    }

    const saveOptionsChange = (e) => {
        var field = e.target.getAttribute("data-field");
        var id = e.target.getAttribute("data-id");
        var value = e.target.value;

        if( value === "" ) return;

        MachineInfo.updateOptionDp(id, field, value).then(
            () => { },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
    }   

    const addOptions = (e) => {        
        setLoading(true);

        MachineInfo.addOptionDp( { type: dpOptList[0].type_option, price: 0.01 } ).then(
            (response) => {

                var curSelect = [...dpOptList];
                var o = {
                    label: "TO DO",
                    type: dpOptList[0].type,
                    id: response
                };

                curSelect.push(o);
                setOptdpList(curSelect);

                var completeList = [...p.dpOptions];
                completeList.push(o);
                p.setDpOptions(completeList);


                setLoading(false);
                setPopUp("Ajouté")
            },
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();

                setLoading(false);
            }
        );
    }

    const deleteOption = (e) => {
        var id = e.target.getAttribute("data-id");
        var k = e.target.getAttribute("data-key");

        var curSelect = [...dpOptList];
        curSelect = arrayRemoveElt(curSelect, k);
        setOptdpList(curSelect);

        var completeList = [...p.dpOptions];
        completeList = arrayRemoveElt(completeList, getKey(completeList, "id", parseInt(id)));
        p.setDpOptions(completeList);

        MachineInfo.deleteOptionDp(id).then(
            () => {},
            (error) => {
                p.setError(errorManagement.handleError(p, error));
                scrollToTop();
            }
        );
        
    }

    return <Fragment>

        <div className="display-flex table-responsive">
            {/** dropdown menu management */}
            <div style={{
                width: "50%", minWidth: "500px", borderRightStyle: "solid", padding: "10px",
                borderWidth: "thin", borderColor: "#dee2e6 #dee2e6 #fff"
            }}>
                <h4 className="text-center">Gestion des menus déroulant</h4>

                {
                    dpSelect && <select className="form-select mb-3 fw-bold text-center" onChange={selectDp}>
                        <option value=""></option>
                        {
                            dpSelect.map((v, k) => {
                                return <option key={"dps" + k} value={v.field}>{v.designation}</option>
                            })
                        }
                    </select>
                }

                {
                    dpList && <table className="table">
                        <thead>
                            <tr className="table-info text-center">
                                <th scope="col">Désignation FR</th>
                                <th scope="col">Désignation EN</th>
                                <th scope="col">Info complémentaire</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dpList.map((v, k) => {
                                    return <tr key={"dpList" + k}>
                                        <th scope="row">
                                            <input className="form-control" value={v.label} data-field="label"
                                                data-id={v.id} data-key={k} onChange={onDpChange} onBlur={saveDp}></input>
                                        </th>
                                        <th scope="row">
                                            <input className="form-control" value={v.en_label === null ? "" : v.en_label} data-field="en_label"
                                                data-id={v.id} data-key={k} onChange={onDpChange} onBlur={saveDp}></input>
                                        </th>
                                        <td>
                                            <input className="form-control" value={v.info1 === null ? "" : v.info1} data-field="info1"
                                                data-id={v.id} data-key={k} onChange={onDpChange} onBlur={saveDp}></input>
                                        </td>
                                        <td>
                                            <img src={"/common/remove_icon.png"} data-id={v.id} data-key={k}
                                                style={{ height: "30px" }} onClick={removeDp}></img>
                                        </td>
                                    </tr>
                                })

                            }
                            <tr>
                                <td className="text-center" colSpan={4}>
                                    <button className="btn btn-outline-secondary" onClick={addDp} disabled={loading}>
                                        {loading && <ButtonWaiting />}
                                        Ajouter un élément</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>

            {/** Options management */}
            <div style={{ width: "50%", minWidth: "500px", padding: "10px" }}>
                <h4 className="text-center">Gestion des options</h4>

                <select className="form-select mb-3 fw-bold text-center" onChange={selectDpOpt}>
                    <option value=""></option>
                    <option value="meca">Méca</option>
                    <option value="cc">CC</option>
                    <option value="meca_cc">Méca / CC</option>
                </select>

                {
                    dpOptList && <table className="table">
                        <thead>
                            <tr className="table-info text-center">
                                <th scope="col">Désignation</th>
                                <th scope="col">Prix</th>
                                <th scope="col">Machines concernées</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dpOptList.map((v, k) => {
                                    return <tr key={"dpList" + k}>
                                        <th scope="row">
                                            <input className="form-control" value={v.label_option} data-field="label_option"
                                                data-id={v.id} data-key={k} onChange={onOptionChange} onBlur={saveOptionsChange}></input>
                                        </th>
                                        <th scope="row">
                                            <input type="number" className="form-control" value={v.price === null ? "" : v.price} data-field="price"
                                                data-id={v.id} data-key={k} onChange={onOptionChange} onBlur={saveOptionsChange}></input>
                                        </th>
                                        <td>
                                            <div className="accordion mb-3" id="accordionDiv">

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={"heading"+k}>
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                            data-bs-target={"#collapse"+k} aria-expanded="false" aria-controls={"collapse"+k}>
                                                            Types de machines concernées
                                                        </button>
                                                    </h2>
                                                    <div id={"collapse"+k} className="accordion-collapse collapse"
                                                        aria-labelledby={"heading"+k} data-bs-parent="#accordionDiv"
                                                        style={{ padding: "10px" }}>
                                                            
                                                        {
                                                            typeOfMachine.map((v2, k2) => {
                                                                return <div className="form-check" key={"tm" + k2}>
                                                                    <input className="form-check-input" type="checkbox"
                                                                        value={v2} onChange={onMachineTypeChoose} 
                                                                        data-id={v.id} data-key={k}
                                                                        checked={v.types_machine && v.types_machine.indexOf(v2) !== -1 } />
                                                                    <label className="form-check-label" for="flexCheckDefault">{v2}</label>
                                                                </div>
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <img src={"/common/remove_icon.png"} data-id={v.id} data-key={k}
                                                style={{ height: "30px" }} onClick={deleteOption}></img>
                                        </td>
                                    </tr>
                                })

                            }
                            <tr>
                                <td className="text-center" colSpan={4}>
                                    <button className="btn btn-outline-secondary" onClick={addOptions} disabled={loading}>
                                        {loading && <ButtonWaiting />}
                                        Ajouter un élément</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
            </div>

        </div>
    </Fragment >

}

export default DpManagement;