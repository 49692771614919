import axios from "axios";
import textPlainHeader from "../fake-header";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL + "miscellaneous";
const user = JSON.parse(localStorage.getItem("user"));

const updateState = (type, value) => {
    return axios
        .post(API_URL + "/update", {
            fake_header: authHeader(),
            type: type,
            value: value
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getStateByType = (type) => {
    return axios
        .post(API_URL + "/get/byType", {
            fake_header: authHeader(),
            type: type
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getAllState = () => {
    return axios
        .post(API_URL + "/get/all", {
            fake_header: authHeader()
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export const logObj = {origin: "", id: "" , log_text: null, old_value: null, new_value: null}

/**
 * @param {*} o 
 * @returns 
 */
const saveLog = (o) => {
    return axios
        .post(API_URL + "/log/save", {
            fake_header: authHeader(),
            o: o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getLog = (o) => {
    return axios
        .post(API_URL + "/log/get", {
            fake_header: authHeader(),
            o: o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}


/**
 * Sends a notification email with the provided data.
 * 
 * @param {Object} o - The data to be sent in the email.
 * @param {JSONArray} o.arr - The array of items to include in the email.
 * @param {string} o.subject - The subject of the email.
 * @param {string} o.body - The body content of the email.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 */
const notifyChangeByEmail = (o) => {
    return axios
        .post(API_URL + "/notify/email", {
            fake_header: authHeader(),
            ...o
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * Sends any notification email 
 * 
 * @param {JSONArray} arr - The array of items to include in the email.
 * @param {string} subject - The subject of the email.
 * @param {string} body - The body content of the email.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 */
const sendAnyEmail = (recipient, subject, body) => {
    return axios
        .post(API_URL + "/notify/email", {
            fake_header: authHeader(),
            recipients: recipient,
            subject: subject,
            body: body
        }, { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    updateState,
    getStateByType,
    getAllState,
    saveLog,
    getLog,
    notifyChangeByEmail,
    sendAnyEmail
}