import { SmallFrame } from "../../../common/smallComponents.js/CustomDivs";


const TestWindow = () => {


  return (
    <SmallFrame  />
  );
};

export default TestWindow;
