import { createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { SmallFrame } from "../../../../common/smallComponents.js/CustomDivs";
import { BtnWithCheck } from "../../../../common/smallComponents.js/DesignedIpt";
import Inputs from "../../../../common/smallComponents.js/Inputs";
import ReactTable from "../../../../common/smallComponents.js/ReactTable";
import { arrayRemoveElt, getKeyInt, removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting, scrollDown, scrollToTop, setPopUp } from "../../../../functions/PageAnimation";
import { forceString, getDateTimeFromDb, getFrFormat } from "../../../../functions/StrFunctions";
import transport from "../../../../services/supplyChain/transport";

const OtherPacking = (p) => {
    const [search, setSearch] = useState();
    const [tab, setTab] = useState("articles");
    const [data, setData] = useState();
    const [selectedArt, setSelectedArt] = useState([]);
    const [added, setAdded] = useState();
    const [loading, setLoading] = useState(false);
    const [artToChange, setArtToChange] = useState([]);
    const focus_field = {
        "dim1": { focus_field: "DIM1L", label: "Dimension 1" },
        "dim2": { focus_field: "DIM2L", label: "Dimension 2" },
        "dim3": { focus_field: "DIM3L", label: "Dimension 3" },
        "weight": { focus_field: "POIDL", label: "Poids" }
    }

    const packingType = [{ ar: "Palette", label: "Palette" },
    { ar: "Caisse", label: "Caisse" },
    { ar: "Colis", label: "Colis" }];

    const newPackEmpty = {
        type: "", truck_load_side: "", truck_load: "",
        truck_unload: "", truck_type: "", truck_height: "", nb_strap: "", truck_weight: "",
        truck_meter: "", pack_width: "", pack_height: "", truck_height: "", pack_weight: "",
        pack_pick_date: "", pack_delivery: "", shipment_id: ""
    };

    const [newPack, setNewPack] = useState(_.cloneDeep(newPackEmpty));

    //,{ article: "Enveloppe", fr_label: "Enveloppe" }

    /**
     * Add all article available in pack
     * @param {*} e 
     */
    const addAll = (e) => {
        var arr = [...selectedArt];

        for (let i = 0; i < data.length; i++) {
            var o = { ...data[i] };

            if (forceString(data[i].machine) === '-1' && e.target.checked) {
                o.data_id = i;
                arr.push(o)
            } else {
                arr = arrayRemoveElt(arr, getKeyInt(arr, "data_id", i));
            }
        }

        setSelectedArt(arr);
    }

    /**
     * Create table
     * @returns 
     */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('id',
            {

                enableSorting: false,
                enableColumnFilter: false,
                header: <div><input type="checkbox" className="form-check-input big" onClick={addAll} /></div>,
                cell: (i) => <div className="text-center">
                    {tab === "packing" && <BtnWithCheck label={"Ajouter"}
                        onClick={() => addPacking(i.row.id)} id={i.row.id} />}

                    {(tab === "articles" && parseInt(i.row.original.machine) === -1) &&
                        <input type="checkbox" className="form-check-input big artToAdd"
                            onChange={(e) => selectArticle(e, i.row.id)}
                            checked={selectedArt.find((v) => { return forceString(v.data_id) === forceString(i.row.id) })} />
                    }
                </div>
            }));

        arr.push(columnHelper.accessor('ar',
            { header: 'Article', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('label',
            { header: 'Désignation', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        arr.push(columnHelper.accessor('ref',
            { header: 'Ref.', cell: (i) => <div className="text-center">{i.getValue()}</div> }));

        return arr;
    }

    /**
     * Add shipment in transport request (pallet, parcel....)
     * @param {*} e 
     */
    const addShipping = (e) => {
        e.preventDefault();

        setLoading(true);

        var o = { ...newPack };
        o.header_id = p.picking.shipment.id;


        transport.saveShipmentDetail(o).then(
            (response) => {
                var picking = { ...p.picking };
                var shipment = { ...picking.shipment }
                var detail = shipment.detail;

                if (o.detail_id) {
                    detail[detail.findIndex(a => a.detail_id === o.detail_id)] = o;
                    setNewPack(_.cloneDeep(newPackEmpty));
                    scrollToTop();
                } else {
                    detail.push(response);
                }

                picking.shipment = shipment;
                p.setPicking(picking);
                setLoading(false);

                setPopUp(o.detail_id ? "Modifié" : "Ajouté")
            }, (error) => {
                setLoading(false);
                setPopUp("Une erreur s'est produite", "danger")
            }
        )
    }

    /**
     * Delete the shipment in transport request (pallet, parcel....)
     * @param {*} detail_id 
     */
    const deleteShipping = (detail_id) => {
        confirmAlert({
            title: "Suppression",
            message: "Confirmez-vous la suppression du moyen de trasnsport ? Le picking ne sera pas impacté",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);

                        transport.deleteDetailById(detail_id, null, p.picking.shipment.id).then(
                            (response) => {
                                var picking = { ...p.picking };
                                var shipment = { ...picking.shipment }
                                var detail = shipment.detail.filter(v => parseInt(v.detail_id) !== parseInt(detail_id));
                                shipment.detail = detail;
                                picking.shipment = shipment;
                                p.setPicking(picking);
                                setLoading(false);

                                setPopUp("Supprimé")
                            }, (error) => {
                                setLoading(false);
                                setPopUp("Une erreur s'est produite", "danger")
                            }
                        )
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    /**
     * Update the data state on change
     */
    useEffect(() => { p.picking && setData([...p.picking.currentPicking]) }, [p.picking.currentPicking])

    // Select article to put in packing
    const selectArticle = (e, id) => {

        var arr = [...selectedArt];
        var o = { ...data[id] };
        if (e.target.checked) {
            o.data_id = id;
            arr.push(o)
        } else {
            arr = arrayRemoveElt(arr, getKeyInt(arr, "data_id", id));
        }
        setSelectedArt(arr);
    }

    // Select tab packing or article or choose the location
    const changeTab = (tab) => {
        setTab(tab);
        setData(tab === "articles" ? [...p.picking.currentPicking] : [...packingType])
    }

    const columns = getColumns();

    // Get tag with level
    function getTag(v, k, currentLevel) {
        if (currentLevel === v.level - 1) {
            return "↳"
        } else if (currentLevel === v.level) {
            if (v.id === -1) return "";
            return "#" + v.id
        } else {
            return "";
        }
    }

    const addElement = (k) => {
        setSearch(k);
        //Maybe temportary if you have to add also packing
        setData([...p.picking.currentPicking])
    }

    const addPacking = () => {

    }

    // Remove article
    const removeArticle = (id) => {
        var curPick = _.cloneDeep(p.picking.currentPicking);
        var pId = getCurPickIdx(id);
        curPick[pId].level = null;
        curPick[pId].rank = 0;
        curPick[pId].machine = -1;

        var o = { ...p.picking }
        o.currentPicking = curPick;
        p.setPicking(o);
    }

    // Add articles checked in list
    const addArticle = () => {
        var detail = _.cloneDeep(p.picking.shipment.detail[search]);

        //Get picking inside this detail
        var pick = p.picking.currentPicking.filter(v => v.machine === detail.detail_id)

        //If the packing is empty insert directly
        if (pick.length === 0) {
            //Set order and level and machine
            insertAfter(detail, 0);
        } else {
            // If there is something else check if there is some container...
            var containers = pick.filter(v => v.type !== "Article")

            // If no insert at the end of array
            if (containers.length === 0) {
                var lastRank = pick[pick.length - 1].rank + 1;

                insertAfter(detail, lastRank);
            } else {
                // If there container get list of them then ask where to place it
            }

        }

        //Close frame
        setSelectedArt([]);
        setAdded();
        setPopUp("Ajouté");
    }

    // Commmon function to aff articles inside packing
    function insertAfter(detail, lastRank) {
        var curPick = _.cloneDeep(p.picking.currentPicking);
        var pId;

        for (let i = 0; i < selectedArt.length; i++) {
            pId = getCurPickIdx(selectedArt[i].id);
            curPick[pId].machine = detail.detail_id;
            curPick[pId].level = 1;
            curPick[pId].rank = lastRank++;
        }

        var o = { ...p.picking }
        o.currentPicking = curPick;
        p.setPicking(o);
        console.log(curPick);
    }

    //Get the index of ID of picking articles
    function getCurPickIdx(id) { return p.picking.currentPicking.findIndex(v => v.id === id); }

    const onShippingChange = (e, field) => {
        var o = { ...newPack }
        o[field] = e.target.value;
        setNewPack(o);
    }

    /**
     * Update the shipement info (pallet, parcel....)
     * @param {*} k 
     */
    const changePackDimension = (k) => {
        var elt = { ...p.picking.shipment.detail[k] };
        elt.update = true;
        setNewPack(elt);

        scrollDown("main")
    }

    /**
     * Get if the shipment has been sent or processed
     * @param {*} id 
     * @returns 
     */
    function hasBeenShipped(id) {
        //Do not insert in this palet if an anothor picking has used it
        var idx = p.picking.all_picking.findIndex(a =>
            p.picking.picking_id !== a.picking_id && forceString(a.machine) === forceString(id));

        if (idx !== -1) return "Utilisé dans le picking n°"
            + p.picking.all_picking[idx].picking_id + ", impossible d'ajouter des éléments ici"

        // Do not insert if this palet has been shipped in this picking
        idx = p.picking.shipment.detail.findIndex(a => id === a.detail_id && a.truck_load_real !== null);

        if (idx !== -1) return "Déjà expédié le "
            + getFrFormat(p.picking.shipment.detail[idx].truck_load_real, true) + ", impossible d'ajouter des éléments ici"

        return null;
    }

    /**
     * Change dimensions and weight
     * @param {*} e 
     * @param {*} row 
     * @param {*} field 
     */
    const changeDim = (e, row, field) => {
        var picking = { ...p.picking };

        var art = picking.currentPicking.filter(a => a.ar.trim() === row.ar.trim());

        if (forceString(art[0][field]) !== forceString(e.target.value)) {
            var o = {
                art: art[0].ar.trim(),
                field: focus_field[field].focus_field,
                label: focus_field[field].label,
                value: e.target.value
            }

            //For all articles except lambda change all rows
            if (o.art.trim() !== "AR_LAMBD_00001") {
                // check if change has been done before
                var change = [...artToChange]
                var idx = change.findIndex(a => a.art.trim() === o.art.trim() && a.field === o.field);

                // If never changed add
                if (idx === -1) {
                    o.old_val = art[0][field]
                    change.push(o);
                } else {
                    // if the change is only coming back to initial value, remove
                    if (forceString(change[idx].old_val) === forceString(o.value)) {
                        change = removeAndReturnArray(change, idx);
                    } else {
                        o.old_val = change[idx].old_val;
                        change[idx] = o
                    }
                }

                setArtToChange(change);

                for (let i = 0; i < picking.currentPicking.length; i++)
                    if (picking.currentPicking[i].ar.trim() === o.art)
                        picking.currentPicking[i][field] = o.value;
            } else {
                var idx = picking.currentPicking.findIndex(a => a.id === row.id);
                picking.currentPicking[idx][field] = o.value;
            }

            p.setPicking(picking);
        }

    }

    const saveArtChange = () => {
        transport.updateArtDimensions(artToChange);
    }

    return <Fragment>
        <h5 className="text-center">Liste de colisage {p.pickingId ? "n°" + p.pickingId : ""}</h5>
        <h6 className="text-center">{p.picking && p.picking.exp.business}</h6>

        {(p.picking && p.picking.shipment.detail && p.picking.shipment.detail.length > 0) &&
            p.picking.shipment.detail.map((v, k) => {
                const shipped = hasBeenShipped(v.detail_id);

                return <div className="card" key={"pack" + k}>
                    {loading && <div className="text-end"><ButtonWaiting /></div>}
                    {(!loading && !shipped) &&
                        <button type="button" className="btn-close ms-auto" aria-label="Close"
                            onClick={() => deleteShipping(v.detail_id)}></button>}

                    <h5 className="fw-bold">{"#" + (k + 1) + ": " + v.type} (Dim: {v.pack_width} x {v.pack_height} x {v.truck_height} - Poids: {v.pack_weight})
                        {!shipped && <img src="/common/change.png" className="see-img w-30 ms-3 cursor-pointer" title="Modifier les dimensions"
                            onClick={() => changePackDimension(k)} />}
                    </h5>

                    {!shipped &&
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center fw-bold"></th>
                                    <th className="text-center fw-bold">Qté</th>
                                    <th className="text-center fw-bold">Article</th>
                                    <th className="text-center fw-bold">Désignation</th>
                                    <th className="text-center fw-bold">Référence</th>
                                    <th className="text-center fw-bold">Type de colisage</th>
                                    <th className="text-center fw-bold">Longueur</th>
                                    <th className="text-center fw-bold">Largeur</th>
                                    <th className="text-center fw-bold">Hauteur</th>
                                    <th className="text-center fw-bold">Poids</th>
                                    <th className="text-center fw-bold"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {p.picking.currentPicking.filter(v2 => parseInt(v2.machine) === v.detail_id).map(
                                    (v3, k3) => {
                                        return <tr key={k3}>
                                            <td></td>
                                            <td>{v3.qty}</td>
                                            <td>{v3.ar}</td>
                                            <td>{v3.label}</td>
                                            <td>{v3.ref}</td>
                                            <td>{v3.type}</td>
                                            <td>
                                                <input type="number" className="form-control"
                                                    defaultValue={v3.dim1} style={{ maxWidth: "100px" }}
                                                    onBlur={(e) => changeDim(e, v3, "dim1")} />
                                            </td>
                                            <td>
                                                <input type="number" className="form-control"
                                                    defaultValue={v3.dim2} style={{ maxWidth: "100px" }}
                                                    onBlur={(e) => changeDim(e, v3, "dim2")} />
                                            </td>
                                            <td>
                                                <input type="number" className="form-control"
                                                    defaultValue={v3.dim3} style={{ maxWidth: "100px" }}
                                                    onBlur={(e) => changeDim(e, v3, "dim3")} />
                                            </td>
                                            <td>
                                                <input type="number" className="form-control"
                                                    defaultValue={v3.weight} style={{ maxWidth: "100px" }}
                                                    onBlur={(e) => changeDim(e, v3, "weight")} />
                                            </td>
                                            <td><img src="/common/remove.png" className="see-img w-30"
                                                onClick={() => removeArticle(v3.id)} /></td>
                                        </tr>
                                    }
                                )}
                            </tbody>
                        </table>
                    }

                    <div>
                        {!shipped ?
                            <button className="btn btn-secondary mt-3" onClick={() => { addElement(k) }}>Ajouter des éléments</button>
                            : <div className="fst-italic">{shipped}</div>
                        }

                    </div>
                </div>
            })
        }

        <div className="text-center mt-3 d-flex">
            <div className="ms-auto me-3">
                <Inputs.SubmitBtn label={"Sauvegarder"} loading={p.saving}
                    onClick={() => { p.saveOtherPicking(false); saveArtChange() }} />
            </div>

            <div className="me-auto ms-3">
                <Inputs.SubmitBtn label={"Sauvegarder ET envoyer"} loading={p.saving}
                    onClick={() => { p.saveOtherPicking(true); saveArtChange() }} />
            </div>
        </div>

        {/** ADD SHIPMENT */}
        <form onSubmit={addShipping} className="card" style={{ width: "500px" }}>
            <h6 className="text-center">Ajouter un moyen de transport</h6>

            <Inputs.SelectGroup label="Type de transport" spanWidth="180px"
                name="type" value={newPack.type}
                onChange={(e) => onShippingChange(e, "type")}
                required options={[
                    { value: "Camion", label: "Camion", display: true }, //, display: (!truckChoosen || truckChoosen !== "no")
                    { value: "Palette", label: "Palette", display: true }, //, display: (!truckChoosen || truckChoosen !== "yes")
                    { value: "Caisse", label: "Caisse", display: true },
                    { value: "Colis", label: "Colis", display: true },
                    { value: "Autres", label: "Autres", display: true }
                ]} />

            {newPack.type === "Camion" && <Fragment>
                {/** TYPE DE TRANSPORT */}
                <Inputs.InputGroup spanWidth="180px" label="Camion bâché, chargt." value={newPack.truck_load_side} list="side"
                    onChange={(e) => onShippingChange(e, "truck_load_side")} required />

                <datalist id="side">
                    <option value="Par l'arrière"></option>
                    <option value="Sur le côté"></option>
                </datalist>

                {/** DATE DE CHARGEMENT */}
                <Inputs.InputGroup spanWidth="180px" type="datetime-local" label="Date de chargt."
                    value={getDateTimeFromDb(newPack.truck_load, "en")}
                    onChange={(e) => onShippingChange(e, "truck_load")} required />

                {/** DATE DE DECHARGEMENT */}
                <Inputs.InputGroup spanWidth="180px" type="datetime-local" label="Date de déchargt."
                    value={getDateTimeFromDb(newPack.truck_unload, "en")}
                    onChange={(e) => onShippingChange(e, "truck_unload")} required />

                {/** TYPE DE CAMION */}
                <Inputs.InputGroup spanWidth="180px" label="Type de camion" value={newPack.truck_type}
                    onChange={(e) => onShippingChange(e, "truck_type")} list="truck_type" required />

                <datalist id="truck_type">
                    <option value="TAULINER"></option>
                    <option value="MEGA"></option>
                    <option value="S2C"></option>
                </datalist>

                {/** HAUTEUR DE CAMION */}
                <Inputs.InputGroup spanWidth="180px" label="Hauteur de camion (m)" type="number" value={newPack.truck_height}
                    onChange={(e) => onShippingChange(e, "truck_height")} required />

                {/** NB DE SANGLES */}
                <Inputs.InputGroup spanWidth="180px" label="Nb de sangles" value={newPack.nb_strap}
                    onChange={(e) => onShippingChange(e, "nb_strap")} required />

                {/** POIDS DE LA MARCHANDISE */}
                <Inputs.InputGroup spanWidth="180px" label="Poids marchandise (t)" type="number" value={newPack.truck_weight}
                    onChange={(e) => onShippingChange(e, "truck_weight")} required />

                {/** POIDS DE LA MARCHANDISE */}
                <Inputs.InputGroup spanWidth="180px" label="Mètres linéaires" value={newPack.truck_meter}
                    onChange={(e) => onShippingChange(e, "truck_meter")} required />
            </Fragment>}

            {newPack.type !== "Camion" && <Fragment>
                {/** LONGUEUR */}
                <Inputs.InputGroup spanWidth="180px" label="Longueur (cm)" type="number" value={newPack.pack_width}
                    onChange={(e) => onShippingChange(e, "pack_width")} required />

                {/** LARGEUR */}
                <Inputs.InputGroup spanWidth="180px" label="Largeur (cm)" type="number" value={newPack.pack_height}
                    onChange={(e) => onShippingChange(e, "pack_height")} required />

                {/** HAUTEUR */}
                <Inputs.InputGroup spanWidth="180px" label="Hauteur (cm)" type="number" value={newPack.truck_height}
                    onChange={(e) => onShippingChange(e, "truck_height")} required />

                {/** POIDS */}
                <Inputs.InputGroup spanWidth="180px" label="Poids (kg)" type="number" value={newPack.pack_weight}
                    onChange={(e) => onShippingChange(e, "pack_weight")} required />

                {/** DATE D'ENLEVEMENT */}
                <Inputs.InputGroup spanWidth="180px" label="Date d'enlèvement" type="date" value={newPack.pack_pick_date}
                    onChange={(e) => onShippingChange(e, "pack_pick_date")} required />

                {/** DATE DE LIVRAISON */}
                <Inputs.InputGroup spanWidth="180px" label="Date de livraison" type="date" value={newPack.pack_delivery}
                    onChange={(e) => onShippingChange(e, "pack_delivery")} required />

            </Fragment>
            }


            <Inputs.SubmitBtn label={newPack.detail_id ? "Modifier" : "Ajouter"} loading={loading} />


            <div className="text-center fs-6 text-danger mt-5">
                <a href="#" className="text-reset"
                    onClick={() => setNewPack(_.cloneDeep(newPackEmpty))}>Réinitialiser le formulaire</a>
            </div>
        </form >

        {
            search !== undefined && <SmallFrame close={() => { setSearch(); setAdded(); setSelectedArt([]) }} component={<div>
                <h5 className="text-center">Ajouter</h5>

                <ul class="nav nav-tabs">
                    {/*<li class="nav-item">
                    <a class={"nav-link fw-bold text-dark " + (tab === "packing" ? "active" : "")}
                        onClick={() => changeTab("packing")}>Des emballages</a>
                </li>*/}

                    <li class="nav-item">
                        <a class={"nav-link fw-bold text-dark " + (tab === "articles" ? "active" : "")}
                            onClick={() => changeTab("articles")}>Des articles</a>
                    </li>

                    {added !== undefined &&
                        <li class="nav-item">
                            <a class={"nav-link fw-bold text-dark " + (tab === "choose" ? "active" : "")}
                                onClick={() => changeTab("choose")}>Emplacement</a>
                        </li>
                    }
                </ul>

                {(data !== undefined && data.length > 0 && tab !== "choose") && <Fragment>
                    {selectedArt !== undefined && selectedArt.length > 0 &&
                        <div className="text-center mb-3">
                            <button className="btn btn-success" onClick={addArticle}>Insérer {selectedArt.length > 1 ? "ces articles" : "cet article"}</button>
                        </div>}

                    <ReactTable columns={columns} data={data} origin="picking_ship_other" classes={"no-padding fixFstCol "} top0={true} />
                </Fragment>
                }

                {/*(tab === "choose" && added !== undefined) && <div>
                <h5 className="text-center">Où souhaitez-vous placer {added.toAdd.length > 1 ? "ces éléments" : "cet élément"} ?</h5>

                {added.insertedPacking.map((v, k) => {
                    return <div className="mb-3" style={{ marginLeft: (1 + (Math.max(parseInt(v.level), 1) - 1) * 50) + "px" }}>
                        <button className="btn btn-success text-start" style={{ width: "250px" }}
                            onClick={() => setElement(v)}>#{v.id} - {v.label}</button>
                    </div>
                })}
            </div>*/}

            </div>} />
        }
    </Fragment >

}

export default OtherPacking;