import { Fragment } from "react";
import Inputs from "../../../../common/smallComponents.js/Inputs"
import Home from "./Home";

const Spectifications = ({data}) => {
    
    return <Fragment>
            <h2 className="text-center">{data[0].id_aff} - {data[0].no_machine}</h2>
        <div className="d-flex justify-content-around flex-wrap mt-4">
            <div  className="d-flex flex-column" style={{width:"70%"}}>
                <diV className="card">
                    <h4 className="card-title">Machine</h4>
                    <ul className="list-group list-group-flush">
                        <li class="list-group-item"><Inputs.InputGroup spanWidth="130px" label="Affaire" value={data[0].id_aff}/></li>
                        <li class="list-group-item"><Inputs.InputGroup spanWidth="130px" label="Machine" value={data[0].no_machine}/></li>
                        <li class="list-group-item"><Inputs.InputGroup spanWidth="130px" label="Configuration" value={data[0].type_machine}/></li>
                        { data[0].machine_state && <li class="list-group-item"><Inputs.InputGroup spanWidth="130px" label="Configuration" value={data[0].machine_state}/>}</li> }
                        <li class="list-group-item">{data[0].id_aff}</li>
                        <li class="list-group-item">{data[0].no_machine}</li>
                        <li class="list-group-item">{data[0].type_machine}</li>
                        { data[0].machine_state && <li class="list-group-item">{data[0].machine_state}</li> }
                    </ul>
                </diV>
            </div>
            
            {/* Vérifier s'il y a plusieurs caisses, si c'est le cas, les affichers toutes */}
            {/* Voir exemple 22/1933 */}
            {data.length > 1 && data.map((item, index) => (
                    <div  className="d-flex flex-column" style={{width:"40%"}}>
                        <div className="see-underline mb-3" style={{ width: "70%" }}>
                            <h4>{`Piste ${item.no_piste} - Caisse ${item.no_caisse}`}</h4>
                        </div>
                        <div className="d-flex">
                            <div  className="d-flex flex-column">
                                <Inputs.InputGroup key={index} spanWidth="130px" label={'Type'} value={item.type_caisse}/>
                                <Inputs.InputGroup key={index} spanWidth="130px" label={'Cannelure'} value={item.cannelure_caisse}/>
                                <Inputs.InputGroup key={index} spanWidth="130px" label={'L (int)'} value={item.w_dim_int}/>
                                <Inputs.InputGroup key={index} spanWidth="130px" label={'l (int'} value={item.l_dim_int}/>
                                {item.h_dim_int && <Inputs.InputGroup key={index} spanWidth="130px" label={'H (int'} value={item.h_dim_int}/>}
                                {item.rabat_caisse && <Inputs.InputGroup key={index} spanWidth="130px" label={'Rabat plié en dernier'} value={item.rabat_caisse}/>}
                                {item.particularites_caisse && <Inputs.FloatingTextArea key={index} spanWidth="130px" label={'Particularités'} value={item.particularites_caisse}/>}
                            </div>
                            <div  className="d-flex flex-column">
                                <Inputs.InputGroup key={index} spanWidth="130px" label={'L (ext)'} value={item.w_dim_ext}/>
                                <Inputs.InputGroup key={index} spanWidth="130px" label={'l (ext)'} value={item.l_dim_ext}/>
                                {item.h_dim_ext && <Inputs.InputGroup key={index} spanWidth="130px" label={'H (ext)'} value={item.h_dim_ext}/>}
                                {item.haut_ext_mini && <Inputs.InputGroup key={index} spanWidth="130px" label={'H ext (mini)'} value={item.haut_ext_mini}/>}
                                {item.haut_ext_maxi && <Inputs.InputGroup key={index} spanWidth="130px" label={'H ext (maxi)'} value={item.haut_ext_maxi}/>}
                                {item.haut_ext_brut && <Inputs.InputGroup key={index} spanWidth="130px" label={'H ext (brut)'} value={item.haut_ext_brut}/>}
                            </div>
                        </div>
                    </div>
                
            ))}
            <Inputs.InputGroup spanWidth="130px" label="Coiffe" value={data[0].cannelure_coiffe}/>
            <Inputs.InputGroup spanWidth="130px" label="Equipements" value={data[0].type_api}/>
            
        </div>
    </Fragment>
}

export default Spectifications;