import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL +"bom/v3";
const user = JSON.parse(localStorage.getItem("user"));

const readBOM = ( o ) => {
    return axios
    .post(API_URL + "/temp/read",
    {
        fake_header: authHeader(),
        ...o
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const saveTempBOM = ( bom, mode ) => {
    return axios
    .post(API_URL + "/temp/save",
    {
        fake_header: authHeader(),
        bom: bom,
        mode: mode
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const deleteBOM = ( machine_info, type_of_bom ) => {
    return axios
    .post(API_URL + "/temp/delete",
    {
        fake_header: authHeader(),
        machine_info: machine_info,
        type_of_bom: type_of_bom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const compareTempBOM = ( bom, machine, source, business, type_of_bom, machine_info ) => {
    return axios
    .post(API_URL + "/temp/compare",
    {
        fake_header: authHeader(),
        bom: bom,
        machine: machine,
        source: source,
        business: business,
        type_of_bom: type_of_bom,
        machine_info: machine_info
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const notifyInjectedBOM = ( bom_planning, machine_info, type_of_bom, recipient ) => {
    return axios
    .post(API_URL + "/temp/notify",
    {
        fake_header: authHeader(),
        bom_planning: bom_planning,
        machine_info: machine_info,
        type_of_bom: type_of_bom,
        recipient: recipient
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

const getIntranetBom = ( machine, type_of_bom ) => {
    return axios
    .post(API_URL + "/intranet/get",
    {
        fake_header: authHeader(),
        machine: machine,
        type_of_bom: type_of_bom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}


const updateIntranetBom = ( changes, machine_info, type_of_bom, bom ) => {
    return axios
    .post(API_URL + "/intranet/update",
    {
        fake_header: authHeader(),
        changes: changes,
        machine_info: machine_info,
        type_of_bom: type_of_bom,
        bom: bom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}


const checkArticles = ( bom ) => {
    return axios
    .post(API_URL + "/check-articles",
    {
        fake_header: authHeader(),
        bom: bom
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}


const createArticle = ( art, force_creation, machine_info ) => {
    return axios
    .post(API_URL + "/create-article",
    {
        fake_header: authHeader(),
        art: art,
        force_creation: force_creation,
        machine_info: machine_info
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}


const firstInjectionFocus = ( type_of_bom, bom, machine_info, step, insert_key, need_date ) => {
    return axios
    .post(API_URL + "/inject/initial",
    {
        fake_header: authHeader(),
        type_of_bom: type_of_bom,
        bom: bom,
        machine_info: machine_info,
        step: step,
        insert_key: insert_key,
        need_date: need_date
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}


const updateFocusBom = ( type_of_bom, bom, machine_info, step, insert_key, need_date ) => {
    return axios
    .post(API_URL + "/inject/update",
    {
        fake_header: authHeader(),
        type_of_bom: type_of_bom,
        bom: bom,
        machine_info: machine_info,
        step: step,
        insert_key: insert_key,
        need_date: need_date
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}


const updateBomStatus = ( bom_planning ) => {
    return axios
    .post(API_URL + "/status/update",
    {
        fake_header: authHeader(),
        bom_planning: bom_planning
    },
    { headers: textPlainHeader() })
    .then((response) => {
        return response.data;
    });
}

export default {
    readBOM,
    saveTempBOM,
    compareTempBOM,
    deleteBOM,
    notifyInjectedBOM,
    getIntranetBom,
    updateIntranetBom,
    checkArticles,
    createArticle,
    firstInjectionFocus,
    updateBomStatus,
    updateFocusBom
}