import { useEffect } from "react";
import BusinessDao from "../../services/machines/BusinessDao";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop } from "../../functions/PageAnimation";
import { useState } from "react";
import { Fragment } from "react";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage from "../../common/smallComponents.js/ErrorMessage";
import Moment from "moment";
import { confirmAlert } from "react-confirm-alert";

const MySpace = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const [business, setBusiness] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const canWrite = user && ( user.roles.includes("ROLE_WRITE_FM") || user.roles.includes("ROLE_ADMIN") )

    Moment.locale("fr");

    useEffect(() => {
        searchByResponsible(user.trigram || "");
    }, []);

    const searchByManager = (e) => {
        searchByResponsible(e);
    }

    const searchByTerm = (e) => {
        e.preventDefault();

        setLoading(true);
        setBusiness();

        BusinessDao.searchByTerm(e.target.term.value, true).then(
            (response) => {
                setBusiness(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

    }

    const searchByResponsible = (term) => {
        setLoading(true);
        setBusiness();

        BusinessDao.searchByResp(term, false).then(
            (response) => {
                setBusiness(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
                setLoading(false);
            }
        );

    }

    const createBusiness = (e) => {
        e.preventDefault();
        var business = e.target.business.value;

        confirmAlert({
            title: "Création de l'affaire " + business,
            message: "Confirmez-vous la création de la fiche affaire " + business,
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        BusinessDao.createBusiness(business).then(
                            (response) => {
                                window.location.href = "/business?code=" + business;
                            },
                            (error) => {
                                setError(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const markAsSent = (k) => {
        var arr = [...business];
        arr[k].sent = 1;
        setBusiness(arr);

        BusinessDao.updateBusinessField(arr[k].id_aff, "sent", "1", "number", false);
    }

    return <Fragment>
        <h4 className="text-center">Espace affaire</h4>

        {loading && <WaitingRoundSnippers />}
        {error && <ErrorMessage error={error} errorState={setError} />}

        <div className="card" style={{ marginTop: "0px" }}>
            <div className="card-body">
                <div className="display-flex" style={{ flexWrap: "wrap" }}>
                    <div style={{width: "240px"}}>Recherche par chef de projet: </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("CBA")}>Bakyono Crépin</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("ABE")}>Benadidou Ahmed</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("BIB")}>Ben Ayad Bilal</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("ETB")}>Bourin Etienne</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("JOB")}>Breithel Jordan</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("DSC")}>Schubert Daniela</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("BTV")}>Tena Villar Beatriz</a></div>
                </div>

                <div className="display-flex" style={{ flexWrap: "wrap" }}>
                    <div style={{width: "240px"}}>Recherche par responsable MeS: </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("HDI")}>Diakho Hamady</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("LUM")}>Moity Lucas</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("BCH")}>Chilini Bastien</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("IME")}>Mettiji Issam</a> - </div>
                    <div style={{ marginLeft: "5px" }}><a href="#" onClick={() => searchByManager("SIM")}>Imani Souleimani</a></div>
                </div>
            </div>

            <form onSubmit={searchByTerm}>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">Autre recherche</span>
                    <input type="text" className="form-control" name="term"
                        placeholder="N° d'affaire, n° de machine, type de config" />
                    <button className="btn btn-success">Rechercher</button>
                </div>
            </form>

            <div className="display-flex">
                {
                    (user && user.roles.includes("ROLE_WRITE_FM")) &&
                    <div style={{ width: "600px", marginRight: "10px" }}>
                        <form onSubmit={createBusiness}>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Créer affaire</span>
                                <input type="text" className="form-control" name="business" 
                                    placeholder="Saisissez le code affaire à créer" onChange={(e) => e.target.value = e.target.value.toUpperCase()}/>
                                <button className="btn btn-outline-success" disabled={loading}>
                                    {loading && <ButtonWaiting />}
                                    Créer
                                </button>
                            </div>
                        </form>
                    </div>
                }
                <div className="accordion" id="accordionExample" style={{ width: "100%" }}>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Légende
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="bg-info bg-gradient fw-bold mb-3" style={{ padding: "5px", borderRadius: "0.5em" }}>
                                    Affaires totalement facturées
                                </div>

                                <div className="bg-success bg-gradient text-white fw-bold mb-3" style={{ padding: "5px", borderRadius: "0.5em" }}>
                                    Affaires notifiées par mail
                                </div>

                                <div className="fw-bold mb-3" style={{ padding: "5px", borderRadius: "0.5em" }}>
                                    <img src={"/common/truck.png"}
                                        style={{ width: "30px", marginLeft: "auto", marginRight: "10px" }}></img>
                                    Affaires totalement livrées
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="accordion" id="accordionDiv" style={{ display: "flex", flexWrap: "wrap" }}>

            {
                business && business.map((v, k) => {
                    return <div className="accordion-item " style={{ width: "33.3%", minWidth: "300px" }} key={"b" + k}>
                        <h2 className="accordion-header " id={"heading" + k}>
                            <button className={"accordion-button collapsed fw-bold "
                                + (v.not_billed === undefined ? "bg-info bg-gradient" :
                                    v.sent === 1 ? "bg-success bg-gradient text-white" : "")}
                                type="button" data-bs-toggle="collapse"
                                data-bs-target={"#collapse" + k} aria-expanded="false" aria-controls={"collapse" + k}>
                                {v.id_aff}&nbsp;{v.date_commande !== null && <span>({v.date_commande})</span>}
                                {
                                    v.delivery_date === true && <img src={"/common/truck.png"}
                                        style={{ width: "30px", marginLeft: "auto" }}></img>
                                }
                            </button>

                        </h2>
                        <div id={"collapse" + k} className="accordion-collapse collapse"
                            aria-labelledby={"heading" + k} data-bs-parent="#accordionDiv">
                            <div className="accordion-body fw-bold">
                                <div className="mb-3">
                                    <a href={"/business?code=" + v.id_aff}>Fiche affaire</a>
                                </div>

                                {
                                    v.machines && v.machines.split(",").map((v2, k2) => {
                                        return <div className="mb-3">
                                            <a href={"/machine?machine=" + v2.split(" ")[0].trim()}>{v2}</a>
                                        </div>

                                    })
                                }

                                { (v.not_billed !== undefined && v.sent !== 1 && canWrite) && 
                                    <div className="mt-auto text-center">
                                        <button className="btn btn-success"
                                            onClick={()=>markAsSent(k)}>Marquer comme envoyé</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                })

            }

        </div>






    </Fragment>

}

export default MySpace;