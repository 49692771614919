import { Fragment, useRef, useState } from "react";
import { removeAndReturnArray, removeEltArray } from "../../../../functions/ArrayFunctions";
import { ButtonWaiting } from "../../../../functions/PageAnimation";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MachineDraggableRow from "./MachineDraggableRow";
import { forceString } from "../../../../functions/StrFunctions";
import transport from "../../../../services/supplyChain/transport";

const MachPacking = (p) => {
    const [cursor, setCursor] = useState(-1);
    const [toAdd, setToAdd] = useState();
    const [list, setList] = useState([]);
    const [artToChange, setArtToChange] = useState([]);
    const focus_field = {
        "dim1": { focus_field: "DIM1L", label: "Dimension 1" },
        "dim2": { focus_field: "DIM2L", label: "Dimension 2" },
        "dim3": { focus_field: "DIM3L", label: "Dimension 3" },
        "weight": { focus_field: "POIDL", label: "Poids" }
    }

    /**
     * Insert Row
     */
    const insert = (e) => {
        e.preventDefault();

        var arr = [...p.picking.machines];
        var container = parseInt(e.target.container.value);

        var full = [...p.picking.machines[toAdd.k].fullPicking];
        var machineContent = arr[toAdd.k].picking;
        var newMachContent = [];

        var lvl = container === -1 ? 1 : machineContent[toAdd.k2].level + 1;

        //Add the articles in the machine object
        for (let i = 0; i < machineContent.length; i++) {
            newMachContent.push(machineContent[i]);
            //Add the articles after it
            if (i === toAdd.k2) {
                for (let j = 0; j < list.length; j++) {
                    var o = { ...full[parseInt(list[j])] };
                    o.level = lvl;
                    newMachContent.push(o);
                }
            }
        }
        arr[toAdd.k].picking = newMachContent;

        resetAndSaveChange(arr);
    }

    const insertSpecific = (e) => {
        e.preventDefault();

        var arr = [...p.picking.machines];
        var container = parseInt(e.target.container.value);
        var machineContent = arr[toAdd.k].picking;
        var newMachContent = [];

        var o = {
            "av": "MA_OPT000_00001",
            "ar": "PA_SPE00_00001",
            "label": e.target.label.value,
            "type": e.target.type.value,
            "machine": arr[toAdd.k].machine.no_machine,
            "dim1": 0,
            "dim2": 0,
            "dim3": 0,
            "weight": 0,
            "qty": 1,
            "level": container === -1 ? 1 : machineContent[toAdd.k2].level + 1
        }

        //Add the articles in the machine object
        for (let i = 0; i < machineContent.length; i++) {
            newMachContent.push(machineContent[i]);
            //Add the article after it
            if (i === toAdd.k2) newMachContent.push(o);
        }
        arr[toAdd.k].picking = newMachContent;

        resetAndSaveChange(arr);

    }

    /**
     * Delete Row
     */
    const deleteRow = (k, k2) => {
        var arr = [...p.picking.machines];
        arr[k].picking = removeAndReturnArray(arr[k].picking, k2)
        resetAndSaveChange(arr);
    }

    /**
     * Common: reset ID and rank then save in state
     * @param {*} arr 
     */
    function resetAndSaveChange(arr) {
        // Reset the ids
        var id = 1, rank = 1;
        for (let i = 0; i < arr.length; i++) {
            var picking = arr[i].picking;

            // Set the new id and rank
            for (let j = 0; j < picking.length; j++) {
                picking[j].id = id++;
                picking[j].rank = rank++;
            }
        }

        // Update the state
        var o = { ...p.picking };
        o.machines = arr;
        p.setPicking(o)

        setToAdd();
        setList([]);

        p.setChangeDone(true);
    }

    function getTree() {
        var tree = [];

        var arr = [...p.picking.machines];

        var machine = { ...arr[toAdd.k] };
        var picking = [...machine.picking];

        for (let i = toAdd.k2; i >= 0; i--) {
            tree.push(picking[i]);
            //If we are in the top level add it and stop the search
            if (picking[i].level === 1) break;
        }

        return tree;
    }

    const updateField = (e, k, k2, field) => {
        var arr = [...p.picking.machines];

        var machine = { ...arr[k] };
        var picking = [...machine.picking];

        //Chekc change
        var o2 = {
            art: picking[k2].ar.trim(),
            field: focus_field[field].focus_field,
            label: focus_field[field].label,
            value: e.target.value
        }

        var change = [...artToChange]

        var idx = change.findIndex(a => a.art.trim() === o2.art && a.field === o2.field);

        // If never changed add
        if (idx === -1) {
            o2.old_val = picking[k2][field]
            change.push(o2);
        } else {
            // if the change is only coming back to initial value, remove
            if (forceString(change[idx].old_val) === forceString(o2.value)) {
                change = removeAndReturnArray(change, idx);
            } else {
                o2.old_val = change[idx].old_val;
                change[idx] = o2
            }
        }

        setArtToChange(change);

        //Set change
        var o = { ...p.picking };
        picking[k2][field] = e.target.value;
        o.machines = arr;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const deleteMachine = (k) => {
        var o = { ...p.picking };
        var machines = [...o.machines];
        machines[k].picking = [];
        o.machines = machines;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const changeComment = (e) => {
        var val = e.target.value;
        var o = { ...p.picking };
        o.picking_head.comments = val;
        p.setPicking(o);

        p.setChangeDone(true);
    }

    const checkToAdd = (e, k) => {
        var arr = [...list];
        if (e.target.checked) { arr.push(k); }
        else { arr = removeEltArray(arr, k); }

        setList(arr);
    }

    const selectContainer = (id, label) => {
        const inputs = document.querySelectorAll('input.expedition_container');
        inputs.forEach(input => input.value = id);
        setToAdd((prevState) => ({ ...prevState, step: 1, id: id, label: label }));
    }

    /**
     * k2 = final position
     * toIndex = initial position
     * level = level of the element to insert inside or before or after
     * position = where to insert 
     * 
     */
    const moveRow = (k, k2, toIndex, level, position) => {
        var picking = { ...p.picking }
        var updatedRows = [...picking.machines[k].picking];

        if (position === "inside") {
            level++;
        } else {
            level = 1;//Math.max( 0, parseInt( level - 1 ) ); 
        }

        updatedRows[toIndex].level = level;
        const [movedRow] = updatedRows.splice(toIndex, 1);

        if ((position === "inside" && parseInt(k2) <= toIndex) || (position === "after" && k2 < toIndex)) {
            k2 += 1;
        } else if (position === "before" && k2 > toIndex) {
            k2 -= 1;
        }

        updatedRows.splice(k2, 0, movedRow);

        // rewrite the rank fields
        var rank = 0;
        for (let i = 0; i < updatedRows.length; i++)
            updatedRows[i].rank = rank++;

        picking.machines[k].picking = updatedRows;
        p.setPicking(picking);
    };



    const saveArtChange = () => {
        transport.updateArtDimensions(artToChange);
    }

    return <Fragment>
        {p.tab === "picking" &&
            <h5 className="text-center">Picking list {p.pickingId ? "n°" + p.pickingId : ""}
                - {p.picking && p.picking.businessFocus.NOCDE}</h5>}

        {p.tab === "packing" &&
            <h5 className="text-center">Packing list {p.pickingId ? "n°" + p.pickingId : ""}
                - {p.picking && p.picking.businessFocus.NOCDE}</h5>}

        {p.picking && <Fragment>

            {p.picking.machines.map((v, k) => {
                return v.picking.length > 0 && <div key={"machine" + k} className="mb-3">
                    <h5>Machine <a href={"/machine?machine=" + v.machine.no_machine} target="_blank">{v.machine.no_machine}</a> ({v.machine.typ_config})
                        <img src="/common/remove_icon.png" onClick={() => deleteMachine(k)}
                            className="no-print cursor-pointer ms-2" style={{ width: "20px" }} />
                    </h5>
                    <DndProvider backend={HTML5Backend}>
                        <table className="table">
                            <thead className="text-center">
                                <tr>
                                    {/*<th style={{ width: "30px" }}></th>*/}
                                    <th scope="col" colSpan={3} className="bg-see text-white">ID</th>
                                    <th scope="col" className="bg-see text-white">Qté</th>
                                    <th scope="col" className="bg-see text-white">Article</th>
                                    <th scope="col" className="bg-see text-white">Désignation</th>
                                    <th scope="col" className="bg-see text-white">Type de colisage</th>
                                    {p.tab === "packing" && <Fragment>
                                        <th scope="col" className="bg-see text-white">Longueur</th>
                                        <th scope="col" className="bg-see text-white">Largeur</th>
                                        <th scope="col" className="bg-see text-white">Hauteur</th>
                                        <th scope="col" className="bg-see text-white">Poids</th>
                                    </Fragment>}
                                    <th scope="col" className="no-print bg-see text-white"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {v.picking.map((v2, k2) => {
                                    return <Fragment>
                                        <MachineDraggableRow key={k2} updateField={updateField} betweenTwo={true}
                                            index={k2} deleteRow={deleteRow} v2={v2} k={k} k2={k2} moveRow={moveRow}
                                            cursor={cursor} setCursor={setCursor} position={"before"} />

                                        <MachineDraggableRow key={k2} updateField={updateField} betweenTwo={false}
                                            index={k2} deleteRow={deleteRow} v2={v2} k={k} k2={k2} moveRow={moveRow}
                                            cursor={cursor} setCursor={setCursor} picking={v.picking} />

                                        <MachineDraggableRow key={k2} updateField={updateField} betweenTwo={true}
                                            index={k2} deleteRow={deleteRow} v2={v2} k={k} k2={k2} moveRow={moveRow}
                                            cursor={cursor} setCursor={setCursor} position={"after"} />
                                    </Fragment>
                                })}
                            </tbody>
                        </table>

                    </DndProvider>

                </div>
            })}

            <div className="form-floating col-6 offset-3 mb-3">
                <textarea className="form-control" placeholder="xxx" id="floatingTextarea2"
                    style={{ height: "100px", padding: "1rem .75rem" }} onBlur={changeComment} ></textarea>
                <label htmlFor="floatingTextarea2">Remarques</label>
            </div>

            <div className="text-center">
                <button className="btn btn-secondary" disabled={p.saving} 
                    onClick={() => { p.savePicking(false); saveArtChange() }}>
                    {p.saving && <ButtonWaiting />}
                    Sauvegarder
                </button>

                {p.tab === "picking" &&
                    <button className="btn btn-success ms-3" disabled={p.saving} 
                        onClick={() => { p.savePicking(true); saveArtChange() } }>
                        {p.saving && <ButtonWaiting />}
                        Sauvegarder et envoyer un mail au SLOG
                    </button>}
            </div>

            {toAdd && <div className="custom-tooltip-v2">
                <div className="modal-body xl blue-purple-bg">
                    <button type="button" className="btn-close" onClick={() => setToAdd()}></button>

                    <h5 className="text-center">Insérer un élément à la machine {p.picking.machines[toAdd.k].machine.no_machine}</h5>

                    <form onSubmit={insert} style={{ width: "800px" }} className="ms-auto me-auto">

                        {/** CHOOSE WHERE THE ARTICLE WILL PLACED */}
                        {toAdd.step === 0 && <Fragment>
                            <h6 className="text-center mb-3">Où désirez-vous placer l'élément ?</h6>

                            <div className="mb-3 text-center">
                                <button type="button" className="see-btn" style={{ width: "400px" }}
                                    onClick={() => selectContainer(-1)}>Machine {p.picking.machines[toAdd.k].machine.no_machine}</button>
                            </div>

                            {getTree().map((v, k) => {
                                return <div className="mb-3 text-center" key={"to_add_other_lvl" + k}>
                                    <button type="button" className="see-btn" style={{ width: "400px" }}
                                        onClick={() => selectContainer(v.id, v.label)}>
                                        <strong>#{v.id}</strong>: {v.ar} - {v.label}
                                    </button>
                                </div>
                            })}

                        </Fragment>}

                        <input type="hidden" name="container" className="expedition_container"
                            defaultValue={p.tab === "picking" ? "-1" : ""} />

                        {/** CHOOSE THE ARTICLE */}
                        {toAdd.step > 0 && <Fragment>
                            <h6 className="text-center">Choisissez un ou plusieurs articles à mettre dans&nbsp;
                                {toAdd.id === 0 ? "la machine" : toAdd.label}
                                <img src="/common/edit.png" className="ms-2 cursor-pointer" style={{ width: "20px" }}
                                    title="Changer de container" onClick={() => setToAdd((prev) => ({ ...prev, step: 0 }))} />
                            </h6>

                            <div style={{ height: "300px" }} className="overflow-y-auto mb-3">
                                <ul className="list-group">
                                    {p.picking.machines[toAdd.k].fullPicking.map((v, k) => {
                                        return <li className="list-group-item" key={"prop" + k}>
                                            <input type="checkbox" className="me-2" onChange={(e) => checkToAdd(e, k)}
                                                checked={list.includes(k)} />
                                            {v.ar + " - " + v.label + " (" + v.type + ")"}
                                        </li>
                                    })}

                                </ul>

                            </div>

                            {list.length > 0 && <div className="text-center">
                                <button className="btn btn-success">Ajouter</button>
                            </div>}

                        </Fragment>}

                    </form>

                    <form onSubmit={insertSpecific} className="ms-auto me-auto" style={{ width: "800px" }}>
                        <input type="hidden" name="container" className="expedition_container"
                            defaultValue={p.tab === "picking" ? "-1" : ""} />

                        {toAdd.step > 0 && <Fragment>
                            <h6 className="text-center">Ou ajoutez un élément spécifique</h6>
                            <div className="input-group mb-3">

                                <span className="input-group-text">Désignation</span>
                                <input type="text" name="label" className="form-control" />

                                <label className="input-group-text">Type de colisage</label>
                                <select className="form-select" name="type" required>
                                    <option value="">Choose...</option>
                                    <option value="Caisse">Caisse</option>
                                    <option value="Carton">Carton</option>
                                    <option value="Chevalet">Chevalet</option>
                                    <option value="Complet">Complet</option>
                                    <option value="Palette">Palette</option>
                                    <option value="Article">Article</option>
                                </select>

                                <button className="btn btn-success">Ajouter</button>
                            </div>
                        </Fragment>}
                    </form>

                </div>
            </div>}

        </Fragment>}

    </Fragment>

}

export default MachPacking;