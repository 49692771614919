
/**
 * 
 * @param {*} props 
 * @param {*} err 
 * @param {*} document to go top
 * @returns error message if exists
 */
const handleError = (props, err, document) => {
    
    localStorage.setItem("url_back", window.location.pathname + window.location.search);
    
    if( err.response.status === undefined || err.response.status === null )
        return "Undefined error occured.";

   
    if(err.response.status === 401 || err.response.status === 403){
        props.history.push('/login');
        //<Redirect to="/login"/>;
        return "Need to authenticate";
    }
    if( err.response ){
        if( err.response.data )
            return err.response.data;
    }

    return  err.message ? err.message : err.response ? 
        err.response.data.message :
        err.message;
}

const getErrorMsg = ( err ) => {
    
    localStorage.setItem("url_back", window.location.pathname + window.location.search);
    
    if( err.response.status === undefined || err.response.status === null )
        return "Undefined error occured.";

   
    if(err.response.status === 401 || err.response.status === 403)
        return "Need to authenticate";
    
    if( err.response ){
        if( err.response.data )
            return err.response.data;
    }

    return  err.message ? err.message : err.response ? 
        err.response.data.message :
        err.message;
}

const needConnection = (props) => {
    localStorage.setItem("url_back", window.location.pathname + window.location.search);
    props.history.push('/login');
}

export default {
    handleError,
    needConnection,
    getErrorMsg
}