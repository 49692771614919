import { Fragment, useEffect, useState } from "react";
import parse from "paste-from-excel";
import { arrayRemoveElt, removeAndReturnArray, removeEltArray } from "../../../functions/ArrayFunctions";
import TableExcelPaste from "../../../common/smallComponents.js/TableExcelPaste";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import { isAtLeastOneNotEmpty, isAtLeastOneNotEmptyExcept, isObjectNotEmpty, isValidDate } from "../../../functions/StrFunctions";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";
import CsContracts from "../../../services/customClient/CsContracts";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import { isDate } from "moment/moment";
import BusinessDao from "../../../services/machines/BusinessDao";
import AddressChoice from "../../../common/smallComponents.js/AddressChoice";

const NewMesPicking = (p) => {
    const [label, setlabel] = useState({ labels: ["Articles", "Machine", "Qté"] });
    const [inputvalue, setinputvalue] = useState({ inputs: [] });
    const [err, setErr] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState("");
    const [mode, setMode] = useState("creation");
    const [resume, setResume] = useState([]);
    const [notifyPurchase, setNotifyPurchase] = useState([]);
    const [addressBook, setAddressBook] = useState();
    const [contact, setContact] = useState(false);
    
    useEffect(() => {
        var cpt = 0;
        var arr = [];

        for (let i = 0; i < p.newPicking.length; i++) {
            arr.push({
                "Articles": p.newPicking[i]["Articles"],
                "Qté": p.newPicking[i]["Qté"],
                "Machine": p.newPicking[i]["Machine"],
            });
            cpt++;
        }

        intiTable(cpt);

        //Get all contacts
        BusinessDao.getAddressBook().then(
            (r) => {
                setAddressBook(r);
            }, (error) => {
                console.error(error);
            }
        )
    }, []);


    /**
    * Clear table
    */
    const intiTable = (cpt = 0) => {
        var arr = new Array();
        for (let i = cpt; i < 200; i++)
            arr.push({ "Articles": "", "Qté": "", "Machine": "" });

        setinputvalue({ inputs: arr })
    }

    function controlInputs() {
        setErr();
        var arr = [];
        var err = "";

        var filtered_arr = inputvalue.inputs.filter( a => a["Articles"] !== "" || a["Qté"] !== "" || a["Machine"] !== ""  )

        //Check if the first row is complete
        for (let i = 0; i < filtered_arr.length; i++) {
            var o = filtered_arr[i];

            // Check if we can save the row
            if (!isEmpty(o)) {
                //All fields are required
                if (o["Articles"].trim() === "") {
                    err += "Remplissez le code article à la ligne " + (i + 1) + "<br>"
                } else if (o["Qté"].trim() === "") {
                    err += "Remplissez la quantité à la ligne " + (i + 1) + "<br>"
                } else if (o["Machine"].trim() === "") {
                    err += "Remplissez le n° de machine à la ligne " + (i + 1) + "<br>"
                } else {
                    arr.push(o);
                }
            }
        }

        return { err: err, arr: arr };
    }

    function isEmpty( o ){ return o["Articles"] === "" || o["Qté"] === "" || o["Machine"] === "" }

    const check = () => {
        var o = controlInputs();
        var err = o.err;
        var arr = o.arr;

        if (err !== "") {
            setErr(err);
            scrollToTop();
            return;
        } else if (arr.length === 0) {
            setErr("Il n'y a rien à envoyer !");
            scrollToTop();
            return;
        } else {
            setLoading(true);

            CsContracts.checkCommissioningPicking(p.business, arr).then(
                (response) => {
                    console.log(response)
                    setResume(response);
                    setMode("confirm");


                    scrollToTop();
                    setLoading(false);
                },
                (error) => {
                    setErr(errorManagement.handleError(p.props, error));
                    setLoading(false);
                    scrollToTop();
                }
            )
        }

    }

    function saveNeeds(arr) {
        var err = "";
       
        //Check date
        if (!isValidDate(date)) err += "Veuillez saisir la date à laquelle vous voulez les pièces<br>";
        if(isAtLeastOneNotEmptyExcept(p.address, ["additionalAd"]))
            err += "Saisissez toutes les informations de livraison (suite facultative)<br>";
        
        for( let i = 0; i<resume.length; i++ ){
            if( getStck( resume[i] ) <= 0 && !notifyPurchase.includes(i) )
                err += "L'article " + resume[i].CDARL + " est insuffisant en stock, veuillez cocher à commander";
        }

        if (err !== "") {
            setErr(err);
            scrollToTop();
            return;
        }   
    
        setLoading(true);

        CsContracts.saveCommissioningPicking(p.business, resume, date, notifyPurchase, p.address).then(
            (response) => {
                var saved = response.saved;
                var err = response.error;

                if (saved.length > 0) {
                    var msg = ""
                    for (let i = 0; i < saved.length; i++)
                        msg += saved[i].CDARL + " pour " + saved[i]['Machine'] + ": OK<br>"

                    setMsg(msg);
                }

                if (err.length > 0) {
                    var msg = ""
                    for (let i = 0; i < err.length; i++)
                        msg += err[i].CDARL + " pour " + err[i]['Machine'] + ": " + err[i].error + "<br>"

                    setErr("Erreur:<br>" + msg);
                }

                scrollToTop();
                setLoading(false);
            },
            (error) => {
                setErr(errorManagement.handleError(p.props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    function getStck(v) {
        var av = parseFloat(v.QTSTL);
        if (v.QTREL) av -= v.QTREL;
        if (v.QTCDL) av += v.QTCDL;
        return av;
    }

    const updateAddress = (e, field) => {
        var o = {...p.address};
        o[field] = e.target.value;
        p.setAddress(o);
    }

    const chooseContact = (e) => {
        var ct = addressBook[e]
        p.setAddress({
        firstName: ct.project_man_fn, lastName: ct.project_man_ln , phone: ct.project_man_mobile, 
        email: ct.project_man_mail, 
        company: ct.company, address: ct.address, additionalAd: ct.add_address || "", 
        post_c: ct.postal_code, country: ct.country, city: ct.city
        });

        setContact(false);
    }

    const toOrder = (e, k) => {
        var arr = [...notifyPurchase];

        if( e.target.checked ){ arr.push( k ); }
        else{ arr = removeEltArray(arr, k); }

        setNotifyPurchase(arr);
        console.log(arr)
    }

    return <div>
        {err && <ErrorMessage error={err} errorState={setErr} />}
        {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

        {mode === "creation" && <Fragment>
            <TableExcelPaste inputvalue={inputvalue} setinputvalue={setinputvalue} label={label} intiTable={intiTable} />

            <div className="text-center mt-3">
                <button className="btn btn-success" onClick={check} disabled={loading}>
                    {loading && <ButtonWaiting />}
                    Vérifier</button>
            </div>
        </Fragment>}

        {mode === "confirm" && <div>
            <h6 className="text-center">Récapitulatif</h6>

            <table className="table text-center">
                <thead>
                    <tr>
                        <th>Machine</th>
                        <th>Article</th>
                        <th>Ref</th>
                        <th>Qté à envoyer</th>
                        <th>Qté dispo</th>
                        <th>Blocage</th>
                        <th>Substitution possible</th>
                        <th>A commander</th>
                    </tr>
                </thead>
                <tbody>
                    {resume.map((v, k) => {
                        return <tr>
                            <td>{v["Machine"]}</td>
                            <td>{v.CDARL}</td>
                            <td>{v.ZN02PA}</td>
                            <td>{v["Qté"]}</td>
                            <td>{getStck(v)}</td>
                            <td className="text-danger fw-bold">{v.BLOCL !== "N" ? "Bloqué" : ""}</td>
                            <td>{v.ZN08PA}</td>
                            <td>
                                <input type="checkbox" onChange={(e) => toOrder(e, k)}/>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>

            <div className="text-center">
                <button className="btn btn-outline-warning"
                    onClick={() => { setMode("creation"); setResume([]) }}>Modifier</button>
            </div>

            <div className="text-center mt-3 card">
                <div className="input-group mb-3 ms-auto me-auto" style={{ width: "800px" }}>

                    <span className="input-group-text">Pour quand avez-vous besoin de ces pièces ?</span>
                    <input className="form-control ms-auto me-auto" type="date" style={{ width: "200px" }}
                        onChange={(e) => setDate(e.target.value)} />
                </div>

                <div className="mb-3 ms-auto me-auto" style={{ width: "800px" }}>
                    {addressBook &&
                        <img className="ms-2 see-img w-30 cursor-pointer" src="/common/contacts.png"
                            onClick={() => setContact(true)} />}

                    <div class="input-group mb-3">
                        <span class="input-group-text" style={{width: "130px"}}>Raison sociale</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "company")}
                            value={p.address.company}/>
                        <span class="input-group-text" style={{width: "80px"}}>Adresse</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "address")}
                            value={p.address.address}/>
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text" style={{width: "130px"}}>Suite</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "additionalAd")}
                            value={p.address.additionalAd}/>
                        <span class="input-group-text" style={{width: "80px"}}>CP</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "post_c")}
                            value={p.address.post_c}/>
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text" style={{width: "130px"}}>Ville</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "city")}
                            value={p.address.city}/>
                        <span class="input-group-text" style={{width: "80px"}}>Pays</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "country")}
                            value={p.address.country}/>
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text" style={{width: "130px"}}>Nom</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "lastName")}
                            value={p.address.lastName}/>
                        <span class="input-group-text" style={{width: "80px"}}>Prénom</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "firstName")}
                            value={p.address.firstName}/>
                    </div>
                    
                    <div class="input-group mb-3">
                        <span class="input-group-text" style={{width: "130px"}}>Téléphone</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "phone")}
                            value={p.address.phone}/>
                        <span class="input-group-text" style={{width: "80px"}}>E-mail</span>
                        <input type="text" class="form-control" onChange={(e)=>updateAddress(e, "email")}
                            value={p.address.email}/>
                    </div>
                </div>

                <div className="text-center">
                    <button className="btn btn-success" onClick={saveNeeds} disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Envoyer</button>
                </div>
            </div>
        </div>}

        <AddressChoice contact={contact} setContact={setContact} chooseContact={chooseContact} addressBook={addressBook}/>


    </div>

}

export default NewMesPicking;