import { confirmAlert } from "react-confirm-alert";
import BusinessDao from "../../../../services/machines/BusinessDao";
import { useEffect, useState } from "react";
import errorManagement from "../../../../services/errorManagement";
import ErrorMessage from "../../../../common/smallComponents.js/ErrorMessage";
import WaitingRoundSnippers from "../../../../common/smallComponents.js/WaitingRoundSnippers";
import SuccessMessage from "../../../../common/smallComponents.js/SuccessMessage";
import { ButtonWaiting, scrollToTop } from "../../../../functions/PageAnimation";
import { diffTwoTimeInFr, getFrFormat, getLabelFromInput } from "../../../../functions/StrFunctions";
import Miscellaneous, { logObj } from "../../../../services/common/Miscellaneous";
import { SmallFrame } from "../../../../common/smallComponents.js/CustomDivs";

const PartSix = (props) => {
    const [err, setErr] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [comDates, setComsDates] = useState({
        date_commande: "", date_fat: "", date_fat_end: "",
        exp: "", delivery: "", commissioning: "", go_live: "", final_report: ""
    });
    const [closeBusiness, setCloseBusiness] = useState(false);

    useEffect(() => {
        if (props.businessInfo.dates_com) setComsDates(JSON.parse(props.businessInfo.dates_com))
    }, [props.businessInfo])

    function getEndOfWarranty() {
        var start = props.businessInfo.date_deb_garantie;//date_recept_pv;
        var month = props.businessInfo.garantie_aff;


        if (start !== null && month !== null) {
            start = new Date(start);
            month = parseInt(month.split(" ")[0]);
            start = start.setMonth(start.getMonth() + month);

            if (!isNaN(start))
                return new Date(start).toISOString().split('T')[0]

        }
    }

    const doCloseBusiness = (e) => {
        e.preventDefault();

        var id_monday = [];
        props.machines.map(v => { if (v.id_monday !== null) id_monday.push(v.id_monday) })

        var o = {
            business: props.businessInfo.id_aff,
            monday_ids: id_monday,
            final_fat: e.target.final_fat.value,
            final_ship: e.target.final_ship.value,
            final_delivery: e.target.final_delivery.value,
            final_commis: e.target.final_commis.value,
            final_golive: e.target.final_golive.value,
            date_recept_pv: e.target.date_recept_pv.value,
        };


        var log = { ...logObj };
        log.origin = "business";
        log.id = props.businessInfo.id_aff;
        log.log_text = "Clôture de l'affaire sur intranet";
        log.old_value = "";
        log.new_value = "";

        scrollToTop();
        setLoading(true);
        BusinessDao.closeBusiness(o).then(
            (response) => {
                Miscellaneous.saveLog(log);
                setLoading(false);
                setCloseBusiness(false);
                window.location.reload();
            },
            (error) => {
                setErr(errorManagement.handleError(props, error));
                setLoading(false);
            }
        );


    }

    const deleteBusiness = () => {
        var business = props.businessInfo.id_aff;

        confirmAlert({
            title: "Supprimer l'affaire " + business,
            message: "Cette action va supprimer la fiche affaire et supprimer les lignes dans Monday",
            buttons: [
                {
                    label: "Oui",
                    onClick: () => {
                        setLoading(true);
                        scrollToTop();
                        var ids_monday = "";
                        props.machines.map(v => { if (v.id_monday !== null) ids_monday += v.id_monday + "," })

                        ids_monday = ids_monday.substring(0, ids_monday.lastIndexOf(","));

                        BusinessDao.deleteBusiness(business, ids_monday).then(
                            (response) => {
                                setLoading(false);
                                window.location.href = "/my-space";
                            },
                            (error) => {
                                setErr(errorManagement.handleError(props, error));
                                setLoading(false);
                            }
                        );
                    }
                },
                {
                    label: "Non"
                }
            ]
        });
    }

    const updateDatesComs = (e, field) => {
        var o = { ...comDates };
        var old_val = o[field];
        var new_val = e.target.value;

        if (old_val.trim() === new_val.trim()) return;

        var log = { ...logObj };
        log.origin = "business";
        log.id = props.businessInfo.id_aff;
        log.log_text = (getLabelFromInput(e.target) || field);
        log.old_value = old_val === null ? "" : old_val;
        log.new_value = new_val;

        o[field] = new_val;

        BusinessDao.updateBusinessField(props.businessInfo.id_aff, "dates_com", JSON.stringify(o), "text", false).then(
            (response) => {
                setComsDates(o);
                Miscellaneous.saveLog(log);
            },
            (error) => { console.error(error) }
        );
    }

    function getDate(base, field) {
        if (base === "planning") {
            return props.businessDates.planning && props.businessDates.planning.reduce((max, item) => {
                return new Date(item[field]) > new Date(max) ? item[field] : max;
            }, props.businessDates.planning[0][field]);
        } else {
            return props.businessDates.delivery && props.businessDates.delivery.reduce((max, item) => {
                return new Date(item[field]) > new Date(max) ? item[field] : max;
            }, props.businessDates.planning[0][field]);
        }
    }

    return (
        <div className="table-responsive">
            {err && <ErrorMessage error={err} />}
            {loading && <WaitingRoundSnippers />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            <br></br>
            <div>
                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_commande}
                            placeholder="xxx" data-field="date_commande" readOnly />
                        <label>Date de la commande (Focus)</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="test" className="form-control" defaultValue={comDates.date_commande}
                            onBlur={(e) => updateDatesComs(e, "date_commande")}
                            placeholder="xxx" data-field="dates_com" />
                        <label>Commentaires date de la commande (Focus)</label>
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" value={props.businessInfo.date_fat}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="date_fat" readOnly />
                        <label>Date de FAT prévue</label>
                    </div>

                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" 
                            value={ props.businessInfo.final_fat || getDate("planning", "fat_start")}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="final_fat" readOnly />
                        <label>Date de FAT réalisée</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="test" className="form-control" defaultValue={comDates.date_fat}
                            onBlur={(e) => updateDatesComs(e, "date_fat")}
                            placeholder="xxx" data-field="dates_com" />
                        <label>Commentaires de FAT</label>
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" 
                            value={props.businessInfo.final_ship || getDate("expedition", "exp_date")}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="final_ship" readOnly />
                        <label>Date d'expédition</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="test" className="form-control" defaultValue={comDates.exp}
                            onBlur={(e) => updateDatesComs(e, "exp")}
                            placeholder="xxx" data-field="dates_com" />
                        <label>Commentaires date d'expédition</label>
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" value={props.businessInfoFocus.DTLIVT}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="..." readOnly />
                        <label>Date de livraison prévue</label>
                    </div>

                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" 
                            value={props.businessInfo.final_delivery || getDate("expedition", "del_date")}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="final_delivery" readOnly />
                        <label>Date de livraison réalisée</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="test" className="form-control" defaultValue={comDates.delivery}
                            onBlur={(e) => updateDatesComs(e, "delivery")}
                            placeholder="xxx" data-field="dates_com" />
                        <label>Commentaires dates de livraison</label>
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" value={props.businessInfo.deb_mes_site}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="deb_mes_site" readOnly />
                        <label>Date de MeS prévue</label>
                    </div>

                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" 
                        value={props.businessInfo.mes_start || getDate("planning", "mes_start")}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="final_commis" readOnly />
                        <label>Date de MeS réalisée</label>
                    </div>

                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="text" className="form-control" value={props.businessDates.durations && props.businessDates.durations.mes_duration + " semaine(s)"}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="..." readOnly />
                        <label>Durée MeS</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="test" className="form-control" defaultValue={comDates.commissioning}
                            onBlur={(e) => updateDatesComs(e, "commissioning")}
                            placeholder="xxx" data-field="dates_com" />
                        <label>Commentaires dates MeS</label>
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" value={props.businessInfo.deb_mep}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="..." readOnly />
                        <label>Date de MeP prévue</label>
                    </div>

                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" 
                        value={props.businessInfo.mep_start || getDate("planning", "mep_start")}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="final_golive" readOnly />
                        <label>Date de MeP réalisée</label>
                    </div>

                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="text" className="form-control" value={props.businessDates.durations && props.businessDates.durations.mep_duration + " semaine(s)"}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="..." readOnly />
                        <label>Durée MeP</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="test" className="form-control" defaultValue={comDates.go_live}
                            onBlur={(e) => updateDatesComs(e, "go_live")}
                            placeholder="xxx" data-field="dates_com" />
                        <label>Commentaires dates MeP</label>
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3 me-1" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" value={props.businessInfo.date_recept_pv}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="date_recept_pv" />
                        <label>Date de signature du PV final</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="test" className="form-control" defaultValue={comDates.final_report}
                            onBlur={(e) => updateDatesComs(e, "final_report")}
                            placeholder="xxx" />
                        <label>Commentaires date de signature du PV final</label>
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_deb_garantie}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="date_deb_garantie"  />
                        <label>Date de début de la garantie</label>
                    </div>


                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" value={getEndOfWarranty()}
                            placeholder="xxx" readOnly />
                        <label>Date de fin de la garantie</label>
                    </div>

                </div>

                <div style={{ display: "flex" }}>
                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_prem_diff}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="date_prem_diff" />
                        <label>Date de première diffusion</label>
                    </div>

                    <div className="form-floating mb-3" style={{ width: "25%", minWidth: "250px", marginLeft: "5px" }}>
                        <input type="date" className="form-control" defaultValue={props.businessInfo.date_clot_intr}
                            onBlur={props.updateBusinessField}
                            placeholder="xxx" data-field="date_clot_intr" readOnly />
                        <label>Date de clôture intranet</label>
                    </div>

                </div>

            </div>

            {
                (!props.readOnly && !props.businessInfo.date_clot_intr) &&
                <div>
                    <div className="divider div-transparent div-arrow-down"></div>
                    <div className="text-center">
                        <button className="btn btn-warning" disabled={loading} onClick={() => setCloseBusiness(true)}>Clôturer l'affaire</button>
                        <button className="btn btn-danger" disabled={loading} onClick={deleteBusiness} style={{ marginLeft: "50px" }}>Supprimer l'affaire</button>
                    </div>
                </div>
            }

            {closeBusiness &&
                <SmallFrame close={() => setCloseBusiness(false)} component={<div>
                    <h5 className="text-center">Vous êtes sur le point de clôturer cette affaire sur l'INTRANET uniquement</h5>
                    <h6 className="text-center mb-3">Mais avant cela, il vous faut confirmer les dates ci-dessous,
                        en cas d'erreur ou manque, modifiez les ici.</h6>
                    <div className="alert alert-warning fw-bold text-center">Cochez toutes les cases pour signifier que vous validez les dates saisies</div>
                    <p className="fst-italic text-center">
                        Rappel: Cette action va écrire la date de clôture (date du jour) dans la fiche affaire et dans mettre le statut "Clôturé" Monday
                    </p>

                    <form style={{ width: "500px" }} className="ms-auto me-auto" onSubmit={(e) => doCloseBusiness(e)}>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={{ width: "330px" }}>Date de la FAT réalisée</span>
                            <input type="date" className="form-control"
                                name="final_fat" defaultValue={getDate("planning", "fat_start")} required />
                            
                            <div class="input-group-text">
                                <input type="checkbox" className="form-check-input" required/>
                            </div>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={{ width: "330px" }}>Date d'expédition de la dernière machine</span>
                            <input type="date" className="form-control"
                                name="final_ship" defaultValue={getDate("expedition", "exp_date")} required />
                            
                                <div class="input-group-text">
                                    <input type="checkbox" className="form-check-input" required/>
                                </div>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={{ width: "330px" }}>Date de livraison réalisée</span>
                            <input type="date" className="form-control"
                                name="final_delivery" defaultValue={getDate("expedition", "del_date")} required />
                            
                                <div class="input-group-text">
                                    <input type="checkbox" className="form-check-input" required/>
                                </div>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={{ width: "330px" }}>Date de MeS réalisée</span>
                            <input type="date" className="form-control"
                                name="final_commis" defaultValue={getDate("planning", "mes_start")} required />
                            
                                <div class="input-group-text">
                                    <input type="checkbox" className="form-check-input" required/>
                                </div>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={{ width: "330px" }}>Date de MeP réalisée</span>
                            <input type="date" className="form-control"
                                name="final_golive" defaultValue={getDate("planning", "mep_start")} required />
                            
                                <div class="input-group-text">
                                    <input type="checkbox" className="form-check-input" required/>
                                </div>
                        </div>

                        <div className="input-group mb-3">
                            <span className="input-group-text" style={{ width: "330px" }}>Date de signature du PV final</span>
                            <input type="date" className="form-control"
                                name="date_recept_pv" defaultValue={props.businessInfo.date_recept_pv} required />
                            
                                <div class="input-group-text">
                                    <input type="checkbox" className="form-check-input" required/>
                                </div>
                        </div>

                        <div className="text-center">
                            <button className="btn btn-success" disabled={loading}>
                                {loading && <ButtonWaiting />}
                                Valider les dates et clôturer</button>
                        </div>
                    </form>
                </div>} />}

        </div>
    )
}

export default PartSix;