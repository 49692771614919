import { Fragment, useEffect, useState } from "react";
import { getAssembly, getCarterisation, getExternalInstaller, getInsideInstaller, getIntegration } from "./CommonTrack";
import CustomToolTip, { CustomToolTipOj } from "../../../../common/smallComponents.js/CustomToolTip";

const TrackingOverview = ({ overview, view }) => {
    const [list, setList] = useState([]);
    const [max_assembly, setMaxAssembly] = useState(0);
    const [max_carterisation, setMaxCarterisation] = useState(0);
    const [max_meca_int, setMaxMecaInt] = useState(0);
    const [max_elec_int, setMaxElecInt] = useState(0);

    const [max_in_inst, setMaxInInst] = useState(0);
    const [max_ext_inst, setMaxExtInst] = useState(0);

    useEffect(() => {
        if (!overview) return;
        console.log(overview)

        var arr = [];

        //For each machine get the status
        var machines = overview.machines;
        for (let i = 0; i < machines.length; i++) {
            var o = { machine: machines[i].trim() }

            if (view === "workshop") {
                getWorkshopStatus(o)
            } else {
                getCommissioningStatus(o)
            }

            arr.push(o);
        }

        setList(arr)
    }, []);

    //Need to search into BOM and specific tasks
    function getWorkshopStatus(o) {
        // Get all taks to do for this machine
        var tasks = overview.bom.filter(a => a.machine.trim() === o.machine)
        o.config = tasks[0].typ_config;

        // Get status for this machine
        var status = overview.status.filter(a => a.machine.trim() === o.machine)

        // Get status
        o.assembly = getAssembly(tasks, status, false);
        o.meca_integration = getIntegration(tasks, "Intégration méca.", status, false);
        o.elec_integration = getIntegration(tasks, "Intégration élec.", status, false);
        o.carterisation = getCarterisation(tasks, status, false);

        // Check the max
        if (o.assembly.length > max_assembly) setMaxAssembly(o.assembly.length)
        if (o.meca_integration.length > max_meca_int) setMaxMecaInt(o.meca_integration.length)
        if (o.elec_integration.length > max_elec_int) setMaxElecInt(o.elec_integration.length)
        if (o.carterisation.length > max_carterisation) setMaxCarterisation(o.carterisation.length)
    }

    //Only specific tasks
    function getCommissioningStatus(o) {
        // Get all taks to do for this machine
        var tasks = overview.bom.filter(a => a.machine.trim() === o.machine)
        o.config = tasks[0].typ_config;

        // Get status for this machine
        var status = overview.status.filter(a => a.machine.trim() === o.machine)

        // Get status
        o.inside_installer = getInsideInstaller(tasks, status, false);
        o.ext_installer = getExternalInstaller(tasks, status, false);

        // Check the max
        setMaxInInst(o.inside_installer.length)
        setMaxExtInst(o.ext_installer.length)
    }

    // Get cell element (common for all)
    function getCell(v, step, index, k) {
        return <Fragment>
            {index < v[step].length ? <td key={k + "_" + index}
                style={{ backgroundColor: v[step][index].machineStatusBg.backgroundColor }}>
                <CustomToolTipOj originText={<div style={{ color: "transparent" }}>_</div>}
                    tooltipText={<table className="text-start">
                        <tr>
                            <td style={{ width: "80px" }}>Status:</td>
                            <td>{v[step][index].machineStatus}</td>
                        </tr>
                        <tr>
                            <td>Tâche:</td>
                            <td>{v[step][index].french_label}</td>
                        </tr>

                        {v[step][index].summary.length > 0 && <tr>
                            <td>Exécutant:</td>
                            <td>{[...new Set(v[step][index].summary.map(item => item.trigram))].join(',')}</td>
                        </tr>}

                        {v[step][index].machineStatus !== "En attente" &&
                            <tr>
                                <td>Début:</td>
                                <td>{v[step][index].start}</td>
                            </tr>
                        }

                        {v[step][index].machineStatus === "Terminé" &&
                            <tr>
                                <td>Fin:</td>
                                <td>{v[step][index].end}</td>
                            </tr>}
                    </table>} />
            </td> :
                <td className="bg-body-secondary">
                    <CustomToolTipOj originText={<div style={{ color: "transparent" }}>_</div>}
                        tooltipText={<div>Non concerné ou nomenclature absente</div>} />
                </td>}
        </Fragment>
    }

    return <div>

        <table className="table table-bordered cursor-pointer">
            <thead className="fixed-header">
                {view === "workshop" ?
                    <tr className="table-secondary text-center fw-bold">
                        <td className="text-start">Machine</td>
                        <td colSpan={max_assembly}>Montage</td>
                        <td colSpan={max_carterisation}>Catérisation</td>
                        <td colSpan={max_meca_int}>Intégration mécanique</td>
                        <td colSpan={max_elec_int}>Intégration électrique</td>
                    </tr> :
                    <tr className="table-secondary text-center fw-bold">
                        <td className="text-start">Machine</td>
                        <td colSpan={max_in_inst}>Mise en service B+</td>
                        <td colSpan={max_ext_inst}>Installation site client</td>
                    </tr>}
            </thead>
            <tbody>{list.map((v, k) => {
                return <tr>

                    <td className="d-flex" style={{ maxWidth: "200px" }}>
                        <div>{v.machine}</div>
                        <div className="fst-italic mt-auto ms-1" style={{ fontSize: "12px" }}>({v.config})</div>
                    </td>
                    {view === "workshop" ? <Fragment>
                        {/** MONTAGE */}
                        {Array.from({ length: max_assembly }).map((_, index) => (
                            getCell(v, "assembly", index, k)
                        ))}

                        {/** CARTERISATION */}
                        {Array.from({ length: max_carterisation }).map((_, index) => (
                            getCell(v, "carterisation", index, k)
                        ))}

                        {/** INTEGRATION MECA */}
                        {Array.from({ length: max_meca_int }).map((_, index) => (
                            getCell(v, "meca_integration", index, k)
                        ))}

                        {/** INTEGRATION ELEC */}
                        {Array.from({ length: max_elec_int }).map((_, index) => (
                            getCell(v, "elec_integration", index, k)
                        ))}</Fragment> :

                        <Fragment>
                            {/** INSIDE INSTALLER */}
                            {Array.from({ length: max_in_inst }).map((_, index) => (
                                getCell(v, "inside_installer", index, k)
                            ))}

                            {/** EXTRENAL INSTALLER */}
                            {Array.from({ length: max_in_inst }).map((_, index) => (
                                getCell(v, "ext_installer", index, k)
                            ))}
                        </Fragment>
                    }

                </tr>

            })}
            </tbody>
        </table>


    </div>

}

export default TrackingOverview;