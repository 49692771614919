import { Fragment, useEffect, useState } from "react";
import { isSideBarOpened, SideNavBarV3 } from "../../common/smallComponents.js/DesignedIpt";
import getUrlParam, { addDaysToDate, getDateTimeFromDb, getFrFormat, getTheMaxDate, hasRole } from "../../functions/StrFunctions";
import ErrorMessage, { InfoMsg, WarningMsg } from "../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import CsContracts from "../../services/customClient/CsContracts";
import errorManagement from "../../services/errorManagement";
import { getHeight, scrollToTop } from "../../functions/PageAnimation";
import { Tab } from "react-bootstrap";
import { getColumns } from "./common/Columns";
import { removeEltArray } from "../../functions/ArrayFunctions";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import NewMesPicking from "./smallContent/NewMesPicking";
import LabelsChoice from "../../common/smallComponents.js/LabelsChoice";
import { downloadTempFile } from "../../services/functions/FilesProcess";

const PickingCommisioning = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [business, setBusiness] = useState("");
    const [error, setError] = useState();
    const [warning, setWarning] = useState();
    const [info, setInfo] = useState();
    const [msg, setMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [hasPreAssembly, setHasPreAssembly] = useState(false);

    const [tab, setTab] = useState(0);

    const [pcikingDates, setPickingDates] = useState([]);
    const [printing, setPrinting] = useState(false);
    const [start, setStart] = useState();
    const [labelMode, setLabelMode] = useState();
    const [printBusiness, setprintBusiness] = useState(false);
    const [printReliquat, setprintReliquat] = useState(false);
    const [duplicateLabels, setduplicateLabels] = useState(1);

    const isAdmin = hasRole(["ROLE_ADMIN"]);
    const isStorehouse = hasRole(["ROLE_STOREHOUSE", "ROLE_ADMIN"]);
    const isCs = hasRole(["ROLE_COMMISSIONING", "ROLE_ADMIN"]);

    const [businessInfo, setBusinessInfo] = useState();
    const [picking, setPicking] = useState();
    const [reliquat, setReliquat] = useState([]);
    const [newPicking, setNewPicking] = useState([]);
    const [firstDate, setFirstDate] = useState("");
    const [address, setAddress] = useState({ firstName: "", lastName: "", phone: "", email: "", 
        company: "", address: "", additionalAd: "", post_c: "", country: "", city: "" });
    const [deliveryDate, setDeliveryDate] = useState("");
    const [expDate, setExpDate] = useState("");

    useEffect(() => {
        var b = getUrlParam(props, "business")

        if (b) {
            setBusiness(b);
            getBusiness(b);
        }
    }, []);

    function getBusiness(business) {
        setLoading(true);

        CsContracts.getCommissioningPicking(business).then(
            (response) => {
                setBusinessInfo(response[0]);
                if( response[0].address ) setAddress( JSON.parse( response[0].address ) )

                if (!response[0].NOCDF) { setPicking([]); }
                else { 
                    setPicking(response); 
                    setExpDate( response[0].DTCRM );
                    setDeliveryDate( response[0].DTLIM );
                }

                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    const checkPreassembly = (e, k) => {
        var arr = [...picking];
        arr[k].preassembly = e.target.checked ? "O" : "N";
        setPicking(arr);
        checkIfAnyPreassembly(arr);
    }

    function checkIfAnyPreassembly(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].preassembly === "O") {
                setHasPreAssembly(true);
                return;
            }
        }

        setHasPreAssembly(false);
    }

    const checkReliq = (e, k) => {
        var arr = [...reliquat];
        var arr2 = [...picking];
        if (e.target.checked) {
            arr.push(k);
            arr2[k].reliquat = "O";
        } else {
            arr = removeEltArray(arr, k);
            arr2[k].reliquat = undefined;
        }

        setPicking(arr2);
        setReliquat(arr);
    }

    /** */
    function getLinks(txt) {
        if (!txt) return "";

        var url = "";
        txt.split("<br/>").map(v => {
            if (v !== "") url += '<a target="_blank" href="/purchase/order/edition?order=' + v.split(":")[0].trim() + '">' + v + '</a><br/>';
        })

        return url;

    }

    /** Update hidden row and comments */
    const updateField = (e, k, field) => {
        var arr = [...picking];

        if (field === "visible") {
            arr[k].visible = e.target.checked ? "N" : "O";
        } else {
            arr[k][field] = e.target.value;
        }

        setPicking(arr);
    }

    //Set columns
    const columns = getColumns(isCs, checkPreassembly, isStorehouse, checkReliq, reliquat, updateField, 
        firstDate, getLinks, true);

    /** */
    const printLabel = (idx) => {
        var arr = getArray(labelMode);

        setLoading(true);
        setPrinting(true);
        CsContracts.downloadLabels(arr, printBusiness, idx, printReliquat, duplicateLabels).then(
            (r) => {
                setLoading(false);
                setPrinting(false);
                downloadTempFile(r);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                setPrinting(false);
                scrollToTop();
            }
        )
    }

    function getArray(mode) {
        var arr = new Array();
        if (mode === "all") {
            arr = [...picking];
        } else if (mode === "reliquat") {
            reliquat.map(v => arr.push(picking[parseInt(v)]));
        } else if (mode.startsWith("date:")) {
            var date = mode.substring(mode.indexOf(":") + 1, mode.length)
            picking.map(v => { if (v.date_picking !== null && v.date_picking === date) arr.push(v) })
        }
        return arr;
    }

    /** Download file*/
    const downloadXls = (mode) => {
        var arr = getCleanedArr(getArray(mode));

        setLoading(true);
        CsContracts.downloadPicking(arr).then(
            (r) => {
                setLoading(false);
                downloadTempFile(r);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )
    }

    function getCleanedArr(arr) {
        var arr2 = [];

        for (let i = 0; i < arr.length; i++)
            if (arr[i].NOCDE) arr2.push(arr[i]);

        return arr2;
    }

    return <div className="bg-see navbarV3 right-side">

        <SideNavBarV3 setPin={setPin} content={
            <div className="ps-2">
                {(!isStorehouse && !business === "") &&
                    <div><a href="/customer-client/picking/commissioning">Traiter une autre affaire</a></div>
                }

                {(isStorehouse && picking) && <Fragment>
                    <h6 className="text-center">Etiquettes</h6>

                    <div>
                        <ul>
                            <li>
                                <a onClick={() => setLabelMode("all")}>Tout le picking</a>
                            </li>

                            {pcikingDates.length > 1 && <Fragment>
                                {pcikingDates.map((v, k) => {
                                    return <li key={"down" + k}>
                                        <a onClick={() => setLabelMode("date:" + v)}>Picking du {v}</a>
                                    </li>
                                })}
                            </Fragment>}

                            {reliquat.length > 0 && <li>
                                <a onClick={() => setLabelMode("reliquat")}>Reliquats sélectionnés</a>
                            </li>}
                        </ul>
                    </div>

                    {labelMode &&
                        <div>
                            <LabelsChoice onChooseTag={printLabel} printingLabel={printing} start={start} />
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    checked={printBusiness} onChange={() => setprintBusiness(!printBusiness)} />
                                <label className="form-check-label">
                                    Ajouter une étiquette Affaire/date
                                </label>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    checked={printReliquat} onChange={() => setprintReliquat(!printReliquat)} />
                                <label className="form-check-label">
                                    Ajouter une étiquette Affaire/Reliquat
                                </label>
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text">Nb étiquettes Aff./date</span>
                                <input type="number" className="form-control text-center fw-bold" value={duplicateLabels}
                                    onChange={(e) => setduplicateLabels(e.target.value)} style={{ width: "60px" }} />
                            </div>
                        </div>
                    }

                    <div className="h-divider"><div className="shadow-divider"></div></div>
                </Fragment>}

                <h6 className="text-center">Télécharger</h6>
                <ul>
                    <li>
                        <a onClick={() => downloadXls("all")}>Tout le picking</a>
                    </li>

                    {pcikingDates.length > 1 && <Fragment>
                        {pcikingDates.map((v, k) => {
                            return <li key={"down" + k}>
                                <a onClick={() => downloadXls("date:" + v)}>Picking du {v}</a>
                            </li>
                        })}
                    </Fragment>}

                    {reliquat.length > 0 && <li>
                        <a onClick={() => downloadXls("reliquat")}>Reliquats sélectionnés</a>
                    </li>}
                </ul>

                <div className="h-divider"><div className="shadow-divider"></div></div>

                <h6 className="text-center">Navigation</h6>
                <a href="/customer-client/picking/list">Liste des affaires SC</a>
            </div>
        } />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

            {business === "" && <Fragment>
                <h6 className="text-center mb-3">Picking Affaire MeS</h6>

                <form className="ms-auto me-auto" style={{ width: "500px" }}>
                    <input className="form-control text-center fw-bold" placeholder="Saisissez le code affaire"
                        name="business" defaultValue={business} autoFocus />
                </form></Fragment>}

            {loading && <WaitingRoundSnippers />}
            {error && <ErrorMessage error={error} setError={setError} />}
            {warning && <WarningMsg warning={warning} warningState={setWarning} />}
            {info && <InfoMsg info={info} infoState={setInfo} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}

            {businessInfo && <div>
                {/** HEADER */}
                <div className="display-flex">

                    <div className="card col-4 mt-0 me-auto ms-0" style={{ minWidth: "500px" }}>
                        <h5 className="card-title mb-1">{businessInfo.NOCDE}: {businessInfo.RSDUE}</h5>

                        { !businessInfo.address && 
                            <span className="card-text">
                                <b>Adresse: </b> {businessInfo.address || "A définir"}
                            </span>
                        }

                        { businessInfo.address && <Fragment>
                            <span className="card-text">
                                <b>Adresse: </b> {address.address} {address.additionalAd} <br></br>
                                {address.post_c} {address.city} {address.country}
                            </span>
                           
                            <span className="card-text">
                                <b>Contact: </b> {address.lastName} {address.firstName} 
                                <br></br>E-mail:{address.email} - Tel: {address.phone}
                            </span>
                            </Fragment>}

                        <span className="card-text"><strong>Chef de projet: </strong>{businessInfo.author || businessInfo.CDAGE}</span>
                        { deliveryDate !== "" && <Fragment>
                            <span className="card-text"><strong>Date d'expédition: </strong>{ getFrFormat( expDate, true )}</span>
                            <span className="card-text"><strong>Date de livraison: </strong>{ getFrFormat( deliveryDate, true )}</span>
                        </Fragment> }
                    </div>

                    <div className="text-center me-auto" style={{ minWidth: "100px" }}>
                        {tab === 0 &&
                            <button className="btn btn-secondary" onClick={() => setTab(1)}>Ajouter des articles</button>}

                        {tab === 1 &&
                            <button className="btn btn-secondary" onClick={() => setTab(0)}>Revenir au tableau</button>}
                    </div>
                </div>

                {tab === 0 && <Fragment>
                    {picking.length === 0 && <div className="alert alert-warning text-center fw-bold">
                        Aucune ligne créée.
                    </div>}

                    {picking.length > 0 && <ReactTable columns={columns} data={picking} show={1000}
                        origin="mes_picking" classes={"no-padding fixFstCol"} top0={true} />}
                </Fragment>}

                {tab === 1 && <div id="mes_new_picking">
                    <NewMesPicking newPicking={newPicking} business={business} props={props} 
                        address={address} setAddress={setAddress}/>
                </div>}

            </div>}

        </div>

    </div>

}

export default PickingCommisioning;