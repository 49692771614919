import React, { useEffect, useState } from 'react';
import { AccordionSingle, SmallFrame } from '../../../../common/smallComponents.js/CustomDivs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const Quality = ({ business }) => {
    const [forms, setForms] = useState([]);
    const [nc, setNC] = useState([]);
    const [ctrl_to_do, setCtrlToDo] = useState([]);
    const [nc_in_progress, setNCInProgress] = useState([]);
    const [nc_articles, setNcArticles] = useState([]);

    const chartRef = React.useRef(null);

    const [detail, setDetail] = useState([]);

    ChartJS.register(ArcElement, Tooltip, Legend);

    const [data, setData] = useState();

    const handleClick = (event) => {
        if (!chartRef.current) return;

        const chart = chartRef.current;
        const points = chart.getElementsAtEventForMode(event, "nearest", { intersect: true }, true);

        if (points.length > 0) {
            const index = points[0].index; // Get clicked segment index
            const label = data.labels[index]; // Get clicked segment label
            const value = data.datasets[0].data[index]; // Get clicked segment value

            displayDetail(label);
        }
    };

    const displayDetail = (label) => {
        switch (label) {
            case "Reste à contrôler":
                setDetail([...ctrl_to_do]);
                break;

            case "Non conformités survenues":
                setDetail([...nc]);
                break;

            case "Points conformes":
                break;

            default:
                break;
        }
        console.log(label)
    }

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        onClick: handleClick,
    };

    useEffect(() => {
        if (business.forms.length > 0) {
            setForms(business.forms)
            setNC(business.forms.filter(v => v.hasBeenNC === 1))
            setCtrlToDo(business.forms.filter(v => v.conform === null))
            setNCInProgress(business.forms.filter(v => v.conform === 0))
            setNcArticles(business.articles_nc);
        }

    }, [business])

    useEffect(() => {
        fillData();
    }, [nc, ctrl_to_do, nc_in_progress])

    function fillData() {
        setData({
            labels: ["Non conformités survenues", "Points conformes", "Reste à contrôler"],
            datasets: [
                {
                    data: [nc.length, (forms.length - nc.length), ctrl_to_do.length],
                    backgroundColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)', 'rgba(255, 206, 86, 0.2)']
                },
            ],
        })
    }



    return <div>
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <td className=' fs-4 fw-bold text-secondary text-center' colSpan={3}>Production</td>
                </tr>
                <tr className='text-center fs-4'>
                    <th className='fw-normal text-secondary'>Taux de non conformités</th>
                    <th className='fw-normal text-secondary'>Non conformités non résolus</th>
                    <th className='fw-normal text-secondary'>Contrôles non effectués</th>
                </tr>
            </thead>
            <tbody>
                <tr className='text-center fs-4 fw-bold'>
                    <td>
                        {((nc.length / forms.length) * 100).toFixed(1)} %
                    </td>
                    <td>
                        {nc_in_progress.length}
                    </td>
                    <td>
                        {((ctrl_to_do.length / forms.length) * 100).toFixed(1)} %
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <div style={{ height: "200px", width: "200px" }} className='ms-auto me-auto'>
                            {data && <Doughnut data={data} options={options} ref={chartRef} />}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className=' fs-4 fw-bold text-secondary text-center' colSpan={3}>Articles commandées en NC</td>
                </tr>
                <tr className='text-center fs-4 fw-bold'>
                    <td colSpan={3}>
                        <div className='d-flex'>
                            <div className='ms-auto me-2'>{nc_articles.length}</div>
                            <div className='me-auto'>
                                <button className='btn btn-outline-secondary' onClick={() => setDetail(nc_articles)}>Détail</button>
                            </div>
                        </div>


                    </td>
                </tr>
            </tbody>
        </table>

        {detail.length > 0 && <SmallFrame close={() => setDetail([])} component={
            <table className='table overflow-y' style={{ maxHeight: '300px' }}>
                <thead>
                    <th>Type de contrôle</th>
                    <th>Point de non conformité</th>
                    <th>Résolu</th>
                </thead>
                <tbody>
                    {detail.map(v => {
                        return <tr>
                            <td>{v.form.trim()}</td>
                            <td>{v.label}</td>
                            <td>{v.conform === 0 ? "Oui" : "Non"}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        } />

        }


    </div>
};

export default Quality;