import { useState } from "react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { arrayRemoveElt, removeAndReturnArray, updateSimpleArray } from "../../../functions/ArrayFunctions";
import { forceString, getFrFormat } from "../../../functions/StrFunctions";
import ErrorMessage from "../../../common/smallComponents.js/ErrorMessage";
import SuccessMessage from "../../../common/smallComponents.js/SuccessMessage";
import transport from "../../../services/supplyChain/transport";
import { ButtonWaiting, scrollToTop } from "../../../functions/PageAnimation";
import errorManagement from "../../../services/errorManagement";

function extractSide(val) {
    var idx = val.indexOf("/");
    if (idx === -1) return val;
    return val.substring(idx + 1, val.length);
}

export const RequestPrice = (p) => {
    const [recipients, setRecipient] = useState([]);
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [sending, setSending] = useState(false);



    const send = () => {
        if (recipients.length === 0) {
            setError("Choisissez au moins un destinataire");
        } else {
            setError();
            setSending(true);
            transport.sendRequestPrice(p.ship.business, getHtml(), recipients, p.ship.id).then(
                (r) => {
                    setMsg("Mail envoyé ! Lorsque vous recevrez une réponse pensez à noter "
                        + "le numéro de référence d'expédition dans l'onglet perso de la commande dans Focus");
                    setSending(false);
                }, (error) => {
                    setError(errorManagement.handleError(p.props, error));
                    scrollToTop();
                    setSending(false);
                }
            )
        }
    }

    function getType() {
        var type = "";
        var o = { camion: 0, palet: 0, other: 0, crate: 0, parcel: 0 }

        for (let i = 0; i < p.shipDetail.length; i++) {
            if (p.shipDetail[i].type.indexOf("Palette") !== -1) {
                o.palet++;
            } else if (p.shipDetail[i].type.indexOf("Colis") !== -1) {
                o.parcel++;
            } else if (p.shipDetail[i].type.indexOf("Camion") !== -1) {
                o.camion++;
            } else if (p.shipDetail[i].type.indexOf("Caisse") !== -1) {
                o.crate++;
            } else if (p.shipDetail[i].type.indexOf("Autres") !== -1) {
                o.other++;
            }
        }

        if (o.camion > 0) type += o.camion + " camion" + (o.camion > 1 ? "s" : "");
        if (o.parcel > 0) type += o.parcel + " colis";
        if (o.palet > 0) type += o.palet + " palette" + (o.palet > 1 ? "s" : "") ;
        if (o.crate > 0) type += o.crate + " caisse" + (o.crate > 1 ? "s" : "");
        if (o.other > 0) type += o.other + " autre" + (o.other > 1 ? "s" : "") + " colis";

        return type;
    }

    function getHtml() {
        var m = JSON.parse(p.ship.machines);
        var machines = "";
        m && m.map(v => machines += v.value + ", ");
        machines = machines.substring(0, machines.lastIndexOf(","));

        var html = "Bonjour,<br><br>Merci de me faire une offre de prix pour la demande de transport de "
            + getType();

        if( machines !== "" ) html += ": " + machines
        html+= "<br><br>";
        
        html += "<table>"
            + "<tbody>"
            + "<tr> "
            + "<td style=\"width: 350px; color: #008996; border-style: none;\"><strong>Adresse de chargement :</strong></td>"
            + "<td style=\"width: 350px; color: #008996; border-style: none;\"><strong>Adresse de livraison :</strong></td>"
            + "</tr>"

            + "<tr>"
            + "<td style=\"width: 350px; border-style: none;\">"
            + forceString(p.ship.cn_sender)
            + "<br />"
            + forceString(p.ship.address_sender).toUpperCase()
            + "<br />"
            + (p.ship.comp_add_sender ? p.ship.comp_add_sender.toUpperCase() + "<br/>" : "")
            + forceString(p.ship.cp_sender).toUpperCase() + " " + forceString(p.ship.city_sender).toUpperCase() + ", "
            + forceString(p.ship.country_sender).toUpperCase()
            + "</td>"

            + "<td style=\"width: 350px; border-style: none;\">"
            + forceString(p.ship.cn_recip).toUpperCase()
            + "<br>"
            + forceString(p.ship.address_recip).toUpperCase();

        if (p.ship.comp_add_recip) html += " " + p.ship.comp_add_recip.toUpperCase();

        html += "<br>"
            + forceString(p.ship.cp_recip).toUpperCase() + " " + forceString(p.ship.city_recip).toUpperCase() + ", "
            + forceString(p.ship.country_recip).toUpperCase()
            + "</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 350px; border-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 350px; border-style: none;\">&nbsp;</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 350px; border-style: none; color: #008996;\"><strong>Expéditeur</strong></td>"
            + "<td style=\"width: 350px; border-style: none; color: #008996;\"><strong>A l&rsquo;attention de : </strong></td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 350px; border-style: none;\">"
            + p.ship.ln_sender + " " + p.ship.fn_sender + " " + p.ship.tel_sender
            + "</td>"
            + "<td style=\"width: 350px; border-style: none;\">"
            + p.ship.ln_recip + " " + p.ship.fn_recip
            + " "
            + p.ship.tel_recip
            + "</td>"
            + "</tr>"
            + "</tbody>"
            + "</table><br>";

        html += "<p><strong>Conditions de transport : Transport sans passage à quai ni déchargement avant arrivée à destination</strong></p>";

        html += "<table>";
        p.shipDetail.map((v, k) => {
            html += (v.type.trim() === "Camion") ? getCamionDetail(v, k) : getOtherDetail(v, k);
        })
        html += "</tbody></table><br><br>";

        if (p.ship.com && p.ship.com.trim() !== "") html += "Observation: " + p.ship.com + "<br><br>";

        html += "<b>Merci de bien respecter les dates ainsi que les heures de chargements / livraisons indiquées ci-dessus s’il vous plait.</b><br><br>";

        html += "Pour tout renseignement complémentaire ou réponse, merci de contacter:<ul>";

        p.purchaser.map(v => {
            if (v.checked) html += "<li>" + v.name + " - Tel: <a href=\"tel:" + v.tel + "\">" + v.tel
                + "</a> - Mail: <a href=\"mailto" + v.mail + ":\">" + v.mail + "</a>"
        })

        html += "</ul>";


        return html;
    }

    function getCamionDetail(v, k) {
        return "<tr>"
            + "<td style=\"width: 250px; border-style: none;color: #008996; font-weight: bold;\">Camion "
            + (k + 1)
            + "</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Camion bâché, chargement</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.truck_load_side
            + "</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Déchargement</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + (p.businessInfo ? extractSide(p.businessInfo.decharg_manu) : "Non connu")
            + "</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Type de camion</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.truck_type
            + "</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Longueur plancher réservé</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.truck_meter
            + " mètres</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Hauteur minimum sous barres </td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.truck_height
            + " mètres</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Machines à sangler par vos soins</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.nb_strap
            + " sangles nécessaires</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Poids total </td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.truck_weight
            + " tonnes</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Chargement</td>"
            + "<td style=\"width: 200px; border-style: none;\">" + getFrFormat(v.truck_load, false)
            + "</td>"
            + "<td style=\"width: 200px; border-style: none; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Déchargement</td>"
            + "<td style=\"width: 200px; border-style: none;\">" + getFrFormat(v.truck_unload, false)
            + "</td>"
            + "<tr><td colspan=\"4\" style=\"height: 10px\"></td></tr>"
            + "</tr>"
    }

    function getOtherDetail(v, k) {
        var type = v.type.indexOf("Palette") !== -1 ? "Palette/caisse " : "Colis ";

        return "<tr>"
            + "<td style=\"width: 250px; border-style: none;color: #008996; font-weight: bold;\"> " + type
            + (k + 1)
            + "</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "</tr>"
            + "<tr>"

            + "<td style=\"width: 200px; border-style: none;\">Longueur</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.pack_width
            + " cm</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Date d'enlèvement</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + getFrFormat(v.pack_pick_date, true)
            + "</td>"
            + "</tr>"

            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Largeur</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.pack_height
            + " cm</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Date de livraison</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + getFrFormat(v.pack_delivery, true)
            + "</td>"
            + "</tr>"

            + "<tr>"

            + "<tr>"
            + "<td style=\"width: 200px; border-style: none;\">Hauteur</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.truck_height
            + " cm</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\"></td>"
            + "<td style=\"width: 200px; border-style: none;\"></td>"
            + "</tr>"

            + "<tr>"

            + "<td style=\"width: 200px; border-style: none;\">Poids</td>"
            + "<td style=\"width: 200px; border-style: none;\">"
            + v.pack_weight
            + " kg</td>"
            + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
            + "</tr>"


    }

    const onChooseRecipient = (e) => {
        var arr = [...recipients];
        if (e.target.checked) {
            arr.push(e.target.value)
        } else {
            arr = arrayRemoveElt(arr, arr.indexOf(e.target.value))
        }

        setRecipient(arr);
    }

    const onChoosePurchaser = (e) => {
        var arr = [...p.purchaser];
        var k = e.target.value;
        arr[k].checked = !arr[k].checked;
        p.setPurchaser(arr);
    }

    return <div className="custom-tooltip-v2">
        <div className="modal-body xl blue-purple-bg">
            <button type="button" className="btn-close" aria-label="Close" onClick={() => p.setSendMail(false)}></button>

            {error && <ErrorMessage error={error} errorState={setError} />}
            {msg && <div className="col-10 offset-1"><SuccessMessage msg={msg} msgState={setMsg} /></div>}

            <div className="d-flex">
                {/** CHOOSE RECIPIENT */}
                <div className="accordion mb-3" id="accordionExample" style={{ width: "300px" }}>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Sous-traitants
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {p.contacts.map((v, k) => {
                                    return <div className="form-check" key={"contact" + k}>
                                        <input className="form-check-input" type="checkbox" onChange={onChooseRecipient}
                                            value={v.email} checked={recipients.includes(v.email)} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">{v.email}</label>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                {/** CHOOSE PURCHASERS */}
                <div className="accordion mb-3 ms-3" id="accordionExample" style={{ width: "300px" }}>
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Acheteur(s) à qui retourner la réponse
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                {p.purchaser.map((v, k) => {
                                    return <div className="form-check" key={"contact" + k}>
                                        <input className="form-check-input" type="checkbox"
                                            onChange={onChoosePurchaser} value={k} checked={v.checked} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">{v.name}</label>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/** TYPE EMAIL BODY */}
            <h6 className="text-center">Aperçu du corps du mail à envoyer</h6>
            <div className="bg-white rounded p-2 mb-3" style={{ maxHeight: "40vh", overflowY: "auto" }}>
                <div dangerouslySetInnerHTML={{ __html: getHtml() }}></div>
            </div>

            <div className="text-center">
                <button className="btn btn-success" onClick={send} disabled={sending}>
                    {sending && <ButtonWaiting />}
                    Envoyer</button>
            </div>

        </div>
    </div>

}

export const SendToPacker = (p) => {
    function getHtml() {
        var m = JSON.parse(p.ship.machines);
        var machines = "";
        m.map(v => machines += v.value + ", ");
        machines = machines.substring(0, machines.lastIndexOf(","));

        var html = "Bonjour,<br><br>Voici les informations pour venir récupérer "
            + m.length + " machine" + (m.length > 1 ? "s:" : "") + " de l'affaire: " +
            p.ship.business
            + ":<br><br>";

        html += "<table>"
            + "<tbody>"
            + "<tr> "
            + "<td style=\"width: 350px; color: #008996; border-style: none;\"><strong>Adresse de chargement :</strong></td>"
            + "<td style=\"width: 350px; color: #008996; border-style: none;\"><strong>Adresse de livraison :</strong></td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 350px; border-style: none;\">SEALED AIR &ndash; B+EQUIPMENT<br />465 AVENUE DU COL DE L&rsquo;ANGE<br />13420 GEMENOS<br>"
            + "</td>"
            + "<td style=\"width: 350px; border-style: none;\">"
            + p.ship.cn_recip.toUpperCase()
            + "<br>"
            + p.ship.address_recip.toUpperCase();

        if (p.ship.comp_add_recip) html += " " + p.ship.comp_add_recip.toUpperCase();

        html += "<br>"
            + p.ship.cp_recip.toUpperCase() + " " + p.ship.city_recip.toUpperCase() + ", "
            + p.ship.country_recip.toUpperCase()
            + "</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 350px; border-style: none;\">&nbsp;</td>"
            + "<td style=\"width: 350px; border-style: none;\">&nbsp;</td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 350px; border-style: none; color: #008996;\"><strong>Expéditeur</strong></td>"
            + "<td style=\"width: 350px; border-style: none; color: #008996;\"><strong>A l&rsquo;attention de : </strong></td>"
            + "</tr>"
            + "<tr>"
            + "<td style=\"width: 350px; border-style: none;\">"
            + p.ship.ln_sender + " " + p.ship.fn_sender + " " + p.ship.tel_sender
            + "</td>"
            + "<td style=\"width: 350px; border-style: none;\">"
            + p.ship.ln_recip + " " + p.ship.fn_recip
            + " "
            + p.ship.tel_recip
            + "</td>"
            + "</tr>"
            + "</tbody>"
            + "</table><br>";

        //html += "<p><strong>Conditions de transport : Transport sans passage à quai ni déchargement avant arrivée à destination</strong></p>";

        html += "<table>";
        p.shipDetail.map((v, k) => {
            html += "<tr>"
                + "<td style=\"width: 250px; border-style: none;color: #008996; font-weight: bold;\">Camion "
                + (k + 1)
                + "</td>"
                + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
                + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
                + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
                + "</tr>"
                + "<tr>"
                + "<td style=\"width: 200px; border-style: none;\">Camion bâché, chargement</td>"
                + "<td style=\"width: 200px; border-style: none;\">"
                + v.truck_load_side
                + "</td>"
                + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Déchargement</td>"
                + "<td style=\"width: 200px; border-style: none;\">"
                + (p.businessInfo ? extractSide(p.businessInfo.decharg_manu) : "Non connu")
                + "</td>"
                + "</tr>"
                + "<tr>"
                + "<td style=\"width: 200px; border-style: none;\">Type de camion</td>"
                + "<td style=\"width: 200px; border-style: none;\">"
                + v.truck_type
                + "</td>"
                + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Longueur plancher réservé</td>"
                + "<td style=\"width: 200px; border-style: none;\">"
                + v.truck_meter
                + " mètres</td>"
                + "</tr>"
                + "<tr>"
                + "<td style=\"width: 200px; border-style: none;\">Hauteur minimum sous barres </td>"
                + "<td style=\"width: 200px; border-style: none;\">"
                + v.truck_height
                + " mètres</td>"
                + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">&nbsp;</td>"
                + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
                + "</tr>"
                + "<tr>"
                + "<td style=\"width: 200px; border-style: none;\">Machines à sangler par vos soins</td>"
                + "<td style=\"width: 200px; border-style: none;\">"
                + v.nb_strap
                + " sangles nécessaires</td>"
                + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">&nbsp;</td>"
                + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
                + "</tr>"
                + "<tr>"
                + "<td style=\"width: 200px; border-style: none;\">Poids total </td>"
                + "<td style=\"width: 200px; border-style: none;\">"
                + v.truck_weight
                + " tonnes</td>"
                + "<td style=\"width: 200px; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">&nbsp;</td>"
                + "<td style=\"width: 200px; border-style: none;\">&nbsp;</td>"
                + "</tr>"
                + "<tr>"
                + "<td style=\"width: 200px; border-style: none;\">Chargement</td>"
                + "<td style=\"width: 200px; border-style: none;\">" + getFrFormat(v.truck_load, false)
                + "</td>"
                + "<td style=\"width: 200px; border-style: none; border-left-style: solid;border-top-style: none;border-bottom-style: none;border-right-style: none;\">Déchargement</td>"
                + "<td style=\"width: 200px; border-style: none;\">" + getFrFormat(v.truck_unload, false)
                + "</td>"
                + "<tr><td colspan=\"4\" style=\"height: 10px\"></td></tr>"
                + "</tr>"
        })
        html += "</tbody></table><br><br>";

        if (p.ship.com && p.ship.com.trim() !== "") html += "Observation: " + p.ship.com + "<br><br>";

        html += "<b>Merci de bien respecter les dates ainsi que les heures de chargements / livraisons indiquées ci-dessus s’il vous plait.</b><br><br>";

        html += "Pour tout renseignement complémentaire ou réponse, merci de contacter Mr Barthelemy Franck ou Mr Bancheraud Yves:"
            + "<ul><li>Tel: <a href=\"tel:+33442361513\">04.42.36.15.13</a> ou <a href=\"tel:+330442361524\">04.42.36.15.24</a></li>"
            + "<li>Mail: <a href=\"mailto:franck.barthelemy@sealedair.com\">franck.barthelemy@sealedair.com</a> ET "
            + "<a href=\"mailto:yves.bancheraud@sealedair.com\">yves.bancheraud@sealedair.com</a></li></ul><br>";


        return html;
    }

    const sendMail = () => {
        var o = {
            businessInfo: p.businessInfo,
            shipmentOrigin: p.ship,
            form: p.form,
            shipDetail: p.shipDetail,
            sent: true,
            packerContacts: p.sendToPacker === -1 ? [] : p.packerContacts,
            sendToPacker: p.sendToPacker,
            formOriginChange: p.formOriginChange,
            msg: getHtml()
        }

        p.setSaving(true);
        transport.saveShipmentForm(o).then(
            (r) => {
                p.setSaving(false);
                window.location.href = "/supply-chain/tools/expedition?page=tracking";
            }, (error) => {
                p.setError(errorManagement.handleError(p.props, error));
                scrollToTop();
                p.setSaving(false);
            }
        )
    }

    return <div className="custom-tooltip-v2">
        <div className="modal-body blue-purple-bg">
            <button type="button" className="btn-close" aria-label="Close"
                onClick={() => {p.setPackerContacts(); p.setSendToPacker()}}></button>

            {p.sendToPacker === undefined && <div>
                <h3 className="text-center mb-5">A qui désirez-vous envoyer la demande de transport ?</h3>

                <div className="d-flex">
                    <button className="see-btn ms-auto me-auto" onClick={() => p.setSendToPacker(-1)}
                        style={{width: "200px"}}>Aux achats</button>

                    <button className="see-btn ms-auto me-auto" onClick={() => p.setSendToPacker(1)}
                        style={{width: "200px"}}>A l'emballeur</button>
                </div>
            </div>}

            { p.sendToPacker !== undefined && 
                <button className="btn btn-outline-secondary mb-3" onClick={()=>p.setSendToPacker()}>Retour</button>}

            {p.sendToPacker === 1 && <div>
                <form onSubmit={sendMail}>
                    {p.packerContacts.map((v, k) => {
                        return <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">Contact n°{k + 1}</span>
                            <input type="text" className="form-control" value={v}
                                onChange={(e) => p.setPackerContacts(updateSimpleArray(p.packerContacts, k, e.target.value))} />
                            {k > 0 && <button type="button" className="btn btn-outline-warning"
                                onClick={() => p.setPackerContacts(removeAndReturnArray(p.packerContacts, k))}>Effacer</button>}
                        </div>
                    })}
                    <button type="button" className="btn btn-outline-secondary mb-3"
                        onClick={() => { var arr = [...p.packerContacts]; arr.push(""); p.setPackerContacts(arr); }}>
                        Ajouter un contact</button>

                    <div>
                        <h6>Aperçu du corps du mail</h6>
                        <div className="bg-light p-2 rounded-1"
                            dangerouslySetInnerHTML={{ __html: getHtml() }}></div>
                    </div>

                    <div className="text-center mt-3">
                        <button className="btn btn-success" type="submit" disabled={p.saving}>
                            {p.saving && <ButtonWaiting />}Envoyer</button>
                    </div>
                </form>

            </div>}

            {p.sendToPacker === -1 && <div className="bg-light p-3 rounded-1">
                <h5 className="text-center">Un mail classique sera envoyé aux achats, cliquez sur le bouton ci-dessous pour confirmer</h5>
                <div className="text-center">
                    <button className="btn btn-success" onClick={sendMail} disabled={p.saving}>
                        {p.saving && <ButtonWaiting />}
                        Envoyer</button>
                </div>
            </div>}
        </div>
    </div>
}