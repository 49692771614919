import { Fragment } from "react"
import { ButtonWaiting } from "../../functions/PageAnimation"

const Input = ({ spanClass = "", spanWidth = "auto", label, inputType = "text",
    inputClass = "", value, placeholder = "", onChange = () => { }, onBlur, autoFocus = false, required = false,
    readOnly = false, key = 0, labelNext, name, hideSpan = false, defaultValue="",
    onDoubleClick = () => { }, list = "" }) => {

    return <Fragment>
        {!hideSpan &&
            <span className={"input-group-text " + spanClass} style={{ width: spanWidth }}>{label}</span>}

        <input type={inputType} className={"form-control " + inputClass} value={value}
            placeholder={placeholder} onChange={(e) => onChange(e)} onBlur={onBlur} autoFocus={autoFocus}
            required={required} readOnly={readOnly} data-label={label} data-key={key} title={value}
            data-label2={labelNext} name={name} defaultValue={defaultValue} onDoubleClick={onDoubleClick}
            list={list} />
    </Fragment>
}


/**
 * InputGroup component renders a labeled input field with various customizable properties.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.spanClass=""] - Additional CSS classes for the span element.
 * @param {string} [props.spanWidth="auto"] - Width of the span element.
 * @param {string} props.label - The label text for the input field.
 * @param {string} [props.inputType="text"] - The type of the input field.
 * @param {string} [props.inputClass=""] - Additional CSS classes for the input element.
 * @param {string|number} props.value - The value of the input field.
 * @param {string} [props.placeholder=""] - Placeholder text for the input field.
 * @param {function} props.onChange - Callback function to handle input change events.
 * @param {function} props.onBlur - Callback function to handle input blur events.
 * @param {boolean} [props.autoFocus=false] - Whether the input should automatically focus on mount.
 * @param {boolean} [props.required=false] - Whether the input field is required.
 * @param {boolean} [props.readOnly=false] - Whether the input field is read-only.
 * @param {number} [props.key=0] - Unique key for the input element.
 * @returns {JSX.Element} The rendered InputGroup component.
 * @param {string} [props.name] - Name attribute.
 */
const InputGroup = ({ spanClass = "", spanWidth = "auto", label, type = "text",
    inputClass = "", value, placeholder = "", onChange = () => { }, onBlur, autoFocus = false, required = false,
    readOnly = false, key = 0, list = "", labelNext, name, div_class = "" }) => {

    return <div className={"input-group mb-3 " + div_class}>
        <span className={"input-group-text " + spanClass} style={{ width: spanWidth }}>{label}</span>

        <input type={type} className={"form-control " + inputClass} value={value}
            placeholder={placeholder} onChange={(e) => onChange(e)} onBlur={onBlur} autoFocus={autoFocus}
            required={required} readOnly={readOnly} data-label={label} data-key={key} title={value}
            list={list} data-label2={labelNext} name={name} />
    </div>
}

const TextAreaGroup = ({ spanClass = "", spanWidth = "auto", label, type = "text",
    inputClass = "", value, placeholder = "", onChange, onBlur, autoFocus = false, required = false,
    readOnly = false, key = 0, height = "auto" }) => {

    return <div className="input-group mb-3">
        <span className={"input-group-text " + spanClass} style={{ width: spanWidth }}>{label}</span>

        <textarea type={type} className={"form-control " + inputClass} value={value}
            placeholder={placeholder} onChange={(e) => onChange(e)} onBlur={onBlur} autoFocus={autoFocus}
            required={required} readOnly={readOnly} data-label={label} data-key={key} title={value}
            style={{ height: height }} />
    </div>
}

const FloatingTextArea = ({ label, type = "text",
    inputClass = "", value, placeholder = "xxx", onChange, onBlur, autoFocus = false, required = false,
    readOnly = false, key = 0, height = "auto" }) => {

    return <div className="form-floating mb-3">
        <textarea type={type} className={"form-control " + inputClass} value={value}
            placeholder={placeholder} onChange={(e) => onChange(e)} onBlur={onBlur} autoFocus={autoFocus}
            required={required} readOnly={readOnly} data-label={label} data-key={key} title={value}
            style={{ height: height }} />
        <label >{label}</label>
    </div>
}


/**
 * SelectGroup component renders a labeled select field with various customizable properties.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.spanClass=""] - Additional CSS classes for the span element.
 * @param {string} [props.spanWidth="auto"] - Width of the span element.
 * @param {string} props.label - The label text for the select field.
 * @param {string} props.name - The name of input.
 * @param {string} props.labelNext - The next label text for the select field.
 * @param {string} [props.inputType="text"] - The type of the select field.
 * @param {string} [props.inputClass=""] - Additional CSS classes for the select element.
 * @param {string|number} props.value - The value of the select field.
 * @param {string} [props.placeholder=""] - Placeholder text for the select field.
 * @param {function} props.onChange - Callback function to handle select change events.
 * @param {boolean} [props.required=false] - Whether the select field is required.
 * @param {boolean} [props.disabled=false] - Whether the select field is disabled.
 * @param {number} [props.key=0] - Unique key for the select element.
 * @param {Array<{value: string|number, label: string}>} [props.options=[]] - Array of options for the select field.
 * @returns {JSX.Element} The rendered SelectGroup component.
 */
const SelectGroup = ({ spanClass = "", spanWidth = "auto", label, inputType = "text",
    inputClass = "", value, placeholder = "", onChange, required = false,
    disabled = false, key = 0, options = [], labelNext, name = "", no_span=false, display_all = false }) => {

    return <div className="input-group mb-3">
        { !no_span &&
        <span className={"input-group-text " + spanClass} style={{ width: spanWidth }}>{label}</span>}

        <select type={inputType} className={"form-select " + inputClass} value={value}
            placeholder={placeholder} onChange={(e) => onChange(e)} title={value} data-label2={labelNext}
            required={required} disabled={disabled} data-label={label} data-key={key} name={name}>

            <option value="">Choisissez...</option>
            {options.map((v, k) => {
                return ( display_all || v.display ) && <option value={v.value} key={"option " + key + " " + k}>{v.label}</option>
            })}

        </select>
    </div>
}

const SubmitBtn = ({ label, loading, onClick, type_btn = "outline-secondary", divClass = "text-center",
        btnWidth = "auto"
 }) => {
    return <div className={divClass}>
        <button className={"btn btn-" + type_btn} disabled={loading} onClick={onClick }
            style={{width: btnWidth}}>
            { loading && <ButtonWaiting /> }
            {label}</button>
    </div>
}

export default {
    Input,
    InputGroup,
    SelectGroup,
    TextAreaGroup,
    SubmitBtn,
    FloatingTextArea
}