import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import errorManagement from "../../services/errorManagement";
import transport from "../../services/supplyChain/transport.js";
import { ButtonWaiting, scrollToTop, setPopUp } from "../../functions/PageAnimation";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers.js";

export const CountriesList = () => {
  const [list, setList] = useState()
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

useEffect(() => {
    transport.getCountriesList().then(
        (response) => {
            setList(response)
            setLoading(false);
        },
        (error) => {
            setError(errorManagement.handleError(error));
            setLoading(false);
        }
        
    )
}, [])

const updateField = (e, k, field) => {
    var value = (field === "schengen"|| field === "nimp15" ) ? (e.target.checked ? 1 : 0) : e.target.value;
    
    var arr = [...list];
    arr[k][field] = value;
    setList(arr);

    transport.updateCountriesList(field, value, arr[k].country_en).then(
        () => {setPopUp("La modification a bien été prise en compte")},
        (error) => {
            console.error(error)
            setPopUp("La modification a échouée")
        }
    )
}

const getColumns = () => {
    var arr = new Array();
    const columnHelper = createColumnHelper();

    arr.push(columnHelper.accessor('country_fr', {
        header: 'Pays',
        cell: i => <div className={"text-center "}>{i.getValue()}</div>
    }));

    arr.push(columnHelper.accessor('country_en', {
        header: 'Country',
        cell: i => <div className={"text-center "}>{i.getValue()}</div>
    }));

    // Voir pour aligner les checkbox
    arr.push(columnHelper.accessor('schengen', {
        header: 'Schengen',
        cell: i => <div className={"text-center"}>
            <input 
                className="form-check-input" 
                type="checkbox" 
                onChange={(e) => updateField(e, i.row.index, "schengen")}
                checked={i.getValue()}/>
        </div>
    }));

    arr.push(columnHelper.accessor('nimp15', {
        header: 'Nimp15',
        cell: i => <div className={"text-center "}>
            <input 
                className="form-check-input" 
                type="checkbox" 
                onChange={(e) => updateField(e, i.row.index, "nimp15")} 
                checked={i.getValue()}/>
        </div>
    }));

    return arr;
}
  //Set columns
  const columns = getColumns();

  return <div class="w-75 mx-auto">
        <h4 className={"text-center my-4"}>Tableau des Pays</h4>
        {loading && <WaitingRoundSnippers />}<br></br>
        { list !== undefined && 
            <ReactTable columns={columns} data={list} show={1000} classes={"fixFstCol"} top0={true} />
        }
    </div>
   
}