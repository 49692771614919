import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import { Fragment } from "react";
import { CustomToolTipDanger, CustomToolTipInfo, CustomToolTipInfoObj } from "../../../common/smallComponents.js/CustomToolTip";
import { compareTwoDates, currentEnDate, forceString } from "../../../functions/StrFunctions";


export const getColumns = (isCs, checkPreassembly, isStorehouse, checkReliq, reliquat, updateField, 
    firstDate, getLinks, mes = false
) => {
    var arr = new Array();
    const columnHelper = createColumnHelper();

    if (!mes) {
        arr.push(columnHelper.accessor('preassembly', {
            header: <div>
                <input type="checkbox" className="me-1" onClick={(e) => checkPreassembly(e, "all")}/>
                Pré-montage
            </div>,
            cell: i => <div className={"text-center " + (i.getValue() === "O" ? "bg-warning-subtle" : "")}
                style={{ height: "100%" }}>
                <input type="checkbox" className="form-check-input big" onChange={(e) => checkPreassembly(e, i.row.id)}
                    checked={i.getValue() === "O"} disabled={!isCs}></input>
            </div>
        }));
    }

    if (isStorehouse) {
        arr.push(columnHelper.accessor('reliquat', {
            header: 'Rlq',
            cell: i => <div className="text-center no-print">
                {i.row.original.NOCDF &&
                    <input type="checkbox" className="form-check-input big" onChange={(e) => checkReliq(e, i.row.id)}
                        checked={reliquat.includes(i.row.id)}></input>}
            </div>
        }));

        arr.push(columnHelper.accessor('pack_ctrl', {
            header: 'Ctrl colisage',
            cell: i => <div className={"text-center "}>
                {i.row.original.NOCDF &&
                    <input type="checkbox" disabled className="form-check-input big" checked ={ i.getValue()}></input>}
            </div>
        }));
    }

    if (!mes && isCs) {
        arr.push(columnHelper.accessor('visible', {
            header: 'Masquer',
            cell: i => <div className="text-center no-print">
                {i.row.original.NOCDF &&
                    <input type="checkbox" className="form-check-input big" checked={i.getValue() && i.getValue() !== 'O'}
                        onChange={(e) => updateField(e, i.row.id, "visible")}></input>}
            </div>
        }));
    }


    arr.push(columnHelper.accessor('NLIGF', {
        header: 'Li',
        cell: i => <div className={"text-center "}>{i.getValue()}</div>
    }));

    arr.push(columnHelper.accessor('ARAVF', {
        header: 'Ref B+',
        cell: i => <div className={"text-center "}>
            <a href={"/article/info/get?article=" + i.getValue()} target="_blank">{i.getValue()}</a>

            {i.row.original.NOCDF &&
                <Fragment>
                    {i.row.original.date_picking !== null &&
                        <Fragment>
                            <br></br>
                            <span style={{ fontSize: "12px" }}>Picking du {Moment(i.row.original.date_picking).format("DD/MM/yyyy")}</span>
                        </Fragment>
                    }
                    {i.row.original.date_picking === null &&
                        <Fragment>
                            <br></br>
                            <span style={{ fontSize: "12px" }}>Picking non lancé</span>
                        </Fragment>
                    }
                    {i.row.original.date_picking !== firstDate &&
                        <div className="bg-info-subtle rounded" style={{ fontSize: "12px" }}>Nouveau</div>}

                    {(i.row.original.initial_qty && i.row.original.initial_qty !== i.row.original.QTARF) &&
                        <div className="bg-info-subtle rounded" style={{ fontSize: "12px" }}>Qté MAJ (anc. {i.row.original.initial_qty} )</div>
                    }
                </Fragment>
            }

            {!i.row.original.NOCDF &&
                <div>
                    <div>{i.row.original.initial_ar}</div>
                    <div className="bg-danger-subtle rounded" style={{ fontSize: "12px" }}>Supprimé (anc. {i.row.original.initial_qty} )</div>
                </div>}
        </div>
    }));

    if (isCs) {
        arr.push(columnHelper.accessor('BLOCL', {
            header: 'Blocage art.',
            cell: i => <div className={"text-center "}>
                {(i.getValue() && i.getValue() !== "N") && "Bloqué !"}
            </div>
        }));
    }

    
    arr.push(columnHelper.accessor('QTSTL', {
        header: <div>
            <CustomToolTipInfo props={{ tooltipText: "Stock disponible physiquement au magasin" }}/>Qté Mag.
        </div>,
        cell: i => <div className={"text-center"}>{i.getValue()}</div>  
    }))

    arr.push(columnHelper.accessor('QTSTL', {
        header: <div>
                <CustomToolTipInfo props={{ tooltipText: "Stock disponible non affecté" }}/>Stock dispo
            </div>,
        cell: i => <div className={"text-center "}>
            {i.row.original.NOCDF &&
                <div className="except-pad">
                    {parseFloat(i.getValue() - i.row.original.QTAFL).toFixed(0)}
                    <div className="except-pad">
                        <CustomToolTipInfoObj props={{
                            tooltipText: <table className="text-start no-pad" style={{ width: "200px" }}>
                                <tr>
                                    <td style={{ width: "150px" }}>Qté en stock: </td>
                                    <td>{i.getValue()}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td>Qté en besoin: </td>
                                    <td>{i.row.original.QTREL}</td>
                                </tr>
                                <tr>
                                    <td>Qté dispo:</td>
                                    <td>{parseFloat(i.getValue() - i.row.original.QTAFL).toFixed(0)}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td>Qté en cmd.: </td>
                                    <td>{i.row.original.QTCDL}</td>
                                </tr>
                                <tr>
                                    <td>Qté à terme: </td>
                                    <td>{(i.getValue() - i.row.original.QTREL) + i.row.original.QTCDL}</td>
                                </tr>
                            </table>
                        }} />
                    </div>
                </div>}
        </div>
    }));

    arr.push(columnHelper.accessor('QTARF', {
        header: 'Qte att.',
        cell: i => <div className={"text-center "}>
            {i.getValue()}
            <div className="fst-italic" style={{fontSize: "12px"}}>Besoin dispo: {i.row.original.QTAFM || 0}</div>
        </div>
    }));


    arr.push(columnHelper.accessor('moved_need_upd', {
        header: 'Qté sortie',
        cell: i => <div className="text-center">
            <div className="except-pad">
                { isCs && (i.row.original.manual_out > 0 ? i.row.original.manual_out : i.getValue()) }
                { !isCs && 
                    <input className="form-control text-center no-arrows" type="number" 
                        defaultValue={
                            i.row.original.manual_out > 0 
                            ? i.row.original.manual_out 
                            : i.getValue()
                        }
                        onBlur={(e) => updateField(e, i.row.id, "manual_out")} style={{width: "80px"}} /> }
                {(i.getValue() !== i.row.original.QTARF && i.row.original.moved > i.row.original.QTARF) &&
                    <CustomToolTipDanger props={{ tooltipText: <div>Vérifiez les mouvements de stock</div> }} />
                }
            </div>
        </div>
    }));


    arr.push(columnHelper.accessor('ZN04PA', {
        header: 'Renvoi',
        cell: i => <div className={"text-center "}>{i.getValue()}</div>
    }));

    arr.push(columnHelper.accessor('ZN02PA', {
        header: 'Libellé technique',
        cell: i => <div className={"text-center "}>{i.getValue()}</div>
    }));

    arr.push(columnHelper.accessor('ZN03PA', {
        header: 'Désignation',
        cell: i => <div className={"text-center "}>{i.getValue()}</div>
    }));

    arr.push(columnHelper.accessor('detail_order', {
        header: 'Cmd. stock',
        cell: i => <div className={"text-center "} dangerouslySetInnerHTML={{ __html: getLinks(i.getValue()) }}></div>
    }));

    arr.push(columnHelper.accessor('need_order', {
        header: 'Cmd. besoins',
        cell: i => <div className={"text-center "} dangerouslySetInnerHTML={{ __html: getLinks(i.getValue()) }}></div>
    }));

    arr.push(columnHelper.accessor('DTLIM', {
        header: 'Délai du besoin',
        cell: i => i.row.original.NOCDF &&
            <div style={{ height: "100%" }} className={"text-center "
                + (i.row.original.moved < i.row.original.QTARF && compareTwoDates(currentEnDate(), i.getValue()) < 0 ?
                    "bg-danger text-white" : "")}>
                {i.getValue() === null ? "" : Moment(i.getValue()).format("DD/MM/yyyy")}
                {i.row.original.moved < i.row.original.QTARF && compareTwoDates(currentEnDate(), i.getValue()) < 0 && <Fragment>
                    <br></br>
                    (Retard)
                </Fragment>}
            </div>
    }));

    arr.push(columnHelper.accessor('com', {
        header: 'Commentaires SAV',
        cell: i => i.row.original.NOCDF &&
            <div className={"text-center"}>
                <textarea className="form-control no-print" defaultValue={i.getValue()}
                    onBlur={(e) => updateField(e, i.row.id, "com")} readOnly={!isCs} />
                <span className="print-only">{i.getValue()}</span>
            </div>
    }));

    arr.push(columnHelper.accessor('com_mag', {
        header: 'Observation MAG',
        cell: i => i.row.original.NOCDF &&
            <div className={"text-center"}>
                <textarea className="form-control no-print" defaultValue={i.getValue()}
                    onBlur={(e) => updateField(e, i.row.id, "com_mag")} readOnly={!isStorehouse} />
                <span className="print-only">{i.getValue()}</span>
            </div>
    }));

    return arr;
}