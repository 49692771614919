import React, { Fragment, useEffect, useState } from 'react';
import getUrlParam, { capitalizeFirstLetter, forceString, hasRole } from '../../../functions/StrFunctions';
import WaitingRoundSnippers from '../../../common/smallComponents.js/WaitingRoundSnippers';
import BomDaoV3 from '../../../services/machines/BomDaoV3';
import { setPopUp } from '../../../functions/PageAnimation';
import errorManagement from '../../../services/errorManagement';
import { isSideBarOpened, SideNavBarV3 } from '../../../common/smallComponents.js/DesignedIpt';
import { getStatusBg } from './SubComp/Common';
import Overview from './SubComp/Overview';
import _ from 'lodash';
import ChangeValidation from './SubComp/ChangeValidation';
import Inputs from '../../../common/smallComponents.js/Inputs';
import { AccordionSingle } from '../../../common/smallComponents.js/CustomDivs';
import History from '../../common/History';
import Historic from '../smallComp/Historic';
import Spares from './SubComp/Spares';
import CreateArticle from './SubComp/CreateArticle';
import InjectInFocus from './SubComp/InjectInFocus';

const ProcessBOM = (props) => {
    const [loading, setLoading] = useState(true);
    const [type_of_bom, setTypeOfBom] = useState("");

    const [bom_content, setBomContent] = useState();
    const [bom_content_origin, setBomContentOrigin] = useState();
    const [bom_content_change, setBomContentChange] = useState([]);
    const [focus_content, setFocusContent] = useState();
    const [history, setHistory] = useState([]);
    const [art_to_create, setArtToCreate] = useState( false );

    const [articles_list, setArticleList] = useState();
    const [bom_planning, setBomPlanning] = useState();
    const [machine_info, setMachineInfo] = useState();

    const [bom_obj, setBomObj] = useState();

    const [pin, setPin] = useState(isSideBarOpened());
    const [tab, setTab] = useState("overview");

    useEffect(() => {
        const startTime = performance.now();
        const machine = getUrlParam(props, "machine");
        const tob = getUrlParam(props, "type_of_bom");

        setTypeOfBom(tob);

        BomDaoV3.getIntranetBom(machine, tob).then(
            (res) => {
                const endTime = performance.now();
                res.admin_infos.push( "Temps total: " + ( ( endTime - startTime ) / 1000 ).toFixed(0) + " s");
                console.log(res)
                setBomObj(res)
                setBomContent(res.bom_content);
                setBomContentOrigin( _.cloneDeep( res.bom_content ) );
                setArticleList(res.articles_list);
                setBomPlanning(res.bom_planning);
                setMachineInfo(res.machine_info);
                setFocusContent( res.focus_content );
                setHistory( res.history );
                setLoading(false);
            }, (error) => {
                setPopUp(errorManagement.handleError(props, error), "danger", 5000);
                setLoading(false);
            }
        )

    }, [])

    useEffect( () => { 
        if( bom_content && bom_content.length > 0 ) 
            setArtToCreate( bom_content.filter( v => v.ar === null || v.ar.trim() === "" ).length > 0 )
     }, [bom_content])

    function navClass( link ){
        if( link === tab ) return "ms-2 fw-bold";
    }

    const notifyPurchasers = () => {
        BomDaoV3.notifyInjectedBOM(bom_planning, machine_info, type_of_bom, "purchase");
        setPopUp("Envoi en cours vou serez en copie")
    }

    return (
        <div className="bg-see navbarV3 right-side">
            <SideNavBarV3 setPin={setPin} force_open={true} content={<div className='ps-3 pe-1'>
                {!loading && <Fragment>
                    <div className='mb-3'>
                        <h6 className='text-center'>Navigation</h6>
                        <div className={'mb-3 ' + navClass("overview") }>
                            <a href='#' onClick={() => setTab("overview")}>Nomenclature</a>
                        </div>

                        { ( machine_info && forceString( machine_info.lot_piece_det ).startsWith("Vendu") ) &&
                        <div className={'mb-3 ' + navClass("spares") }>
                            <a href='#' onClick={() => setTab("spares")}>
                                { ( bom_obj && bom_obj.spares_doublons.length > 0 ) &&
                                        <img className='w-30 me-1' src='/common/alert_icon.png'/>
                                }
                                Lot de pièces détachées</a>
                        </div>}

                        <div className={'mb-3 ' + navClass("history") }>
                            <a href='#' onClick={() => setTab("history")}>Historique</a>
                        </div>
                    </div>

                    <div className='mb-3'>
                        <h6 className='text-center'>Actions</h6>

                        { (bom_content && art_to_create ) && <div className={'mb-3 ' + navClass("create_articles") }>
                            <a href='#' onClick={() => setTab("create_articles")}>Articles à créer</a>
                        </div>}

                        {bom_content_change.length > 0 && <div className={'mb-3 ' + navClass("change_validation") }>
                            <a href='#' onClick={() => setTab("change_validation")}>Modifs à sauvegarder</a>
                        </div>}

                        { ( bom_planning && bom_planning.status.toLowerCase().indexOf( "be" ) !== -1 ) &&
                            <div className={'mb-3 ' + navClass("change_validation") }>
                                <a href='#' onClick={notifyPurchasers}>Valider et notifier les achats</a>
                            </div>
                        }

                        { ( hasRole(["ROLE_ADMIN", "ROLE_PURCHASE"])  && !art_to_create) && 
                        <div className={'mb-3 ' + navClass("inject_focus") }>
                            <a href='#' onClick={() => setTab("inject_focus")}>Injecter dans Focus</a>
                        </div>}

                    </div>
                </Fragment>}


                <AccordionSingle title="Infos" body={
                    <ul>
                        <li className='mb-3'>Les modifs ne sont plus instantanées, cliquez sur 'Sauvegarder les modifs de la nomenclature' pour les appliquer</li>
                        <li className='mb-3'>XPMC et XPEL sont des sous-ensemble pour les spares (X étant le numéro de ligne de la machine)</li>
                        { ( bom_obj && hasRole(["ROLE_ADMIN"]) ) && bom_obj.admin_infos.map( v => 
                            <li>{v}</li>
                        )}
                    </ul>
                } />

            </div>} />

            <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>

                <h5 className='text-center'>Nomenclature {capitalizeFirstLetter(type_of_bom)}</h5>
                {loading && <WaitingRoundSnippers launchGame={true}/>}

                {machine_info &&
                    <div className='d-flex mb-1'>
                        <div className='w-100'>
                            Machine: <a className='ms-1 fw-bold' href={"/machine?machine=" + machine_info.no_machine.trim()}
                                target='_blank'>{machine_info.no_machine}</a>
                        </div>
                        <div className='w-100'>
                            Config: <strong>{machine_info.typ_config}</strong>
                        </div>
                        <div className='w-100'>
                            Affaire: <a className='ms-1 fw-bold' href={"/business?code=" + machine_info.affaire.trim()}
                                target='_blank'>{machine_info.affaire}</a>
                        </div>
                        <div className='w-100 d-flex'>
                            <div>
                                Statut:
                            </div>
                            <div className={'ms-1 rounded w-100 ps-1 fw-bold text-center ' + getStatusBg( bom_planning && bom_planning.status)}>
                                {bom_planning && bom_planning.status}
                            </div>
                        </div>
                    </div>
                }
                <div>
                    {tab === "overview" && <Overview bom_content={bom_content} setBomContent={setBomContent}
                        articles_list={articles_list}
                        bom_content_change={bom_content_change} setBomContentChange={setBomContentChange}
                        bom_content_origin={bom_content_origin} focus_content={focus_content} />}

                    {tab === "change_validation" && <ChangeValidation bom_content={bom_content} setBomContent={setBomContent}
                        bom_content_change={bom_content_change} setBomContentChange={setBomContentChange}
                        bom_content_origin={bom_content_origin} loading={loading} setLoading={setLoading}
                        type_of_bom={type_of_bom} machine_info={machine_info} setTab={setTab} />}

                    { tab === "spares" && <Spares bom_content={bom_content} 
                        type_kit={machine_info.lot_piece_det} bom_obj={bom_obj}/> }

                    { tab === "history" && <Historic historic={history}/>}
                    
                    { tab === "create_articles" && <CreateArticle bom_content={bom_content} 
                        setBomContent={setBomContent} articles_list={articles_list}
                        bom_content_change={bom_content_change} setBomContentChange={setBomContentChange}
                        bom_content_origin={bom_content_origin} machine_info={machine_info} setLoading={setLoading}/>}

                    { tab === "inject_focus" && <InjectInFocus bom_content={bom_content} focus_content={focus_content}
                        type_of_bom={type_of_bom} machine_info={machine_info} setLoading={setLoading}
                        loading={loading} bom_planning={bom_planning} setBomPlanning={setBomPlanning} type_kit={machine_info.lot_piece_det} />
                    }
                </div>

            </div>
        </div>
    );
};

export default ProcessBOM;