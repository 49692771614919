import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { hasRole } from "../../../functions/StrFunctions";

const BOM = (props) => {
    const { t } = useTranslation();

    return (
        (props && (props.roles.includes('ROLE_ADMIN') || props.roles.includes('ROLE_BOM_VALIDATION')
            || props.roles.includes('ROLE_DESIGNER') || props.roles.includes('ROLE_PURCHASE')
            || props.roles.includes('ROLE_KIT_MANAGE') || props.roles.includes('ROLE_KIT_USE')
            || props.roles.includes('ROLE_BOM_VIEW'))
        ) &&

        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Nomenclatures
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {
                    props.roles && (props.roles.includes("ROLE_DESIGNER") || props.roles.includes("ROLE_ADMIN")) &&
                    <Fragment>
                        <li>
                            <a href={"/bom/read"} className="dropdown-item">
                                Importer
                            </a>
                        </li>
                    </Fragment>
                }

                {
                    props.roles && (props.roles.includes("ROLE_DESIGNER") || props.roles.includes('ROLE_PURCHASE')
                        || props.roles.includes("ROLE_ADMIN") || props.roles.includes('ROLE_BOM_VIEW')) &&
                    <li>
                        <a href={"/bom/list"} className="dropdown-item">
                            Consulter
                        </a>
                    </li>

                }

                <li><hr className="dropdown-divider" /></li>
                <a class="nav-link disabled text-center fw-bold" aria-disabled="true">V.3</a>
                {/* , "ROLE_DESIGNER" */}
                {hasRole(["ROLE_ADMIN"]) &&
                    <li>
                        <a href={"/bom/read/v3"} className="dropdown-item">
                            Importer
                        </a>
                    </li>}

                    {/* , "ROLE_DESIGNER", "ROLE_PURCHASE", "ROLE_BOM_VIEW" */}
                {hasRole(["ROLE_ADMIN"]) &&
                    <li>
                        <a href={"/bom/list"} className="dropdown-item">
                            Consulter
                        </a>
                    </li>}



                {hasRole(["ROLE_ADMIN", "ROLE_PURCHASE"]) &&
                    <a href={"/tables?table=cabinets"} className="dropdown-item">
                        Prix des armoires
                    </a>}


                {
                    props.roles && (props.roles.includes("ROLE_DESIGNER") || props.roles.includes("ROLE_ADMIN")) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                            <a href={"/article/needs/create"} className="dropdown-item">
                                Ajouter des besoins
                            </a>
                        </li>
                        <li>
                            <a href={"/article/needs/list"} className="dropdown-item">
                                Liste des besoins
                            </a>
                        </li>
                    </Fragment>
                }

                {
                    props.roles && (props.roles.includes("ROLE_KIT_MANAGE")
                        || props.roles.includes('ROLE_KIT_USE')
                        || props.roles.includes("ROLE_ADMIN")) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>
                        {props.roles && (props.roles.includes("ROLE_KIT_MANAGE")
                            || props.roles.includes("ROLE_ADMIN")) &&
                            <a href={"/article/kit"} className="dropdown-item">
                                Création de kits
                            </a>}

                        <a href={"/article/kit/list"} className="dropdown-item">
                            Liste des kits
                        </a>

                        <a href={"/article/kit/need/list"} className="dropdown-item">
                            Kits lancés en affaire
                        </a>
                    </Fragment>
                }
            </ul>
        </li>
    )

}

export default BOM;