import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { hasRole } from "../../../functions/StrFunctions";

const BusinessAndMachine = (props) => {
    const { t } = useTranslation("business");

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Espace projet
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">


                {
                    (props.roles && (props.roles.includes("ROLE_WRITE_FM") || props.roles.includes("ROLE_READ_FM")
                        || props.roles.includes("ROLE_ADMIN"))) &&
                    <Fragment>
                        <li>
                            <a href={"/my-space"} className="dropdown-item">
                                Espace affaire
                            </a>
                        </li>

                        {
                            props.roles.includes("ROLE_ADMIN") && 
                            <li>
                            <a href={"/life-sheet-machine"} className="dropdown-item">
                                Fiches de vie machines
                            </a>
                        </li>
                        }

                        <li>
                            <a href="/purchase/order/machine/check" className="dropdown-item">
                                Suivi achats / machines
                            </a>
                        </li>

                        <li>
                            <a href="/machine/tecma" className="dropdown-item">
                                Suivi achats / TECMA
                            </a>
                        </li>


                        {props.roles.includes("ROLE_INTERNAL") &&
                            <li>
                                <a href="/general-planning" className="dropdown-item">
                                    Planning général
                                </a>
                            </li>
                        }

                    </Fragment>
                }

                {/*
                    (props.roles && (props.roles.includes("ROLE_TRANSPORT") || props.roles.includes("ROLE_ADMIN"))) &&
                    <Fragment>
                     <li><hr className="dropdown-divider" /></li>
                     
                        <li>
                            <a href={"/supply-chain/transport/list"} className="dropdown-item">
                                Gestion des transports
                            </a>
                        </li>
                        <li>
                            <a href="/supply-chain/departure/request" className="dropdown-item">Départ de machine</a>
                        </li>
                    </Fragment>
                */}

                {
                    (props.roles && (props.roles.includes("ROLE_ADMIN_BILL") || props.roles.includes("ROLE_WRITE_FM"))) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>

                        <li>
                            <a href="/bill/machines/list" className="dropdown-item">Facturation</a>
                        </li>
                    </Fragment>
                }

                {
                    (props.roles && (props.roles.includes("ROLE_ADMIN_BILL")
                        || props.roles.includes("ROLE_WRITE_FM")
                        || props.roles.includes("ROLE_READ_FM")
                        || props.roles.includes("ROLE_SEE"))) &&
                    <Fragment>

                        <li><hr className="dropdown-divider" /></li>

                        <li>
                            <a href="/budget" className="dropdown-item">{t('ct budget')}</a>
                        </li>
                    </Fragment>
                }

                {
                    (props.roles && (props.roles.includes("ROLE_ADMIN")
                        || props.roles.includes("ROLE_SALESMAN"))) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>

                        <li>
                            <a href="/contract-review" className="dropdown-item">Revue de contrat</a>
                        </li>
                    </Fragment>
                }

                {
                    (props.roles && (props.roles.includes("ROLE_ADMIN") || props.roles.includes("ROLE_MACHINE_PWD"))) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>

                        <li>
                            <a href="/machine/pwd" className="dropdown-item">Génération pwd machine</a>
                        </li>
                    </Fragment>
                }

                {
                    (props.roles && (props.roles.includes("ROLE_ADMIN") || props.roles.includes("ROLE_MACHINE_PWD"))) &&
                    <Fragment>
                        <li><hr className="dropdown-divider" /></li>

                        <li>
                            <a href="/machine/in-reserve" className="dropdown-item">Machine en réserve (proto)</a>
                        </li>
                    </Fragment>
                }

                {hasRole(["ROLE_WRITE_FM", "ROLE_ADMIN"]) && <Fragment>
                    <li><hr className="dropdown-divider" /></li>
                    <li>
                        <a href={"/qhse/ppm"} className="dropdown-item">
                            Log. PPM
                        </a>
                    </li>

                    <li>
                        <a href={"/customer-client/fat"} className="dropdown-item">
                            FAT interne
                        </a>
                    </li>
                </Fragment>}

            </ul>
        </li>
    )

}

export default BusinessAndMachine;