import { removeAndReturnArray } from "../../../../functions/ArrayFunctions";
import { setPopUp } from "../../../../functions/PageAnimation";
import { forceString } from "../../../../functions/StrFunctions";

export function getStatusBg(val) {
    if ( ! val || val === null) return "";

    if (val === "En attente Achat") {
        return "bg-success-subtle"
    } else if (val.indexOf("téléchargé") !== -1 ||val.indexOf("Injecté") !== -1) {
        return "bg-warning-subtle";
    } else if (val.indexOf("BE") !== -1) {
        return "bg-primary-subtle";
    }

    return "";
}

/**
     * ON CATEGORY CHANGE
     */
export const categoryChange = (bom_content, bom_content_change, setBomContent, bom_content_origin, setBomContentChange, 
        optionsArray, e, k) => {
    var new_val = e.target.value.trim();

    // Check if this category exists
    if (!optionsArray.includes(new_val)) {
        setPopUp("La catégorie " + new_val + " n'existe pas", "danger", 3000)
        return
    }

    //Check if there any change
    if (bom_content_origin[k].cat.trim() === new_val) {
        //If come back to origin value remove the change
        cancelChange(k, "cat", new_val);
        return;
    }

    // Apply change
    addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "cat", "Catégorie", new_val, bom_content_origin[k].cat, undefined, undefined, bom_content_origin[k].id);

    setPopUp("Pensez à valider les modifs");
}

/**
 * ON QTY CHANGE
 */
export const qtyChange = (bom_content, bom_content_change, setBomContent, bom_content_origin, setBomContentChange, e, k) => {
    var new_val = parseFloat(e.target.value);

    // Check if > 0
    if (new_val < 0) {
        setPopUp("La quantité doit être positive", "danger", 3000)
        return
    }

    //Check if there any change
    if (parseFloat(bom_content_origin[k].qty) === new_val) {
        //If come back to origin value remove the change
        cancelChange(k, "qty", new_val);
        return;
    }

    // Apply change
    addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "qty", "Qté à commander", new_val, bom_content_origin[k].qty, undefined, undefined, bom_content_origin[k].id);

    setPopUp("Pensez à valider les modifs");
}

/**
 * ON REF CHANGE
 */
export const refChange = async (bom_content, bom_content_origin, articles_list, bom_content_change, 
        setBomContentChange, setBomContent, e, k) => {
    var new_val = e.target.value;
    var bom = [...bom_content];
    var change = [...bom_content_change];

    //Check if there any change
    if (bom_content_origin[k].ref.trim() === new_val) {
        //If come back to origin value remove the change
        var res = await cancelChange(k, "ref", new_val, true, change);
        change = res;
        bom[k].ref = bom_content_origin[k].ref

        // get the previous associated article
        await cancelChange(k, "ar", bom_content_origin[k].ar, true, change);
        bom[k].ar = bom_content_origin[k].ar
        bom[k].frenchLabel = bom_content_origin[k].frenchLabel
        bom[k].stock = bom_content_origin[k].available_at_term;
        bom[k].coms = bom_content_origin[k].coms;

        setBomContentChange(change);
        return;
    }

    // Check doublons
    var doublon = bom_content_origin.findIndex(v => v.ref.trim().toUpperCase() === new_val.trim().toUpperCase()
        && v.se === bom_content[k].se && v.rep === bom_content[k].rep);

    if (doublon !== -1) {
        setPopUp("La combinaison de cette ref + rep + S/E existe déjà. Doublon non autorisé.", "danger", 5000)
        return;
    }

    // Set change for ref
    var res = await addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "ref", "Ref.", new_val, bom_content_origin[k].ref, true, change, bom_content_origin[k].id);

    // Change here the ref in the BOM
    bom[k].ref = new_val;

    // Check if ref exists to get article code else erase article code
    var idx = articles_list.findIndex(v => v.ref === new_val);

    // Change article code and label
    if (idx !== -1) {
        res = await addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "ar", "Art.", articles_list[idx].art, bom_content_origin[k].ar, true, change, bom_content_origin[k].id);
        bom[k].ar = articles_list[idx].art;
        bom[k].frenchLabel = articles_list[idx].french_label;
        bom[k].stock = articles_list[idx].available_at_term;
        bom[k].coms = articles_list[idx].coms;
    } else {
        res = await addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "ar", "Art.", "", bom_content_origin[k].ar, true, change, bom_content_origin[k].id);
        bom[k].ar = "";
        bom[k].frenchLabel = "";
        bom[k].stock = "";
        bom[k].coms = "";
    }

    setBomContent(bom);
    setBomContentChange(change);

    setPopUp("Pensez à valider les modifs");
}

/**
 * ON MAKER CHANGE
 */
export const makerChange = (bom_content, bom_content_change, setBomContent, setBomContentChange,bom_content_origin, e, k) => {
    var new_val = e.target.value.trim();

    //Check if there any change
    if (bom_content_origin[k].maker.trim() === new_val) {
        //If come back to origin value remove the change
        cancelChange(k, "maker", new_val);
        return;
    }

    // Apply change
    addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "maker", "Fabricant", new_val.toUpperCase(), bom_content_origin[k].maker, undefined, undefined, bom_content_origin[k].id);

    setPopUp("Pensez à valider les modifs");
}

/**
 * ON CHAPTER CHANGE
 */
export const familyChange = (bom_content, bom_content_change, setBomContent, setBomContentChange,bom_content_origin, e, k) => {
    var new_val = e.target.value.trim();

    //Check if there any change
    if ( forceString( bom_content_origin[k].family_prod) .trim() === new_val) {
        //If come back to origin value remove the change
        cancelChange(k, "family_prod", new_val);
        return;
    }

    // Apply change
    addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "family_prod", "Famille de produit", new_val.toUpperCase(), bom_content_origin[k].family_prod, undefined, undefined, bom_content_origin[k].id);

    setPopUp("Pensez à valider les modifs");
}

/**
 * ON ARTICLE CHANGE
 */
export const artChange = async (bom_content, bom_content_origin, bom_content_change, setBomContentChange, 
        articles_list, setBomContent, e, k) => {
    var new_val = e.target.value;
    var bom = [...bom_content];
    var change = [...bom_content_change];

    //Check if there any change
    if ( forceString( bom_content_origin[k].ar ).trim() === new_val) {
        //If come back to origin value remove the change
        var res = await cancelChange(k, "ar", bom_content_origin[k].ar, true, change);
        bom[k].ar = bom_content_origin[k].ar
        change = res;

        // get the previous associated ref
        res = await cancelChange(k, "ref", bom_content_origin[k].ref, true, change);
        bom[k].ref = bom_content_origin[k].ref
        change = res;

        bom[k].frenchLabel = bom_content_origin[k].frenchLabel
        bom[k].stock = bom_content_origin[k].available_at_term;
        bom[k].coms = bom_content_origin[k].coms;

        setBomContentChange(change);

        return;
    }

    //Check if the article code exists if it's not empty
    var idx = articles_list.findIndex(v => v.art === new_val);
    if (idx === -1) {
        setPopUp("Cet article n'existe pas", "danger", 3000);
        return;
    }

    // Check doublons if not empty
    if (new_val.trim() !== "") {
        var doublon = bom_content_origin.findIndex(v => forceString(v.ar).trim().toUpperCase() === new_val.trim().toUpperCase()
            && v.se === bom_content[k].se && v.rep === bom_content[k].rep);

        if (doublon !== -1) {
            setPopUp("La combinaison de cet ar + rep + S/E existe déjà. Doublon non autorisé.", "danger", 5000)
            return;
        }
    }


    // Set change for ref
    var res = await addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "ar", "Code article", new_val, bom_content_origin[k].ar, true, change, bom_content_origin[k].id);

    // Change here the ref in the BOM
    bom[k].ar = new_val;

    // Change article code
    res = await addChange(bom_content, bom_content_change, setBomContent, setBomContentChange,k, "ref", "Ref.", articles_list[idx].ref, bom_content_origin[k].ref, true, change, bom_content_origin[k].id);
    bom[k].ar = articles_list[idx].art;
    bom[k].frenchLabel = articles_list[idx].french_label;
    bom[k].stock = articles_list[idx].available_at_term;
    bom[k].coms = articles_list[idx].coms;

    setBomContent(bom);
    setBomContentChange(change);

    setPopUp("Pensez à valider les modifs");
}

/**
     * LOG CHANGE
     */
export function addChange(bom_content, bom_content_change, setBomContent, setBomContentChange, k,
    field, label, new_val, old_val, do_note_upd = false, change_param, id) {

    return new Promise((resolve, reject) => {
        var change = change_param !== undefined ? change_param : [...bom_content_change];
        var bom = [...bom_content];

        //Check if there is already change
        var idx = change.findIndex(v => parseInt(v.id) === parseInt(k) && field === v.field);

        if (idx === -1) {
            change.push({
                id: k,
                field: field,
                label: label,
                new_val: new_val,
                old_val: old_val,
                se: bom_content[k].se,
                rep: bom_content[k].rep,
                row_id: id
            });

            idx = change.length - 1;
        } else {
            change[idx][field] = new_val;
        }

        bom[k][field] = new_val;

        if (!do_note_upd) {
            setBomContent(bom);
            setBomContentChange(change);
        }


        resolve({
            bom: bom,
            change: change
        })
    });
}

/**
 * CANCEL CHANGE
 */
export async function cancelChange(bom_content, bom_content_change, setBomContent, setBomContentChange, k,
    field, val, do_note_upd = false, change_param) {
    return new Promise((resolve) => {

        if (!do_note_upd) {
            var idx = bom_content_change.findIndex(v => parseInt(v.id) === parseInt(k) && field === v.field);

            if (idx === -1) resolve(false);

            setBomContentChange(removeAndReturnArray(bom_content_change, idx));

            // Apply change in table
            var bom = [...bom_content];
            bom[k][field] = val;
            setBomContent(bom);
        } else {
            var idx = change_param.findIndex(v => parseInt(v.id) === parseInt(k) && field === v.field);
            if (idx === -1) resolve(false);

            change_param = removeAndReturnArray(change_param, idx);
        }


        resolve(change_param)
    });
}