import { Fragment, useState } from "react"
import { fileToBase64, getBase64 } from "../../../functions/StrFunctions";
import Camera, { FACING_MODES } from "react-html5-camera-photo";

const Photos = () => {

    const [photo, setPhoto] = useState()
    const [takeAPhoto, setTakeAPhoto] = useState(false);

    const onChooseFile = (e) => {
        if (e.target.files.length === 0)
            return;

        getBase64(e.target.files[0], (res) => {
            setPhoto(res)
        });
    }

    const cancelPhoto = () => {
        setTakeAPhoto(false);
    }

    const takePhoto = (e) => {
        setTakeAPhoto(true);
    }
    const handlePhoto = (data) => {
        setPhoto(data);
    }
    // fileToBase64

    return <Fragment>
                <button type="button" className="custom-file-upload photo" style={{ marginLeft: "10px" }}
                    onClick={takePhoto}></button>
                <label className="custom-file-upload folder" style={{ marginLeft: "10px", marginRight: "auto" }}>
                    <input type="file" accept="image/*" onChange={onChooseFile} />
                </label>

                {photo && <img src={photo}></img>}

                {takeAPhoto &&
                    <div className="custom-tooltip">

                        <button type="button" className="btn btn-info" onClick={cancelPhoto}>Annuler</button>

                        <br></br>
                        <Camera 
                            idealFacingMode={FACING_MODES.ENVIRONMENT}  
                            isMaxResolution={true}
                            onTakePhoto={(data) => { handlePhoto(data) }} 
                        />
                    </div>
                }
            </Fragment>
    
}

export default Photos

// Voir pour telecharger en b64