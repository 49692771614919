import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Inputs from '../../../common/smallComponents.js/Inputs';
import WaitingRoundSnippers from '../../../common/smallComponents.js/WaitingRoundSnippers';
import { ButtonWaiting, setPopUp } from '../../../functions/PageAnimation';
import { getEnFormat } from '../../../functions/StrFunctions';
import AI from '../../../services/common/AI';
import errorManagement from '../../../services/errorManagement';
import he from "he";

const IA = () => {
    const [prompt, setPrompt] = useState("");
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState();
    const [model, setModel] = useState("qwen:7b");
    const [models, setModels] = useState();
    const HTML_REQUIRED = "Provide the following response in valid HTML format with proper headings, paragraphs, and lists.";
    const AI_URL = "https://cltlaitestd01.sealedair.corp/ollama";

    const [context, setContext] = useState({
        "role": "system",
        "content": getContext()
    });
    const [messages, setMessages] = useState([context])

    // Model qwen:7b: 4731.80 milliseconds
    // Model gemma: 14375.90 milliseconds
    // Model codegemma: 14549.30 milliseconds
    // Model phi4: 23855.90 milliseconds
    // Model mistral: 7033.80 milliseconds
    // Model llama3.2:1b: 3290.60 milliseconds
    // nomic-embed-text doesn't work
    // Model llama3.2:lastest: 9072.80 milliseconds
    // Model llama2: 12276.50 milliseconds

    useState(() => {
        //Get models 
        AI.getModels().then(
            (res) => {
                var arr = [];

                for (let i = 0; i < res.models.length; i++) arr.push({
                    label: res.models[i].name + ' ( upd at: ' + getEnFormat(res.models[i].modified_at) + " )",
                    value: res.models[i].model,
                    display: true
                })
                setModels(arr)
            }, (error) => {
                setPopUp(errorManagement.getErrorMsg(error), "danger", 5000)
            }
        )
    }, []);

    useEffect(() => {
        if (prompt !== "") callLlamaChat({ preventDefault: () => { } });
    }, [model])

    function getContext() {
        const assistantDescription = `
You are B+ assistant. He is an assistant for the call center of an After Sale service in a company that designs, assembles, tests, and installs 2-dimensional packaging machines to logistic warehouses. He will directly answer our customers. It should help detect the criticality of the situation and transfer the request to the Spare Parts area if the customer only wants to buy spare parts, or to the Interventions area if the machines need to be checked for maintenance or because of a crash. It is important that the assistant requests the name of the customer, the name of the end user, the location of the site, the serial number of the machine, and a contact on site (name and phone number) if not in the original message. If we need to go on site, he will then calculate the travel time by car, train, and plane from Gémenos in France and inform the customer. If the topic is a spare parts order, the assistant will ask for the part reference and will propose to add an Excel file with the customer’s needs. It should answer formally to the customer and temper the customer if they are upset. The assistant cannot refer to any customer or give any sensitive information about our company. After each interaction, the assistant will send a recap email to the support services team summarizing the customer's request, including the name of the customer, the end user, the location of the site, the serial number of the machine, and any other pertinent information. The subject of the email will be composed this way : SERIAL NUMBER - END USER : short description of the request. Send the email to nicolas.roquet@sealedair.com. If a file is attached to the conversation, make sure it is attached to the recap email, it is not necessary to read it and transcript it into the email core message.

Additionally, this assistant handles requests related to height reduction machines used at the end of logistic lines before shipment. These machines fold boxes to reduce their height to match the product, then apply a lid to seal them. The key stages of the process include:

1. **Marking Station**: Boxes are introduced into the machine, where folding lines are created for height reduction.
2. **Folding Station**: A plate folds the box along the marking lines. Glue is applied to ensure a strong fold.
3. **Lidding Cavity**: A lid is glued and pressed onto the top of the folded box.

**Main Conveyor**: A step-by-step conveyor driven by a servomotor moves the boxes. Pushers and holders maintain the boxes during the transfer, and the distance between these elements matches the length of the boxes.

**Lid Storage and Application**: Lids are stored in a magazine and moved by a margin arm onto guiding rails (the transfer unit), which extends to the lidding station. The pusher moves the lids, and glue lines are applied during transfer. The distance between the guiding rails must match the lid length plus four millimeters.

**Tolerance Considerations**: Box dimensions must stay within +/- 2 mm to avoid issues. Misalignment can affect folding, lid application, and glue lines. Lid dimensions must be within a +4 mm tolerance; otherwise, they can get stuck, causing glue accumulation and blockages.

**Lid Dimension Variations**: Lids may vary due to production issues or differences between curved and flat lids. If the machine is tuned for a specific dimension, these variations can significantly impact the proper functioning of the transfer unit, potentially causing misalignment and operational delays.

**Frequent Problems at the Folding Station**:
- **Weak Folding Hold**: When the folding station lifts, the folds of the box may not hold properly. This issue is typically caused by one of the following factors:
  - **Glue Line Issues**: If the glue lines are not properly spread, the folds will be weak and unable to maintain their shape.
  - **Misalignment of Marking Lines**: There are diagonal lines on the left and right sides of the box, and horizontal lines on all sides. These lines must meet precisely at one spot to ensure a clean fold.
    - If the diagonal line on the left and the horizontal lines do not meet in the correct spot, the fold will be misaligned.
    - Misalignment creates stress on the fold, resulting in bumps or deformities at the corners.
    - This can cause a spring effect, where the folded flaps open because the fold is not clean and properly secured.

**Daily Maintenance Routine**:
1. **Cleaning**: Blow pressurized air into the machine to remove dust and cardboard pieces. Remove excess glue, focusing on sub-assemblies where cardboard may get stuck (marking cavity, lidding cavity, and transfer unit). Ensure there are no obstructions.
2. **Initialization**: After cleaning, initialize the machine and start production.

**Common Error - Box Stuck in Marking Cavity**:
- This issue arises when the box gets stuck in the marking cavity and doesn't go down with the elevator, often due to incorrect box dimensions.
- 80% of cases are caused by **overfilled boxes**, where products deform the box. If the box is too deformed, it may crash when entering the marking cavity or get stuck during the marking process, preventing the elevator from lowering the box.

**Box and Tray Types**:
- **FEFCO 0453**: Tray style, generally steadier in dimensions but limited in available sizes.
- **FEFCO 0200**: Commonly used boxes, but prone to dimension variations, especially with larger sizes, which can result in stoppages during installation and operation.

**Challenges with FEFCO 0200**: Dimension variation is common, especially with larger boxes, causing machine stoppages. During installation, it's essential to review box specifications with the supplier to ensure alignment.

**Document Creation**:
The assistant will create documents with the following formatting requirements:
- **Header**: The company logo (uploaded as 'see-corporate-logo-descriptor-full-color.png') on the left and the current date on the right.
- **Footer**: The footer will contain the page number on the right and the reference: "This document is the property of Sealed Air and cannot be shared" in the center.
- **Colors**: Use the main colors of the company logo (teal and red) in the document design.
`;

        return assistantDescription;

    }

    const changeContext = ( mode ) => {
        var context = "";

        if( mode === "dev" ){
            context = "You are a developer assistant";
            setModel("codegemma:latest");
        }else if( mode === "sav" ){ 
            context = getContext();
            setModel("qwen:7b");
        }

        setContext({
            "role": "system",
            "content": context
        })
    }

    // const callLlamaBack = async (e) => {

    //     e.preventDefault();
    //     setLoading(true);
    //     const startTime = performance.now();
    //     setDuration();

    //     setResponse("Thinking...");

    //     try {
    //         const response = await AI.getResponse(model, prompt);

    //         const converted_res = parseOllamaResponse(response);
    //         setResponse(converted_res.response);

    //         const endTime = performance.now();
    //         setDuration(endTime - startTime);

    //         setLoading(false);
    //     } catch (error) {
    //         console.error(error)
    //         setLoading(false);
    //         setPopUp(error, "danger", 5000)
    //     }


    // };

    const callLlamaChat = async (e) => {

        e.preventDefault();
        setLoading(true);
        const startTime = performance.now();
        setDuration();

        setResponse("Thinking...");

        try {
            var o = [...messages];
            o.push({ role: "user", content: prompt });
            setPrompt("");
            setMessages( s => [...s, { role: "user", content: prompt }] )

            const response = await AI.chat(model, o);
            const converted_res = parseOllamaChatResponse(response);
            
            setMessages( s => [...s, { role: "assistant", content: converted_res.response }] )
            // o.push({ role: "assistant", content: converted_res.response })
            // setMessages(o);

            const endTime = performance.now();
            setDuration(endTime - startTime);
            

            setLoading(false);
        } catch (error) {
            console.error(error)
            setLoading(false);
            setPopUp(error, "danger", 5000)
        }


    };

    function parseOllamaResponse(rawData) {
        const lines = rawData.trim().split("\n"); // Split each line as JSON object
        let parsedData = lines.map(line => {
            let jsonObject = JSON.parse(line);

            // Ensure response exists and is a string
            if (jsonObject.response && typeof jsonObject.response === "string") {
                console.log(jsonObject.response)
                jsonObject.response = he.decode(jsonObject.response) // Decode HTML entities
                    .replace(/\n/g, "<br>") // Replace new lines with <br>
                    .replace(/```([\s\S]*?)```/g, '<pre>$1</pre>') // Format code blocks
                    .replace(/`(.*?)`/g, '<code>$1</code>'); // Format inline code
            }

            return jsonObject;
        });

        // Extract and merge responses
        const finalResponse = parsedData.map(obj => obj.response).join("");

        // Extract metadata from last object
        const lastEntry = parsedData[parsedData.length - 1];

        return {
            model: lastEntry.model,
            response: finalResponse,
            done: lastEntry.done,
            metadata: {
                created_at: lastEntry.created_at,
                done_reason: lastEntry.done_reason,
                total_duration: lastEntry.total_duration,
                load_duration: lastEntry.load_duration,
                eval_count: lastEntry.eval_count,
                eval_duration: lastEntry.eval_duration
            }
        };
    }

    function parseOllamaChatResponse(rawData) {

        // Split raw data into lines and parse each line as a JSON object
        const lines = rawData.trim().split("\n").map(line => JSON.parse(line));

        // Extract and merge responses
        let finalResponse = lines.map(obj => obj.message.content).join("");
        // Replace newline characters with <br> tags
        finalResponse = finalResponse.replace(/\n/g, '<br>');

        // Replace markdown bold syntax (**text**) with HTML bold tags
        finalResponse = finalResponse.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        finalResponse = finalResponse.replace(/```(.*?)```/gs, '<div class="code-block">$1</div>');

        // Extract metadata from the last response
        const lastEntry = lines[lines.length - 1];

        return {
            model: lastEntry.model,
            response: finalResponse,
            done: lastEntry.done,
            metadata: {
                created_at: lastEntry.created_at,
            }
        };

    }

    const resetChat = () => {
        setMessages([{
            "role": "system",
            "content": "You are a helpful assistant."
        }])
    }

    const updateContext = (e) => {
        const o = { ...context };
        o.content = e.target.value;
        setContext(o);

        var msg = [...messages];
        msg[0] = { ...o };
        setMessages(msg);
    }

    return (
        <div className='d-flex'>
            <div className='text-center ms-1 me-3'>
                {models &&
                    <Inputs.SelectGroup label='Models' options={models} value={model}
                        onChange={(e) => setModel(e.target.value)} />
                }

                <div className="form-floating">
                    <textarea className='form-control mb-3' value={context.content} onChange={(e) => setContext( e.target.value )}
                        onBlur={(e) => updateContext(e)} placeholder='Context' style={{ height: "500px" }} />
                    <label for="floatingTextarea">Context</label>
                </div>

                <button className='btn btn-secondary' onClick={resetChat}>New chat</button>
            </div>

            <div className='w-100'>
                <div>
                    <button className='btn btn-secondary me-3' onClick={() => changeContext("dev")}>Developper mode</button>
                    <button className='btn btn-secondary' onClick={() => changeContext("sav")}>Cust. client mode</button>
                </div>
                <div className='card ms-auto me-auto mb-3 overflow-y-auto' style={{ width: "100%", height: "500px" }}>
                    {messages.map(v => {
                        return v.role !== "system" && <div dangerouslySetInnerHTML={{ __html: v.content }}
                            style={{ width: "90%" }}
                            className={(v.role === "user" ?
                                "ms-auto bg-secondary-subtle p-2 fw-bold rounded"
                                : "me-auto") + " mb-3"} ></div>
                    })}
                </div>

                <form className='ms-auto me-auto' id="form" onSubmit={callLlamaChat} style={{ width: "100%" }}>
                    {!models && <WaitingRoundSnippers />}

                    <div className='mb-3'>
                        <textarea type="text"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            placeholder="Enter your prompt..."
                            className="form-control"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    callLlamaChat(e);
                                }
                            }}
                        />
                    </div>

                    <div className='text-center'>
                        <button type='submit' disabled={loading}
                            className='btn btn-outline-success'>
                            {loading && <ButtonWaiting />}
                            Submit</button>
                    </div>
                </form>


                {duration &&
                    <div className='text-center'>
                        Model <strong>{model}</strong>: <strong>{duration.toFixed(2)}</strong> milliseconds
                    </div>}
            </div>
        </div>
    );
};

export default IA;