import React, { Fragment } from 'react';
import { sPlural } from '../../../../functions/StrFunctions';
import Planning from './Planning';

const HomeV2 = ({ business, planning }) => {
    const label_w = 120;

    const SimpleDisplay = ({ label, value }) => {
        return <div className='d-flex mb-3 border rounded p-1 ms-auto me-auto' style={{ maxWidth: "550px" }}>
            <div className={'w-' + label_w + " border-end ms-1"}>{label}</div>
            <div className='fw-bold ms-1' dangerouslySetInnerHTML={{ __html: value }}></div>
        </div>
    }

    const openMap = () => {
        var address = business.business_info.adress_livrais +", ";
        if( business.business_info.adress_comp_livrais && business.business_info.adress_comp_livrais !== "" )
            address += business.business_info.adress_comp_livrais;
        address += business.business_info.cp_livrais +", " 
        + business.business_info.ville_livrais
        +", " + business.business_info.pays_livrais;

        const encodedAddress = encodeURIComponent(address);
        const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
        window.open(mapsUrl, '_blank'); 
    }

    return <div>
        <table className='w-100' >
            <tr>
                {/* Machine info */}
                <td style={{ verticalAlign: "top", width: "33.3%" }} className='pe-3'>
                    <SimpleDisplay label={"Machine"} value={business.machines.no_machine} />
                    <SimpleDisplay label={"Configuration"} value={business.machines.typ_config} />

                    <div className='d-flex mb-3 border rounded p-1 ms-auto me-auto' style={{ maxWidth: "550px" }}>
                        <div className={'w-' + label_w + " border-end mt-auto mb-auto ms-1"}>{sPlural("Caisse", business.caisse)}</div>
                        <div className='fw-bold ms-1'>
                            {business.caisse.length === 1 && <Fragment>
                                {business.caisse[0].l_dim_int + " x " + business.caisse[0].w_dim_int + " x "
                                    + business.caisse[0].h_dim_int + " - " + business.caisse[0].cannelure_caisse}
                            </Fragment>}

                            {business.caisse.length > 1 && business.caisse.map(v => {
                                return <li>{v.l_dim_int + " x " + v.w_dim_int + " x "
                                    + v.h_dim_int + " - " + v.cannelure_caisse}</li>
                            })}
                        </div>
                    </div>

                    <SimpleDisplay label={"Coiffe"} value={business.coiffe.cannelure_coiffe} />
                    <SimpleDisplay label={"Equipement"} value={business.machines.type_api} />

                </td>

                {/* Contrat info */}
                <td style={{ verticalAlign: "top", width: "33.3%" }} className='pe-3'>
                    <SimpleDisplay label={"Affaire"} value={business.machines.affaire} />
                    <SimpleDisplay label={"Client Projet"} value={business.business_info.customer_id} />
                    <SimpleDisplay label={"Client Final"} value={business.business_info.client_final} />
                    <SimpleDisplay label={"Produits"} value={business.business_info.produit_aff} />
                </td>

                {/* Map */}
                <td style={{ verticalAlign: "top", width: "33.3%" }}>
                    <SimpleDisplay label={"Société"} value={business.business_info.societe_livrais} />
                    <SimpleDisplay label={"Adresse"} value={business.business_info.adress_livrais} />
                    {(business.business_info.adress_comp_livrais && business.business_info.adress_comp_livrais !== "") &&
                        <SimpleDisplay label={"Adresse comp."} value={business.business_info.adress_comp_livrais} />}
                    <SimpleDisplay label={"CP / ville / pays"} value={business.business_info.cp_livrais +", " + business.business_info.ville_livrais 
                        +", " + business.business_info.pays_livrais} />
                    <div className='text-center'>
                        <a id="mapLink" href="#" onClick={openMap}>Ouvrir dans Google Maps</a>
                    </div>
                </td>
                    
            </tr>
        </table>

        <div>
            <Planning planning={planning}/>
        </div>
    </div>
};

export default HomeV2;