import { Fragment } from "react"

/**
 * @param setScanned
 */
const ScanReader = (p) => {
    let timer = null;
    
    const onScan = (e) => {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            var scanned = document.getElementById("scanIpt").value;
            document.getElementById("scanIpt").value = "";
            p.setScanned(scanned);
        }, 100);

    }

    return <Fragment>
        <input className="form-control text-center mb-3" onChange={onScan} autoComplete="off"
            placeholder="Cliquez ici pour commencer à scanner" id="scanIpt" autoFocus></input>
    </Fragment>

}

export default ScanReader;