import { forceString, getCurrentUser, getFrFormat } from "../../../../functions/StrFunctions";

//Get assembly tasks
export function getAssembly(bom, status, sort = true) {
    var arr = [];
    var o, ref = "";

    //Give NM but not FO-, IS-, HB-, TU-
    for (let i = 0; i < bom.length; i++) {
        o = bom[i];
        if (o.ref && o.ref.length > 17 && o.ref.startsWith("NM-")) {
            ref = o.ref.substring(0, 17);
            if (!ref.endsWith("FO-") && !ref.endsWith("IS-") && !ref.endsWith("HB-") && !ref.endsWith("TU-")) {
                o.code = ref.split("-")[2];
                o.station = "Montage";
                arr.push(o);
            }
        }
    }

    //Add cleat chain
    arr.push({
        "french_label": "CHAINE A TASSEAUX",
        "machine": bom && bom[0].machine,
        "se": "1GEG",
        "ref": "NM-XXXXXXXX-0GC3-000-A",
        "workstation": "Montage",
        "code": "0GC3",
        "station": "Montage"
    });

    //Add Gluing preparation if exists
    const index = bom.findIndex(obj => forceString(obj.french_label).toUpperCase().indexOf("ENCOLLAGE") !== -1);

    if (index !== -1) {
        arr.push({
            "french_label": "PRE ENCOLLAGE",
            "machine": bom && bom[0].machine,
            "se": "1GEG",
            "ref": "NM-XXXXXXXX-0GET-000-A",
            "workstation": "Montage",
            "code": "0GET",
            "station": "Montage"
        });
    }

    return getStatus(arr, status, sort);
}

// Get integration tasks
export function getIntegration(bom, workstation, status, sort = true) {
    var arr = [];
    var o, ref = "";

    //Give SE like %EG and ar like AR% and ref not like PR%
    for (let i = 0; i < bom.length; i++) {
        o = bom[i];
        if (o.ref && o.ref.length > 17) {
            ref = o.ref.substring(0, 17);
            if (o.se.endsWith("EG") && !ref.startsWith("PR-") && forceString(o.ar).startsWith("AR")) {
                o.code = ref.split("-")[2];
                o.station = workstation;
                arr.push(o);
            }
        }
    }

    return getStatus(arr, status, sort);
}

// Get carterisation
export function getCarterisation( bom, status, sort = true ) {
    var arr = [];
  
    //Want to merge all subsets in one
    arr.push({
        "french_label": "Cartérisation",
        "machine": bom && bom[0].machine,
        "se": "1GEG",
        "ref": "NM-XXXXXXXX-CART-000-A",
        "workstation": "Montage",
        "code": "CART",
        "station": "Cartérisation"
    });

    return getStatus(arr, status, sort);
}

// B+ installer
export function getInsideInstaller(bom, status, sort = true) {
    var arr = [];
  
    arr.push( getCommissTask(bom, "Programme & Test des E/S", "0TES", "Mise en service B+") )
    arr.push( getCommissTask(bom, "Réglages méca. transfert/cavité", "0RGT", "Mise en service B+") )
    arr.push( getCommissTask(bom, "Réglages méca. convoyeur à tasseaux", "0RGC", "Mise en service B+") )
    arr.push( getCommissTask(bom, "Réglages méca. poste de marquage", "0RGM", "Mise en service B+") )
    arr.push( getCommissTask(bom, "Réglages méca. poste de pliage", "0RGP", "Mise en service B+") )

    arr.push( getCommissTask(bom, "FAT interne", "0FAT", "Mise en service B+") )
    arr.push( getCommissTask(bom, "FAT client", "1FAT", "Mise en service B+") )
    arr.push( getCommissTask(bom, "Démontage machine", "1DMM", "Mise en service B+") )

    return getStatus(arr, status, sort);
}

// Get itinerant installer
export function getExternalInstaller(bom, status, sort = true) {
    var arr = [];
    arr.push( getCommissTask(bom, "Installation machine", "0INM", "Installation site client") )
    arr.push( getCommissTask(bom, "Mise en service", "0MES", "Installation site client") )
    arr.push( getCommissTask(bom, "Mise en production", "0MEP", "Installation site client") )
    
    return getStatus(arr, status, sort);
}


function getCommissTask(bom,  label, code, typeInstaller){
    return {
        "french_label": label,
        "machine": bom && bom[0].machine,
        "se": "1GEG",
        "ref": "OP-XXXXXXXX-"+code+"-000-A",
        "workstation":  typeInstaller,
        "code": code,
        "station": typeInstaller
    }
}

/**********************************************************************
 * FUNCTIONS
 **********************************************************************/

function getStatus(arr, status, sort = true) {
    var machine, ref, station;

    for (let i = 0; i < arr.length; i++) {
        machine = arr[i].machine;
        ref = arr[i].ref;
        station = arr[i].station;

        var o = { ...arr[i], ...getMachineStatus(machine, ref, station, status) };
        arr[i] = { ...o };
    }

    if( sort ) arr.sort((a, b) => { return a.rank - b.rank });

    return arr;
}

function getMachineStatus(machine, ref, station, statusArr) {
    var status = {
        machineStatus: "En attente",
        machineStatusBg: getBg("En attente"),
        userStatus: "En attente",
        userStatusBg: getBg("En attente"),
        summary: [],
        rank: 1
    };

    for (let j = 0; j < statusArr.length; j++) {
        if (machine.trim() === statusArr[j].machine.trim() && ref.trim() === statusArr[j].task.trim() &&
            station.trim() === statusArr[j].workstation.trim()) {
            status.machineStatus = statusArr[j].machineStatus;
            status.machineStatusBg = getBg(statusArr[j].machineStatus);
            status.userStatus = getUserStatus(statusArr[j].summary);
            status.userStatusBg = getBg(status.userStatus);
            status.summary = getSummary(statusArr[j].summary);
            status.rank = getRank(status.userStatus);
            status.start = statusArr[j] ? getFrFormat(statusArr[j].start, true) : ""
            status.end = statusArr[j] ? getFrFormat(statusArr[j].end, true) : ""
        }

    }

    return status;
}

function getRank(status) {
    if (status === "Bloqué") return 0;
    if (status === "Démarré" || status === "Redémarré") return 1;
    if (status === "Pause") return 2;
    if (status === "En attente") return 3;
    if (status === "Terminé") return 4;
}

function getSummary(summary) {
    var arr = [];

    if (summary) {
        var split = summary.split(",");
        for (let i = 0; i < split.length; i++) {
            var split2 = split[i].split("-");
            arr.push({ trigram: split2[2], status: split2[1], user_id: split2[0], block_cause: split2[3] });
        }
    }

    return arr;
}

function getUserStatus(summary){
    var status = "En attente";
    var user = getCurrentUser()

    if( summary ){
        var id = user.id;
        var split = summary.split(",");
        for( let i=0; i<split.length; i++ ){
            var id = parseInt(split[i].substring(0, split[i].indexOf("-")));
            if( id === parseInt(user.id) ) return split[i].split("-")[1];
        } 
    }

    return status;
}

function getBg(status) {
    if (!status) return {};

    if (status === "En attente") {
        return { backgroundColor: "rgb(87, 155, 252)", color: "white" }
    } else if (status === "Démarré" || status === "Redémarré") {
        return { backgroundColor: "rgb(253,171,61)" }
    } else if (status === "Terminé" || status === "Chgt. pers.") {
        return { backgroundColor: "rgb(156, 211, 38)" }
    } else if (status === "Bloqué") {
        return { backgroundColor: "rgb(223, 47, 74)", color: "white" }
    } else if (status === "Pause") {
        return { backgroundColor: "rgb(255, 240, 36)" }
    }
}

