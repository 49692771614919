import axios from "axios";
import authHeader from "../auth-header";
import textPlainHeader from "../fake-header";

const API_URL = process.env.REACT_APP_API_URL + "production/tracking";
const user = JSON.parse(localStorage.getItem("user"));
const token = user && "\"Bearer " + user.accessToken + "\"";

const getTask = (trigram, machine) => {
    return axios
        .post(API_URL + "/get",
            {
                fake_header: authHeader(),
                trigram: trigram,
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const createTask = (o) => {
    return axios
        .post(API_URL + "/create-task",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const createTaskV2 = (o) => {
    return axios
        .post(API_URL + "/create-task/v2",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getActivities = (machine, workstation) => {
    return axios
        .post(API_URL + "/get-activities",
            {
                fake_header: authHeader(),
                workstation: workstation,
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTrackingList = () => {
    return axios
        .post(API_URL + "/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTrackingListDetail = (o) => {
    return axios
        .post(API_URL + "/list/detail",
            {
                fake_header: authHeader(), ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const deleteRow = (id) => {
    return axios
        .post(API_URL + "/list/delete",
            {
                fake_header: authHeader(),
                id:id
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateField = (o) => {
    return axios
        .post(API_URL + "/list/update",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

/**
 * V2
 */

const getTrackingListV2 = () => {
    return axios
        .post(API_URL + "/v2/list",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTrackingOverview = () => {
    return axios
        .post(API_URL + "/v2/overview",
            {
                fake_header: authHeader()
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const getTrackingInfo = (trigram, machine) => {
    return axios
        .post(API_URL + "/get/v2",
            {
                fake_header: authHeader(),
                trigram: trigram,
                machine: machine
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const saveTask = (o, url) => {
    return axios
        .post(API_URL + "/create-task/v2/" + url,
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

const updateStatus = (o) => {
    return axios
        .post(API_URL + "/list/update/machine-status",
            {
                fake_header: authHeader(),
                ...o
            },
            { headers: textPlainHeader() })
        .then((response) => {
            return response.data;
        });
}

export default {
    getTask,
    createTask,
    createTaskV2,
    getActivities,
    getTrackingList,
    getTrackingListDetail,
    deleteRow,
    updateField,
    //v2
    getTrackingListV2,
    getTrackingOverview,
    getTrackingInfo,
    saveTask,
    updateStatus
}