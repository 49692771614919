import { Fragment, useEffect, useState } from "react";
import SuccessMessage from "../../common/smallComponents.js/SuccessMessage";
import WaitingRoundSnippers from "../../common/smallComponents.js/WaitingRoundSnippers";
import ErrorMessage, { WarningMsg, WarningMsgObj } from "../../common/smallComponents.js/ErrorMessage";
import CsContracts from "../../services/customClient/CsContracts";
import errorManagement from "../../services/errorManagement";
import { ButtonWaiting, scrollToTop, setPopUp } from "../../functions/PageAnimation";
import { createColumnHelper } from "@tanstack/react-table";
import Moment from "moment";
import ReactTable from "../../common/smallComponents.js/ReactTable";
import { confirmAlert } from "react-confirm-alert";
import Packing from "./smallContent/Packing";
import getUrlParam, { getDateTimeFromDb, getFrFormat, getPreviousMonth, getPreviousYear, hasRole, sPlural } from "../../functions/StrFunctions";
import { SideNavBarV3, isSideBarOpened } from "../../common/smallComponents.js/DesignedIpt";
import { ProgressBar } from "react-bootstrap";
import CustomToolTip from "../../common/smallComponents.js/CustomToolTip";
import { downloadTempFile } from "../../services/functions/FilesProcess";
import { addOrRemoveOnCheck } from "../../functions/ArrayFunctions";
import transport from "../../services/supplyChain/transport";
import Inputs from "../../common/smallComponents.js/Inputs";

const PickingList = (props) => {
    const [pin, setPin] = useState(isSideBarOpened());
    const [error, setError] = useState();
    const [msg, setMsg] = useState();
    const [warning, setWarning] = useState();
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState();
    const [businessPack, setBusinessPack] = useState();
    const [searchPack, setsearchPack] = useState(false);

    Moment.locale("fr");
    const user = JSON.parse(localStorage.getItem("user"));
    const isStorehouse = user && (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_STOREHOUSE"));
    const isAdmin = user && user.roles.includes("ROLE_ADMIN");
    const isCommissioning = hasRole(["ROLE_COMMISSIONING"])

    
    const [toShip, setToShip] = useState([]);

    useEffect(() => {
        CsContracts.getPickingList(getUrlParam(props, "displayAll") !== null).then(
            (response) => {
                setList(response);
                if (!isStorehouse || isAdmin) searchPackings(response);
                setLoading(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setLoading(false);
                scrollToTop();
            }
        )

        if (hasRole(["ROLE_CUSTOMER_CLIENT", "ROLE_ADMIN"])) {
            CsContracts.getMissingDBusinessList().then(
                (response) => {
                    setWarning(
                        <div>Attention des affaires spares vendues avec les machines n'ont pas été traitées sur Focus:<br />
                            <div className="accordion ms-auto me-auto" id="accordionExample" style={{width: "600px"}}>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed text-center fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Détail
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Affaire</th>
                                                        <th>Date de livraison</th>
                                                        <th>Alerte</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {response.map((v, k) => {
                                                        return <tr key={"miss_" + k}>
                                                            <td>{v.id_aff}</td>
                                                            <td>{v.date_livraison && getFrFormat(v.date_livraison, true)}</td>
                                                            <td>{v.alert}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )

                },
                (error) => {
                    console.error(error)
                }
            )
        }

    }, []);

    function searchPackings(arr) {
        setsearchPack(true);
        CsContracts.getPackingBusiness(arr).then(
            (response) => {
                setList(response)
                setsearchPack(false);
            },
            (error) => {
                setError(errorManagement.handleError(props, error));
                setsearchPack(false);
                scrollToTop();
            }
        )
    }

    /** */
    const updateField = (e, k, field) => {
        var value = e.target.value;

        if (field === "status" && (value === "ANNULE" || value === "COMPLET")) {
            confirmAlert({
                title: "Fin de picking",
                message: "Confirmez-vous l'état d'envoi à " + value + ", CECI EFFACERA TOUS LES BESOINS DE CETTE AFFAIRE",
                buttons: [
                    {
                        label: "Oui",
                        onClick: () => {
                            CsContracts.closeNeed(list[k].business, value);
                            updDb(value, k, field);
                        }
                    },
                    {
                        label: "Non"
                    }
                ]
            });
        } else {
            updDb(value, k, field)
        }

    }

    function updDb(value, k, field) {
        var arr = [...list];
        arr[k][field] = value;
        setList(arr);

        CsContracts.updatePickingList(arr[k], field).then(
            () => { },
            (error) => {
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    function getProgressionBar(progress) {
        progress = (!progress || isNaN(progress)) ? 0 : progress.toFixed(0);

        return <ProgressBar animated now={progress} label={`${progress}%`} />;
    }

    function getAlert(val) {
        if (val === "Ok") {
            return <div className="rounded-circle bg-success bg-gradient ms-auto me-auto"
                style={{ width: "30px", height: "30px" }}>&nbsp;</div>
        } else {
            val = val.replace(",Ok", "").replace("Ok,", "");
            if (val === "Date de livraison < date du besoin") {
                <div className="rounded-circle bg-warning bg-gradient ms-auto me-auto"
                    style={{ width: "30px", height: "30px" }}>&nbsp;</div>
            } else {
                return <div className="rounded-circle bg-danger bg-gradient ms-auto me-auto text-danger cursor-pointer"
                    style={{ width: "30px", height: "30px" }}>
                    <CustomToolTip props={
                        { tooltipText: val, originText: "-" }} />
                </div>
            }
        }
    }

    /** */
    const getColumns = () => {
        var arr = new Array();
        const columnHelper = createColumnHelper();

        arr.push(columnHelper.accessor('alert', {
            header: 'Alertes',
            cell: i => <div className={"text-center "}>{getAlert(i.getValue())}</div>
        }));

        arr.push(columnHelper.accessor('xxxx', {
            header: 'Statut',
            cell: i => <div className={"text-center "}>
                {i.row.original.status === "COMPLET" ? "Commande expédiée" :
                    (((i.row.original.qte_recu_corr - i.row.original.nc_qty) / i.row.original.cmd_besoin) * 100) >= 100 ? "Picking terminé" :
                        (i.row.original.qte_picked_corr && i.row.original.qte_picked_corr > 0) ? "Picking en cours" :
                            (i.row.original.cmd_besoin && i.row.original.cmd_besoin > 0) ? "En commande achats" :
                                (i.row.original.alert.indexOf("Picking non lanc") !== -1) ? "Picking lancé" :
                                    "Besoins crées"
                }

            </div>
        }));

        arr.push(columnHelper.accessor('business', {
            header: 'Affaires',
            cell: i => <div className={"text-center "}>
                {i.row.original.origin === "bp_besoins_mes_detail" && <Fragment>
                    <a href={"/customer-client/picking/commissioning?business=" + i.getValue()} target="_blank">{i.getValue()}</a>
                    <div className="fst-italic">MeS</div>
                </Fragment>
                }

                {i.row.original.origin === "bp_besoins_sav_detail" &&
                    <a href={"/customer-client/picking?business=" + i.getValue()} target="_blank">{i.getValue()}</a>
                }
            </div>
        }));

        arr.push(columnHelper.accessor('cdage', {
            header: 'CDP',
            cell: i => <div className={"text-center "}>{i.getValue()}</div>
        }));


        arr.push(columnHelper.accessor('delivery_date', {
            header: 'Date d\'expédition',
            cell: i => <div className={"text-center "}>
                {   i.getValue().indexOf("T") === -1 ?
                    getFrFormat( i.getValue(), true ) :
                    Moment(i.getValue().substring(0, i.getValue().indexOf("T"))).format("DD/MM/yyyy")}</div>
        }));


        arr.push(columnHelper.accessor('x', {
            header: 'Garantie',
            cell: i => <div className={"text-center "}>
                {i.row.original.business.endsWith("G") ? "X" : ""}
            </div>
        }));


        arr.push(columnHelper.accessor('type_pack', {
            header: 'Conditionnement',
            cell: i => <div className="text-center">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" value="box" name={"pack" + i.row.id}
                        checked={i.getValue() === 'box'} onChange={(e) => updateField(e, i.row.id, "type_pack")} />
                    <label className="form-check-label">Boite</label>
                </div>

                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" value="carton" name={"pack" + i.row.id}
                        checked={i.getValue() === 'carton'} onChange={(e) => updateField(e, i.row.id, "type_pack")} />
                    <label className="form-check-label">Carton</label>
                </div>
            </div>
        }));

        function getBg(val) {
            if (val === "COMPLET") return "rgb(146,208,80)"
            if (val === "PARTIEL") return "rgb(255,192,0)"
            if (val === "ANNULE") return "rgb(146,0,0)"
            return "transparent";
        }

        arr.push(columnHelper.accessor('status', {
            header: 'État de l\'envoi',
            cell: i => <div className={"text-center "}>
                <select className={"form-select " + (i.getValue() === "ANNULE" ? "text-white" : "")} value={i.getValue()}
                    style={{ backgroundColor: getBg(i.getValue()) }}
                    onChange={(e) => updateField(e, i.row.id, "status")}>
                    <option value=""></option>
                    <option value="COMPLET">Complet</option>
                    <option value="PARTIEL">Partiel</option>
                    <option value="ANNULE">Annulé</option>
                </select>
            </div>
        }));

        arr.push(columnHelper.accessor('qte_initiale', {
            header: 'Qté demandée',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('cmd_besoin', {
            header: 'Qté en cmd sur aff.',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('last_order_need_date', {
            header: 'Dernière livraison aff.',
            cell: i => <div 
                className={"text-center " 
                    + (i.row.original.alert.indexOf("La commande aff. arrivera après la date demandée par le SAV") !== -1 
                    ? "text-danger" : "text-success"
                )}>
                {getFrFormat(i.getValue(), true)}
                </div>
        }));

        arr.push(columnHelper.accessor('cmd_stck', {
            header: 'Qté en cmd sur stck.',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('last_order_stck_date', {
            header: 'Dernière livraison stck',
            cell: i => <div className="text-center">{getFrFormat(i.getValue(), true)}</div>
        }));

        arr.push(columnHelper.accessor('qte_picked_corr', {
            header: 'Qté mouvementée',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('qte_recu_corr', {
            header: 'Qté reçue en cmd',
            cell: i => <div className="text-center">
                {i.getValue()}
                {i.row.original.nc_qty > 0 &&
                    <div className="fst-italic text-danger">{i.row.original.nc_qty} NC</div>}
            </div>
        }));

        arr.push(columnHelper.accessor('xxx', {
            header: 'Prog. recpt. pièces',
            cell: i => getProgressionBar(
                ((i.row.original.qte_recu_corr - i.row.original.nc_qty) / i.row.original.cmd_besoin) * 100)
        }
        ));

        arr.push(columnHelper.accessor('global_progress', {
            header: 'Prog. picking',
            cell: i => getProgressionBar(i.getValue())
        }
        ));

        arr.push(columnHelper.accessor('remain', {
            header: 'Qté restante à traiter',
            cell: i => <div className="text-center">{i.getValue()}</div>
        }));

        arr.push(columnHelper.accessor('affected', {
            header: 'Sortie possible',
            cell: i => <div className="text-center">
                {i.getValue() > 0 && <img src="/common/check.png" style={{ maxWidth: "30px" }}></img>}
            </div>
        }));

        arr.push(columnHelper.accessor('comments', {
            header: 'Commentaires',
            cell: i => <div className={"text-center "}>
                <textarea className="form-control" defaultValue={i.getValue()}
                    onBlur={(e) => updateField(e, i.row.id, "comments")}></textarea>
            </div>
        }));

        if (!isStorehouse || isAdmin) {
            arr.push(columnHelper.accessor('del_note', {
                header: 'BL / Factures',
                cell: i => <div className="text-center">
                    <div>{i.getValue()}</div>
                    <div>{i.row.original.bill_nb}</div>
                </div>
            }));
        }

        arr.push(columnHelper.accessor('preassembly_notif', {
            header: 'Date de notif pre-assemblage',
            cell: i => <div className="text-center">
                <div>{getFrFormat(i.getValue(), true)}</div>
            </div>
        }));



        arr.push(columnHelper.accessor('xx', {
            header: '',
            enableColumnFilter: false,
            enableSorting: false,
            cell: i => <div className="text-center">
                {isStorehouse && <div>
                    <button className="btn btn-outline-info"
                        onClick={() => doPacking(i.row.id)}>FDC</button>
                </div>}
                {searchPack && <div><ButtonWaiting />Recherche de colis</div>}
                {i.row.original.pack_id && <div style={{ fontSize: "12px" }}>{i.row.original.pack_date}</div>}
            </div>
        }));

        if(isStorehouse  ){
            arr.push(columnHelper.accessor('xxx', {
            header: 'FDC',
            enableColumnFilter: false,
            enableSorting: false,
            cell: i => <div className="text-center">
                <input type="checkbox" className="form-check-input big"
                    onChange={ (e) => checkToSend(e, i.row.id) } checked={ toShip.includes( parseInt( i.row.id ) ) }/>
            </div>
        }));
        }
        

        return arr;
    }

    const doPacking = (k) => {
        setBusinessPack({
            row: list[k],
            business: list[k].business
        })
    }

    //Set columns
    const [columns, setColomns] = useState( getColumns() );

    useEffect( () => { setColomns( getColumns() ) }, [toShip, list] )

    const exportStats = (e) => {
        e.preventDefault();

        var month = e.target.month.value;
        var year = e.target.year.value;

        setLoading(true);
        CsContracts.generateSparesStats(month, year).then(
            (res) => {
                setLoading(false);
                downloadTempFile(res);
            },
            (error) => {
                setLoading(false);
                setError(errorManagement.handleError(props, error));
                scrollToTop();
            }
        )
    }

    const checkToSend = ( e, k ) => {
        var arr = [...toShip];

        if( e.target.checked ){
            arr.push( parseInt( k ) );
        }else{
            arr = arr.filter( v => parseInt( v ) !== parseInt( k ) )
        }

        setToShip( arr );
    }

    const createShipping = () => {
        var msg = "Confirmez-vous l'envoi ";
        msg += toShip.length > 1 ? "groupé de " + toShip.length + " affaires" : "de cette affaire";
        msg += "? Ceci créera un envoi pour l'ensemble des articles qui n'ont pas déjà été envoyés."

        confirmAlert({
            title: "Confirmation",
            message: msg,
            buttons: [
                {
                    label: "Oui, je confirme",
                    onClick: () => {
                        var business = [];
                        for( let i = 0; i< toShip.length; i++ ) business.push( list[ toShip[i] ].business )
                        setLoading(true)
                        transport.createCompleteProcessByBusiness( business.join(",") ).then(
                            (res) => {
                                var url = process.env.REACT_APP_URL
                                + "supply-chain/tools/expedition?page=doc&shipment_id=" + res.shipment_id
                                + "&id=" + res.id
                                + "&picking_id=" + res.picking_id
                                + "&type=other";

                                window.location.href = url;
                            },(error) => {
                                setPopUp( errorManagement.handleError(props, error), "danger" )
                                setLoading(false)
                            }
                        )
                            
                    }
                },
                {
                    label: "Non, je fais le picking à la douchette",
                    onClick: () => {
                        var business = [];
                        for( let i = 0; i< toShip.length; i++ ) business.push( list[ toShip[i] ].business )
                        setLoading(true)
                        transport.createCompleteProcessByBusiness( business.join(","), true ).then(
                            (res) => {
                                var url = process.env.REACT_APP_URL
                                + "supply-chain/tools/expedition?page=doc&shipment_id=" + res.shipment_id
                                + "&id=" + res.id
                                + "&picking_id=" + res.picking_id
                                + "&type=other";

                                window.location.href = url;
                            },(error) => {
                                setPopUp( errorManagement.handleError(props, error), "danger" )
                                setLoading(false)
                            }
                        )
                    }
                },
                {
                    label: "Annuler"
                }
            ]
        });
    }

    return <div className="bg-see custom-bootstrap" style={{ height: "91.5vh", marginTop: "-1.5em" }}>
        <SideNavBarV3 setPin={setPin} content={<div>

            { toShip.length > 0 && <div>
                <h6 className="text-center">Expédier {toShip.length + " " + sPlural( "affaire", toShip )}</h6>

                <Inputs.SubmitBtn label={"Commencer"} loading={loading} onClick={createShipping} 
                    divClass="mb-3 text-center"/>

                <div className="h-divider"><div className="shadow-divider"></div></div>
                </div>}

            <h6 className="text-center">Créer ou chercher un picking</h6>

            <form action={"/customer-client/picking" + (isCommissioning ? "/commissioning" : "")}
                className="col-10 offset-1 mb-3">
                <div className="input-group mb-3">
                    <input type="text" className="form-control text-center fw-bold" name="business" placeholder="Code affaire" />
                </div>
                <div className="text-center">
                    <button type="submit" className="btn btn-outline-success">Rechercher</button>
                </div>
            </form>

            {isAdmin && <Fragment>
                <h6 className="text-center">Créer ou chercher un picking (Mes)</h6>

                <form action="/customer-client/picking/commissioning" className="col-10 offset-1 mb-3">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control text-center fw-bold" name="business" placeholder="Code affaire" />
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-outline-success">Rechercher</button>
                    </div>
                </form>
            </Fragment>}

            <div className="h-divider"><div className="shadow-divider"></div></div>

            {getUrlParam(props, "displayAll") === null &&
                <div className="text-center">
                    <a className="fw-bold" href="/customer-client/picking/list?displayAll=true">
                        Cliquez ici pour afficher les affaires envoyées/annulées</a>
                </div>
            }

            {getUrlParam(props, "displayAll") !== null &&
                <div className="text-center">
                    <a className="fw-bold" href="/customer-client/picking/list">
                        Cliquez ici pour masquer les affaires envoyées/annulées</a>
                </div>
            }

            <form onSubmit={exportStats} className="mt-3 ps-2 pe-2">
                <br></br>
                <h6 className="text-center">Stats. des spares</h6>

                <div className="input-group mb-3">
                    <input type="number" className="form-control text-center fw-bold" placeholder="Mois" name="month"
                        defaultValue={getPreviousMonth()} />
                    <input type="number" className="form-control text-center fw-bold" placeholder="Année" name="year"
                        defaultValue={getPreviousYear()} />
                    <button type="submit" className="btn btn-success" disabled={loading}>
                        {loading && <ButtonWaiting />}
                        Exporter</button>
                </div>
            </form>

        </div>} />

        <div id="main" className={"white-bg table-responsive " + (pin ? "pin" : "")}>
            <h5 className="text-center no-print">Affaires Service Clients</h5>
            {warning && <WarningMsgObj warning={warning} warningState={setWarning} />}

            {error && <ErrorMessage error={error} />}
            {msg && <SuccessMessage msg={msg} msgState={setMsg} />}
            {loading && <WaitingRoundSnippers />}

            {(list && !businessPack) &&

                <ReactTable columns={columns} data={list} show={100}
                    origin="picking_list" classes={"fixFstCol"} top0={true} />}

            {businessPack && <Packing businessPack={businessPack.business} row={businessPack.row}
                setBusinessPack={setBusinessPack} setError={setError} setMsg={setMsg} />}

        </div>
    </div>
}

export default PickingList;