import { Fragment, useEffect, useState } from "react"

/**
 * Simple divider
 */
export const Divider = () => {
    return <div className="divider-v2">
        <div class="h-divider"><div class="shadow"></div></div>
    </div>
}

/**
 * p.text
 * @param {*} p 
 */
export const DividerWithTxt = (p) => {
    return <div className="divider-v2">
        <div class="h-divider">
            <div class="shadow"></div>
            <div class="text"><i dangerouslySetInnerHTML={{ __html: p.text }}></i></div>
        </div>
    </div>
}

{/**
    component = object
    close = function
*/}
export const SmallFramecopy = ({ component, close }) => {
    const [position, setPosition] = useState({ x: 0, y: 100 });
    const [size, setSize] = useState({ width: 600, height: 150 });
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [startDrag, setStartDrag] = useState({ x: 0, y: 0 });
    const [startSize, setStartSize] = useState({ width: 0, height: 0 });

    const RESIZE_BORDER_WIDTH = 20;

    useEffect(() => {
        var centerX = (window.innerWidth - size.width);
        centerX -= centerX / 2;
        const centerY = window.innerHeight / 2 - size.height / 2;
        console.log(centerX)
        // setPosition({ x: centerX, y: centerY });
    }, [size.width, size.height]);

    useEffect(() => {
        const modalBody = document.querySelector('.modal-body');


    }, [])

    const handleMouseDown = (e) => {
        const modalBody = document.querySelector('.modal-body');
        console.log((modalBody.clientWidth / 2) - position.x);
        const rect = modalBody.getBoundingClientRect();
        const isOnRightEdge = e.clientX >= rect.right - RESIZE_BORDER_WIDTH;
        const isOnBottomEdge = e.clientY >= rect.bottom - RESIZE_BORDER_WIDTH;
        if (isOnRightEdge || isOnBottomEdge) {

            setIsResizing(true);
            setStartSize({ width: size.width, height: size.height });
            setStartDrag({ x: e.clientX, y: e.clientY });
        } else {
            setIsDragging(true);
            setStartDrag({ x: e.clientX - position.x, y: e.clientY - position.y });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const newX = e.clientX - startDrag.x;
            const newY = e.clientY - startDrag.y;
            setPosition({ x: newX, y: newY });

        }

        if (isResizing) {
            const newWidth = startSize.width + (e.clientX - startDrag.x);
            const newHeight = startSize.height + (e.clientY - startDrag.y);
            setSize({ width: Math.max(100, newWidth), height: Math.max(100, newHeight) });
            // setPosition({x : e.clientX + (e.clientX - newWidth- startDrag.x), y: position.y}) 
            const widthDiff = newWidth - startSize.width;
            const heightDiff = newHeight - startSize.height;
            setPosition((prevPosition) => ({
                x: prevPosition.x - widthDiff / 2,
                y: prevPosition.y - heightDiff / 2,
            }));
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setIsResizing(false);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, isResizing, startDrag, startSize]);

    return (<div className="custom-tooltip-v2 resizable-movable" >
        <div
            className="modal-body blue-purple-bg"
            style={{
                position: "absolute", top: position.y, left: position.x, width: size.width,
                height: size.height, userSelect: "none", cursor: isResizing ? "se-resize" : "move",
                boxSizing: "border-box"
            }}
            onMouseDown={handleMouseDown}
        >
            <div>
                <button type="button" className="btn-close" onClick={close}></button>
            </div>

            {component}

        </div>
    </div>)


}

export const SmallFrame = ({ component, close }) => {
    return <div className="custom-tooltip-v2">
        <div className="modal-body xl modal-div bg-light">
            <button type="button" className="btn-close" onClick={close}></button>
            <div style={{ maxHeight: "75vh" }} className="overflow-y-auto">
                {component}
            </div>
        </div>
    </div>

}

export const AccordionSingle = ({ title, body, acc_id='0' }) => {
    return <div className="accordion" id={"accordion" + acc_id}>
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" 
                    data-bs-target={"#collapse" + acc_id} aria-expanded="false" aria-controls={"collapse" + acc_id}>
                    <div className="fw-bold text-center" style={{ width: "100%" }}>
                        {title}
                    </div>
                </button>
            </h2>
            <div id={"collapse" + acc_id} className="accordion-collapse collapse" data-bs-parent={"#accordion" + acc_id}>
                <div className="accordion-body">
                    <div>{body}</div>
                </div>
            </div>
        </div>
    </div>
}